import React from "react";
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';

import DraggableDialog from "../DraggableDialog/DraggableDialog";


function ServerLogs(props) {
  const { t } = useTranslation();

  return (
    <DraggableDialog
      maxWidth="sm"
      title={t('Console.header')}
      open={props.open}
      actions={[
        { onClick: props.onClose, color: "primary", label: t('Console.close') },
      ]}
      onClose={props.onClose}
    >
      
      <TextField
          id="outlined-multiline-static"
          multiline
          minRows={12}
          fullWidth
          variant="outlined"
          value={props.text}
        />

    </DraggableDialog>
  );
}

export default ServerLogs;
