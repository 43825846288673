import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  formControl: {
    marginRight: '36px',
    marginBottom: '6px',
    width: 'max-content',
  },

  checkboxLabels: {
    fontSize: theme.fonts.responsiveSmall,
    wordBreak: 'break-word',
    textTransform: 'capitalize',
  },

  operatorsLabels: {
    fontSize: theme.fonts.responsiveSmall,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    whiteSpace: 'wrap',
    marginRight: '25px',
  },

  optionsGridContainer_medium: {
    display: 'grid',
    width: '542px',
    marginLeft: '24px',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  optionsGridContainer_small: {
    display: 'grid',
    width: '542px',
    marginLeft: '24px',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },

  optionsGridContainer_xsmall: {
    display: 'grid',
    width: '542px',
    marginLeft: '24px',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
}));
