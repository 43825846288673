export default function emptyCache(){
    if('caches' in window){
        caches.keys().then(names => {
            if(names.length) {
                names.forEach(name => {
                    caches.delete(name);
                })
                window.location.reload(true);
            }
        });
    }
}