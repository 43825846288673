import React from "react";
import { makeStyles } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles(theme => (
    {
        Accordion: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            minHeight: '60px',
          },

          AccordionNested: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            minHeight: '40px',
          },
          
          Header: {
            background: theme.palette.background.paper,
            width: '100%',
            minHeight: '60px',
            height: '60px',
            border: '2px solid',
            borderColor: theme.palette.background.default,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          },

          NestedHeader: {
            background: theme.palette.background.default,
            width: '100%',
            minHeight: '40px',
            height: '40px',
            border: '2px solid',
            borderColor: theme.palette.background.paper,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },

          headerName: {
            marginLeft: '5%',
            marginRight: 'auto',
            fontSize: theme.fonts.responsiveMedium,
            textTransform: 'uppercase'
          },

          Icon: {
            marginRight: '3%'
          },
          
          Content: {
            background: '#eee',
            width: '100%',   
            transformOrigin: 'top',
            transition: 'max-height 0.4s'
          },
          
          open: {
            maxHeight: '30vh',
            overflow: 'auto',
          },
          
          close: {
            maxHeight: 0,
          },
          
    }
))

const Accordion = (props) => {
  const classes = useStyles();

  const { open, setOpen, nested, name, children } = props;

  const contentStyle = [classes.Content];
  contentStyle.push(open ? classes.open : classes.close);

  return (
    <div
      className={nested ? classes.AccordionNested : classes.Accordion}
    >
      <div className={nested ? classes.NestedHeader : classes.Header}
           onClick={setOpen}
      >
          <p className={classes.headerName}>{name}</p>
          {open ? <ExpandLessIcon className={classes.Icon} /> 
                : <ExpandMoreIcon className={classes.Icon} />}
          </div>
      <div className={contentStyle.join(" ")}>{children}</div>
    </div>
  );
};

export default Accordion;