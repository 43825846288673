import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { useStyles } from './ChartCard.style';

function ChartCard(props) {
  const classes = useStyles();

  return (
    <Card className={props.CardClass} key='exams'>
      <CardHeader
        className={classes.CardHeader}
        classes={props.classes}
        title={props.title}
        subheader={props.subheader}
        action={props.action}
        classes={{
          title: classes.title,
          subheader: props.uniqueStyle ? classes.subheader_unique : classes.subheader,
          action: classes.action,
        }}
      />

      <CardContent className={props.CardContentClass}>
        {props.loading ? (
          <CircularProgress
            color='primary'
            style={{
              width: 'min(30%, 10vh)',
              height: 'auto',
              opacity: 0.7,
            }}
          />
        ) : (
          props.children
        )}
      </CardContent>
    </Card>
  );
}

export default ChartCard;
