import { createSlice } from '@reduxjs/toolkit';

import { formatDate } from '../../helpers/formatDate';

const initialState = {
  customDate: [
    formatDate(new Date(), '00:00'),
    formatDate(new Date(), '23:59'),
  ],
  compressionDates: null,
  performanceDates: null,
  selectedRange: '',
};

export const datesSlice = createSlice({
  name: 'filterDates',
  initialState,
  reducers: {
    // setDates: (state, action) => {
    //   state.dates = action.payload;
    // },
    setCustomDate: (state, action) => {
      state.customDate = action.payload;
    },
    setSelectedRange: (state, action) => {
      state.selectedRange = action.payload;
    },
    setCompressionDates: (state, action) => {
      state.compressionDates = action.payload;
    },
    setPerformanceDates: (state, action) => {
      state.performanceDates = action.payload;
    },
    resetDatesFilters: (state, _) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  // setDates,
  setCustomDate,
  setSelectedRange,
  resetDatesFilters,
  setCompressionDates,
  setPerformanceDates,
} = datesSlice.actions;

export default datesSlice.reducer;
