import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import {
  Typography,
  TableRow,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  Grid,
  TextField,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchPost } from '../../../../../../helpers/fetching';
import { useStyles } from '../../CustomFilter.style';
import { setSnackbar } from '../../../../../../store';
import { Search, Clear } from '@material-ui/icons';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export default function SearchPatients(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const [inputText, setInputText] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [retrievedUsers, setRetrievedUsers] = useState([]);
  const [openTooltip, setOpenTooltip] = useState('');
  const filterPrompt = useSelector((state) => state.filter.search);

  const tableHeader = [
    { name: 'examId', label: t('Filters.examId'), align: 'left', padding: 'none' },
    { name: 'name', label: t('Filters.name'), align: 'left', padding: 'none' },
    { name: 'dob', label: t('Filters.dob'), align: 'left', padding: 'none' },
    { name: 'date', label: t('Filters.date'), align: 'left', padding: 'none' },
    { name: 'operator', label: t('Filters.operator'), align: 'left', padding: 'none' },
    { name: 'actions', label: '', align: 'left', padding: 'none' },
  ];

  // Effects
  useEffect(() => {
    if (typingTimeout) clearTimeout(typingTimeout);

    const patientSearch = () => {
      if (inputText.length === 0 || inputText.length < 3) {
        setRetrievedUsers([]);
      }
      if (inputText.length >= 3) {
        const timeout = setTimeout(async () => {
          try {
            const response = await fetchPost('patient_search', { searchText: inputText });

            if (response.success) {
              if (response.data) {
                setRetrievedUsers(response.data);
              } else {
                dispatch(setSnackbar({ msg: 'No patient matched search', severity: 'error' }));
              }
            } else {
              dispatch(setSnackbar({ msg: response.msg, severity: 'error' }));
              return;
            }
          } catch (error) {
            dispatch(setSnackbar({ msg: 'An error occurred during search', severity: 'error' }));
          }
        }, 800);

        setTypingTimeout(timeout);
      }
    };

    patientSearch();

    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [inputText]);

  useEffect(() => {
    if (filterPrompt !== inputText) {
      setInputText(filterPrompt || '');
    }
  }, [filterPrompt]);

  const addUserToList = (user) => {
    const selectedPatient = { ...user, found: true };
    props.setPatientList((prevList) => [...prevList, selectedPatient]);
    dispatch(setSnackbar({ msg: t('Filters.patient_added'), severity: 'success', duration: 1000 }));
  };

  const removeUserFromList = (userID) => {
    props.setPatientList((prevList) => prevList.filter((user) => user.studyId !== userID));
    dispatch(setSnackbar({ msg: t('Filters.patient_removed'), severity: 'info', duration: 1000 }));
  };

  const actionCell = (user) => {
    const isInPatientList = props.patientList?.some((patient) => patient.studyId === user.studyId);

    const handleClick = () => {
      if (isInPatientList) {
        removeUserFromList(user.studyId);
      } else {
        addUserToList(user);
      }
      setOpenTooltip('');
    };

    const handleTooltipOpen = () => {
      setOpenTooltip(user.studyId);
    };

    const handleTooltipClose = () => {
      setOpenTooltip('');
    };

    const tooltipText = isInPatientList ? t('Filters.remove') : t('Filters.add_to_list');
    const IconComponent = isInPatientList ? CheckCircleIcon : AddCircleOutlineIcon;

    return (
      <Tooltip
        arrow
        placement='top-end'
        onOpen={handleTooltipOpen}
        onClose={handleTooltipClose}
        open={openTooltip === user.studyId}
        id={user.studyId}
        title={<Typography className={classes.tooltip_text}>{tooltipText}</Typography>}
      >
        <IconComponent
          fontSize='small'
          onClick={handleClick}
          style={{ cursor: 'pointer', fill: 'green' }}
        />
      </Tooltip>
    );
  };

  return (
    <Grid
      xs={props.size ? props.size : 4}
      item
      className={classes.grid_container}
      style={{ ...props.style }}
    >
      <div className={classes.table_title_container}>
        <Typography className={classes.table_title}>{t('Filters.custom_list_search')}</Typography>

        <TextField
          size='small'
          id='search-examinations'
          label={t('Filters.search_helper')}
          multiline
          className={classes.textField}
          value={inputText}
          onChange={(event) => setInputText(event.target.value)}
          variant='outlined'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Search />
              </InputAdornment>
            ),
            endAdornment: inputText && (
              <InputAdornment
                style={{ cursor: 'pointer' }}
                position='end'
                onClick={() => setInputText('')}
              >
                <Clear />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {tableHeader.map((head, index) => (
                <TableCell
                  key={head.label || index}
                  align={head.align || 'left'}
                  className={classes.table_row}
                >
                  {head.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {retrievedUsers?.length > 0 ? (
            <TableBody>
              {retrievedUsers.map((user) => (
                <TableRow key={user.studyId}>
                  <TableCell className={classes.table_row}>{user.studyId}</TableCell>
                  <TableCell className={classes.table_row}>{user.name}</TableCell>
                  <TableCell className={classes.table_row}>
                    {user.dateOfBirth}
                  </TableCell>
                  <TableCell className={classes.table_row}>{user.examDate}</TableCell>
                  <TableCell className={classes.table_row}>{user.operatorName}</TableCell>
                  <TableCell className={classes.table_row}>{actionCell(user)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell
                  align={'center'}
                  colSpan={tableHeader.length}
                  variant='footer'
                  style={{ borderBottom: 'unset' }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography
                      className={classes.table_placeholder}
                      style={{ fontSize: theme.fonts.responsiveMediumBig }}
                    >
                      {t('Filters.placeholder_helper')}
                    </Typography>
                    <Typography
                      className={classes.table_placeholder}
                      style={{ fontSize: theme.fonts.responsiveMediumSmall }}
                    >
                      {t('Filters.placeholder_helper_min')}
                    </Typography>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Grid>
  );
}
