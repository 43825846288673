import React, { useState } from 'react';

import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { DropzoneAreaBase } from 'material-ui-dropzone';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../ExaminationTable.style';

export default function DropzoneAreaTable(props) {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleDelete = (deletedObj) => {
    props.setFileObjects((prevState) => prevState.filter((obj) => obj !== deletedObj));
    if (props.fileObjects.length === 0) props.setDropAreaVisible(false);
  };

  const uploadButonClick = () => {
    setLoading(true);
    props.sendToOrthanc();
    props.setDropAreaVisible(false);
  };

  const handleAdd = (newObjs) => {
    const invalidFiles = newObjs.filter((file) => {
      return file.file.size > 50 * 2000000 || !file.file.name.endsWith('.dcm');
    });

    if (invalidFiles.length > 0) {
      setError(t('Upload.error_not_compatible'));
    } else {
      props.setFileObjects((prevState) => prevState.concat(newObjs));
    }
    setError(false);
  };

  const getFileLimitExceedMessage = (filesLimit) => {
    setError(true);
  };

  const getDropRejectMessage = () => {
    setError(true);
  };

  return (
    <>
      <DropzoneAreaBase
        fileObjects={props.fileObjects}
        dropzoneText={t('DatePicker.dicoms_list')}
        acceptedFiles={['.dcm']}
        onAdd={handleAdd}
        onDelete={handleDelete}
        onDropRejected={getDropRejectMessage}
        getFileLimitExceedMessage={getFileLimitExceedMessage}
        getDropRejectMessage={getDropRejectMessage}
        filesLimit={12}
        maxFileSize={50 * 2000000}
        showAlerts={false}
        useChipsForPreview={true}
        dropzoneProps={{ noClick: true }}
        clearOnUnmount={true}
        disableRejectionFeedback={true}
        previewChipProps={{
          classes: {
            root: classes.previewChip_root,
          },
        }}
        classes={{
          text: classes.dropzone_text,
          root: classes.dropzone_root,
          active: classes.dropzone_active,
          textContainer: classes.dropzone_textContainer,
          icon: classes.dropzone_icon,
        }}
      />
      {error && (
        <div className={classes.error_container}>
          <Typography className={classes.drag_error}>{t('Upload.error')}</Typography>
          <Typography className={classes.drag_error_texts}>{t('Upload.error_files')}</Typography>
          <Typography className={classes.drag_error_texts}>{t('Upload.error_size')}</Typography>
        </div>
      )}
      <Button
        variant='contained'
        onClick={() => uploadButonClick()}
        disabled={loading}
        style={{
          backgroundColor: theme.palette.complementary.turquoise,
          width: '50%',
          alignSelf: 'center',
        }}
      >
        {t('Menu.upload')}
      </Button>
    </>
  );
}
