// Version
export const IFU_VERSION = window.__RUNTIME_CONFIG__.IFU_VERSION;

// Network
let protocol;
if (window.__RUNTIME_CONFIG__.TLS === 'yes' || window.__RUNTIME_CONFIG__.PROXY_TLS === 'yes') {
  protocol = 'https';
} else {
  protocol = 'http';
}

// Network
export let API_ENDPOINT;
export let AI_ENDPOINT;
export let WS_ENDPOINT;
if (window.__RUNTIME_CONFIG__.NODE_ENV === 'development') {
  API_ENDPOINT = `${protocol}://${window.__RUNTIME_CONFIG__.SERVER_IP}:3000`;
  AI_ENDPOINT = `${protocol}://${window.__RUNTIME_CONFIG__.SERVER_IP}:5000`;
  WS_ENDPOINT = `${protocol}://${window.__RUNTIME_CONFIG__.SERVER_IP}:5000`;
} else {
  API_ENDPOINT = `${protocol}://${window.__RUNTIME_CONFIG__.SERVER_IP}/API`;
  AI_ENDPOINT = `${protocol}://${window.__RUNTIME_CONFIG__.SERVER_IP}/AI`;
  WS_ENDPOINT = `${protocol}://${window.__RUNTIME_CONFIG__.SERVER_IP}`;
}

// Premium features
export const PREMIUM_CANCER_RISK = window.__RUNTIME_CONFIG__.RISK === 'yes';
export const PREMIUM_RETROSPECTIVE_PROCESSING =
  window.__RUNTIME_CONFIG__.RETROSPECTIVE_PROCESSING === 'yes';
export const PREMIUM_DATA_EXPORT = window.__RUNTIME_CONFIG__.DATA_EXPORT === 'yes';
export const PREMIUM_DATA_ANNOTATION = window.__RUNTIME_CONFIG__.DATA_ANNOTATION === 'yes';

// Density
export const PREMIUM_DENSITY = window.__RUNTIME_CONFIG__.DENSITY === 'yes';

// Diagnostics
export const DENSITY = window.__RUNTIME_CONFIG__.DENSITY === 'yes';
export const DIAGNOSTICS_MICROCALC = window.__RUNTIME_CONFIG__.DIAGNOSTICS_MICROCALC === 'yes';
export const DIAGNOSTICS_OPACITIES = window.__RUNTIME_CONFIG__.DIAGNOSTICS_OPACITIES === 'yes';

// Quality
export const QUALITY_SYSTEM = window.__RUNTIME_CONFIG__.QUALITY_EVALUATION_SYSTEM;
export const QUALITY_VALUES = window.__RUNTIME_CONFIG__.QUALITY_VALUES.split(',');
export const OVERALL_QUALITY = window.__RUNTIME_CONFIG__.OVERALL_QUALITY == 'yes';
export const BLUR = window.__RUNTIME_CONFIG__.BLUR;
export const COMPRESSION_LOW = Number(window.__RUNTIME_CONFIG__.COMPRESSION_LOW);
export const COMPRESSION_HIGH = Number(window.__RUNTIME_CONFIG__.COMPRESSION_HIGH);
export const COMPRESSION_TOLERANCE = Number(window.__RUNTIME_CONFIG__.COMPRESSION_TOLERANCE);

// Sentry
export const CLIENT = window.__RUNTIME_CONFIG__.CLIENT;
export const CLIENT_ID = window.__RUNTIME_CONFIG__.CLIENT_ID;
