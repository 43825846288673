import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  premium: {
    fontSize: '2rem',
    color: theme.palette.complementary.mainActions,
  },

  premium_container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    height: '28px',
    width: 'auto',
  },
  premium_cta: {
    fontSize: '1rem',
    color: theme.palette.complementary.mainActions,
  },
}));
