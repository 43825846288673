export function getNewSelected(old_list, new_list, old_selected, parse=true) {
    if (parse) {
      old_list = old_list.map((item) => Object.values(item)[0]);
      new_list = new_list.map((item) => Object.values(item)[0]);
    }
    const unselected = old_list.filter(item => !old_selected.includes(item));
    const new_selected = new_list.filter((item) => !unselected.includes(item));
    return new_selected;
  }

export function createOperatorList(operators, operator_users) {
    const operator_list = [];
    let aliases;

    // Registered
    for (let user of operator_users) {
      aliases = user.aliases.split(";");

      operator_list.push({
        name: user.username,
        registered: true,
        aliases
      });

      operators = operators.filter(op => !user.aliases.includes(op.name.toLowerCase()));
    }

    // Not registered
    for (let operator of operators) {
      operator_list.push({
        name: operator.name,
        registered: false,
        aliases: [operator.name.toLowerCase()]
      });
    }

    return operator_list;
}

export function getStarsNotesAver(aliases, ranking) {
    const result = {};
    let count = 0;
    let sumStars = 0;
    let sumNotes = 0;

    ranking.forEach(op => {
      result[op.name.toLowerCase()] = { 
        stars: op.stars,
        notes: op.notes
      };
    });

    aliases.forEach(alias => {
      if (result[alias]) {
        if (result[alias].stars) {
          sumStars += result[alias].stars;
          count++;
        }
        sumNotes += result[alias].notes;
      }
    });

    return {
      stars: count ? (sumStars / count).toFixed(2) : 0,
      notes: sumNotes
    }
}

export const getSelectedAliases = (operator_list, selected_operators) => {
  let selected_aliases = [];
  for (let operator of operator_list) {
    if (selected_operators.includes(operator.name))
      selected_aliases = selected_aliases.concat(operator.aliases);
  }
  return selected_aliases;
}

export const unwindDevices = institutions => {
  let devices = [];
  for (let key of Object.keys(institutions))
  devices = devices.concat(institutions[key]);
  
  return devices;
}

export const iconify2SVG = ({ body, width, height }) =>
  `data:image/svg+xml;base64,${btoa(
    `<svg xmlns="http://www.w3.org/2000/svg" 
          viewBox="0 0 ${width} ${height}">
      ${body}
     </svg>`)}`;

export const formatModelName = modelName => {
  modelName = modelName.replaceAll('model', '');
  modelName = modelName.replaceAll('cascade', '');
  modelName = modelName.replaceAll('top', 'cranial');
  modelName = modelName.replaceAll('bottom', 'caudal');
  modelName = modelName.replaceAll('pecto', 'pectoralis');
  modelName = modelName.split('_');
  const projIdx = modelName.findIndex(word => word.toLowerCase() === 'cc' || word.toLowerCase() === 'mlo');
  if (projIdx > 0)
    modelName[projIdx] = modelName[projIdx].toUpperCase();
  return modelName.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
}