import React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { connect } from 'react-redux';

am4core.useTheme(am4themes_animated);

class Histogram extends React.Component {
  componentDidMount() {
    this.initChart();
  }

  initChart() {
    const chart = am4core.create(this.props.chartdiv, am4charts.XYChart);
    chart.numberFormatter.numberFormat = '#.##';
    chart.data = this.props.data;

    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    chart.padding(0, 30, 0, 30);
    chart.zoomOutButton.disabled = true;

    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    categoryAxis.fontSize = '1.5vh';
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.labels.template.fill = am4core.color(
      this.props.theme.palette.texts.secondary
    );

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.calculateTotals = true;
    valueAxis.renderer.maxWidth = 0;
    valueAxis.visible = false;
    valueAxis.renderer.grid.template.disabled = true;

    var series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.columns.template.width = am4core.percent(40);
    series1.dataFields.categoryX = 'category';
    series1.dataFields.valueY = 'value';
    series1.columns.template.tooltipText = '{value}';
    series1.columns.template.adapter.add('tooltipText', (text, target) => {
      const val = target.tooltipDataItem.valueY;
      if (val === 0.5) return '~{category} mGy: <1 projections';
      else return '~{category} mGy: {value} projections';
    });
    // series1.columns.template.fill = am4core.color(
    //   this.props.theme.palette.complementary.turquoise
    // );
    series1.columns.template.strokeWidth = 0;
    series1.columns.template.stroke = am4core.color(this.props.theme.palette.primary.light);
    series1.columns.template.strokeOpacity = 1;
    series1.columns.template.column.cornerRadiusTopLeft = 10;
    series1.columns.template.column.cornerRadiusTopRight = 10;

    // Adapter to set fill color dynamically from the "color" property in data
    series1.columns.template.adapter.add('fill', (fill, target) => {
      const color = target.dataItem.dataContext.color;
      if (color) return am4core.color(color);
      if (!color) return this.props.theme.palette.complementary.turquoise;

      return fill;
    });

    this.chart = chart;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.darkState !== this.props.darkState ||
      prevProps.theme.palette.primary.main !== this.props.theme.palette.primary.main
    ) {
      this.chart.dispose();
      this.initChart();
    } else {
      if (prevProps.data !== this.props.data) {
        this.chart.data = this.props.data;
        this.chart.validateData();
      }
    }
  }

  componentWillUnmount() {
    if (this.chart) this.chart.dispose();
  }

  render() {
    return <div id={this.props.chartdiv} style={{ width: '100%', height: '100%' }}></div>;
  }
}

function mapStateToProps(state) {
  return { data: state.statistics.doseData.histo };
}

export default connect(mapStateToProps)(Histogram);
