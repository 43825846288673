import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container_overlay: {
    zIndex: 3,
    width: '100%',
    height: '100%',
    position: 'absolute',
  },

  canvas_overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '5px',
    userSelect: 'none',
    opacity: 0.7,
    zIndex: 1200,
  },

  bounding_rect: {
    border: '1.2px',
    background: 'transparent',
    position: 'absolute',
    cursor: 'pointer',
    zIndex: 1500,
  },

  menu_item_container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4px 0px 4px 8px',
    margin: '4px 6px',
    textTransform: 'uppercase',
  },

  menu_item_icon: {
    marginLeft: '12px',
    width: '12px',
  },

  menu_item_text: {
    textTransform: 'uppercase',
    fontSize: theme.fonts.responsiveMedium,
  },

  lession_class: {
    textShadow: '0px 0px 3px rgb(0 0 0)',
    minWidth: '60px',
    height: '12px',
    position: 'absolute',
  },

  box_index: {
    position: 'absolute',
    fontSize: theme.fonts.responsiveSmall,
    zIndex: 10,
    color: '#dddddd',
    textShadow: '0px 0px 3px rgb(0 0 0)',
    margin: 0,
    height: 'min(1.2vh, 1rem)',
    width: 'min(1.2vh, 1rem)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
  },

  tooltip_text: {
    letterSpacing: '0.1rem',
    fontSize: theme.fonts.responsiveSmall,
  },
}));
