import React, { useState } from 'react';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import LinearProgress from './LinearProgress';
import { useTranslation } from 'react-i18next';
import * as actions from '../../../../store/index';

import DraggableDialog from '../DraggableDialog/DraggableDialog';
import { fetchPost } from '../../../../helpers/fetching';
import DicomPic from '../../../../resources/icons/dicom.png';
import { Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { FullscreenExit } from '@material-ui/icons';
import Logo from '../../../../resources/coorporate_images/brayz_logo_small.png';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  dropzoneText: {
    marginBottom: '8px',
    marginTop: '18px',
  },
  dropZone: {
    height: '100%',
    fullWidth: 'true',
  },
  previewContainer: {
    container: 'true',
    display: 'flex',
    margin: 'auto',
    width: 'auto',
    height: '400px',
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingTop: '10px',
  },
  preview: {
    height: '150px',
    paddingTop: '6px',
  },
  dicomImg: {
    height: '100px',
  },
}));

function DicomIcon() {
  const classes = useStyles();

  return <img className={classes.dicomImg} src={DicomPic} alt='DICOM file' />;
}

const SendDicoms = (props) => {
  const [uploadingProgress, setUploadingProgress] = useState(100);
  const [fileObjects, setFileObjects] = useState([]);
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const activeDialog = useSelector((state) => state.gui.activeDialog);

  function onClose() {
    if (uploadingProgress === 100) {
      dispatch(actions.setActiveDialog(null));
    } else
      props.setSnackbar({
        msg: t('Upload.wait_message'),
        severity: 'warning',
      });
  }

  async function sendToOrthanc() {
    if (!fileObjects.length) return;

    if (uploadingProgress !== 100) return;

    setUploadingProgress(0);
    const fakeProgress = setInterval(() => {
      setUploadingProgress((prevState) => Math.min(prevState + 2 / fileObjects.length, 99));
    }, 500);

    for (let i = 0; i < fileObjects.length; i++) {
      const response = await fetchPost('dicoms', fileObjects[i].data.split(',')[1], 'bbox');
      setUploadingProgress(((i + 1) / fileObjects.length) * 100);
      if (response.success)
        props.setSnackbar({
          msg: `${i + 1} / ${fileObjects.length} ${t('Upload.snack_uploaded')}`,
          severity: 'success',
        });
      else
        props.setSnackbar({
          msg: response.msg,
          severity: 'error',
        });
    }
    setFileObjects([]);
    clearInterval(fakeProgress);
    dispatch(actions.setActiveDialog(null));
  }

  return (
    <DraggableDialog
      maxWidth='md'
      title={t('Upload.header')}
      open={activeDialog === 'upload'}
      actions={[
        { onClick: onClose, color: 'primary', label: t('Upload.cancel') },
        {
          onClick: sendToOrthanc,
          color: 'primary',
          label: t('Upload.upload'),
        },
      ]}
      onClose={onClose}
    >
      <>
        {uploadingProgress !== 100 && <LinearProgress value={uploadingProgress} />}
        <DropzoneAreaBase
          classes={{
            text: classes.dropzoneText,
          }}
          fileObjects={fileObjects}
          acceptedFiles={['.dcm']}
          dropzoneText={t('Upload.dropzone_text')}
          onAdd={(newObjs) => setFileObjects((prevState) => prevState.concat(newObjs))}
          onDelete={(deletedObj) =>
            setFileObjects((prevState) => prevState.filter((obj) => obj !== deletedObj))
          }
          filesLimit={12}
          maxFileSize={5 * 10485760}
          showAlerts={false}
          getPreviewIcon={() => <DicomIcon />}
          previewGridClasses={{
            container: classes.previewContainer,
            item: classes.preview,
            image: classes.previewImg,
          }}
        />
      </>
    </DraggableDialog>
  );
};

export default SendDicoms;
