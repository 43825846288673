/* eslint no-eval: 0 */
import React, { useState, useEffect } from 'react';
import * as actions from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { DIAGNOSTICS_MICROCALC, DIAGNOSTICS_OPACITIES } from '../../../../../config';
import {
  changeBiradsToShow,
  handleFilteringLesionType,
  lesionExists,
  hideAndResetActions,
} from './Helpers/DiagnosticRevisionTableHelpers';
import { useStyles } from './DiagnosticRevisionTable.style';

import MicrocalcificationsTable from './Modules/MicrocalcificationsTable';
import OpacitiesTable from './Modules/OpacitiesTable';

const DiagnosticRevisionTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const results = useSelector((state) => state.results);
  const labeling = useSelector((state) => state.labeling);
  const {
    applied_opacities,
    applied_microcalc,
    confirmed_opacities,
    confirmed_microcalc,
    shown_microcalc,
    shown_opacities,
  } = useSelector((state) => state.results);
  const { diagnosticsInRevisionMode, singleBoxHover } = useSelector((state) => state.labeling);

  const [expandOpacitiesAccordion, setExpandOpacitiesAccordion] = useState(true);
  const [expandMicrocalcAccordion, setExpandMicrocalcAccordion] = useState(false);

  const microcalcificationsResult = labeling?.customMicrocalc;
  const opacitiesResult = labeling?.customOpacities;

  const biradsClasses = ['birads2', 'birads3', 'birads4', 'birads5', 'lesionKnown'];

  const microcalcEmpty = lesionExists(microcalcificationsResult);
  const opacitiesEmpty = lesionExists(opacitiesResult);

  const handleAccordionModule = (accordionModule, expandAccordion) => {
    if (accordionModule === 'opacities') {
      setExpandOpacitiesAccordion(expandAccordion);
      if (expandAccordion) {
        if (DIAGNOSTICS_MICROCALC && microcalcificationsResult) {
          setExpandMicrocalcAccordion(!expandAccordion);
          dispatch(actions.hideAllMicrocalc());
          hideAndResetActions(dispatch, actions);
        }
        handleFilteringLesionType('opacities', dispatch, actions);
      } else {
        if (DIAGNOSTICS_MICROCALC && microcalcificationsResult) {
          setExpandMicrocalcAccordion(!expandAccordion);
          handleFilteringLesionType('microcalc', dispatch, actions);
        }
        dispatch(actions.hideAllOpacities());
        hideAndResetActions(dispatch, actions);
      }
    } else if (accordionModule === 'microcalc') {
      setExpandMicrocalcAccordion(expandAccordion);
      if (expandAccordion) {
        if (DIAGNOSTICS_OPACITIES && opacitiesResult) {
          setExpandOpacitiesAccordion(!expandAccordion);
          dispatch(actions.hideAllOpacities());
          hideAndResetActions(dispatch, actions);
        }
        handleFilteringLesionType('microcalc', dispatch, actions);
      } else {
        if (DIAGNOSTICS_OPACITIES && opacitiesResult) {
          setExpandOpacitiesAccordion(!expandAccordion);
          handleFilteringLesionType('opacities', dispatch, actions);
        }
        dispatch(actions.hideAllMicrocalc());
        hideAndResetActions(dispatch, actions);
      }
    }
  };

  // Effects

  useEffect(() => {
    if (singleBoxHover) {
      if (singleBoxHover?.module === 'opacities' && !expandOpacitiesAccordion) {
        handleAccordionModule('opacities', true);
      } else if (singleBoxHover?.module === 'microcalc' && !expandMicrocalcAccordion) {
        handleAccordionModule('microcalc', true);
      }
    } else return;
  }, [singleBoxHover]);

  useEffect(() => {
    if (diagnosticsInRevisionMode) {
      if (!opacitiesEmpty) {
        handleFilteringLesionType('opacities', dispatch, actions);
        setExpandOpacitiesAccordion(true);
        setExpandMicrocalcAccordion(false);
      } else if (opacitiesEmpty && !microcalcEmpty) {
        handleFilteringLesionType('microcalc', dispatch, actions);
        setExpandMicrocalcAccordion(true);
        setExpandOpacitiesAccordion(false);
      }
    } else return;
  }, [diagnosticsInRevisionMode]);

  return (
    <>
      {/* MICROCALCIFICATIONS */}
      {DIAGNOSTICS_MICROCALC && (
        <div className={classes.diagnostic_lesion_module_container}>
          <MicrocalcificationsTable
            handleAccordionModule={handleAccordionModule}
            expandMicrocalcAccordion={expandMicrocalcAccordion}
          />
        </div>
      )}
      {/* OPACITIES  */}
      {DIAGNOSTICS_OPACITIES && (
        <div className={classes.diagnostic_lesion_module_container}>
          <OpacitiesTable
            handleAccordionModule={handleAccordionModule}
            expandOpacitiesAccordion={expandOpacitiesAccordion}
          />
        </div>
      )}
    </>
  );
};

export default DiagnosticRevisionTable;
