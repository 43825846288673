import React from 'react';
import { Grid, useTheme } from '@material-ui/core';
import SummaryParagraphSubHeader from '../SummaryParagraphSubHeader';
import { useTranslation } from 'react-i18next';

export default function PatientPurpose(props) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid xs={3} md={3} sm={3} container item direction='column'>
      <Grid item>
        <SummaryParagraphSubHeader subHeader={t('ResultView.purpose')}></SummaryParagraphSubHeader>
      </Grid>
      <Grid item>
        <p
          style={{
            ...props.style,
            letterSpacing: '0.1rem',
            color: theme.palette.texts.primary,
          }}
        >
          {props.result}
        </p>
      </Grid>
    </Grid>
  );
}
