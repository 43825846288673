import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  filterDrawerContainer: {
    background: 'gray',
    opacity: 0.5,
  },

  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.secondary.main,
    alignItems: 'center',
  },

  dropzoneText: {
    marginBottom: '8px',
    marginTop: '18px',
  },

  dropZone: {
    height: '100%',
    fullWidth: 'true',
  },

  loading_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    minHeight: '200px',
  },

  upload_container: {
    padding: '14px',
  },

  save_title_container: {
    padding: '0px 0px 6px 12px',
  },

  file_container: {
    display: 'flex',
  },

  file_name: {
    fontSize: theme.fonts.responsiveMedium,
  },

  accepted_files: {
    fontSize: theme.fonts.responsiveMedium,
    fontStyle: 'italic',
  },

  select_file_btn_container: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '3%',
    justifyContent: 'center',
  },

  dropZone: { minHeight: '200px' },

  dropZone_hide: {
    display: 'none',
  },

  table_title_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '50px',
    maxHeight: '50px',
  },

  table_title: {
    fontSize: theme.fonts.responsiveMedium,
    margin: '10px 0',
    fontWeight: 'bold',
  },

  table_row: {
    padding: '8px',
    maxWidth: '10vw',
    overflow: 'hidden',
    whiteSpace: 'break-spaces',
    wordBreak: 'break-word',
  },

  grid_container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    minHeight: '40vh',
    maxHeight: '70vh',
    overflowY: 'auto',
  },

  tooltip_text: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    fontSize: '12px',
  },

  save_filter_text: {
    letterSpacing: '0.1rem',
    padding: '6px',
    fontSize: theme.fonts.responsiveBig,
  },

  save_filter_container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 0 20px 0',
  },

  action_button: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontSize: theme.typography.h6.fontSize,
  },

  close_button: {
    color: theme.palette.primary.main,
  },

  draggable_title_container: {
    padding: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  save_title: {
    letterSpacing: '0.2rem',
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.primary.main,
  },

  exam_status: {
    fontSize: theme.fonts.responsiveMedium,
    fontStyle: 'italic',
  },

  status_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  table_placeholder: {
    color: 'gray',
    padding: '15%',
    fontStyle: 'italic',
    padding: '14px 10px 10px',
  },
}));
