import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Icon, SvgIcon, Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';
import InfoIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme) => ({
  titleParagraph: {
    letterSpacing: '0.2rem',
    color: theme.palette.texts.primary,
    fontSize: theme.fonts.responsiveBig,
    whiteSpace: 'nowrap',
  },

  titleParagraph_click: {
    letterSpacing: '0.2rem',
    color: theme.palette.texts.primary,
    fontSize: theme.fonts.responsiveBig,
    whiteSpace: 'nowrap',
    '&:hover': {
      color: theme.palette.primary.main,
      textShadow: ' 0px 0px 5px #55000f',
      textDecoration: 'underline',
    },
  },

  icon: {
    color: theme.palette.texts.secondary,
    fontSize: '1.5rem',
    height: '25px',
    width: 'auto',
  },

  tooltipText: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    fontSize: theme.fonts.responsiveMedium,
  },
}));

export default function SummaryParagraph(props) {
  const classes = useStyles();
  const darkState = useSelector((store) => store.gui.darkState);

  return (
    <Tooltip
      title={
        <Typography className={classes.tooltipText} color='inherit'>
          {props.tootlipTitle && props?.clickable ? props.tootlipTitle : props.header}
        </Typography>
      }
      placement='left'
      arrow
    >
      <Typography
        onClick={props?.onClick}
        className={props?.clickable ? classes.titleParagraph_click : classes.titleParagraph}
        paragraph={false}
        style={{ ...props.style, cursor: props.clickable ? 'pointer' : 'default' }}
      >
        {props.header}
      </Typography>
    </Tooltip>
  );
}
