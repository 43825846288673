import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { Divider, Typography, Paper, Slide, IconButton } from '@material-ui/core';
import Draggable from 'react-draggable';

import CollapseImageDialog from './CollapseImageDialog';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  draggableTitle: {
    cursor: 'move',
    margin: 0,
    letterSpacing: '0.2rem',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
    transform: 'scale(1.1)',
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='right' ref={ref} {...props} />;
});

function PaperComponent(props) {
  return (
    <Draggable handle='#draggable-dialog-title' cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.draggableTitle} disableTypography {...other}>
      <Typography variant='h5'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
export default function CapturesDialog(props) {
  const { t } = useTranslation();

  return (
    <Dialog
      BackdropProps={{ style: { opacity: 0 } }}
      style={{ pointerEvents: 'none' }}
      PaperProps={{ style: { pointerEvents: 'auto' } }}
      TransitionComponent={Transition}
      keepMounted
      PaperComponent={PaperComponent}
      aria-labelledby='draggable-dialog-title'
      onClose={props.onClose}
      open={props.openDialog}
      fullWidth
      maxWidth='md'
      disableEnforceFocus
    >
      <DialogTitle id='draggable-dialog-title' onClose={props.onClose}>
        {t('ResultView.secondCaptures')}
      </DialogTitle>
      <Divider />

      <DialogContent
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CollapseImageDialog open={props.open} proj_name={props.proj_name} uids={props.uids} />
      </DialogContent>
    </Dialog>
  );
}
