import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  title: {
    letterSpacing: '0.2rem',
    textTransform: 'uppercase',
    paddingRight: theme.spacing(1),
    fontSize: theme.fonts.responsiveBig,
  },

  subheader: {
    letterSpacing: '0.1rem',
    fontSize: theme.fonts.responsiveMedium,
    textTransform: 'uppercase',
  },

  subheader_unique: {
    letterSpacing: '0.1rem',
    fontSize: theme.fonts.responsiveMedium,
  },

  action: {
    margin: 0,
  },

  CardHeader: {
    height: '20%',
    alignItems: 'start',
    padding: '4px 8px 8px',
  },
}));
