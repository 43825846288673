import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import {
  ListSubheader,
  Switch,
  FormControlLabel,
  CardContent,
  Grid,
  Card,
  CardHeader,
  Typography,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { fetchGet } from '../../../../../helpers/fetching';

import ButonGroupDicomFilter from '../ButtonGroup';

const useStyles = makeStyles((theme) => ({
  titleSettingsCard: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontSize: '1.1rem',
  },
  action: {
    margin: 0,
  },
  listItem: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'flex-start',
    color: theme.palette.texts.secondary,
  },
  listHeader: {
    fontSize: theme.typography.h6.fontSize,
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.texts.primary,
  },
  list: {
    width: '100%',
    minHeight: '100%',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '5px',
  },
}));

const emptyFields = {
  Name: '',
  AET: '',
  IP: '',
  Port: '',
};

const ReportServers = (props) => {
  const classes = useStyles();
  const [fieldValues, setFieldValues] = useState(emptyFields);
  const [selected, setSelected] = useState(0);
  const { t } = useTranslation();
  const { settings } = props;

  function onTextChange(event) {
    const { value, name } = event.target;
    const newValues = { ...fieldValues };
    newValues[name] = value;
    setFieldValues(newValues);
  }

  function addNewItem(_) {
    for (let k in fieldValues) {
      fieldValues[k] = fieldValues[k].trim();
    }
    if (validate()) props.addItem(fieldValues);
  }

  function validate() {
    for (let field in fieldValues) {
      if (!(fieldValues[field] + '').length) {
        props.setSnackbar({
          msg: t('Settings.form_all_required'),
          severity: 'warning',
        });
        return false;
      }
    }
    if (!/^[a-z0-9]+$/i.test(fieldValues.Name)) {
      props.setSnackbar({
        msg: t('Settings.form_name_alphanumeric'),
        severity: 'warning',
      });
      return false;
    }
    // if (
    //   !/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
    //     fieldValues.IP
    //   )
    // ) {
    //   props.setSnackbar({
    //     msg: t('Settings.form_ip_incorrect'),
    //     severity: 'warning',
    //   });
    //   return false;
    // }
    if (!/^[0-9]+$/i.test(fieldValues.Port)) {
      props.setSnackbar({
        msg: t('Settings.form_port_integer'),
        severity: 'warning',
      });
      return false;
    }
    return true;
  }

  async function onClickEcho() {
    if (!fieldValues.Name) {
      props.setSnackbar({
        msg: t('Settings.not_selected'),
        severity: 'warning',
      });
      return;
    }
    const response = await fetchGet(`pacs_echo/${fieldValues.Name}`, 'bbox');

    if (!response.success) {
      props.setSnackbar({
        msg: response.msg,
        severity: 'error',
      });
      return;
    }

    if (response.status) {
      props.setSnackbar({
        msg: 'Echo Succeded',
        severity: 'success',
      });
    } else {
      props.setSnackbar({
        msg: 'Echo Failed',
        severity: 'warning',
      });
    }
  }

  return (
    <>
      <Grid item>
        <Card elevation={1}>
          <CardHeader
            disableTypography={true}
            title={
              <Typography className={classes.titleSettingsCard} variant='h6'>
                {t('Settings.report_servers')}
              </Typography>
            }
          />
          <CardContent style={{ paddingTop: '0px' }}>
            <Grid spacing={2} container direction='column'>
              <Grid
                item
                container
                wrap='nowrap'
                direction='row'
                justifyContent='space-between'
                spacing={2}
              >
                <Grid xs={6} sm={6} md={6} container item direction='column' spacing={2}>
                  <Grid item>
                    {Object.keys(fieldValues).map((field) => (
                      <TextField
                        name={field}
                        label={field}
                        key={field}
                        fullWidth={true}
                        value={fieldValues[field]}
                        onChange={onTextChange}
                        variant='outlined'
                        margin='normal'
                      />
                    ))}
                  </Grid>
                </Grid>

                <Grid xs={6} sm={6} md={6} item>
                  <List
                    aria-labelledby='list-subheader'
                    subheader={
                      <ListSubheader
                        className={classes.listHeader}
                        component='div'
                        id='list-subheader'
                      >
                        {t('Settings.dicom_servers')}
                      </ListSubheader>
                    }
                    className={classes.list}
                  >
                    {props.items.map((item, i) => (
                      <ListItem
                        className={classes.listItem}
                        button
                        key={item.Name}
                        onClick={() => {
                          setFieldValues(props.items[i]);
                          setSelected(i);
                        }}
                        selected={i === selected}
                      >
                        {item.Name}
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
              <Grid item>
                <ButonGroupDicomFilter
                  onClickDelete={() => {
                    props.deleteItem(fieldValues);
                    setFieldValues(emptyFields);
                  }}
                  onClickAdd={addNewItem}
                  onClickClear={() => setFieldValues(emptyFields)}
                  onClickEcho={onClickEcho}
                ></ButonGroupDicomFilter>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default ReportServers;
