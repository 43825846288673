import { useSelector } from 'react-redux';

export function useAppliedEvaluation(currentModule, appliedResults, inRevision) {
  const results = useSelector((state) => state.results);
  const labeling = useSelector((state) => state.labeling);

  if (inRevision || currentModule === 'opacities') {
    return labeling?.[`custom${currentModule.charAt(0).toUpperCase()}${currentModule.slice(1)}`];
  } else {
    if (appliedResults) {
      return results?.evaluations?.[currentModule]?.[appliedResults];
    } else {
      return results?.evaluations?.[currentModule]?.bbox;
    }
  }
}
