import React, { useState, useRef, useEffect } from 'react';
import gui, { setSnackbar } from '../../../../../store/slices/gui';
import { setActiveDialog } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { catalog } from '../../../../../language/catalog';
import { useStyles } from './RiskAssessment.style';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { fetchGet } from '../../../../../helpers/fetching';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Logo from '../../../../../resources/coorporate_images/brayz_logo_small.png';
import BarChartIcon from '@material-ui/icons/BarChart';
import TimelineIcon from '@material-ui/icons/Timeline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import * as actions from '../../../../../store/index';
import { highDensity } from '../../../../../helpers/highDensity';
import { PREMIUM_DENSITY } from '../../../../../config';

const RiskAssessmentReport = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const results = useSelector((state) => state.results);
  const { pat_name, patient_id } = useSelector((state) => state.results);

  const [patientRiskAssessment, setPatientRiskAssessment] = useState({});

  const riskFormContent = catalog.resources.en.translation.risk_improved_form;
  const riskManagementCatalog = catalog.resources.en.translation.risk_management;
  const { name: patientName, patient_id: patientId } = results;
  const { age } = patientRiskAssessment.risk_factors ?? {};

  const patientUDI = patientId || patient_id;

  useEffect(() => {
    const fetchPatientForm = async () => {
      try {
        const response = await fetchGet(`risk_model/${patientUDI}`, 'bbox');

        if (response.success) {
          setPatientRiskAssessment(response.data);
        } else {
          dispatch(
            setSnackbar({
              msg: response.msg,
              severity: 'error',
            })
          );
        }
      } catch (error) {
        dispatch(
          setSnackbar({
            msg: t('patient_risk.error_fetching'),
            severity: 'error',
          })
        );
      }
    };

    if (patientUDI) {
      fetchPatientForm();
    }
  }, [patientUDI]);

  const patientRelativeRisk = () => {
    if (patientRiskAssessment.risk_evaluation?.life_time_risk >= 30) {
      return (
        <>
          <WarningIcon className={classes.patient_risk_high} fontSize='large' />
          <Typography
            className={[classes.patient_relative_risk, classes.patient_risk_high].join(' ')}
          >
            {t('risk_management.high_average')}
          </Typography>
        </>
      );
    }
    if (
      patientRiskAssessment.risk_evaluation?.life_time_risk >= 17 &&
      patientRiskAssessment.risk_evaluation?.life_time_risk <= 30
    ) {
      return (
        <>
          <TimelineIcon className={classes.patient_risk_moderate} fontSize='large' />
          <Typography
            className={[classes.patient_relative_risk, classes.patient_risk_moderate].join(' ')}
          >
            {t('risk_management.sligh_high_average')}
          </Typography>
        </>
      );
    }
    if (patientRiskAssessment.risk_evaluation?.life_time_risk < 17) {
      return (
        <>
          <BubbleChartIcon className={classes.patient_risk_low} fontSize='large' />
          <Typography
            className={[classes.patient_relative_risk, classes.patient_risk_low].join(' ')}
          >
            {t('risk_management.normal_average')}
          </Typography>
        </>
      );
    }
  };

  const patientRecomendations = () => {
    if (patientRiskAssessment.risk_evaluation?.life_time_risk >= 30) {
      if (age >= 60) {
        return riskManagementCatalog.recomendation_high_sixtyplus?.map((e, i) => (
          <div className={classes.recommendations} key={i}>
            <ArrowRightIcon />
            <Typography key={e} className={classes.recomendation_text}>
              {t(`risk_management.recomendation_high_sixtyplus.${i}`)}
            </Typography>
          </div>
        ));
      }
      if (age < 60 && age >= 50) {
        if (highDensity(results) === 'C' || highDensity(results) === 'D')
          return riskManagementCatalog.recomendation_high_fifty_dense?.map((e, i) => (
            <div className={classes.recommendations} key={i}>
              <ArrowRightIcon />
              <Typography key={e} className={classes.recomendation_text}>
                {t(`risk_management.recomendation_high_fifty_dense.${i}`)}
              </Typography>
            </div>
          ));
        else
          return riskManagementCatalog.recomendation_high_fifty?.map((e, i) => (
            <div className={classes.recommendations} key={i}>
              <ArrowRightIcon />
              <Typography key={e} className={classes.recomendation_text}>
                {t(`risk_management.recomendation_high_fifty.${i}`)}
              </Typography>
            </div>
          ));
      }
      if (age < 50 && age > 40) {
        return riskManagementCatalog.recomendation_high_fourty?.map((e, i) => (
          <div className={classes.recommendations} key={i}>
            <ArrowRightIcon />
            <Typography key={e} className={classes.recomendation_text}>
              {t(`risk_management.recomendation_high_fourty.${i}`)}
            </Typography>
          </div>
        ));
      }
      if (age < 40) {
        return riskManagementCatalog.recomendation_high_thirty?.map((e, i) => (
          <div className={classes.recommendations} key={i}>
            <ArrowRightIcon />
            <Typography key={e} className={classes.recomendation_text}>
              {t(`risk_management.recomendation_high_thirty.${i}`)}
            </Typography>
          </div>
        ));
      } else
        return riskManagementCatalog.recomendation_high?.map((e, i) => (
          <div className={classes.recommendations} key={i}>
            <ArrowRightIcon />
            <Typography key={e} className={classes.recomendation_text}>
              {t(`risk_management.recomendation_high.${i}`)}
            </Typography>
          </div>
        ));
    }

    if (
      patientRiskAssessment.risk_evaluation?.life_time_risk >= 17 &&
      patientRiskAssessment.risk_evaluation?.life_time_risk <= 30
    ) {
      if (age >= 60) {
        return riskManagementCatalog.recomendation_moderate_sixtyplus?.map((e, i) => (
          <div className={classes.recommendations} key={i}>
            <ArrowRightIcon />
            <Typography key={e} className={classes.recomendation_text}>
              {t(`risk_management.recomendation_moderate_sixtyplus.${i}`)}
            </Typography>
          </div>
        ));
      }
      if (age < 60 && age >= 50) {
        return riskManagementCatalog.recomendation_moderate_fifty?.map((e, i) => (
          <div className={classes.recommendations} key={i}>
            <ArrowRightIcon />
            <Typography key={e} className={classes.recomendation_text}>
              {t(`risk_management.recomendation_moderate_fifty.${i}`)}
            </Typography>
          </div>
        ));
      }
      if (age > 40) {
        return riskManagementCatalog.recomendation_moderate_fourty?.map((e, i) => (
          <div className={classes.recommendations} key={i}>
            <ArrowRightIcon />
            <Typography key={e} className={classes.recomendation_text}>
              {t(`risk_management.recomendation_moderate_fourty.${i}`)}
            </Typography>
          </div>
        ));
      } else
        return riskManagementCatalog.recomendation_moderate?.map((e, i) => (
          <div className={classes.recommendations} key={i}>
            <ArrowRightIcon />
            <Typography key={e} className={classes.recomendation_text}>
              {t(`risk_management.recomendation_moderate.${i}`)}
            </Typography>
          </div>
        ));
    }

    if (patientRiskAssessment.risk_evaluation?.life_time_risk < 17) {
      return riskManagementCatalog.recomendation_normal?.map((e, i) => (
        <div className={classes.recommendations} key={i}>
          <ArrowRightIcon />
          <Typography key={e} className={classes.recomendation_text}>
            {t(`risk_management.recomendation_normal.${i}`)}
          </Typography>
        </div>
      ));
    }
  };

  const transformHormoneData = (answer) => {
    if (answer === 0) return t('patient_risk.h_never');
    if (answer === 1) return t('patient_risk.h_past');
    if (answer === 2) return t('patient_risk.h_current');
    return '';
  };

  const transformWithDate = (answer, answerDate) => {
    if (answer === 1) {
      if (answerDate && answerDate !== '-' && answerDate !== '') {
        return (
          <Typography className={classes.risk_text_answers}>
            {t('patient_risk.yes_in', {
              yesDate: answerDate,
            })}
          </Typography>
        );
      } else
        return (
          <Typography className={classes.risk_text_answers}>{t('patient_risk.yes')}</Typography>
        );
    }
    return <Typography className={classes.risk_text_answers}>{t('patient_risk.no')}</Typography>;
  };

  const transformSimpleBool = (answer) => {
    if (answer === 1) return t('patient_risk.yes');
    else return t('patient_risk.no');
  };

  const transformFamilyMembers = (members) => {
    if (members) {
      const groupedMembers = {};

      Object.entries(members).forEach(([key, value]) => {
        const keyParts = key.split('_');
        const keyWithoutPrefix = keyParts.slice(1, 3).join('_');

        const isType = key.endsWith('_type');
        const memberKey = isType ? keyWithoutPrefix.replace(/_type$/, '') : keyWithoutPrefix;

        if (!groupedMembers[memberKey]) {
          groupedMembers[memberKey] = { ages: [], types: [] };
        }

        if (isType) {
          groupedMembers[memberKey].types.push(value);
        } else {
          groupedMembers[memberKey].ages.push(value);
        }
      });

      return Object.entries(groupedMembers).map(([keyWithoutPrefix, { ages, types }]) => {
        return {
          family_member: keyWithoutPrefix,
          family_ages: ages.join(', '),
          family_types: types.join(', '),
        };
      });
    }
    return [];
  };

  const getFamilyTitle = (opt) => {
    if (!opt) return '';

    const { family_member, family_ages, family_types } = opt;
    const questionIndex = riskFormContent.findIndex((obj) => obj.risk_id === 'family');
    if (questionIndex === -1) return '';

    const familyItemIndex = riskFormContent[questionIndex].complementary_items_list.findIndex(
      (obj) => obj.opt_id === family_member || family_member.startsWith('other')
    );
    if (familyItemIndex === -1) return '';

    let familyTitle = '';
    if (!family_member.startsWith('other')) {
      const itemTitleKey = `risk_improved_form.${questionIndex}.complementary_items_list.${familyItemIndex}.item_title`;
      familyTitle = t(itemTitleKey);
    }

    const familyAgesFormatted =
      family_ages !== 'Yes'
        ? family_ages.includes(',')
          ? family_ages
          : `${t('patient_risk.age')}:  ${family_ages}`
        : '';

    const breastTypeArray = family_types.split(',').map((type) => t(`patient_risk.${type.trim()}`));
    const breastTypeFormatted = breastTypeArray.join(', ');

    return familyTitle
      ? `${familyTitle} - ${breastTypeFormatted} - ${familyAgesFormatted}`
      : `${breastTypeFormatted} - ${familyAgesFormatted}`;
  };

  const examinationReasons = patientRiskAssessment?.risk_factors?.reason
    ?.map((obj) => {
      return Object.entries(obj).reduce((acc, [key, value]) => {
        if (value === true) {
          acc.push(key);
        } else if (Array.isArray(value) && value.length > 0) {
          acc.push(key);
        }
        return acc;
      }, []);
    })
    .filter((arr) => arr.length > 0);

  const transformReasons = (examReason) => {
    const question = riskFormContent.findIndex((obj) => obj.risk_id === 'reason');
    const reasonPosition = riskFormContent[question].complementary_items_list.findIndex(
      (obj) => obj.opt_id === examReason
    );

    return examReason === 'other'
      ? patientRiskAssessment?.risk_factors.reason[11].other_reason
      : t(
          `risk_improved_form.${[
            question,
          ]}.complementary_items_list.${reasonPosition}.item_title`.toString()
        );
  };

  const getBreastTranslation = (breast) => {
    if (breast === 'ovarian') return `${t('patient_risk.ovarian')}`;
    if (breast === 'right_breast') return `${t('patient_risk.right_breast')}`;
    if (breast === 'left_breast') return `${t('patient_risk.left_breast')}`;
    if (breast === 'both_breast') return `${t('patient_risk.both_breast')}`;
    else return t('patient_risk.no');
  };

  const getAlcoholTranslation = (alcohol) => {
    if (!alcohol) return 'No alcohol consuption';
    else if (alcohol === 'light') return t('patient_risk.alcohol_light');
    else if (alcohol === 'moderate') return t('patient_risk.alcohol_moderate');
    else if (alcohol === 'high') return t('patient_risk.alcohol_high');
    else if (alcohol === 'heavy') return t('patient_risk.alcohol_heavy');
  };

  const transformMenopause = (age) => {
    if (age === 'not yet') return t('patient_risk.period');
    else return age;
  };

  return (
    <Dialog
      className={classes.risk_dialog_container}
      maxWidth='md'
      fullWidth
      open={props.open}
      onClose={() => dispatch(setActiveDialog(null))}
    >
      <DialogTitle style={{ padding: '0' }}>
        <div
          className={classes.draggable_title_container}
          style={{
            backgroundImage: `url(${Logo})`,
            backgroundSize: '600px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right center',
          }}
        >
          <div className={classes.patient_info_container}>
            <Typography className={classes.risk_results_title}>
              {t('risk_management.risk_assessment_title')}
            </Typography>
            <Typography className={classes.patient_info}>
              {patientName ? patientName : pat_name}
            </Typography>
          </div>

          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={() => {
              dispatch(setActiveDialog(null));
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent className={classes.content_container}>
        <div className={classes.draggable_risk_container}>
          <div className={classes.draggable_risk_info}>
            <Typography className={classes.patient_risk_title}>
              {t('risk_management.patient_risk')}
            </Typography>
            <Tooltip
              title={
                <>
                  <div className={classes.risk_info}>
                    <Typography className={classes.risk_info_text}>
                      {t('risk_management.life_time_risk')}:
                    </Typography>
                    <Typography className={classes.risk_info_text}>
                      {patientRiskAssessment.risk_evaluation?.life_time_risk.toFixed(1)}
                    </Typography>
                  </div>
                  <div className={classes.risk_info}>
                    <Typography className={classes.risk_info_text}>
                      {t('risk_management.RR')}:
                    </Typography>
                    <Typography className={classes.risk_info_text}>
                      {patientRiskAssessment.risk_evaluation?.RR.toFixed(1)}
                    </Typography>
                  </div>
                  <div className={classes.risk_info}>
                    <Typography className={classes.risk_info_text}>
                      {t('risk_management.five_year_risk')}:
                    </Typography>
                    <Typography className={classes.risk_info_text}>
                      {patientRiskAssessment.risk_evaluation?.five_year_risk.toFixed(1)}
                    </Typography>
                  </div>
                  <div className={classes.risk_info}>
                    <Typography className={classes.risk_info_text}>
                      {t('risk_management.cohort_five_year_risk')}:
                    </Typography>
                    <Typography className={classes.risk_info_text}>
                      {patientRiskAssessment.risk_evaluation?.cohort_five_year_risk.toFixed(1)}
                    </Typography>
                  </div>
                </>
              }
              arrow
              interactive
              placement='bottom'
            >
              <IconButton className={classes.InfoIcon}>
                <InfoIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.lifetime_risk_container}>{patientRelativeRisk()}</div>
          {patientRiskAssessment?.risk_evaluation?.life_time_risk && (
            <Typography className={classes.average_results_text}>
              {t('risk_management.average_results', {
                patient_percentage:
                  patientRiskAssessment?.risk_evaluation?.life_time_risk?.toFixed(1),
              })}
            </Typography>
          )}
        </div>

        <div className={classes.recomendations_container}>
          <Typography className={classes.patient_risk_title}>
            {t('risk_management.recommendations')}
          </Typography>
          <Typography className={classes.risk_text}>{t('risk_management.protocol')}</Typography>
          {patientRecomendations()}
        </div>

        <Accordion className={classes.answers_container}>
          <AccordionSummary className={classes.patient_risk_title} expandIcon={<ExpandMoreIcon />}>
            {t('risk_management.patient_answers')}
          </AccordionSummary>

          <AccordionDetails className={classes.questions_extra_container}>
            <div className={classes.questions_container}>
              <div className={classes.risk_options_container}>
                <Typography className={classes.risk_text}>{t('patient_risk.doctor')}</Typography>
                <Typography className={classes.risk_text_answers}>
                  {patientRiskAssessment.risk_factors?.doctor}
                </Typography>
              </div>

              {examinationReasons && (
                <div className={classes.risk_options_container}>
                  <Typography className={classes.risk_text}>{t('patient_risk.reason')}</Typography>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                    }}
                  >
                    {examinationReasons?.length > 0 ? (
                      examinationReasons?.map((item, index) => (
                        <Typography key={index} className={classes.risk_text_answers}>
                          {transformReasons(item[0])}
                        </Typography>
                      ))
                    ) : (
                      <Typography className={classes.risk_text_answers}>No reason</Typography>
                    )}
                  </div>
                </div>
              )}

              <div className={classes.risk_options_container}>
                <Typography className={classes.risk_text}>{t('patient_risk.age')}</Typography>
                <Typography className={classes.risk_text_answers}>
                  {patientRiskAssessment.risk_factors?.age}
                </Typography>
              </div>

              <div className={classes.risk_options_container}>
                <Typography className={classes.risk_text}>{t('patient_risk.density')}</Typography>
                <Typography className={classes.risk_text_answers}>
                  {patientRiskAssessment.risk_factors?.density}
                </Typography>
              </div>

              <div className={classes.risk_options_container}>
                <Typography className={classes.risk_text}>{t('patient_risk.height')}</Typography>
                <Typography className={classes.risk_text_answers}>
                  {patientRiskAssessment.risk_factors?.height}
                </Typography>
              </div>

              <div className={classes.risk_options_container}>
                <Typography className={classes.risk_text}>{t('patient_risk.weight')}</Typography>
                <Typography className={classes.risk_text_answers}>
                  {patientRiskAssessment.risk_factors?.weight}
                </Typography>
              </div>

              <div className={classes.risk_options_container}>
                <Typography className={classes.risk_text}>{t('patient_risk.mammo')}</Typography>
                {transformWithDate(
                  patientRiskAssessment.risk_factors?.mammo,
                  patientRiskAssessment?.risk_factors?.mammo_date
                )}
              </div>

              <div className={classes.risk_options_container}>
                <Typography className={classes.risk_text}>
                  {t('patient_risk.sonography')}
                </Typography>
                {transformWithDate(
                  patientRiskAssessment.risk_factors?.sonography,
                  patientRiskAssessment?.risk_factors?.sonography_date
                )}
              </div>

              <div className={classes.risk_options_container}>
                <Typography className={classes.risk_text}>{t('patient_risk.mri')}</Typography>
                {transformWithDate(
                  patientRiskAssessment.risk_factors?.mri,
                  patientRiskAssessment?.risk_factors?.mri_date
                )}
              </div>

              <div className={classes.risk_options_container}>
                <Typography className={classes.risk_text}>
                  {t('patient_risk.operated_or_biopsy')}
                </Typography>
                <div className={classes.risk_answers_container}>
                  {transformWithDate(
                    patientRiskAssessment.risk_factors?.operated_or_biopsy,
                    patientRiskAssessment?.risk_factors?.operated_or_biopsy_date
                  )}
                  <Typography className={classes.risk_text_answers}>
                    {getBreastTranslation(
                      patientRiskAssessment.risk_factors?.operated_or_biopsy_breast
                    )}
                  </Typography>
                  <Typography className={classes.risk_text_answers}>
                    {!patientRiskAssessment.risk_factors?.operated_or_biopsy_results ||
                    patientRiskAssessment.risk_factors?.operated_or_biopsy_results === ''
                      ? t('patient_risk.no_results')
                      : patientRiskAssessment.risk_factors?.operated_or_biopsy_results === 'benign'
                      ? t('patient_risk.benign')
                      : t('patient_risk.malignant')}
                  </Typography>
                </div>
              </div>

              {/* <div className={classes.risk_options_container}>
                <Typography className={classes.risk_text}>{t('patient_risk.hormones')}</Typography>
                <div className={classes.risk_answers_container}>
                  <Typography className={classes.risk_text_answers}>
                    {transformHormoneData(patientRiskAssessment.risk_factors?.hormones)}
                  </Typography>
                  {patientRiskAssessment?.risk_factors?.hormones_years && (
                    <Typography className={classes.risk_text_answers}>
                      {t('patient_risk.for_years', {
                        how_long: patientRiskAssessment?.risk_factors?.hormones_years,
                      })}
                    </Typography>
                  )}
                </div>
              </div> */}

              <div className={classes.risk_options_container}>
                <Typography className={classes.risk_text}>{t('patient_risk.hrt')}</Typography>
                <div className={classes.risk_answers_container}>
                  <Typography className={classes.risk_text_answers}>
                    {transformHormoneData(patientRiskAssessment.risk_factors?.hrt)}
                  </Typography>
                  {patientRiskAssessment?.risk_factors?.hrt_years && (
                    <Typography className={classes.risk_text_answers}>
                      {`${t('patient_risk.for_years', {
                        how_long: patientRiskAssessment?.risk_factors?.hrt_years,
                      })}`}
                    </Typography>
                  )}
                </div>
              </div>

              <div className={classes.risk_options_container}>
                <Typography className={classes.risk_text}>{t('patient_risk.hormones')}</Typography>
                <div className={classes.risk_answers_container}>
                  <Typography className={classes.risk_text_answers}>
                    {transformSimpleBool(patientRiskAssessment.risk_factors?.hormones)}
                  </Typography>
                  {patientRiskAssessment?.risk_factors?.hormones_date && (
                    <Typography className={classes.risk_text_answers}>
                      {`${t('patient_risk.for_years', {
                        how_long: patientRiskAssessment?.risk_factors?.hormones_date,
                      })}`}
                    </Typography>
                  )}
                </div>
              </div>

              <div className={classes.risk_options_container}>
                <Typography className={classes.risk_text}>
                  {t('patient_risk.age_menarche')}
                </Typography>
                <Typography className={classes.risk_text_answers}>
                  {patientRiskAssessment.risk_factors?.age_menarche}
                </Typography>
              </div>

              <div className={classes.risk_options_container}>
                <Typography className={classes.risk_text}>{t('patient_risk.menopause')}</Typography>
                <Typography className={classes.risk_text_answers}>
                  {patientRiskAssessment.risk_factors?.age_menopause !== 0
                    ? transformMenopause(patientRiskAssessment.risk_factors?.age_menopause)
                    : t('patient_risk.period')}
                </Typography>
              </div>

              <div className={classes.risk_options_container}>
                <Typography className={classes.risk_text}>{t('patient_risk.radiation')}</Typography>
                {transformWithDate(
                  patientRiskAssessment.risk_factors?.radiation,
                  patientRiskAssessment.risk_factors?.radiation_date
                )}
              </div>

              <div className={classes.risk_options_container}>
                <Typography className={classes.risk_text}>
                  {t('patient_risk.diagnosed_cancer')}
                </Typography>
                <div className={classes.risk_answers_container}>
                  <Typography className={classes.risk_text_answers}>
                    {getBreastTranslation(patientRiskAssessment?.risk_factors?.diagnosed_cancer)}
                  </Typography>
                  {patientRiskAssessment?.risk_factors?.diagnosed_cancer_age && (
                    <Typography className={classes.risk_text_answers}>
                      {`${t('patient_risk.at')}
                      ${patientRiskAssessment?.risk_factors?.diagnosed_cancer_age} ${t(
                        'patient_risk.years'
                      )}`}
                    </Typography>
                  )}
                </div>
              </div>

              <div className={classes.risk_options_container}>
                <Typography className={classes.risk_text}>{t('patient_risk.chemo')}</Typography>
                <Typography className={classes.risk_text_answers}>
                  {transformSimpleBool(patientRiskAssessment.risk_factors?.chemo)}
                </Typography>
              </div>

              <div className={classes.risk_options_container}>
                <Typography className={classes.risk_text}>{t('patient_risk.births')}</Typography>
                <Typography className={classes.risk_text_answers}>
                  {patientRiskAssessment.risk_factors?.births}
                </Typography>
              </div>

              <div className={classes.risk_options_container}>
                <Typography className={classes.risk_text}>{t('patient_risk.alcohol')}</Typography>
                <Typography className={classes.risk_text_answers}>
                  {getAlcoholTranslation(patientRiskAssessment.risk_factors?.alcohol)}
                </Typography>
              </div>

              <div className={classes.risk_options_container}>
                <Typography className={classes.risk_text}>{t('patient_risk.family')}</Typography>
                <Typography className={classes.risk_text_answers}>
                  {patientRiskAssessment.risk_factors?.family}
                </Typography>
              </div>

              {patientRiskAssessment.risk_factors?.family != 0 && (
                <div className={classes.risk_options_container}>
                  <Typography className={classes.risk_text}>
                    {t('patient_risk.family_members')}
                  </Typography>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                    }}
                  >
                    {transformFamilyMembers(
                      patientRiskAssessment?.risk_factors?.family_members
                    ).map((item, index) => (
                      <Typography key={index} className={classes.risk_text_answers}>
                        {getFamilyTitle(item)}
                      </Typography>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </AccordionDetails>
        </Accordion>

        <div className={classes.remember_container}>
          <Typography className={classes.patient_risk_title}>
            {t('risk_management.remember')}
          </Typography>
          <Typography className={classes.remember_text}>{t('risk_management.fyi')}</Typography>
          <Typography className={classes.remember_text}>{t('risk_management.fyi_more')}</Typography>
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          data-test-id='dialog-close-action'
          className={classes.actionButton}
          onClick={() => {
            dispatch(setActiveDialog('risk_management_form'));
          }}
          color='primary'
          key='edit'
          variant='contained'
        >
          {t('patient_risk.edit_risk')}
        </Button>
        <Button
          data-test-id='dialog-close-action'
          className={classes.actionButton}
          onClick={() => {
            dispatch(setActiveDialog(null));
          }}
          color='primary'
          key='close'
          variant='contained'
        >
          {t('Console.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RiskAssessmentReport;
