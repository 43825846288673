import { createSlice } from '@reduxjs/toolkit';

import { fetchGet, fetchDelete } from '../../helpers/fetching';

const initialState = {
  snackbar: null,
  info: null,
  activeDialog: null,
  examinationListDrawer: false,
  actionToConfirm: null,
  notifications: [],
  darkState: true,
  contextMenu: null,
  landscape: true,
  serverLogs: '',

  presentation_lesions: 'contour_boxes',
  presentation_microcalc: 'contour_boxes',
  presentation_parenchyma: 'contour_boxes',
  presentation_folds: 'contour_boxes',
  presentation_blur: 'contour_boxes',
  presentation_pectoralis: 'contour_boxes',

  NIPPLE_ANGLE: null,
  NIPPLE_IN_PROFILE: null,
  PNL_DIFF: null,
  PNL_FROM_EDGE: null,
  SYMMETRY: null,
  IMF_DEPICTED: null,
  PARENCHYMA_CUTS: null,

  language: null,

  zoomedProjection: null,

  tabsIndex: 0,
};

export const guiSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setSnackbar: (state, action) => {
      state.snackbar = action.payload;
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    setActiveDialog: (state, action) => {
      state.activeDialog = action.payload;
    },
    setExaminationListDrawer: (state, action) => {
      state.examinationListDrawer = action.payload;
    },
    setActionToConfirm: (state, action) => {
      state.actionToConfirm = action.payload;
    },
    setContextMenu: (state, action) => {
      state.contextMenu = action.payload;
    },
    setLandscape: (state, action) => {
      state.landscape = action.payload;
    },

    setInfo: (state, action) => {
      state.info = action.payload;
    },
    setServerLogs: (state, action) => {
      state.serverLogs = action.payload;
    },
    setDarkState: (state, action) => {
      state.darkState = action.payload;
    },
    setGlobalSettings: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setPresentationOfLesions: (state, action) => {
      state.presentation_lesions = action.payload;
    },
    setPresentationOfMicrocalc: (state, action) => {
      state.presentation_microcalc = action.payload;
    },
    setPresentationOfParenchyma: (state, action) => {
      state.presentation_parenchyma = action.payload;
    },
    setPresentationOfFolds: (state, action) => {
      state.presentation_folds = action.payload;
    },
    setPresentationOfBlur: (state, action) => {
      state.presentation_blur = action.payload;
    },
    setPresentationOfPectoralis: (state, action) => {
      state.presentation_pectoralis = action.payload;
    },
    setZoomedProjection: (state, action) => {
      state.zoomedProjection = action.payload;
    },

    setTabsIndex: (state, action) => {
      state.tabsIndex = action.payload;
    },
  },
});

export const {
  setSnackbar,
  setNotifications,
  setActiveDialog,
  setExaminationListDrawer,
  setActionToConfirm,
  setContextMenu,
  setLandscape,
  setGlobalSettings,
  setServerLogs,
  setDarkState,
  setInfo,
  setPresentationOfLesions,
  setPresentationOfMicrocalc,
  setPresentationOfParenchyma,
  setPresentationOfFolds,
  setPresentationOfBlur,
  setPresentationOfPectoralis,
  setZoomedProjection,
  setTabsIndex,
} = guiSlice.actions;

export default guiSlice.reducer;

// Derrived
export const updateServerLogs = (msg) => {
  return (dispatch, getState) => {
    const lineLimit = 30;
    let newLogs = getState().gui.serverLogs + msg + '\n';
    if (newLogs.split('\n').length > lineLimit)
      newLogs = newLogs.split('\n').slice(-lineLimit, -1).join('\n') + '\n';
    dispatch(setServerLogs(newLogs));
  };
};

// Async
export const deleteNotification = (id) => {
  return async (dispatch, getState) => {
    const response = await fetchDelete(`notifications`, { id });
    if (response.success) {
      const reducedNotifications = getState().gui.notifications.filter((note) => note.id !== id);
      dispatch(setNotifications(reducedNotifications));
    } else
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
  };
};

export const fetchNotifications = (username) => {
  return async (dispatch) => {
    const response = await fetchGet(`notifications/${username}`);
    if (response.success) {
      dispatch(setNotifications(response.data));
    }
  };
};

export const toggleDarkState = () => {
  return (dispatch, getState) => {
    const curDarkState = getState().gui.darkState;
    dispatch(guiSlice.actions.setDarkState(!curDarkState));
  };
};

export const fetchGlobalSettings = () => {
  return async (dispatch) => {
    const response = await fetchGet('global_settings', 'bbox');
    if (response.success) dispatch(setGlobalSettings(response.data));
  };
};
