import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DraggableDialog from '../DraggableDialog/DraggableDialog';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as actions from '../../../../store/index';
import { fetchGet } from '../../../../helpers/fetching';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Typography,
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import animation from '../../../../resources/processing_animation.gif';

const useStyles = makeStyles((theme) => ({
  AccordionDetails: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '40vh',
  },

  listHeader: {
    fontSize: theme.fonts.responsiveMedium,
    fontWeight: 'bold',
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.texts.primary,
  },

  chipSmall: {
    margin: '6px',
  },

  helperText: {
    color: '#ff7676',
    fontSize: '0.8rem',
    margin: '1px',
  },

  table_placeholder: {
    color: 'gray',
    padding: '15%',
    border: 'none',
    fontSize: '10px',
    fontStyle: 'italic',
    padding: '14px 10px 10px',
    borderBottom: 'unset',
  },
}));

function UsersStats(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const notifications = useSelector((state) => state.gui.notifications);

  const [userStats, setUserStats] = useState([]);
  const [order, setOrder] = useState('ASC');
  const [orderBy, setOrderBy] = useState('');
  const [loading, setLoading] = useState(true);

  const heads = [
    { key: 'name', label: t('users.name'), align: 'left' },
    { key: 'role', label: t('users.role'), align: 'left' },
    { key: 'total_exams', label: t('users.totalexams'), align: 'center' },
    { key: 'stars', label: t('users.rating'), align: 'left' },
    { key: 'revisions', label: t('users.revisions'), align: 'center' },
    { key: 'notes', label: t('users.notes'), align: 'center' },
    // { key: 'logged', label: t('users.times'), align: 'left' },
    { key: 'last_login', label: t('users.lastlogin'), align: 'left' },
  ];

  const fetchUserStats = useCallback(async () => {
    const response = await fetchGet('user_stats');
    if (response.success) {
      const filteredStats = response.data.filter((user) => user.username !== 'admin');
      setUserStats(filteredStats);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchUserStats();
      setLoading(false);
    };
    fetchData();
  }, [fetchUserStats]);
  const EnhancedTableHead = ({ order, orderBy, onRequestSort }) => {
    const createSortHandler = (property) => (event) => onRequestSort(event, property);
    return (
      <TableHead style={{ background: '#4f4747' }}>
        <TableRow>
          {heads.map((head) => (
            <TableCell
              key={head.key}
              style={{ fontWeight: 'bold' }}
              align={head.align}
              padding='normal'
              sortDirection={orderBy === head.key ? order.toLowerCase() : false}
            >
              <TableSortLabel
                active={orderBy === head.key}
                direction={orderBy === head.key ? order.toLowerCase() : 'asc'}
                onClick={createSortHandler(head.key)}
              >
                {head.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['ASC', 'DESC']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === 'DESC'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'ASC';
    setOrder(isAsc ? 'DESC' : 'ASC');
    setOrderBy(property);
  };

  return (
    <Box sx={{ width: '100%' }}>
      {loading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <img
            style={{ width: '100px', height: '100px', borderRadius: '50%' }}
            src={animation}
            alt='logoanimation'
          />
          <Typography style={{ fontSize: '10px', color: 'gray', fontStyle: 'italic' }}>
            {t('Dashboard.loading')}
          </Typography>
        </div>
      ) : (
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size={'medium'}>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={userStats.length}
              />
              {userStats.length > 0 ? (
                <TableBody>
                  {userStats.sort(getComparator(order, orderBy)).map((row) => (
                    <TableRow hover tabIndex={-1} key={row.username}>
                      {heads.map((head) => {
                        if (head.key === 'stars')
                          return (
                            <TableCell align={head.align} key={`${row.username}-${head.key}`}>
                              <Rating
                                style={{
                                  color: theme.palette.primary.main,
                                }}
                                size='small'
                                value={row[head.key]}
                                readOnly
                                precision={0.25}
                              />
                            </TableCell>
                          );

                        return (
                          <TableCell align={head.align} key={`${row.username}-${head.key}`}>
                            {row[head.key] !== undefined ? row[head.key] || 0 : '--'}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography className={classes.table_placeholder}>
                        {t('UserPanel.nothing')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Paper>
      )}
    </Box>
  );
}

export default UsersStats;
