import { Component } from 'react';
import { withStyles } from '@material-ui/core';
import DraggableDialog from '../Layout/Dialogs/DraggableDialog/DraggableDialog';

const myStyle = (theme) => ({
  p1: {
    fontSize: theme.fonts.responsiveMedium,
  },
  p2: {
    fontSize: theme.fonts.responsiveMedium,
    color: theme.palette.secondary.light,
  },
});

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: '' };
  }

  static getDerivedStateFromError(_) {
    return { hasError: true };
  }

  componentDidCatch(error, _) {
    this.setState({
      errorMessage: error.message,
    });
  }

  render() {
    const { classes } = this.props;
    if (this.state.hasError) {
      return (
        <DraggableDialog
          maxWidth='sm'
          title={this.props.t('support.error')}
          open={true}
          onClose={() => this.setState({ hasError: false })}
          actions={[
            {
              onClick: () => this.setState({ hasError: false }),
              color: 'primary',
              label: this.props.t('Console.close'),
            },
          ]}
        >
          <p className={classes.p1}>{this.props.t('Layout.error')}</p>
          <p className={classes.p2}>{this.state.errorMessage}</p>
        </DraggableDialog>
      );
    }

    return this.props.children;
  }
}

export default withStyles(myStyle)(ErrorBoundary);
