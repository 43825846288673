import { createSlice } from '@reduxjs/toolkit';

import history from '../../history';
import { fetchGet, fetchPost, fetchDelete } from '../../helpers/fetching';
import { setSnackbar } from '../index';

const initialState = {
  totalExaminations: 0,
  currentExam: null,
  exam_list: [],
  currentlyProcessed: null,
  fetchingExamList: false,
  processingProgress: [0, 0],
  scrollPosition: 0,
  pagination: [0, 40], // list pagination [start, how many]
  orderBy: 'date',
  order: 'DESC',
  pendingReprocessing: false,
};

export const examinationsSlice = createSlice({
  name: 'examinations',
  initialState,
  reducers: {
    setTotalExaminations: (state, action) => {
      state.totalExaminations = action.payload;
    },
    setCurrentExam: (state, action) => {
      state.currentExam = action.payload;
    },
    setExaminationList: (state, action) => {
      state.exam_list = action.payload;
    },
    setCurrentlyProcessed: (state, action) => {
      state.currentlyProcessed = action.payload;
    },
    setInitialExamState: () => {
      return { ...initialState };
    },
    setFetchingExamList: (state, action) => {
      state.fetchingExamList = action.payload;
    },
    setProcessingProgress: (state, action) => {
      state.processingProgress = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    setPendingReprocessing: (state, action) => {
      state.pendingReprocessing = action.payload;
    },
    setScrollPosition: (state, action) => {
      state.scrollPosition = action.payload;
    },
  },
});

export const {
  setTotalExaminations,
  setCurrentExam,
  setCurrentlyProcessed,
  setExaminationList,
  setInitialExamState,
  setFetchingExamList,
  setProcessingProgress,
  setPagination,
  setPendingReprocessing,
  setScrollPosition,
  setOrder,
  setOrderBy,
} = examinationsSlice.actions;

export default examinationsSlice.reducer;

// Second order
export const showExamination = (exam_id) => {
  return async (dispatch) => {
    const newURL = `/bbox/results/details/${exam_id}`;
    if (history.location.pathname !== newURL) {
      history.push(newURL);
    }
  };
};

export const switchExamResult = (step) => {
  return (dispatch, useState) => {
    let idx = -1;
    const exam_list = useState().examinations.exam_list;
    const currentExam = useState().examinations.currentExam;

    exam_list.some((exam, i) => {
      if (exam.id === currentExam) {
        idx = i;
        return true;
      }
      return false;
    });
    if (idx !== -1) {
      let newIdx = idx + step;
      if (newIdx < 0) newIdx = exam_list.length - 1;
      if (newIdx >= exam_list.length) newIdx = 0;
      dispatch(setCurrentExam(exam_list[newIdx].id));
    }
  };
};

export const getNextPatientName = (step) => {
  return (dispatch, useState) => {
    let idx = -1;
    const exam_list = useState().examinations.exam_list;
    const currentExam = useState().examinations.currentExam;

    exam_list.some((exam, i) => {
      if (exam.id === currentExam) {
        idx = i;

        return true;
      }
      return false;
    });
    if (idx !== -1) {
      let newIdx = idx + step;
      if (newIdx < 0) newIdx = exam_list.length - 1;
      if (newIdx >= exam_list.length) newIdx = 0;
      return exam_list[newIdx].name;
    }
  };
};

// Async
export const fetchTotalExaminations = () => {
  return async (dispatch, getState) => {
    const filter = getState().filter;

    const response = await fetchPost('total_exams', { filter }, 'server', false);

    if (!response.success) {
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
      return;
    }

    if (response.data.n !== getState().examinations.totalExaminations)
      dispatch(setTotalExaminations(response.data.n));
  };
};

export const fetchExaminations = () => {
  return async (dispatch, getState) => {
    dispatch(setFetchingExamList(true));

    const filter = getState().filter;
    const pagination = getState().examinations.pagination;
    const order = getState().examinations.order;
    const orderBy = getState().examinations.orderBy;

    const response = await fetchPost(
      'exams',
      { filter, limit: pagination, order, orderBy },
      'server',
      false
    );
    if (!response.success) {
      dispatch(setFetchingExamList(false));
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
    } else {
      dispatch(setExaminationList(response.data));
      dispatch(setFetchingExamList(false));
    }
  };
};

export const deleteExam = (exam_id) => {
  return async (dispatch, getState) => {
    const totalExaminations = getState().examinations.totalExaminations;
    const currentExam = getState().examinations.currentExam;

    const response = await fetchDelete('delete', exam_id, 'bbox');
    if (response.success) {
      if (exam_id === currentExam) dispatch(switchExamResult(1));
      dispatch(setTotalExaminations(totalExaminations - 1));
      dispatch(
        setSnackbar({
          msg: `Examination deleted`,
          severity: 'success',
        })
      );
    } else {
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
    }
  };
};

export const deletePatient = (exam_id) => {
  return async (dispatch, getState) => {
    const currentExam = getState().examinations.currentExam;
    const response = await fetchDelete('patient_delete', exam_id, 'bbox');
    if (response.success) {
      dispatch(fetchExaminations());
      if (currentExam === exam_id) {
        dispatch(switchExamResult(1));
      }
      dispatch(
        setSnackbar({
          msg: `Patient deleted`,
          severity: 'success',
        })
      );
    } else {
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
    }
  };
};
