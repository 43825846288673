import React from 'react';
import { Grid, Tooltip, useTheme } from '@material-ui/core';
import SummaryParagraphSubHeader from '../SummaryParagraphSubHeader';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export default function PatientID(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const patient_id = useSelector((store) => store.results.patient_id);

  return (
    <>
      <SummaryParagraphSubHeader subHeader={t('ResultView.pat_id')} />

      <Tooltip placement='bottom-end' title={patient_id}>
        <p
          style={{
            ...props.style,
            letterSpacing: '0.1rem',
            textTransform: 'uppercase',
            whiteSpace: 'break-spaces',
            maxWidth: '90%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: theme.palette.texts.primary,
          }}
        >
          {patient_id}
        </p>
      </Tooltip>
    </>
  );
}
