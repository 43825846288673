import { Badge, Chip, Fab, Paper, Popper, Tooltip } from '@material-ui/core';
import HowToRegIcon from '@material-ui/icons/HowToReg';

function TooltipBadgeFab({
  content,
  open,
  badgeContent,
  onClick,
  onMouseEnter,
  onMouseLeave,
  popperContent,
  anchorRef,
  popperOpen,
  classes,
  theme,
  t,
}) {
  return (
    <Tooltip placement='right-end' arrow title={content} open={open}>
      <span>
        <Chip
          className={classes.reviewer_badge}
          label={badgeContent}
          variant='outlined'
          onClick={onClick}
          size='small'
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          ref={anchorRef}
          color='secondary'
        />

        {/* <Badge
          color='secondary'
          overlap='circular'
          badgeContent={badgeContent}
          classes={{ badge: classes.reviewer_badge }}
          size='small'
        >
          <Fab
            size='small'
            className={classes.reviewer_fabs}
            onClick={onClick}
            color='primary'
            ref={anchorRef}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <HowToRegIcon style={{ fontSize: '18px' }} />
          </Fab>
        </Badge> */}

        <Popper
          open={popperOpen}
          anchorEl={anchorRef.current}
          placement='bottom-start'
          disablePortal={false}
          keepMounted={false}
          modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'scrollParent',
            },
          }}
        >
          <Paper className={classes.popperPaper}>{popperContent}</Paper>
        </Popper>
      </span>
    </Tooltip>
  );
}

export default TooltipBadgeFab;
