import React, { useEffect, useState } from 'react';
import { Drawer } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

import Accordion from './Accordion';
import { setModelsList, fetchModelsList } from '../../../store';
import ModelsTable from './ModelsTable';
import SearchBar from '../DrawerExamList/SearchBar/SearchBar';
import ButtonGroup from './ButtonGroup';

const useStyles = makeStyles((theme) => ({
  Drawer: {
    caretColor: 'rgba(0,0,0,0)',
  },

  drawerPaper: {
    position: 'relative',
    overflow: 'hidden',
  },

  searchBar: {
    border: '1px solid',
    borderRadius: '0px',
    borderColor: theme.palette.divider,
  },
}));

export default function DrawerModelList(props) {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { modelsList } = useSelector((state) => state.models);
  const examinationListDrawer = useSelector((state) => state.gui.examinationListDrawer);

  const [filteredModelList, setFilteredModelsList] = useState([]);
  const [modelsFilter, setModelsFilter] = useState('all');
  const [search, setSearch] = useState('');
  const [openAccordion, setOpenAccordion] = useState(null);

  const drawerStyle = { width: theme.drawerWidth };
  if (!props.open) {
    drawerStyle.width = 0;
    drawerStyle.transition = 'width 0s 100ms';
  }

  // Fetching
  useEffect(() => {
    dispatch(fetchModelsList(modelsFilter));
    return () => {
      dispatch(setModelsList(null));
    };
  }, [dispatch, modelsFilter]);

  // Search
  useEffect(() => {
    const filtered = {};
    for (let task in modelsList)
      filtered[task] = modelsList[task].filter((model) =>
        model.name.toLowerCase().includes(search.toLowerCase())
      );
    setFilteredModelsList(filtered);
  }, [search, modelsList]);

  return (
    <Drawer
      className={classes.Drawer}
      style={drawerStyle}
      variant='persistent'
      anchor='left'
      open={examinationListDrawer}
      classes={{ paper: classes.drawerPaper }}
    >
      <>
        <ButtonGroup filter={modelsFilter} setFilter={setModelsFilter} />
        <SearchBar
          className={classes.searchBar}
          value={search}
          onChange={setSearch}
          onRequestSearch={setSearch}
          onCancelSearch={setSearch}
          placeholder='Search'
        />
      </>
      {!modelsList ? (
        <>
          <CircularProgress
            style={{
              marginLeft: '50%',
              marginTop: '50%',
            }}
          />
        </>
      ) : (
        <>
          {Object.keys(filteredModelList)
            .filter((task) => filteredModelList[task].length)
            .map((model_task) => (
              <Accordion
                key={model_task}
                name={model_task}
                open={openAccordion === model_task}
                setOpen={() => {
                  if (openAccordion === model_task) setOpenAccordion(null);
                  else setOpenAccordion(model_task);
                }}
              >
                <ModelsTable
                  modelsList={filteredModelList[model_task]}
                  modelsFilter={modelsFilter}
                />
              </Accordion>
            ))}
        </>
      )}
    </Drawer>
  );
}
