import React from 'react';
import { Grid, useTheme } from '@material-ui/core';
import SummaryParagraphSubHeader from '../SummaryParagraphSubHeader';
import { useTranslation } from 'react-i18next';

export default function PatientAge(props) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <SummaryParagraphSubHeader subHeader={props.title} />
      <p
        style={{
          ...props.style,
          letterSpacing: '0.1rem',
          color: theme.palette.texts.primary,
        }}
      >
        {props.result}
      </p>
    </>
  );
}
