import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  info: {
    marginLeft: 'auto',
  },

  AccordionDetails: {
    marginLeft: '30px',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },

  Accordion: {
    width: '45%',
    margin: '6px',
    boxShadow: 'none',
    border: '0.5px solid #80808047',
    borderRadius: '5px',

    '&.Mui-expanded': {
      margin: '6px',
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
  },

  SelectionBox: {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: '600px',
    padding: theme.spacing(1),
    overflowY: 'auto',
    alignItems: 'flex-start',
  },

  checkboxLabels: {
    fontSize: theme.fonts.responsiveSmall,
    wordBreak: 'break-word',
  },

  titleFilterCard: {
    letterSpacing: '0.2rem',
    textTransform: 'uppercase',
    color: theme.palette.texts.primary,
    inlineSize: 'min-content',
    margin: 0,
  },

  InstitutionsButton: {
    marginLeft: 'auto',
  },

  SelectButton: {
    marginLeft: '1%',
  },
}));
