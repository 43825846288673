import React, { useState, useEffect } from 'react';
import {
  TextField,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Collapse,
  CardHeader,
  CardContent,
  Card,
} from '@material-ui/core';
import ButtonGroupRestrictions from './ButtonGroupRestrictions';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { postOperatorNotes } from '../../../../../store';
import SummaryParagraphHeader from '../SummaryParagraphHeader';
import { ExpandMore } from '@material-ui/icons';
import { DIAGNOSTICS_MICROCALC, DIAGNOSTICS_OPACITIES } from '../../../../../config';

const useStyles = makeStyles((theme) => ({
  input: {
    fontSize: theme.fonts.responsiveMedium,
    letterSpacing: '0.1rem',
    padding: '0',
  },

  multiline: {
    padding: '4px',
  },
  accordion_container: {
    width: '100%',
  },

  accordion_summary: {
    borderRadius: '5px',
    display: 'flex',
    height: '30px',
    minHeight: '30px',

    '&.Mui-expanded': {
      height: '30px',
      minHeight: '30px',
      borderRadius: '5px 5px 0 0',
    },
  },

  accordion_details: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    background: '#454545',
    borderRadius: '0 0 5px 5px',
  },
}));

export default function NotesSummary(props) {
  const { t } = useTranslation();
  const [text, setText] = useState('');
  const [typingTimeout, setTypingtimeout] = useState(null);
  const dispatch = useDispatch();
  const storeText = useSelector((state) => state.results.notes);
  const classes = useStyles();
  const patient_id = useSelector((store) => store.results.patient_id);
  const darkState = useSelector((store) => store.gui.darkState);

  const [painButtonClicked, setPainButtonClicked] = useState(false);
  const [immobilityButtonClicked, setImmobilityButtonClicked] = useState(false);
  const [sizeButtonClicked, setSizeButtonClicked] = useState(false);

  function inputHandler(txt) {
    if (txt.length > 300) txt = txt.slice(0, 300);
    setText(txt);

    if (typingTimeout) clearTimeout(typingTimeout);

    const timeout = setTimeout(async () => {
      dispatch(postOperatorNotes(txt, t));
    }, 2000);

    setTypingtimeout(timeout);
  }

  useEffect(() => {
    setText(storeText);

    if (storeText.includes('pain')) {
      setPainButtonClicked(true);
    } else {
      setPainButtonClicked(false);
    }

    if (storeText.includes('mobility')) {
      setImmobilityButtonClicked(true);
    } else {
      setImmobilityButtonClicked(false);
    }

    if (storeText.includes('big breast')) {
      setSizeButtonClicked(true);
    } else {
      setSizeButtonClicked(false);
    }
  }, [storeText, patient_id, props]);

  const slideProps = {
    mountOnEnter: true,
    unmountOnExit: true,
    timeout: { enter: 225, exit: 225 },
  };

  return (
    <Card style={{ borderRadius: '5px', height: '100%' }}>
      <CardHeader
        style={{ padding: '0', background: darkState ? '#535353' : '#b9b9b9' }}
        title={
          <div
            style={{
              display: 'flex',
              width: '100%',
              padding: '0 16px',
              justifyContent: 'space-between',
            }}
          >
            <SummaryParagraphHeader
              header={t('ResultView.Notes')}
              style={{
                margin: 0,
                display: 'flex',
                alignItems: 'flex-end',
              }}
            />
          </div>
        }
      />
      <CardContent style={{ padding: '0px' }}>
        <Grid
          alignItems='center'
          justifyContent='space-between'
          container
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            minHeight:
              !(
                (DIAGNOSTICS_MICROCALC || DIAGNOSTICS_OPACITIES) &&
                localStorage.getItem('role') !== 'technician'
              ) && '16vh',
          }}
        >
          <Grid
            item
            xs={10}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <TextField
              id='outlined-multiline-static'
              multiline
              minRows={3}
              fullWidth
              variant='outlined'
              value={text}
              onChange={(e) => inputHandler(e.target.value)}
              InputProps={{
                classes: {
                  input: classes.input,
                  multiline: classes.multiline,
                },
              }}
              style={{ maxHeight: '80px', overflowY: 'auto', padding: '0', marginLeft: '16px' }}
            />
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              padding: '0 4px 4px 0',
            }}
          >
            <ButtonGroupRestrictions
              inSummary={true}
              direction={'column'}
              clickPain={() => {
                inputHandler(text + ' ' + t('Limitations.pain') + '\n'), setPainButtonClicked(true);
              }}
              clickImmobility={() => {
                inputHandler(text + ' ' + t('Limitations.immobility') + '\n'),
                  setImmobilityButtonClicked(true);
              }}
              clickSize={() => {
                inputHandler(text + ' ' + t('Limitations.size') + '\n'), setSizeButtonClicked(true);
              }}
              painButtonClicked={painButtonClicked}
              immobilityButtonClicked={immobilityButtonClicked}
              sizeButtonClicked={sizeButtonClicked}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
