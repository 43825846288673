import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import {
  ListSubheader,
  Switch,
  FormControlLabel,
  CardContent,
  Grid,
  Card,
  CardHeader,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import ButonGroupDicomFilter from '../ButtonGroup';

const useStyles = makeStyles((theme) => ({
  titleSettingsCard: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontSize: '1.1rem',
  },
  action: {
    margin: 0,
  },
  listItem: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'flex-start',
    color: theme.palette.texts.secondary,
  },
  listHeader: {
    fontSize: theme.typography.h6.fontSize,
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.texts.primary,
  },
  list: {
    width: '100%',
    minHeight: '100%',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '5px',
  },
}));

const emptyForm = {
  name: '',
  tag: '',
  action: '',
  keywords: '',
};

const DicomFilters = (props) => {
  const classes = useStyles();
  const [fieldValues, setFieldValues] = useState(emptyForm);
  const [selected, setSelected] = useState(0);
  const { t } = useTranslation();

  function onTextChange(event) {
    const { value, name } = event.target;
    const newValues = { ...fieldValues };
    newValues[name] = value;
    setFieldValues(newValues);
  }

  function addNewItem(_) {
    if (validate()) props.addItem(fieldValues);
  }

  function validate() {
    let valid = true;
    for (let field in fieldValues) {
      if (!fieldValues[field].length) {
        props.setSnackbar({
          msg: 'All fields are required.',
          severity: 'error',
        });
        return false;
      }
    }
    return valid;
  }

  return (
    <Card elevation={1}>
      <CardHeader
        classes={{
          action: classes.action,
        }}
        disableTypography={true}
        title={
          <Typography className={classes.titleSettingsCard} variant='h6'>
            {t('Settings.filters')}
          </Typography>
        }
        action={
          <FormControlLabel
            // label="Apply DICOM Filter"
            control={<Switch checked={props.checked} onChange={props.onChange} name={props.name} />}
          />
        }
      ></CardHeader>
      <CardContent>
        <Grid spacing={2} container direction='column'>
          <Grid
            item
            container
            wrap='nowrap'
            direction='row'
            justifyContent='space-between'
            spacing={2}
          >
            <Grid xs={6} sm={6} md={6} container item direction='column' spacing={2}>
              <Grid item>
                <TextField
                  name='name'
                  label={t('Settings.name')}
                  key='name'
                  fullWidth={true}
                  value={fieldValues.name}
                  onChange={onTextChange}
                  variant='outlined'
                />
              </Grid>
              <Grid item>
                <TextField
                  name='tag'
                  label={t('Settings.dicom_tag')}
                  key={'tag'}
                  fullWidth={true}
                  value={fieldValues.tag}
                  onChange={onTextChange}
                  variant='outlined'
                />
              </Grid>
              <Grid item>
                <Select
                  name='action'
                  value={fieldValues.action}
                  onChange={onTextChange}
                  label='Action'
                  fullWidth={true}
                  variant='outlined'
                >
                  <MenuItem value={'ANY'}>{t('Settings.any')}</MenuItem>
                  <MenuItem value={'NONE'}>{t('Settings.none')}</MenuItem>
                </Select>
              </Grid>
              <Grid item>
                <TextField
                  name='keywords'
                  label={t('Settings.keywords')}
                  key='keywords'
                  fullWidth={true}
                  value={fieldValues.keywords}
                  onChange={onTextChange}
                  variant='outlined'
                />
              </Grid>
            </Grid>
            <Grid xs={6} sm={6} md={6} item>
              <List
                aria-labelledby='list-subheader'
                subheader={
                  <ListSubheader className={classes.listHeader} component='div' id='list-subheader'>
                    {t('Settings.implemented_filters')}
                  </ListSubheader>
                }
                className={classes.list}
              >
                {props.items.map((item, i) => (
                  <ListItem
                    className={classes.listItem}
                    button
                    key={item.name}
                    onClick={() => {
                      setFieldValues(props.items[i]);
                      setSelected(i);
                    }}
                    selected={i === selected}
                  >
                    {item.name}
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
          <Grid item>
            <ButonGroupDicomFilter
              onClickDelete={() => {
                props.deleteItem(fieldValues);
                setFieldValues(emptyForm);
              }}
              onClickAdd={() => {
                addNewItem();
                setFieldValues(emptyForm);
              }}
              onClickClear={() => setFieldValues(emptyForm)}
            ></ButonGroupDicomFilter>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DicomFilters;
