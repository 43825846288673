import { createSlice } from '@reduxjs/toolkit';

import { fetchPost } from '../../helpers/fetching';
import { setSnackbar } from '../index';
import {
  format_pie_dens_data,
  format_quality_bar_data,
  format_bar_exams_data,
  format_histogram_dose,
  format_line_qual_data,
  format_line_comp_data,
  format_quality_pie_data,
} from '../../helpers/formatChartsData';
import { array } from 'prop-types';
import { OVERALL_QUALITY, DIAGNOSTICS_OPACITIES, DIAGNOSTICS_MICROCALC, DENSITY } from '../../config';

const initialState = {
  pieDensityData: [],
  loadingDensity: false,

  pieQualityData: [],
  loadingQuality: false,

  pieStudyQualityData: [],
  loadingQuality: false,

  performanceQualityData: [],
  loadingPerformance: false,

  lineCompressionData: [],
  loadingCompression: false,

  barQualityData: {},
  loadingFeatures: false,

  barExamsData: [],
  loadingExams: false,

  doseData: {
    histo: [],
    aver: 0,
  },
  loadingDose: false,

  opacities: null,
  loadingOpacities: false,

  microcalc: null,
  loadingMicrocalc: false,
};

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    setStatistics: (_, action) => {
      return { ...action.payload };
    },

    setInitialStatistics: () => {
      return { ...initialState };
    },

    setBarExamsData: (state, action) => {
      state.barExamsData = action.payload;
    },

    setPieDensityData: (state, action) => {
      state.pieDensityData = action.payload;
    },

    setPieQualityData: (state, action) => {
      state.pieQualityData = action.payload;
    },

    setPieStudyQualityData: (state, action) => {
      state.pieStudyQualityData = action.payload;
    },

    setDoseData: (state, action) => {
      state.doseData = action.payload;
    },

    setOpacities: (state, action) => {
      state.opacities = action.payload;
    },

    setMicrocalc: (state, action) => {
      state.microcalc = action.payload;
    },

    setBarQualityData: (state, action) => {
      state.barQualityData = action.payload;
    },

    setPerformanceQualityData: (state, action) => {
      state.performanceQualityData = action.payload;
    },

    setLineCompressionData: (state, action) => {
      state.lineCompressionData = action.payload;
    },

    setLoading: (state, action) => {
      if (!(action.payload instanceof Array) || action.payload.length < 2) {
        return;
      }
      const key = `loading${action.payload[0]}`;
      if (key in state) {
        state[key] = action.payload[1];
      }
    },
  },
});

export const {
  setStatistics,
  setInitialStatistics,
  setBarExamsData,
  setPieDensityData,
  setPieQualityData,
  setPieStudyQualityData,
  setDoseData,
  setOpacities,
  setMicrocalc,
  setBarQualityData,
  setPerformanceQualityData,
  setLineCompressionData,
  setLoading,
} = statisticsSlice.actions;

export default statisticsSlice.reducer;

// Async
export const fetchMostStatistics = (theme, t, signal) => {
  return async (dispatch, getState) => {
    const filter = getState().filter;

    const fetchingPromises = [
      fetchExamStats(filter, dispatch, t, signal),
      fetchQualStats(filter, dispatch, theme, t, signal),
      fetchDoseStats(filter, dispatch, theme, signal),
      fetchPGMIStats(filter, dispatch, t, signal),
    ]



    if (OVERALL_QUALITY) {
      fetchingPromises.push(
        fetchStudyQualStats(filter, dispatch, theme, t, signal)
      )
    }

    if (DENSITY) {
      fetchingPromises.push(
        fetchDensStats(filter, dispatch, theme, t, signal)
      )
    }

    if (DIAGNOSTICS_OPACITIES) {
      fetchingPromises.push(
        fetchOpacitiesStats(filter, dispatch, signal)
      )
    }

    if (DIAGNOSTICS_MICROCALC) {
      fetchingPromises.push(
        fetchMicrocalcStats(filter, dispatch, signal)
      )
    }

    Promise.all(fetchingPromises);
  };
};

export function fetchPerformanceAction(signal) {
  return async (dispatch, getState) => {
    dispatch(setLoading(['Performance', true]));
    let filterwithDates = { ...getState().filter };

    const dates = getState().dates.performanceDates;
    if (!!dates)
      filterwithDates = {
        ...filterwithDates,
        dates,
      };
    const performanceResponse = await fetchPost('statistics/performance', {
      filter: filterwithDates,
    }, undefined, undefined, undefined, signal);
    if (performanceResponse.success) {
      const performance_data = format_line_qual_data(performanceResponse.data);
      dispatch(setPerformanceQualityData(performance_data));
      dispatch(setLoading(['Performance', false]));
    } else if (!performanceResponse.msg.includes('aborted'))
      dispatch(
        setSnackbar({
          msg: performanceResponse.msg,
          severity: 'error',
        })
      );
  };
}

export function fetchCompressionAction(signal) {
  return async (dispatch, getState) => {
    dispatch(setLoading(['Compression', true]));
    let filterwithDates = { ...getState().filter };

    const dates = getState().dates.compressionDates;
    if (!!dates)
      filterwithDates = {
        ...filterwithDates,
        dates,
      };

    const compressionResponse = await fetchPost('statistics/compression', {
      filter: filterwithDates,
    }, undefined, undefined, undefined, signal);
    if (compressionResponse.success) {
      const compression_data = format_line_comp_data(compressionResponse.data);
      dispatch(setLineCompressionData(compression_data));
      dispatch(setLoading(['Compression', false]));
    } else if (!compressionResponse.msg.includes('aborted'))
      dispatch(
        setSnackbar({
          msg: compressionResponse.msg,
          severity: 'error',
        })
      );
  };
}

async function fetchExamStats(filter, dispatch, t, signal) {
  dispatch(setLoading(['Exams', true]));
  const examResponse = await fetchPost('statistics/examinations', {
    filter: filter,
  }, undefined, undefined, undefined, signal);
  if (examResponse.success) {
    const bar_exams_data = format_bar_exams_data(examResponse.data, t, signal);
    dispatch(setBarExamsData(bar_exams_data));
    dispatch(setLoading(['Exams', false]));
  } else if (!examResponse.msg.includes('aborted'))
    dispatch(
      setSnackbar({
        msg: examResponse.msg,
        severity: 'error',
      })
    );
}

async function fetchDensStats(filter, dispatch, theme, t, signal) {
  dispatch(setLoading(['Density', true]));
  const densityResponse = await fetchPost('statistics/density', {
    filter: filter,
  }, undefined, undefined, undefined, signal);
  if (densityResponse.success) {
    const pie_dens_data = format_pie_dens_data(densityResponse.data, theme, t, signal);
    dispatch(setPieDensityData(pie_dens_data));
    dispatch(setLoading(['Density', false]));
  } else if (!densityResponse.msg.includes('aborted'))
    dispatch(
      setSnackbar({
        msg: densityResponse.msg,
        severity: 'error',
      })
    );
}

async function fetchQualStats(filter, dispatch, theme, t, signal) {
  dispatch(setLoading(['Quality', true]));
  const qualityResponse = await fetchPost('statistics/quality', {
    filter: filter,
  }, undefined, undefined, undefined, signal);
  if (qualityResponse.success) {
    const pie_qual_data = format_quality_pie_data(qualityResponse.data, theme, t);
    dispatch(setPieQualityData(pie_qual_data));
    dispatch(setLoading(['Quality', false]));
  } else if (!qualityResponse.msg.includes('aborted'))
    dispatch(
      setSnackbar({
        msg: qualityResponse.msg,
        severity: 'error',
      })
    );
}

async function fetchStudyQualStats(filter, dispatch, theme, t, signal) {
  dispatch(setLoading(['StudyQuality', true]));
  const qualityResponse = await fetchPost('statistics/study_quality', {
    filter: filter,
  }, undefined, undefined, undefined, signal);
  if (qualityResponse.success) {
    const pie_qual_data = format_quality_pie_data(qualityResponse.data, theme, t);
    dispatch(setPieStudyQualityData(pie_qual_data));
    dispatch(setLoading(['StudyQuality', false]));
  } else if (!qualityResponse.msg.includes('aborted'))
    dispatch(
      setSnackbar({
        msg: qualityResponse.msg,
        severity: 'error',
      })
    );
}

async function fetchDoseStats(filter, dispatch, theme, signal) {
  dispatch(setLoading(['Dose', true]));
  const doseResponse = await fetchPost('statistics/dose', {
    filter: filter,
  }, undefined, undefined, undefined, signal);
  if (doseResponse.success) {
    const dose_histo = format_histogram_dose(doseResponse.data.histo, theme);
    dispatch(
      setDoseData({
        histo: dose_histo,
        aver: doseResponse.data.avg,
      })
    );
    dispatch(setLoading(['Dose', false]));
  } else if (!doseResponse.msg.includes('aborted'))
    dispatch(
      setSnackbar({
        msg: doseResponse.msg,
        severity: 'error',
      })
    );
}

async function fetchOpacitiesStats(filter, dispatch, signal) {
  dispatch(setLoading(['Opacities', true]));
  const diagnosticsResponse = await fetchPost('statistics/opacities', {
    filter: filter,
  }, undefined, undefined, undefined, signal);
  if (diagnosticsResponse.success) {
    dispatch(setOpacities(diagnosticsResponse.data));
    dispatch(setLoading(['Opacities', false]));
  } else if (!diagnosticsResponse.msg.includes('aborted'))
    dispatch(
      setSnackbar({
        msg: diagnosticsResponse.msg,
        severity: 'error',
      })
    );
}

async function fetchMicrocalcStats(filter, dispatch, signal) {
  dispatch(setLoading(['Microcalc', true]));
  const diagnosticsResponse = await fetchPost('statistics/microcalc', {
    filter: filter,
  }, undefined, undefined, undefined, signal);
  if (diagnosticsResponse.success) {
    dispatch(setMicrocalc(diagnosticsResponse.data));
    dispatch(setLoading(['Microcalc', false]));
  } else if (!diagnosticsResponse.msg.includes('aborted'))
    dispatch(
      setSnackbar({
        msg: diagnosticsResponse.msg,
        severity: 'error',
      })
    );
}

async function fetchPGMIStats(filter, dispatch, t, signal) {
  dispatch(setLoading(['Features', true]));
  const pgmiResponse = await fetchPost('statistics/pgmi', {
    filter: filter,
  }, undefined, undefined, undefined, signal);
  if (pgmiResponse.success) {
    const bar_quality_data = format_quality_bar_data(pgmiResponse.data, t);
    dispatch(setBarQualityData(bar_quality_data));
    dispatch(setLoading(['Features', false]));
  } else if (!pgmiResponse.msg.includes('aborted'))
    dispatch(
      setSnackbar({
        msg: pgmiResponse.msg,
        severity: 'error',
      })
    );
}

