import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  institutions_dialog: {
    zIndex: theme.zIndex.backdrops,
  },

  RuleItem: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },

  left: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    textAlign: 'end',
    minWidth: 'fit-content',
  },

  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    width: '600px',
  },

  center: {
    flex: 0.2,
    display: 'flex',
    justifyContent: 'center',
  },
}));
