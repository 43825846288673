import React, { useState } from 'react';
import * as actions from '../../../../store/index';
import { Grid, Tabs, Tab, Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useStyles } from './CustomFilter.style';
import UploadCSV from './CustomFilterModules/UploadCSV';
import CreateList from './CustomFilterModules/CreateList';
import DraggableDialog from '../../Dialogs/DraggableDialog/DraggableDialog';
import SaveFilterDialog from '../CustomFilter/CustomFilterModules/SaveFilter/SaveFilterDialog';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function CustomFilter(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [saveFilter, setSaveFilter] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [pendingTab, setPendingTab] = useState(null);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    if (patientList && patientList.length > 0) {
      setShowDialog(true);
      setPendingTab(newValue);
    } else setValue(newValue);
  };

  const saveCustomFilter = () => {
    setSaveFilter(true);
  };

  const closeUsersDialog = () => {
    dispatch(actions.setActiveDialog(null));
  };

  return (
    <>
      <DraggableDialog
        maxWidth='xl'
        open={props.open}
        title={t('Filters.custom_filter')}
        onClose={() => dispatch(actions.setActiveDialog(null))}
        aria-labelledby='custom-filter-dialog'
        actions={[
          { onClick: closeUsersDialog, color: 'primary', label: t('export.cancel') },
          patientList &&
            patientList.length > 0 && {
              onClick: saveCustomFilter,
              color: 'primary',
              label: t('Filters.apply'),
            },
        ]}
        contentStyle={{ overflowY: 'hidden' }}
      >
        <Grid
          container
          direction='row'
          justifyContent='space-evenly'
          alignItems='flex-start'
          spacing={1}
          style={{ minHeight: '30vh' }}
        >
          <Grid item xs={1}>
            <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100%' }}>
              <Tabs
                orientation='vertical'
                variant='scrollable'
                value={value}
                onChange={handleChange}
                aria-label='Vertical tabs example'
                sx={{ borderRight: 1, borderColor: 'divider' }}
              >
                <Tab label={t('Filters.upload_file')} {...a11yProps(0)} />
                <Tab label={t('Filters.create_list')} {...a11yProps(1)} />
              </Tabs>
            </Box>
          </Grid>
          <Grid item xs={11}>
            <TabPanel value={value} index={0}>
              <UploadCSV patientList={patientList} setPatientList={setPatientList} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <CreateList patientList={patientList} setPatientList={setPatientList} />
            </TabPanel>
          </Grid>
        </Grid>
      </DraggableDialog>
      {(saveFilter || showDialog) && (
        <SaveFilterDialog
          open={saveFilter || showDialog}
          onClose={() => (setShowDialog(false), setSaveFilter(false))}
          patientList={patientList}
          dialogText={t(saveFilter ? 'Filters.save_filter_title' : 'Filters.save_filter_tab')}
          setPatientList={setPatientList}
          changingTabs={showDialog}
          pendingTab={pendingTab}
          setValue={setValue}
        />
      )}
    </>
  );
}
