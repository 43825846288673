import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DraggableDialog from '../../Dialogs/DraggableDialog/DraggableDialog';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  dialogText: {
    letterSpacing: '0.1rem',
    fontSize: theme.typography.h6.fontSize,
    color: theme.palette.texts.secondary,
  },
  dialogUserName: {
    letterSpacing: '0.1rem',
    textTransform: 'Uppercase',
    fontSize: theme.typography.h6.fontSize,
    color: theme.palette.primary.main,
  },
}));

function InRevisionDialog(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <DraggableDialog
      maxWidth='sm'
      title={t('revision.discard_dialog_header')}
      open={props.open}
      actions={[
        {
          onClick: () => {
            props.onDiscard();
          },
          color: 'primary',
          label: t('revision.discard'),
        },
        {
          onClick: () => {
            props.onClose();
          },

          color: 'primary',
          label: t('revision.go_back'),
        },
        {
          onClick: () => {
            props.onSave();
          },

          color: 'primary',
          label: t('revision.save_all'),
        },
      ]}
      onClose={props.onClose}
    >
      <Typography className={classes.dialogText}>{t('revision.discard_dialog_text')}</Typography>
    </DraggableDialog>
  );
}

export default InRevisionDialog;
