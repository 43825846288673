import React, { Fragment, useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';

import DraggableDialog from '../DraggableDialog/DraggableDialog';
import { useTranslation } from 'react-i18next';

const headers = [
  'Density',
  'Nipple',
  'Parenchyma',
  'Pectoralis CC',
  'PNL',
  'Pect. Angle',
  'IMF',
  'Nipple Location',
  'Pect. M. Top',
  'Pect. M. Bottom',
];

const LabelingStats = (props) => {
  const evaluations = useSelector((state) => state.results.evaluations);
  const [mergedEvaluations, setMergedEvaluations] = useState([]);
  const theme = useTheme();
  const { t } = useTranslation();

  useEffect(() => {
    setMergedEvaluations({
      ...evaluations?.density,
      ...evaluations?.quality,
    });
  }, [evaluations]);

  function getCell(headerValue, result, bbox) {
    if (!result || !bbox)
      return {
        value: '',
        style: {},
      };

    let location;
    const warningStyle = {
      background: theme.palette.secondary.dark,
      color: 'black',
    };

    try {
      switch (headerValue) {
        case 'Density':
          return {
            value: result?.DensClass,
            style: result?.DensClass === bbox.DensClass ? {} : warningStyle,
          };
        case 'Nipple':
          return {
            value: result?.Nipple,
            style: result?.Nipple === bbox.Nipple ? {} : warningStyle,
          };
        case 'Parenchyma':
          return {
            value: result?.ParenchymaCuts,
            style: result?.ParenchymaCuts === bbox.ParenchymaCuts ? {} : warningStyle,
          };
        case 'Pectoralis CC':
          return {
            value: result?.PectoralisCC,
            style: result?.PectoralisCC === bbox.PectoralisCC ? {} : warningStyle,
          };
        case 'PNL':
          return {
            value: `${result?.PectoNippleLength.toFixed(2)} mm`,
            style: result?.PectoNippleLength === bbox.PectoNippleLength ? {} : warningStyle,
          };
        case 'Pect. Angle':
          return {
            value: result?.PectAngle,
            style: result?.PectAngle === bbox.PectAngle ? {} : warningStyle,
          };
        case 'IMF':
          return {
            value: result?.Infram,
            style: result?.Infram === bbox.Infram ? {} : warningStyle,
          };
        case 'Nipple Location':
          location = result?.location_nipple;
          return {
            value: `${location[0].toFixed(3)}, ${location[1].toFixed(3)}`,
            style: result?.location_nipple === bbox.location_nipple ? {} : warningStyle,
          };
        case 'Pect. M. Top':
          if (!result?.hasOwnProperty('location_pecto_top'))
            return {
              value: '',
              style: {},
            };
          location = result?.location_pecto_top;
          return {
            value: `${location[0].toFixed(3)}, ${location[1].toFixed(3)}`,
            style: result?.location_pecto_top === bbox.location_pecto_top ? {} : warningStyle,
          };
        case 'Pect. M. Bottom':
          if (!result?.hasOwnProperty('location_pecto_bottom'))
            return {
              value: '',
              style: {},
            };
          location = result?.location_pecto_bottom;
          return {
            value: `${location[0].toFixed(3)}, ${location[1].toFixed(3)}`,
            style: result?.location_pecto_bottom === bbox.location_pecto_bottom ? {} : warningStyle,
          };
        case 'Microcalc Susp.':
          return {
            value: !!result?.overlays?.microcalcMalignant ? 'present' : 'none',
            style:
              JSON.stringify(result?.overlays?.microcalcMalignant) ===
              JSON.stringify(bbox?.overlays?.microcalcMalignant)
                ? {}
                : warningStyle,
          };
        case 'Microcalc Unsusp.':
          return {
            value: !!result?.overlays?.microcalcBenign ? 'present' : 'none',
            style:
              JSON.stringify(result?.overlays?.microcalcBenign) ===
              JSON.stringify(bbox?.overlays?.microcalcBenign)
                ? {}
                : warningStyle,
          };
        case 'Lesions Susp.':
          return {
            value: !!result?.overlays?.lesionMalignant ? 'present' : 'none',
            style:
              JSON.stringify(result?.overlays?.lesionMalignant) ===
              JSON.stringify(bbox?.overlays?.lesionMalignant)
                ? {}
                : warningStyle,
          };
        case 'Lesions Unsusp.':
          return {
            value: !!result?.overlays?.lesionBenign ? 'present' : 'none',
            style:
              JSON.stringify(result?.overlays?.lesionBenign) ===
              JSON.stringify(bbox?.overlays?.lesionBenign)
                ? {}
                : warningStyle,
          };
        default:
          return {
            value: '',
            style: {},
          };
      }
    } catch (err) {
      console.log('Error for value:', headerValue);
    }
  }

  return (
    <DraggableDialog
      title={t('ResultView.labelingStatistics')}
      open={props.open}
      actions={[
        {
          onClick: props.onClose,
          color: 'primary',
          label: t('ResultView.close'),
        },
      ]}
      onClose={props.onClose}
      maxWidth='xl'
    >
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow key='header'>
              <TableCell
                style={{
                  fontWeight: 'bold',
                }}
                key='created_by'
              >
                {t('ResultView.createdBy')}
              </TableCell>
              <TableCell
                style={{
                  fontWeight: 'bold',
                }}
                key='projection'
              >
                {t('ResultView.projection')}
              </TableCell>
              {headers.map((h) => (
                <TableCell
                  style={{
                    fontWeight: 'bold',
                  }}
                  key={h}
                >
                  {h}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(mergedEvaluations).map((username) => (
              <Fragment key={username}>
                <TableRow
                  key={username + 'rcc'}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell style={{ fontWeight: 'bold' }} key='name_rcc'>
                    {username}
                  </TableCell>
                  <TableCell key='rcc'>{t('Dashboard.rcc').toUpperCase()}</TableCell>
                  {headers.map((h) => {
                    const cell = getCell(
                      h,
                      mergedEvaluations[username]?.rcc,
                      mergedEvaluations.bbox?.rcc
                    );
                    return (
                      <TableCell style={cell?.style} key={h + 'rcc'}>
                        {cell?.value}
                      </TableCell>
                    );
                  })}
                </TableRow>

                <TableRow
                  key={username + 'lcc'}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell key='name_lcc' />
                  <TableCell key='lcc'>{t('Dashboard.lcc').toUpperCase()}</TableCell>
                  {headers.map((h) => {
                    const cell = getCell(
                      h,
                      mergedEvaluations[username]?.lcc,
                      mergedEvaluations.bbox?.lcc
                    );
                    return (
                      <TableCell style={cell?.style} key={h + 'lcc'}>
                        {cell?.value}
                      </TableCell>
                    );
                  })}
                </TableRow>

                <TableRow
                  key={username + 'rmlo'}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell key='name_rmlo' />
                  <TableCell key='rmlo'>{t('Dashboard.rmlo').toUpperCase()}</TableCell>
                  {headers.map((h) => {
                    const cell = getCell(
                      h,
                      mergedEvaluations[username]?.rmlo,
                      mergedEvaluations['bbox']?.rmlo
                    );
                    return (
                      <TableCell style={cell?.style} key={h + 'rmlo'}>
                        {cell?.value}
                      </TableCell>
                    );
                  })}
                </TableRow>

                <TableRow
                  key={username + 'lmlo'}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell key='name_lmlo' />
                  <TableCell key='lmlo'>{t('Dashboard.lmlo').toUpperCase()}</TableCell>
                  {headers.map((h) => {
                    const cell = getCell(
                      h,
                      mergedEvaluations[username]?.lmlo,
                      mergedEvaluations.bbox?.lmlo
                    );
                    return (
                      <TableCell style={cell?.style} key={h + 'lmlo'}>
                        {cell?.value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </DraggableDialog>
  );
};

export default LabelingStats;
