import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Fab, Tooltip, Typography, Zoom } from '@material-ui/core';
import { Icon } from '@iconify/react';
import Backdrop from '@material-ui/core/Backdrop';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import radioactiveIcon from '@iconify-icons/mdi/radioactive';
import blurRadial from '@iconify-icons/mdi/blur-radial';
import BlockIcon from '@material-ui/icons/Block';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CloseIcon from '@material-ui/icons/Close';
import CachedIcon from '@material-ui/icons/Cached';
import { fetchResults } from '../../../../../../store';
import { PREMIUM_DENSITY } from '../../../../../../config';

const useStyles = makeStyles((theme) => ({
  fab: {
    backgroundColor: theme.palette.complementary.turquoiseDark,
    margin: 2,
    padding: 1,
    zIndex: 700,
    height: '30px',
    minHeight: '30px',
    width: '30px',

    '&:hover': {
      backgroundColor: theme.palette.complementary.turquoise,
      zIndex: 700,
    },
  },

  staticTooltipLabel: {
    backgroundColor: theme.palette.complementary.turquoiseDark,
    fontSize: theme.fonts.responsiveMedium,
    width: 'max-content',
    color: '#FFFF',
  },

  fabClosed: {
    backgroundColor: theme.palette.complementary.turquoiseDark,

    '&.MuiFab-primary': {
      height: '27px',
      minHeight: '27px',
      width: '27px',
    },
    '&:hover': {
      backgroundColor: theme.palette.complementary.turquoise,
    },
  },
}));

export default function ExaminationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [openOperativeTooltip, setOpenOperativeTooltip] = useState(false);
  const [openClipsTooltip, setOpenClipsTooltip] = useState(false);
  const [openEvalTooltip, setOpenEvalTooltip] = useState(false);

  // Store state
  const { folder } = useSelector((state) => state.results);
  const { tabsIndex } = useSelector((state) => state.gui);
  const currentExam = useSelector((state) => state.examinations.currentExam);

  const isProjectionLeft = (proj_name) => {
    if (proj_name === 'lcc' || proj_name === 'lmlo') return 1;
    return null;
  };

  const isProjectionRight = (proj_name) => {
    if (proj_name === 'rcc' || proj_name === 'rmlo') return 1;
    return null;
  };

  const rightBreastSide = props.proj_name === 'rcc' || props.proj_name === 'rmlo';

  const densityColor = (dens) => {
    if (dens === 'A') {
      return theme.palette.projections.acrA;
    } else if (dens === 'B') {
      return theme.palette.projections.acrB;
    } else if (dens === 'C') {
      return theme.palette.projections.acrC;
    } else if (dens === 'D') {
      return theme.palette.projections.acrD;
    }
    return theme.palette.complementary.mainLight;
  };

  const examinationStatusText = () => {
    if (props.fetchingEval) {
      return t('ResultView.evaluating');
    } else if (!!props.qualityEvaluation?.[props.proj_name]) {
      return t('ResultView.evaluated');
    } else {
      return t('ResultView.not_eval');
    }
  };

  const examinationStatusIcon = () => {
    if (props.fetchingEval) {
      return <CachedIcon style={{ fontSize: '20px' }} />;
    } else if (!!props.qualityEvaluation?.[props.proj_name]) {
      return (
        <Tooltip
          TransitionComponent={Zoom}
          title={
            <Typography
              style={{
                letterSpacing: '0.1rem',
                textTransform: 'uppercase',
                fontSize: theme.fonts.responsiveMedium,
              }}
              color='inherit'
            >
              {t('ResultView.click_here')} {t('ResultView.discard')}
            </Typography>
          }
          arrow
          placement={rightBreastSide ? 'left' : 'right'}
          onClose={() => setOpenEvalTooltip(false)}
          open={openEvalTooltip}
        >
          <DoneOutlineIcon
            style={{
              cursor: 'pointer',
              fontSize: '20px',
            }}
            onMouseEnter={(event) => {
              setOpenEvalTooltip(true);
              mouseEnterTooltip(event);
            }}
          />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip
          title={
            <Typography
              style={{
                letterSpacing: '0.1rem',
                textTransform: 'uppercase',
                fontSize: theme.fonts.responsiveMedium,
              }}
              color='inherit'
            >
              {t('ResultView.click_here')} {t('ResultView.eval')}
            </Typography>
          }
          TransitionComponent={Zoom}
          arrow
          placement={rightBreastSide ? 'left' : 'right'}
          onClose={() => setOpenEvalTooltip(false)}
          open={openEvalTooltip}
        >
          <BlockIcon
            style={{
              cursor: 'pointer',
              fontSize: '20px',
            }}
            onMouseEnter={(event) => {
              setOpenEvalTooltip(true);
              mouseEnterTooltip(event);
            }}
            onMouseLeave={(event) => {
              mouseExitTooltip(event);
              setOpenEvalTooltip(false);
            }}
          />
        </Tooltip>
      );
    }
  };

  const mouseEnterTooltip = (event) => {
    event.target.style.transform = 'scale(1.2) ';
  };

  const mouseExitTooltip = (event) => {
    event.target.style.transform = 'scale(1) ';
  };

  const actions = [
    {
      icon: examinationStatusIcon(),
      name: examinationStatusText(),
      id: 'evaluation',
      operation: 'evaluation',
    },

    {
      icon: <Icon icon={radioactiveIcon} fontSize={30} style={{ cursor: 'default' }} />,
      name: props?.projection?.dose
        ? `${t('ResultView.dose')} ${props?.projection?.dose} mGy`
        : `${t('ResultView.dose')} ${t('ResultView.missing').toUpperCase()}`,
    },
    // {
    //   icon: <Icon icon={blurRadial} fontSize={30} style={{ cursor: 'default' }} />,
    //   name: `${t('ResultView.density').toUpperCase()} ACR ${
    //     props?.densityEvaluation?.[props.proj_name]?.DensClass
    //       ? props?.densityEvaluation?.[props.proj_name]?.DensClass
    //       : t('ResultView.missing_evaluated').toUpperCase()
    //   }`,
    // },
  ];

  function handleOpen() {
    setHidden(false);
    if (!hidden) {
      setOpen(true);
    }
  }

  function handleClose() {
    setOpenClipsTooltip(false);
    setOpenOperativeTooltip(false);
    setOpenEvalTooltip(false);
    setOpen(false);
  }

  function handleAction(e, operation) {
    e.preventDefault();
    e.stopPropagation();

    if (operation === 'evaluation') {
      props.setEvaluated(!props.qualityEvaluation?.[props.proj_name]);
      dispatch(fetchResults());
    }

    handleClose();
  }

  return tabsIndex === 0 || (tabsIndex !== 0 && !PREMIUM_DENSITY) ? (
    <SpeedDial
      ariaLabel='examination_actions'
      hidden={hidden}
      icon={<MoreHorizIcon />}
      openIcon={<CloseIcon />}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      onClick={handleAction}
      classes={{ fab: classes.fabClosed }}
      style={{
        height: '100%',
        position: 'absolute',
        bottom: 4,
        right: isProjectionLeft(props.proj_name),
        left: isProjectionRight(props.proj_name),
        margin: 4,
        zIndex: 200,
      }}
    >
      {actions.map((action, i) => (
        <SpeedDialAction
          tooltipPlacement={rightBreastSide ? 'right' : 'left'}
          key={action.name + i}
          icon={action.icon}
          tooltipTitle={action.name}
          tooltipOpen
          classes={classes}
          onClick={handleClose}
          value={action.icon}
          style={{
            zIndex: 200,
          }}
          onClick={(e) => {
            handleAction(e, action.operation);
          }}
        />
      ))}
    </SpeedDial>
  ) : (
    <Tooltip
      arrow
      title={
        <Typography
          style={{
            letterSpacing: '0.1rem',
            textTransform: 'uppercase',
            fontSize: theme.fonts.responsiveMedium,
            fontWeight: 'bold',
          }}
        >
          {`${props?.proj_name?.toUpperCase()} ${t('ResultView.density')}: ${
            props?.densityEvaluation?.[props.proj_name]?.DensClass
          }`}
        </Typography>
      }
    >
      <div
        style={{
          background: 'transparent',
          border: `1px solid ${densityColor(
            props?.densityEvaluation?.[props.proj_name]?.DensClass
          )}`,
          zIndex: 9,
          height: '25px',
          width: '25px',
          position: 'absolute',
          bottom: 4,
          right: isProjectionLeft(props.proj_name),
          left: isProjectionRight(props.proj_name),
          margin: 4,
          cursor: 'default',
          display: 'flex',
          borderRadius: '50%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <p
          style={{
            color: densityColor(props?.densityEvaluation?.[props.proj_name]?.DensClass),
            fontSize: theme.fonts.responsiveMedium,
            margin: 0,
            padding: 0,
            lineHeight: '24px',
            textAlign: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          {props?.densityEvaluation?.[props.proj_name]?.DensClass}
        </p>
      </div>
    </Tooltip>
  );
}
