/* eslint no-eval: 0 */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as actions from '../../../../../store';
import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Menu,
  Chip,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  chipContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    background: theme.palette.complementary.mainLighter,
    borderRadius: '5px',
    // margin: '2px 4px 0 4px',
    height: '100%',
  },
}));

const QualityFeatures = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Store state
  const qualityOverlaysToShow = useSelector((state) => state.images.qualityOverlaysToShow);
  const shownQualityOverlayType = useSelector((state) => state.labeling.shownQualityOverlayType);

  //Local state
  const [selectedClipOption, setSelectedClipOption] = useState('clips');

  const handleChipClick = (key) => {
    handleLesionsChange({ target: { value: key } });
  };

  const lesions = {
    parenchyma: {
      label: t('ResultView.parenchyma'),
      color: '#5b33dc',
      show: qualityOverlaysToShow.parenchymaRCC,
    },
    pectoralis: {
      label: t('ResultView.pectoralis'),
      color: theme.palette.lesions.pectoralis,
      show: qualityOverlaysToShow.pectoralisRCC,
    },
    skinFolds: {
      label: t('ResultView.skin_folds'),
      color: '#ad68b7',
      show: qualityOverlaysToShow.skinFoldsRCC,
    },
    clips: {
      label: t('ResultView.clips'),
      color: theme.palette.lesions.clips,
      show: qualityOverlaysToShow.clipsRCC,
    },
  };

  const handleLesionsChange = (e) => {
    if (shownQualityOverlayType === e.target.value) {
      dispatch(actions.hideAllLesions());
      dispatch(actions.setShownQualityOverlayType(''));
      dispatch(actions.setQualityInDrawingMode(false));
    } else {
      dispatch(actions.setShownQualityOverlayType(e.target.value));
      dispatch(actions.showOverlayOfKind(e.target.value));
      dispatch(actions.setQualityInDrawingMode(true));
    }
  };

  return (
    <div className={classes.chipContainer}>
      {Object.entries(lesions).map(([key, item]) => (
        <Chip
          size='small'
          key={key}
          label={item.label}
          variant='outlined'
          style={{
            backgroundColor:
              shownQualityOverlayType === key ? item.color : theme.palette.complementary.mainLight,
            color: theme.palette.complementary.generalTexts,
            letterSpacing: '0.1rem',
            height: '20px',
            width: '20%',
            boxShadow: shownQualityOverlayType === key && 'rgb(72 72 72 / 89%) 1px 1px 4px 0px',
            fontWeight: shownQualityOverlayType === key && 'bold',
          }}
          onClick={(e) => handleChipClick(key)}
        />
      ))}
    </div>
  );
};

export default QualityFeatures;
