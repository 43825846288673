import React from 'react';
import { TableCell, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core';
import { ReactComponent as AutoAwesome } from '../../../../../../../resources/icons/auto_awesome.svg';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import DoneIcon from '@material-ui/icons/Done';
import PredictedIcon from '../../../../../../../resources/icons/logo_full_color.png';

export const ModuleTableHead = ({ module, classes, t }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.table_cell_boxes_title}>{t('diagnose.projection')}</TableCell>
        <TableCell className={classes.table_cell_boxes_title} align='center'>
          {t('diagnose.birads')}
        </TableCell>
        {module === 'opacities' && (
          <TableCell className={classes.table_cell_boxes_title} style={{ minWidth: '20px' }} />
        )}

        {/* <TableCell className={classes.table_cell_boxes_title} align='center'>
          {t('diagnose.conf_score')}
        </TableCell> */}
        <TableCell className={classes.table_cell_boxes_title} />
        <TableCell className={classes.table_cell_boxes_title} />
        <TableCell className={classes.table_cell_boxes_title} />
      </TableRow>
    </TableHead>
  );
};

export const RowIconStatus = ({
  lesionAssigned,
  lesionReassigned,
  confirmed,
  labeling,
  reassigned,
  classes,
  t,
}) => {
  if (lesionAssigned) {
    return (
      <Tooltip
        title={<Typography className={classes.tooltip_text}>{t('diagnose.added_user')}</Typography>}
        arrow
      >
        <AutoAwesome style={{ fill: '#ffc252', width: '16px', height: '16px' }} />
      </Tooltip>
    );
  }

  if (lesionReassigned && !lesionAssigned) {
    return (
      <Tooltip
        title={
          <Typography className={classes.tooltip_text}>
            {t('diagnose.reassigned_previous', { prev_type: `${reassigned}` })}
          </Typography>
        }
        arrow
      >
        <SpellcheckIcon
          fontSize='small'
          className={classes.row_reassign_icon}
          style={{ fill: 'rgb(76, 175, 80)' }}
        />
      </Tooltip>
    );
  }

  if (confirmed && !labeling) {
    return (
      <Tooltip
        title={<Typography className={classes.tooltip_text}>{t('diagnose.confirmed')}</Typography>}
        arrow
      >
        <DoneIcon
          fontSize='small'
          className={classes.row_reassign_icon}
          style={{ fill: 'rgb(76, 175, 80)' }}
        />
      </Tooltip>
    );
  }

  return (
    <Tooltip
      title={<Typography className={classes.tooltip_text}>{t('diagnose.ai_detected')}</Typography>}
      arrow
    >
      <img className={classes.row_reassign_icon} src={PredictedIcon} alt='AI' />
    </Tooltip>
  );
};
