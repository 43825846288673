import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as actions from '../../../../../../store';
import { Chip, Divider, Tooltip, Typography, useTheme } from '@material-ui/core';
import { useStyles } from './QualityProjections.style';
import symmetryVertical from '@iconify/icons-bi/symmetry-vertical';
import { Icon } from '@iconify/react';
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AdjustIcon from '@material-ui/icons/Adjust';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import WavesIcon from '@material-ui/icons/Waves';
import { QUALITY_SYSTEM } from '../../../../../../config';
import { useQualityTranslation } from '../../../../../../helpers/hooks/useQualityTranslation';

export const parenchymaClass = (parenchymaResult, t) => {
  const resultMapping = {
    correct: {
      text: t('ResultView.quality_parenchyma_ok'),
      classColor: 'correct',
    },
    moderate: {
      text: t('ResultView.quality_parenc_not'),
      classColor: 'moderate',
    },
    insufficient: {
      text: t('ResultView.quality_parenc_not'),
      classColor: 'insufficient',
    },
  };

  const defaultResult = {
    text: t('ResultView.is_not_evaluated'),
    classColor: 'none',
  };

  return resultMapping[parenchymaResult] || defaultResult;
};

export const pnlTooltip = (PNL, view_position, higherPNL, pnlDifference, t) => {
  const currentStatePNL = higherPNL ? t('ResultView.more') : t('ResultView.less');
  const projPNL = view_position?.includes('cc')
    ? view_position === 'rcc'
      ? 'RMLO'
      : 'LMLO'
    : view_position === 'rmlo'
    ? 'RCC'
    : 'LCC';

  if (!PNL) {
    return { text: t('ResultView.is_not_evaluated'), classColor: 'none' };
  }

  const pnlMap = {
    correct: {
      text: t('ResultView.pnl_line_range_ok'),
    },
    moderate: {
      text: `${t('ResultView.pnl_line_range_moderate')}. `,
      textHelper: ` ${view_position?.toUpperCase()} ${t(
        'ResultView.pnl_measures'
      )} ${pnlDifference} ${currentStatePNL} ${t('ResultView.than')} ${projPNL}`,
    },
    insufficient: {
      text: `${t('ResultView.pnl_line_not_range')}. `,
      textHelper: `${view_position?.toUpperCase()} ${t(
        'ResultView.pnl_measures'
      )} ${pnlDifference} ${currentStatePNL} ${t('ResultView.than')} ${projPNL}`,
    },
  };

  const pnlResult = PNL ? pnlMap[PNL] : pnlMap.default;

  return (
    pnlResult || {
      text: t('ResultView.pnl_line_not_compare'),
      classColor: 'neutral',
    }
  );
};

export const pnlColor = (result) => {
  const { PNL } = result ?? {};
  if (!result) {
    return {
      color: 'neutral',
    };
  }
  const pnlMap = {
    correct: {
      color: 'correct',
    },
    moderate: {
      color: 'moderate',
    },
    insufficient: {
      color: 'insufficient',
    },
    default: {
      color: 'neutral',
    },
  };

  const pnlResult = PNL ? pnlMap[PNL] : pnlMap.default;

  return (
    pnlResult || {
      color: 'neutral',
    }
  );
};

export const nippleProfileClass = (nippleResult, t) => {
  if (!nippleResult || nippleResult === 'not_evaluated') {
    return {
      text: t('ResultView.is_not_evaluated'),
      classColor: 'none',
    };
  }

  const resultTextMap = {
    correct: t('ResultView.correctly'),
    moderate: t('ResultView.moderately'),
    insufficient: t('ResultView.insufficiently'),
  };

  const classColorMap = {
    correct: 'correct',
    moderate: 'moderate',
    insufficient: 'insufficient',
  };

  const resultText = resultTextMap[nippleResult] || '';
  const classColor = classColorMap[nippleResult] || classColorMap.none;

  return {
    text: `${t('ResultView.tooltip_nipple')} ${resultText}`,
    classColor: classColor,
  };
};

export const nippleOrientationClass = (nippleResult, nippleAngle) => {
  const defaultValues = {
    pnlY: '42%',
  };

  if (nippleResult === 'moderate' || nippleResult === 'insufficient') {
    const pnlY = nippleAngle > 0 ? '38%' : '46%';

    return { pnlY };
  }

  if (nippleResult === 'correct' || !nippleResult) {
    return defaultValues;
  }
};

export const pectoMuscClass = (pectoResult, t) => {
  const resultMapping = {
    correct: `${t('ResultView.tooltip_pect_musc')}: ${t('ResultView.correctly')}`,
    moderate: `${t('ResultView.tooltip_pect_musc')}: ${t('ResultView.moderately')}`,
    insufficient: `${t('ResultView.tooltip_pect_musc')}: ${t('ResultView.insufficiently')}`,
  };

  const defaultText = t('ResultView.is_not_evaluated');
  const defaultClassColor = 'none';

  return {
    text: resultMapping[pectoResult] || defaultText,
    classColor: pectoResult ? pectoResult : defaultClassColor,
  };
};

export const pectoAngleClass = (angleResult, angleValue, t) => {
  const pectAngleValue = angleValue ? `${angleValue.toFixed(1)}°` : '';
  const resultMapping = {
    correct: t('ResultView.correctly'),
    moderate: t('ResultView.moderately'),
    insufficient: t('ResultView.insufficiently'),
  };

  const defaultText = t('ResultView.is_not_evaluated');
  const defaultClassColor = 'none';

  const resultText = resultMapping[angleResult] || defaultText;
  const classColor = angleResult ? angleResult : defaultClassColor;

  if (!angleResult || !angleValue)
    return {
      text: defaultText,
      classColor: classColor,
    };
  else
    return {
      text: `${t('ResultView.tooltip_pect_ang')} ${resultText}: ${pectAngleValue}`,
      classColor: classColor,
    };
};

export const inframFoldClass = (inframFold, t) => {
  const resultMapping = {
    correct: t('ResultView.correctly'),
    moderate: t('ResultView.moderately'),
    insufficient: t('ResultView.insufficiently'),
  };

  const defaultText = t('ResultView.is_not_evaluated');
  const defaultClassColor = 'none';

  const resultText = resultMapping[inframFold] || defaultText;
  const classColor = inframFold ? inframFold : defaultClassColor;

  if (!inframFold)
    return {
      text: resultText,
      classColor: classColor,
    };
  return {
    text: `${t('ResultView.tooltip_infram')} ${resultText}`,
    classColor: classColor,
  };
};

const pectoLevelColor = (pectoLevel) => {
  if (!pectoLevel) return 'none';

  if (QUALITY_SYSTEM && QUALITY_SYSTEM === 'german') {
    return pectoLevel;
  } else {
    return pectoLevel === 'moderate' ? 'correct' : pectoLevel;
  }
};

export const pectoLevelClass = (pectoLevel, t) => {
  const resultMapping = {
    correct: `${t('ResultView.tooltip_pect_level')} ${t('ResultView.correctly')}`,
    moderate:
      QUALITY_SYSTEM === 'german'
        ? `${t('ResultView.tooltip_pect_level')} ${t('ResultView.insufficiently')}`
        : `${t('ResultView.tooltip_pect_level')} ${t('ResultView.correctly')}`,
    insufficient:
      QUALITY_SYSTEM === 'german'
        ? t('ResultView.tooltip_pect_level_insuff')
        : `${t('ResultView.tooltip_pect_level')} ${t('ResultView.insufficiently')}`,
  };

  const defaultText = t('ResultView.is_not_evaluated');
  const resultText = resultMapping[pectoLevel] || defaultText;
  const classColor = pectoLevelColor(pectoLevel);

  return {
    text: resultText,
    classColor: classColor,
  };
};

const symmetryColor = (symmetry, theme) => {
  if (symmetry === 'correct') return theme.palette.projections.symmetryCorrect;

  if (symmetry === 'moderate') return theme.palette.projections.symmetryModerate;

  if (symmetry === 'insufficient') return theme.palette.projections.symmetryInsufficient;

  if (symmetry === 'not_evaluated') return theme.palette.projections.not_evaluated;

  return theme.palette.projections.not_evaluated;
};

export const symmetryChip = (
  currentSymmetry,
  currentView,
  onClickSymmetryCC,
  onClickSymmetryMLO,
  zoomedProjection,
  classes,
  theme,
  t
) => {
  if (zoomedProjection || !currentSymmetry) return;

  return (
    <Tooltip
      placement='top'
      title={
        <Typography
          style={{
            fontSize: '1.3vh',
            lineHeight: 1.3,
          }}
        >
          {currentSymmetry === 'not_evaluated'
            ? t('ResultView.not_eval_feature')
            : `${currentView} ${t('ResultView.symmetry')}: ${currentSymmetry}`}
        </Typography>
      }
      arrow
    >
      <Chip
        label={
          currentSymmetry === 'not_evaluated'
            ? t('ResultView.not_eval_feature')
            : `${currentView} ${t('ResultView.symmetry')}`
        }
        size='small'
        icon={
          <Icon
            style={{
              width: '20px',
              height: 'auto',
              borderRadius: '5px',
              padding: '2px',
            }}
            color={symmetryColor(currentSymmetry, theme)}
            icon={symmetryVertical}
            rotate={4}
          />
        }
        variant='outlined'
        style={{
          height: '20px',
          fontSize: theme.fonts.responsiveMediumBig,
          lineHeight: 1.3,
          color: symmetryColor(currentSymmetry, theme),
          border: `1px solid ${symmetryColor(currentSymmetry, theme)}`,
          cursor: onClickSymmetryMLO || onClickSymmetryCC ? 'pointer' : 'help',
          zIndex: 900,
        }}
        onClick={() => {
          if (onClickSymmetryCC && currentSymmetry !== null) onClickSymmetryCC();
          if (onClickSymmetryMLO && currentSymmetry !== null) onClickSymmetryMLO();
        }}
      />
    </Tooltip>
  );
};

const findHigherClass = (class1, class2) => {
  const classOrder = ['adequate', 'correct', 'limited', 'moderate', 'insufficient', 'inadequate'];
  const class1Index = classOrder.indexOf(class1);
  const class2Index = classOrder.indexOf(class2);
  return class1Index > class2Index ? class1 : class2;
};

export const singleSymmetryChip = (
  evaluation,
  currentView,
  qualityInRevisionTab,
  onClickChip,
  zoomedProjection,
  classes,
  theme,
  t
) => {
  if (zoomedProjection || !evaluation) return;
  const currentProjections = currentView === 'cc' ? ['rcc', 'lcc'] : ['rmlo', 'lmlo'];
  const currentRSymmetry = evaluation?.[currentProjections?.[0]]?.symmetry;
  const currentLSymmetry = evaluation?.[currentProjections?.[1]]?.symmetry;

  const higherClass = findHigherClass(currentRSymmetry, currentLSymmetry);
  if (!currentRSymmetry && !currentLSymmetry) return;

  const handleClick = (proj) => {
    if (onClickChip && evaluation?.[proj]?.symmetry) {
      onClickChip(proj);
    }
  };

  return (
    <div
      style={{
        display: 'inline-flex',
        height: '20px',
        fontSize: theme.fonts.responsiveMediumBig,
        lineHeight: 1.3,

        cursor: qualityInRevisionTab ? 'pointer' : 'help',
        zIndex: 900,
        overflow: 'hidden',
      }}
    >
      {/* Right Side */}
      <Tooltip
        placement='top'
        title={
          <Typography
            style={{
              fontSize: '1.3vh',
              lineHeight: 1.3,
            }}
          >
            {currentRSymmetry === 'not_evaluated'
              ? t('ResultView.not_eval_feature')
              : `${currentProjections[0].toUpperCase()} ${t(
                  'ResultView.symmetry'
                )}: ${useQualityTranslation(currentRSymmetry, t)}`}
          </Typography>
        }
        arrow
      >
        <div
          style={{
            padding: '0 8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: symmetryColor(currentRSymmetry, theme),
            border: `1px solid ${symmetryColor(currentRSymmetry, theme)}`,
            borderRadius: '16px',
          }}
          onClick={() => handleClick(currentProjections[0])}
        >
          {currentRSymmetry === 'not_evaluated'
            ? t('ResultView.not_eval_feature')
            : useQualityTranslation(currentRSymmetry, t)}
        </div>
      </Tooltip>

      {/* Icon in the middle */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0 4px',
        }}
      >
        <Icon
          icon={symmetryVertical}
          style={{ fontSize: '18px', color: symmetryColor(higherClass, theme) }}
        />
      </div>

      {/* Left Side */}
      <Tooltip
        placement='top'
        title={
          <Typography
            style={{
              fontSize: '1.3vh',
              lineHeight: 1.3,
            }}
          >
            {currentLSymmetry === 'not_evaluated'
              ? t('ResultView.not_eval_feature')
              : `${currentProjections[0].toUpperCase()} ${t(
                  'ResultView.symmetry'
                )}: ${useQualityTranslation(currentLSymmetry, t)}`}
          </Typography>
        }
        arrow
      >
        <div
          style={{
            padding: '0 8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: symmetryColor(currentLSymmetry, theme),
            border: `1px solid ${symmetryColor(currentLSymmetry, theme)}`,
            borderRadius: '16px',
          }}
          onClick={() => handleClick(currentProjections[1])}
        >
          {currentLSymmetry === 'not_evaluated'
            ? t('ResultView.not_eval_feature')
            : useQualityTranslation(currentLSymmetry, t)}
        </div>
      </Tooltip>
    </div>
  );
};

//SKIN FOLDS
const skinFoldChipColor = (foldsList, theme) => {
  const severityValues = {
    mild: 0,
    medium: 1,
    severe: 2,
  };

  if (foldsList?.length > 0) {
    const skinFoldsSeverityNumbers = foldsList.map((fold) => severityValues[fold.severity]);
    const highestSeverity = Math.max(...skinFoldsSeverityNumbers);
    if (highestSeverity === 0) {
      return theme.palette.lesions.skinFoldsMild;
    }
    if (highestSeverity === 1) {
      return theme.palette.lesions.skinFoldsMedium;
    }
    if (highestSeverity === 2) {
      return theme.palette.lesions.skinFoldsSevere;
    }
    return theme.palette.lesions.skinFolds;
  }
  return theme.palette.lesions.noSkinFolds;
};

export const skinFoldsChip = (
  skinFolds,
  showSkinFolds,
  view_position,
  hidePectOverlays,
  notOnlyBoxes,
  zoomedProjection,
  t,
  theme
) => {
  return (
    <>
      <Tooltip
        placement='right-end'
        title={
          skinFolds ? (
            notOnlyBoxes ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  style={{
                    fontSize: '0.7rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '10px',
                  }}
                >
                  {`${view_position?.toUpperCase()}
                  ${t('ResultView.skin_folds')}`}
                </Typography>
                <Typography
                  style={{
                    fontSize: '0.7rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '10px',
                  }}
                >
                  <Brightness1Icon
                    fontSize='inherit'
                    style={{
                      color: theme.palette.lesions.skinFoldsSevere,
                      marginRight: '4px',
                    }}
                  />
                  {t('ResultView.skin_folds_severe')}
                </Typography>

                <Typography
                  style={{
                    fontSize: '0.7rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '10px',
                  }}
                >
                  <Brightness1Icon
                    fontSize='inherit'
                    style={{
                      color: theme.palette.lesions.skinFoldsMedium,
                      marginRight: '4px',
                    }}
                  />
                  {t('ResultView.skin_folds_medium')}
                </Typography>

                <Typography
                  style={{
                    fontSize: '0.7rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '10px',
                  }}
                >
                  <Brightness1Icon
                    fontSize='inherit'
                    style={{
                      color: theme.palette.lesions.skinFoldsMild,
                      marginRight: '4px',
                    }}
                  />
                  {t('ResultView.skin_folds_mild')}
                </Typography>
              </div>
            ) : (
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.3vh',
                }}
              >
                {t('ResultView.show_skin_folds')}
              </Typography>
            )
          ) : (
            <Typography
              style={{
                fontWeight: 'bold',
                fontSize: '1.3vh',
              }}
            >
              {t('ResultView.no_skin_folds')}
            </Typography>
          )
        }
        arrow
        interactive
      >
        <Chip
          variant='outlined'
          onMouseEnter={() => skinFolds && showSkinFolds(view_position)}
          onMouseLeave={() => skinFolds && hidePectOverlays()}
          label={skinFolds ? t('ResultView.skin_folds') : t('ResultView.none_skin_folds')}
          size='small'
          icon={
            <WavesIcon
              fontSize='small'
              style={{
                color: 'inherit',
                width: '18px',
              }}
            />
          }
          style={{
            color: skinFoldChipColor(skinFolds, theme),
            border: `1px solid ${skinFoldChipColor(skinFolds, theme)}`,
            height: '20px',
            fontSize: zoomedProjection
              ? theme.fonts.responsiveMedium
              : theme.fonts.responsiveMediumBig,
            marginTop: '6px',
            cursor: 'help',
          }}
        />
      </Tooltip>
    </>
  );
};

// BREAST VOLUME
const chipColor = (value, theme) => {
  if (value === 'correct') return theme.palette.projections.volumeUp;
  if (value === 'moderate') return theme.palette.projections.moderate;
  if (value === 'insufficient') return theme.palette.projections.volumeDown;
  if (value === 'not_evaluated') return theme.palette.projections.not_evaluated;
  return theme.palette.projections.not_evaluated;
};

const currentBreastDiff = (view_position, currentBreastTissueDepicted, breastAreaDifference, t) => {
  const absDifference = Math.abs(breastAreaDifference);
  const direction = breastAreaDifference < 0 ? t('ResultView.less') : t('ResultView.more');
  const viewPositionUpper = view_position?.toUpperCase();
  const firstLetterMLO = view_position?.slice(0, 1).toUpperCase();

  if (view_position.includes('mlo')) {
    return `- ${viewPositionUpper} ${t('ResultView.current_breast_tissue_helper', {
      currentBreast: `${currentBreastTissueDepicted?.toFixed(0)}`,
    })} ${t('ResultView.has')} ${absDifference}cm³ ${direction} ${t(
      'ResultView.than'
    )} ${firstLetterMLO}CC`;
  } else {
    return `- ${viewPositionUpper} ${t('ResultView.current_breast_tissue_helper', {
      currentBreast: `${currentBreastTissueDepicted?.toFixed(0)}`,
    })} ${t('ResultView.has')} ${absDifference}cm³ ${direction} ${t(
      'ResultView.than'
    )} ${firstLetterMLO}MLO`;
  }
};

export const breastVolumeChip = (
  currentBreastTissueDepicted,
  volumeProjectionDiff,
  breastAreaDifference,
  noPastBreastEvaluations,
  pastBreastVolume,
  volumeLastExamDiff,
  volumeTooltipOpen,
  setVolumeTooltipOpen,
  volumeChipClicked,
  setVolumeChipClicked,
  zoomedProjection,
  view_position,
  smallView,
  theme,
  t,
  dispatch
) => {
  if (!currentBreastTissueDepicted) {
    return (
      <Tooltip
        placement='top-start'
        arrow
        title={
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: '1.3vh',
              lineHeight: 1.3,
            }}
          >
            {`${t('ResultView.breast_tissue_abbreviation')}: ${t('ResultView.not_available')}`}
          </Typography>
        }
      >
        <Chip
          label={smallView ? 'N/A' : t('ResultView.not_available')}
          size='small'
          icon={
            <AdjustIcon
              fontSize='small'
              style={{
                color: 'gray',
                marginLeft: '2px',
              }}
            />
          }
          variant='outlined'
          style={{
            cursor: 'help',
            height: '20px',
            fontSize: zoomedProjection
              ? theme.fonts.responsiveMedium
              : theme.fonts.responsiveMediumBig,
          }}
        />
      </Tooltip>
    );
  }
  if (currentBreastTissueDepicted) {
    if (noPastBreastEvaluations) {
      return (
        <Tooltip
          placement='top-start'
          arrow
          title={
            <>
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.3vh',
                  lineHeight: 1.3,
                  margin: '4px 0px',
                }}
              >
                {t('ResultView.current_projection')}
              </Typography>
              {volumeProjectionDiff !== 'not_evaluated' && (
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1.3vh',
                    lineHeight: 1.3,
                    margin: '4px 0px',
                    color: chipColor(volumeProjectionDiff, theme),
                  }}
                >
                  {`${t('ResultView.volume_variance')}: ${volumeProjectionDiff}`}
                </Typography>
              )}
              <Typography
                style={{
                  fontSize: '1.3vh',
                  lineHeight: 1.3,
                }}
              >
                {volumeProjectionDiff === 'not_evaluated'
                  ? t('ResultView.diff_not_available')
                  : currentBreastDiff(
                      view_position,
                      currentBreastTissueDepicted,
                      breastAreaDifference,
                      t
                    )}
              </Typography>
            </>
          }
        >
          <Chip
            variant='outlined'
            label={
              smallView
                ? `Volume: ${currentBreastTissueDepicted?.toFixed(0)}`
                : `${t(
                    'ResultView.breast_tissue_abbreviation'
                  )}: ${currentBreastTissueDepicted?.toFixed(0)}`
            }
            size='small'
            icon={
              <AdjustIcon
                fontSize='small'
                style={{
                  color: chipColor(volumeProjectionDiff, theme),
                  marginLeft: '2px',
                }}
              />
            }
            style={{
              border: `1px solid ${chipColor(volumeLastExamDiff, theme)}`,
              height: '20px',
              fontSize: zoomedProjection
                ? theme.fonts.responsiveMedium
                : theme.fonts.responsiveMediumBig,
              cursor: 'help',
            }}
          />
        </Tooltip>
      );
    } else
      return (
        <Tooltip
          interactive
          open={volumeTooltipOpen}
          arrow
          placement='top-start'
          title={
            <>
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.3vh',
                  lineHeight: 1.3,
                  margin: '4px 0px',
                  minHeight: '18px',
                }}
              >
                {t('ResultView.current_projection')}
              </Typography>
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.3vh',
                  lineHeight: 1.3,
                  margin: '4px 0px',
                  color: chipColor(volumeProjectionDiff, theme),
                }}
              >
                {`${t('ResultView.volume_variance')}: ${volumeProjectionDiff}`}
              </Typography>

              <Typography
                style={{
                  fontSize: '1.3vh',
                  marginBottom: '4px',
                  lineHeight: 1.3,
                }}
              >
                {currentBreastDiff(
                  view_position,
                  currentBreastTissueDepicted,
                  breastAreaDifference,
                  t
                )}
              </Typography>

              <Divider />

              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.3vh',
                  lineHeight: 1.3,
                  margin: '4px 0px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => dispatch(actions.setCurrentExam(pastBreastVolume?.[0]?.exam_id))}
              >
                {t('ResultView.prev_exam')}
                <ArrowRightIcon
                  fontSize='medium'
                  style={{
                    color: chipColor(volumeLastExamDiff, theme),
                    cursor: 'pointer',
                    marginLeft: '2px',
                  }}
                  onClick={() => dispatch(actions.setCurrentExam(pastBreastVolume?.[0]?.exam_id))}
                />
              </Typography>

              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.3vh',
                  lineHeight: 1.3,
                  margin: '4px 0px',
                  color: chipColor(volumeLastExamDiff, theme),
                }}
              >
                {`${t('ResultView.volume_variance')}: ${volumeLastExamDiff}`}
              </Typography>
              <div>
                <Typography
                  style={{
                    fontSize: '1.3vh',
                    lineHeight: 1.3,
                  }}
                >
                  {`- ${t(
                    'ResultView.current_breast_tissue'
                  )}: ${currentBreastTissueDepicted?.toFixed(0)} cm³`}
                </Typography>
                <Typography
                  style={{
                    fontSize: '1.3vh',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {`- ${t(
                    'ResultView.past_breast_tissue'
                  )}: ${pastBreastVolume?.[0]?.BreastVolume?.toFixed(
                    1
                  )} cm³ (${pastBreastVolume?.[0]?.date.slice(0, 10)})`}
                </Typography>
              </div>
            </>
          }
        >
          <Chip
            onDelete={
              volumeChipClicked
                ? () => {
                    setVolumeChipClicked(!volumeChipClicked);
                    if (!volumeChipClicked) {
                      setVolumeTooltipOpen(true);
                    } else {
                      setVolumeTooltipOpen(false);
                    }
                  }
                : undefined
            }
            variant='outlined'
            label={
              smallView
                ? `${t('ResultView.volume')}: ${currentBreastTissueDepicted?.toFixed(0)}`
                : `${t(
                    'ResultView.breast_tissue_abbreviation'
                  )}: ${currentBreastTissueDepicted?.toFixed(0)}`
            }
            size='small'
            icon={
              <AdjustIcon
                fontSize='small'
                style={{
                  color: chipColor(volumeProjectionDiff, theme),
                  marginLeft: '2px',
                }}
              />
            }
            style={{
              cursor: 'pointer',
              border: `1px solid ${chipColor(volumeLastExamDiff, theme)}`,
              height: '20px',
              fontSize: zoomedProjection
                ? theme.fonts.responsiveMedium
                : theme.fonts.responsiveMediumBig,
            }}
            onClick={() => {
              setVolumeChipClicked(!volumeChipClicked);
              if (!volumeChipClicked) {
                setVolumeTooltipOpen(true);
              } else {
                setVolumeTooltipOpen(false);
              }
            }}
            onMouseEnter={() => {
              setVolumeTooltipOpen(true);
            }}
            onMouseLeave={() => {
              if (!volumeChipClicked) setVolumeTooltipOpen(false);
            }}
          />
        </Tooltip>
      );
  }

  return;
};

//Blur
const blurredAreaInfo = (blur, theme, t) => {
  let color = '';
  let label = '';
  let tooltip = '';

  if (blur === 'correct') {
    color = theme.palette.projections.blur_ok;
    label = t('ResultView.correct_blurred_area');
    tooltip = t('ResultView.no_blurred_area');
  } else if (blur === 'insufficient') {
    color = theme.palette.projections.blur_insufficient;
    label = t('ResultView.blurred_area');
    tooltip = t('ResultView.has_blurred_area');
  } else if (!blur || blur === 'not_evaluated') {
    color = 'gray';
    label = t('ResultView.blur_not_evaluated');
    tooltip = t('ResultView.not_eval_blurred_area');
  }

  return {
    color,
    label,
    tooltip,
  };
};

export const blurAreaChip = (
  blurredAreas,
  onClickBlur,
  qualityInfoMode,
  qualityInRevison,
  zoomedProjection,
  theme,
  t
) => {
  return (
    <>
      <Tooltip
        arrow
        title={
          <Typography
            style={{
              letterSpacing: '0.1rem',
              fontSize: theme.fonts.responsiveMedium,
            }}
          >
            {blurredAreaInfo(blurredAreas, theme, t)?.tooltip}
          </Typography>
        }
      >
        <Chip
          variant='outlined'
          onClick={() => {
            onClickBlur && qualityInRevison && onClickBlur();
          }}
          label={
            blurredAreas
              ? blurredAreaInfo(blurredAreas, theme, t)?.label
              : t('ResultView.blur_not_evaluated')
          }
          size='small'
          icon={
            <BlurCircularIcon
              fontSize='small'
              style={{
                color: 'inherit',
                width: '18px',
              }}
            />
          }
          style={{
            color: blurredAreaInfo(blurredAreas, theme, t)?.color,
            border: `1px solid ${blurredAreaInfo(blurredAreas, theme, t)?.color}`,
            height: '20px',
            fontSize: zoomedProjection
              ? theme.fonts.responsiveMedium
              : theme.fonts.responsiveMediumBig,
            marginTop: '6px',
            cursor: qualityInRevison ? 'pointer' : 'help',
          }}
        />
      </Tooltip>
    </>
  );
};
