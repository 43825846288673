export function highDensity(data) {
  let highestDensClass = null;
  let densClass;

  for (const key in data) {
    if (
      data.hasOwnProperty(key) &&
      typeof data[key] === 'object' &&
      data[key]?.DensClass !== null
    ) {
      const entry = data[key];

      if (entry) {
        densClass = entry.DensClass;
      }

      if (!highestDensClass || densClass > highestDensClass) {
        highestDensClass = densClass;
      }
    }
  }

  return highestDensClass;
}
