import { Link, Typography } from '@material-ui/core';

export function CopyrightShort() {
  return (
    <Typography
      style={{ letterSpacing: '0.1rem', textTransform: 'uppercase' }}
      variant="body2"
      color="textSecondary"
      align="center"
    >
      {'Copyright © '}
      <Link color="inherit" href="https://b-rayz.ch/">
        b-rayZ {''}
      </Link>

      {new Date().getFullYear()}
    </Typography>
  );
}
