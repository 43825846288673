import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import App from './App';
import * as serviceWorker from './serviceWorker';
import './fonts/Inter-Regular.ttf';
import history from './history';
import './language/i18n';
import clearCache from './helpers/cashe';
import store from './store/rootReducer';

const isDevURL =
  window.location.hostname === 'dev.b-rayz.ch' ||
  window.location.hostname === 'demo.b-rayz.ch' ||
  process.env?.REACT_APP_SOFTWARE_VERSION?.includes('-dev');

clearCache();


ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
