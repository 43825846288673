import React, { useState, useEffect } from 'react';
import * as actions from '../../../../../store/index';
import {
  Switch,
  FormControlLabel,
  CircularProgress,
  Button,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  Slider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import io from 'socket.io-client';
import DatePicker from '../../../DrawerExamList/DatePicker/DatePicker';
import { API_ENDPOINT, AI_ENDPOINT } from '../../../../../config';
import { fetchDelete } from '../../../../../helpers/fetching';
import { formatDate } from '../../../../../helpers/formatDate';
import { setProcessingProgress } from '../../../../../store';

import CustomDatePicker from '../../../DrawerExamList/DatePicker/CustomDatePicker';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  titleSettingsCard: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontSize: '1.1rem',
  },

  spinner: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },

  progressLabel: {
    display: 'flex',
    justifyContent: 'center',
  },

  Slider: {
    width: '60%',
  },
}));

function Processing(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentlyProcessed, processingProgress, pendingReprocessing } = useSelector(
    (state) => state.examinations
  );
  const [reprocessRange, setReprocessRange] = useState('all');
  const [reprocessDates, setReprocessDates] = useState([
    formatDate(new Date(999, 1, 1), '00:00'),
    formatDate(new Date(), '23:59'),
  ]);

  useEffect(() => {
    const newSocket = io(AI_ENDPOINT, {
      withCredentials: true,
      credentials: 'include',
    });

    newSocket.on('connect', () => {
      newSocket.on('reprocessed', (val) => dispatch(actions.setProcessingProgress(val)));
    });

    newSocket.on('disconnect', () => {
      newSocket.off('reprocessed');
    });

    return () => newSocket.close();
  }, []);
  // TODO Karol: Revise processing state
  // console.log(pendingReprocessing, processingProgress, currentlyProcessed);

  return (
    <Grid container direction='column' spacing={1}>
      <Grid item>
        <Card elevation={1}>
          <CardHeader
            disableTypography={true}
            classes={{
              action: classes.action,
            }}
            title={
              <Typography className={classes.titleSettingsCard} variant='h6'>
                {t('Settings.dicom_receive')}
              </Typography>
            }
          />
          <CardContent>
            <Grid
              container
              direction='row'
              alignItems='center'
              justifyContent='flex-start'
              spacing={2}
            >
              <Grid item>
                <FormControlLabel
                  control={
                    props.loadingOrthanc ? (
                      <>
                        <CircularProgress className={classes.spinner} />
                      </>
                    ) : (
                      <Switch checked={props.serverActive} onChange={props.setOrthanc} />
                    )
                  }
                  label={t('Settings.server')}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    props.loadingWatcher ? (
                      <>
                        <CircularProgress className={classes.spinner} />
                      </>
                    ) : (
                      <Switch checked={props.watcherActive} onChange={props.setWatcher} />
                    )
                  }
                  label={t('Settings.watcher')}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {localStorage.getItem('role') === 'admin' && (
        <>
          <Grid item>
            <Card elevation={1}>
              <CardHeader
                disableTypography={true}
                classes={{
                  action: classes.action,
                }}
                title={
                  <Typography className={classes.titleSettingsCard} variant='h6'>
                    {t('Settings.reprocess_all')}
                  </Typography>
                }
                subheader={
                  <Typography variant='subtitle2'>{t('Settings.reprocess_all_extra')}</Typography>
                }
              />
              <CardContent>
                <Grid container direction='row' alignItems='center' justifyContent='space-between'>
                  <Grid item style={{ width: '500px' }}>
                    <DatePicker
                      setDates={setReprocessDates}
                      customDate={reprocessDates}
                      setCustomDate={setReprocessDates}
                      selectedRange={reprocessRange}
                      setSelectedRange={setReprocessRange}
                    />
                    {reprocessRange === 'custom' && (
                      <CustomDatePicker
                        setDates={setReprocessDates}
                        customDate={reprocessDates}
                        setCustomDate={setReprocessDates}
                      />
                    )}
                  </Grid>
                  <Grid item>
                    {pendingReprocessing || currentlyProcessed ? (
                      <>
                        <>
                          <CircularProgress className={classes.spinner} />
                        </>
                        {processingProgress[0] === 0 && processingProgress[1] === 0 ? (
                          'Processing'
                        ) : (
                          <p className={classes.progressLabel}>
                            {`${processingProgress[0]} / ${processingProgress[1]}`}
                          </p>
                        )}
                      </>
                    ) : (
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={() => props.recalculateAllHandler(reprocessDates)}
                      >
                        {t('Settings.reprocess')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default Processing;
