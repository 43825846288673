import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/CheckCircle';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  CaptureProjection: {
    position: 'relative',
  },

  projection_image: {
    maxWidth: '100%',
    borderRadius: '5px',
    zIndex: 1,
  },

  secondCaptureCheckIconLeft: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.primary.main,
    cursor: 'pointer',
    zIndex: 9,
    height: '30px',
    width: '30px',
    minHeight: '30px',
  },

  secondCaptureCheckIconRight: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
    color: theme.palette.primary.main,
    cursor: 'pointer',
    zIndex: 9,
    height: '30px',
    width: '30px',
    minHeight: '30px',
  },
}));

export default function (props) {
  const classes = useStyles();
  const { tabsIndex } = useSelector((state) => state.gui);

  return (
    <div className={classes.CaptureProjection}>
      <img
        crossOrigin='use-credentials'
        className={classes.projection_image}
        src={props.src}
        alt={`NO ${props.proj_name} PROJECTION`}
        onClick={props.setAsRelevant}
        onLoad={props.onLoad}
        style={{
          maxHeight: tabsIndex === 2 ? '46vh' : '43vh',
        }}
      />

      {props.isRelevant && (
        <CheckIcon
          fontSize='large'
          className={
            props.left ? classes.secondCaptureCheckIconLeft : classes.secondCaptureCheckIconRight
          }
        />
      )}
    </div>
  );
}
