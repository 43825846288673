import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import * as actions from '../../../../../store';
import { Card, Zoom, Tooltip } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import LineComponent from '../LineComponent/LineComponent';
import { API_ENDPOINT } from '../../../../../config';
import ContourOverlay from '../ContourOverlay/ContourOverlay';
import animation from '../../../../../resources/processing_animation.gif';
import { useAppliedEvaluation, useEvaluation } from '../../../../../helpers/hooks/useEvaluation';
import DiagnoseOverlays from '../BoxesOverlay/DiagnoseOverlays';

const useStyles = makeStyles((theme) => ({
  projection_container: {
    display: 'flex',
    position: 'relative',
    maxWidth: '100%',
    maxHeight: '100%',
  },

  projection_image: {
    position: 'relative',
    width: '100%',
    borderRadius: '5px',
    height: '100%',
    zIndex: 1,
  },

  projection_image_v: {
    position: 'relative',
    maxWidth: '100%',
    borderRadius: '5px',
    zIndex: 1,
  },

  animation: {
    width: '50%',
    height: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '10',
    opacity: 0.6,
  },

  evaluate_icon: {
    color: theme.palette.primary.main,
    width: '35%',
    height: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '10',
    opacity: '50%',
  },

  boxes_container: {
    zIndex: 3,
    width: '100%',
    height: '100%',
    position: 'absolute',
  },

  canvas_overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '5px',
    userSelect: 'none',
    opacity: 0.7,
    zIndex: 2000,
    border: '1px dashed red',
  },

  menu_item_container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4px 0px 4px 8px',
    margin: '4px 6px',
    textTransform: 'uppercase',
  },

  menu_item_icon: {
    marginLeft: '12px',
    width: '12px',
  },

  menu_item_text: {
    textTransform: 'uppercase',
    fontSize: theme.fonts.responsiveMedium,
  },
}));

export default function Projection(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();

  // Store state
  const {
    drawing,
    qualityInRevisionMode,
    shownQualityOverlayType,
    qualityInDrawingMode,
    diagnosticsInRevisionMode,
  } = useSelector((state) => state.labeling);
  const results = useSelector((state) => state.results);
  const { projections, applied_quality } = useSelector((state) => state.results);
  const currentExam = useSelector((state) => state.examinations.currentExam);
  const {
    qualityOverlaysToShow,
    parenchymaCutsToShow,
    linesToShow,
    pectAngleLinesToShow,
    nippleProfileToShow,
    IMFToShow,
    pectLevelToShow,
  } = useSelector((state) => state.images);
  const { presentation_lesions, presentation_parenchyma, tabsIndex, landscape, zoomedProjection } =
    useSelector((state) => state.gui);

  const canvasRef = useRef(null);
  const canvasOverlaysRef = useRef(null);
  const canvasBoxesRef = useRef(null);

  const canvasOffSetX = useRef(null);
  const canvasOffSetY = useRef(null);

  // Local state
  const [imgSize, setImgSize] = useState([0, 0]);
  const [leftDown, setLeftDown] = useState(false);
  const [middleDown, setMiddleDown] = useState(false);
  const [imgPosition, setImgPosition] = useState([0, 0]);
  const [zoom, setZoom] = useState(1);
  const [movePoint, setMovePoint] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [src, setSrc] = useState('');
  const [customResult, setCustomResult] = useState(null);
  const [overlayTypes, setOverlayTypes] = useState({});
  const [startX, setStartX] = useState(null);
  const [startY, setStartY] = useState(null);
  const [tempRectangle, setTempRectangle] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedOption, setSelectedOption] = useState({});

  // Variables;

  const pectoLevelLineToShow = Object.keys(pectLevelToShow).filter(
    (key) => pectLevelToShow[key] === true
  );
  const showPnlLines = Object.values(linesToShow).some((value) => value === true);
  const pnlLineToShow = Object.keys(linesToShow).filter((key) => linesToShow[key] === true);
  const showPectAngleLines = Object.values(pectAngleLinesToShow).some((value) => value === true);
  const IMFShow = Object.keys(IMFToShow).filter((key) => IMFToShow[key] === true);
  const pectAngleLineToShow = Object.keys(pectAngleLinesToShow).filter(
    (key) => pectAngleLinesToShow[key] === true
  );
  const nippleEdgeToShow = Object.keys(nippleProfileToShow).filter(
    (key) => nippleProfileToShow[key] === true
  );
  const parenchymaShow = Object.values(parenchymaCutsToShow).some(
    (value) =>
      value === true || qualityOverlaysToShow.parenchymaRMLO || qualityOverlaysToShow.parenchymaLMLO
  );
  const parenchymaCCShow = Object.values(parenchymaCutsToShow).some(
    (value) =>
      value === true || qualityOverlaysToShow.parenchymaRCC || qualityOverlaysToShow.parenchymaLCC
  );
  const parenchymaLateralShow = Object.values(parenchymaCutsToShow).some(
    (value) =>
      value === true ||
      qualityOverlaysToShow.parenchymaRCClateral ||
      qualityOverlaysToShow.parenchymaLCClateral
  );
  const parenchymaMedialShow = Object.values(parenchymaCutsToShow).some(
    (value) =>
      value === true ||
      qualityOverlaysToShow.parenchymaRCCmedial ||
      qualityOverlaysToShow.parenchymaLCCmedial
  );

  // useEffects
  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');
    dispatch(actions.setInitialRectangles());
    setContextMenu(null);
    setSelectedOption({});
    setTempRectangle(null);
    if (context) {
      context.clearRect(0, 0, canvas.width, canvas.height);
    }
  }, [diagnosticsInRevisionMode]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');

    if (context) {
      const canvasOffSet = canvas?.getBoundingClientRect();
      if (canvasOffSetX.current) {
        canvasOffSetX.current = canvasOffSet.left;
        canvasOffSetY.current = canvasOffSet.top;
      }

      const boundingRect = canvas?.getBoundingClientRect();
      if (boundingRect) {
        canvas.width = boundingRect.width;
        canvas.height = boundingRect.height;
        context.lineWidth = 3;
      }
    }

    if (isDrawing && tempRectangle) {
      context.strokeRect(
        tempRectangle.startX,
        tempRectangle.startY,
        tempRectangle.width,
        tempRectangle.height,
        tempRectangle.color,
        tempRectangle.rectangle_class,
        tempRectangle.rectangle_type
      );
    }
  }, [props.microcalcRectangles, props.opacitiesRectangles, tempRectangle, zoomedProjection]);

  useEffect(() => {
    setZoom(1);
    setImgPosition([0, 0]);
  }, [currentExam, props?.src, zoomedProjection, props?.proj_name]);

  useEffect(() => {
    const newSrc = !!props?.uid
      ? `${API_ENDPOINT}/static_images/${results.folder}/${props?.uid}.png`
      : `${API_ENDPOINT}/static_images/${results.folder}/${projections[props.proj_name]?.uid}.png`;
    setSrc(newSrc);

    dispatch(actions.setInitialRectangles());
    setTempRectangle(null);
  }, [props?.uid, results.folder]);

  // Free drawing Handlers
  const onMouseDown = useCallback((event) => {
    if (event.button === 0) setLeftDown(true);
    else if (event.button === 1) setMiddleDown(true);
  }, []);

  const onMouseUp = useCallback(
    (event) => {
      if (event.button === 0) {
        setLeftDown(false);
        if (isDrawing) onDrawingFinished();
      } else if (event.button === 1) setMiddleDown(false);

      if (!!movePoint) {
        setMovePoint(null);
        // setDerivedFeatures();
      }
    },
    [movePoint, isDrawing]
  );

  function onMouseMoving({ clientX, clientY, movementX, movementY }) {
    let rect;
    if (tabsIndex === 1 || qualityInRevisionMode) {
      rect = canvasOverlaysRef.current?.getBoundingClientRect();
    } else {
      rect = canvasBoxesRef.current?.getBoundingClientRect();
    }

    if (!rect) return;

    if (!!movePoint) {
      const x = (clientX - rect.left) / rect.width;
      const y = (clientY - rect.top) / rect.height;
      dispatch(
        actions.updateCustomQualityFeature(props?.proj_name.toUpperCase(), movePoint, [x, y], true)
      );
    } else if (middleDown) {
      const inFrameCoords = makeSureInFrame(
        rect,
        movementX / 2 + imgPosition[0],
        movementY / 2 + imgPosition[1]
      );
      setImgPosition(inFrameCoords);
    } else if (leftDown) {
      const scaleX = imgSize[0] / rect.width;
      const scaleY = imgSize[1] / rect.height;
      const x = (clientX - rect.left) * scaleX;
      const y = (clientY - rect.top) * scaleY;
      draw(x, y, scaleX);
    }
  }

  function makeSureInFrame(rect, x, y) {
    // const rect = canvasOverlaysRef.current.getBoundingClientRect();

    const zoomFactor = zoomedProjection === props?.proj_name ? 2.02 * zoom : zoom;

    const limitX = (0.5 * (zoom - 1) * rect.width) / zoomFactor;
    const limitY = (0.5 * (zoom - 1) * rect.height) / zoomFactor;

    x = Math.min(x, limitX);
    y = Math.min(y, limitY);
    x = Math.max(x, -limitX);
    y = Math.max(y, -limitY);

    return [x, y];
  }

  function onCanvasClick({ clientX, clientY, button }) {
    if (button === 0) {
      const rect = canvasOverlaysRef.current.getBoundingClientRect();
      const scaleX = imgSize[0] / rect.width;
      const scaleY = imgSize[1] / rect.height;
      const x = (clientX - rect.left) * scaleX;
      const y = (clientY - rect.top) * scaleY;
      draw(x, y, scaleX);
    }
  }

  function draw(x, y, scale) {
    if (
      (qualityInRevisionMode && !qualityInDrawingMode) ||
      (qualityInRevisionMode && qualityInDrawingMode === '')
    )
      return;

    let color;
    const d = 0.3 * drawing.radius * scale;

    if (qualityOverlaysToShow.clipsRCC) color = theme.palette.lesions.clips;
    else if (qualityOverlaysToShow.parenchymaRCC) color = theme.palette.lesions.parenchyma;
    else if (qualityOverlaysToShow.skinFoldsRCC) color = theme.palette.lesions.skinFolds;
    else if (qualityOverlaysToShow.pectoralisRCC) color = theme.palette.lesions.pectoralis;
    else if (qualityOverlaysToShow.scarsRCC) color = theme.palette.lesions.scars;
    else return;

    const context = canvasOverlaysRef.current.getContext('2d');

    if (drawing.mode === 'erase') context.globalCompositeOperation = 'destination-out';
    else context.globalCompositeOperation = 'source-over';

    context.fillStyle = color;
    context.beginPath();
    context.arc(x, y, d, 0, 2 * Math.PI);
    context.fill();

    context.globalCompositeOperation = 'source-over';
    setIsDrawing(true);
  }

  function onDrawingFinished() {
    setIsDrawing(false);
    const canvas = canvasOverlaysRef.current;
    const imageDataUrl = canvas.toDataURL('image/png');
    if (qualityInRevisionMode)
      dispatch(
        actions.updateCustomQualityOverlays(shownQualityOverlayType, props?.proj_name, imageDataUrl)
      );

    dispatch(actions.setQualityLocalChanges(true));
  }

  function onMouseScroll(event) {
    const newValue = Math.max(-0.001 * event.deltaY + zoom, 1);
    if (newValue === 1) setImgPosition([0, 0]);
    setZoom(newValue);

    onMouseMoving(event);
  }

  return (
    <Card
      elevation={7}
      className={classes.Card}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseUp}
    >
      <div
        className={classes.projection_container}
        style={{
          transform: `scale(${zoom}`,
          top: imgPosition[1],
          left: imgPosition[0],
          justifyContent: props?.left ? 'flex-start' : 'flex-end',
        }}
      >
        {/* Overlays for Quality Revision */}
        {(tabsIndex === 0 || tabsIndex === 1 || (tabsIndex === 2 && qualityInRevisionMode)) && (
          <ContourOverlay
            proj_name={props?.proj_name}
            canvasRef={canvasOverlaysRef}
            imgSize={imgSize}
            onMouseDown={onCanvasClick}
            onMouseMove={onMouseMoving}
            onMouseUp={onMouseUp}
            onWheel={onMouseScroll}
            tabsIndex={tabsIndex}
            evaluation={props?.evaluation}
            parenchymaCutsToShow={parenchymaCutsToShow}
          />
        )}

        {/* Diagnostic Overlays and Revision Canvas */}
        {(tabsIndex === 0 || (tabsIndex === 2 && !qualityInRevisionMode)) && (
          <DiagnoseOverlays
            proj_name={props?.proj_name}
            canvasRef={canvasBoxesRef}
            imgSize={imgSize}
            onWheel={onMouseScroll}
            canvasOffSetX={canvasOffSetX}
            canvasOffSetY={canvasOffSetY}
            onMouseMove={onMouseMoving}
          />
        )}

        {/* Evaluate projection */}
        {!props?.evaluation && !props?.fetchingEval && (
          <Tooltip
            title={t('ResultView.not_evaluated')}
            arrow
            interactive
            placement='bottom'
            TransitionComponent={Zoom}
          >
            <BlockIcon className={classes.evaluate_icon} />
          </Tooltip>
        )}

        {!!props?.fetchingEval && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img className={classes.animation} src={animation} alt='evaluating' />
          </div>
        )}
        <img
          crossOrigin='use-credentials'
          className={landscape ? classes.projection_image : classes.projection_image_v}
          style={{
            maxHeight: landscape ? (tabsIndex === 2 ? '46vh' : '43vh') : 'calc(29vh)',
          }}
          src={src}
          alt={`NO ${props?.proj_name} PROJECTION`}
          onClick={props?.onCaptureClick}
          onLoad={(event) => {
            if (props?.onLoad) props?.onLoad();
            setImgSize([event.target.naturalWidth, event.target.naturalHeight]);
          }}
        />

        {/* IMF box  and PNL over projections*/}
        {(qualityInRevisionMode ||
          props?.proj_name === pnlLineToShow.toString() ||
          props?.proj_name === pectAngleLineToShow.toString() ||
          props?.proj_name === nippleEdgeToShow.toString() ||
          props?.proj_name === pectoLevelLineToShow.toString() ||
          props?.proj_name === IMFShow.toString()) && (
          <LineComponent
            onMouseUp={showPnlLines ? () => {} : onMouseUp}
            onMouseMove={showPnlLines ? () => {} : onMouseMoving}
            onWheel={showPnlLines ? () => {} : onMouseScroll}
            onMouseDown={showPnlLines ? () => {} : onCanvasClick}
            showRevisionLines={qualityInRevisionMode && !qualityInDrawingMode}
            projection={props?.projection}
            showProjLines={showPnlLines ? props?.proj_name === pnlLineToShow.toString() : false}
            evaluation={props.evaluation}
            proj_name={props?.proj_name}
            movePoint={movePoint}
            parenchymaShow={parenchymaShow || parenchymaCCShow}
            parenchymaMedialShow={parenchymaMedialShow}
            parenchymaLateralShow={parenchymaLateralShow}
            setMovePoint={setMovePoint}
            showPetcAngleLine={
              showPectAngleLines ? props?.proj_name === pectAngleLineToShow.toString() : false
            }
            showNippleEdge={props?.proj_name === nippleEdgeToShow.toString()}
            showIMF={props?.proj_name === IMFShow.toString()}
            showPectoLevel={props?.proj_name === pectoLevelLineToShow.toString()}
          />
        )}
      </div>
    </Card>
  );
}
