import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { setActionToConfirm } from '../../../../store';
import * as actions from '../../../../store/index';
import { fetchDelete, fetchGet, fetchPut } from '../../../../helpers/fetching';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Tooltip,
  Typography,
  IconButton,
  Divider,
  Chip,
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Fab,
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { Delete, Edit, Done, Spellcheck, DoneOutline } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import { Alert } from '@material-ui/lab';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import animation from '../../../../resources/processing_animation.gif';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  AccordionDetails: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '40vh',
  },

  listHeader: {
    fontSize: theme.fonts.responsiveMedium,
    fontWeight: 'bold',
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.texts.primary,
  },

  chipSmall: {
    margin: '6px',
  },

  helperText: {
    color: '#ff7676',
    fontSize: '0.8rem',
    margin: '1px',
  },

  add_user_container: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px',
    minHeight: '40px',
  },

  tooltip_text: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    fontSize: '12px',
  },

  table_placeholder: {
    color: 'gray',
    padding: '15%',
    border: 'none',
    fontSize: '10px',
    fontStyle: 'italic',
    padding: '14px 10px 10px',
    borderBottom: 'unset',
  },
}));

function UsersPanel(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const notifications = useSelector((state) => state.gui.notifications);
  const currentRole = localStorage.getItem('role');

  const [users, setUsers] = useState([]);
  const [order, setOrder] = useState('ASC');
  const [orderBy, setOrderBy] = useState('');
  const [editUser, setEditUser] = useState(null);
  const [aliases, setAliases] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState({ msg: '', severity: '' });

  const heads = useMemo(
    () => [
      { key: 'status', label: '', align: 'center' },
      { key: 'username', label: t('users.name'), align: 'left' },
      { key: 'active', label: t('users.active'), align: 'left' },
      { key: 'aliases', label: t('users.dicomTag'), align: 'left' },
      { key: 'role', label: t('users.role'), align: 'left' },
      { key: 'annotator', label: t('users.annotator'), align: 'center' },
      { key: 'actions', label: t('users.actions'), align: 'center' },
    ],
    []
  );

  const fetchUsers = useCallback(async () => {
    const response = await fetchGet('users');
    if (response.success) {
      const filteredUsers = response.data.filter((user) => user.username !== 'admin');
      setUsers(filteredUsers);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchUsers();
      setLoading(false);
    };
    fetchData();
  }, [fetchUsers]);

  const updateUser = async (user, value, moduleRow) => {
    const updateBody = {
      [moduleRow]: typeof value === 'number' || typeof value === 'boolean' ? !value : value,
    };
    try {
      const response = await fetchPut(`user/${user}`, updateBody);
      if (response.success) {
        fetchUsers();
        setNotification({ msg: t('UserPanel.snack_user_updated'), severity: 'success' });
      } else {
        setNotification({ msg: t('UserPanel.snack_user_failed'), severity: 'error' });
      }
    } catch (error) {
      console.error('Failed to update user', error);
      setNotification({ msg: t('UserPanel.snack_user_failed'), severity: 'error' });
    }
  };

  const deleteUser = async (username) => {
    if (!username) {
      setNotification({ msg: t('UserPanel.snack_not_selected'), severity: 'warning' });
      return;
    }
    const response = await fetchDelete(`user/${username}`, { username });
    if (response.success) {
      setUsers((prevState) => prevState.filter((u) => u.username !== username));
      setNotification({ msg: t('UserPanel.snack_not_selected'), severity: 'success' });
      fetchUsers();
    } else {
      setNotification({ msg: t('UserPanel.snack_delete_failed'), severity: 'error' });
    }
  };

  const handleAliasesChange = (event) => {
    setAliases(event.target.value);
  };

  const handleKeyDown = (row, event) => {
    if (event.key === 'Enter') {
      updateUser(row.username, aliases, 'aliases');
      setTimeout(() => setEditUser(null), 1000);
    }
  };

  const EnhancedTableHead = ({ order, orderBy, onRequestSort }) => {
    const createSortHandler = (property) => (event) => onRequestSort(event, property);
    return (
      <TableHead style={{ background: '#4f4747' }}>
        <TableRow>
          {heads.map((head) => (
            <TableCell
              key={head.key}
              style={{ fontWeight: 'bold' }}
              align={head.align}
              padding='normal'
              sortDirection={orderBy === head.key ? order.toLowerCase() : false}
            >
              <TableSortLabel
                active={orderBy === head.key}
                direction={orderBy === head.key ? order.toLowerCase() : 'asc'}
                onClick={createSortHandler(head.key)}
              >
                {head.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['ASC', 'DESC']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === 'DESC'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'ASC';
    setOrder(isAsc ? 'DESC' : 'ASC');
    setOrderBy(property);
  };

  const closeUsersDialog = () => {
    dispatch(setActiveDialog(null));
    dispatch(deleteNotification(notifications.map(({ id }) => id)));
  };

  return (
    <Box sx={{ width: '100%' }}>
      {loading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <img
            style={{ width: '100px', height: '100px', borderRadius: '50%' }}
            src={animation}
            alt='logoanimation'
          />
          <Typography style={{ fontSize: '10px', color: 'gray', fontStyle: 'italic' }}>
            {t('Dashboard.loading')}
          </Typography>
        </div>
      ) : (
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size={'medium'}>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                heads={heads}
                rowCount={users.length}
              />
              {users.length > 0 ? (
                <TableBody>
                  {users.sort(getComparator(order, orderBy)).map((row) => (
                    <TableRow hover tabIndex={-1} key={row.username}>
                      {heads.map((head) => {
                        if (head.key === 'actions')
                          return currentRole === 'admin' ||
                            (currentRole === 'supervisor' && row.role !== 'supervisor') ? (
                            <TableCell align={head.align} key={head.key}>
                              <Tooltip
                                arrow
                                placement='bottom'
                                title={
                                  <Typography
                                    style={{
                                      letterSpacing: '0.1rem',
                                      textTransform: 'uppercase',
                                      fontSize: theme.fonts.responsiveMedium,
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {editUser === row.username
                                      ? t('users.save')
                                      : t('UserPanel.edit')}
                                  </Typography>
                                }
                              >
                                {editUser === row.username ? (
                                  <IconButton
                                    size='small'
                                    onClick={() => {
                                      updateUser(row.username, aliases, 'aliases');
                                      setEditUser(null);
                                    }}
                                  >
                                    <CheckIcon />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    size='small'
                                    onClick={() => {
                                      setEditUser(row.username);
                                      setAliases(row.aliases);
                                    }}
                                  >
                                    <Edit fontSize='small' />
                                  </IconButton>
                                )}
                              </Tooltip>
                              <Tooltip
                                arrow
                                placement='bottom'
                                title={
                                  <Typography
                                    style={{
                                      letterSpacing: '0.1rem',
                                      textTransform: 'uppercase',
                                      fontSize: theme.fonts.responsiveMedium,
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {t('UserPanel.delete')}
                                  </Typography>
                                }
                              >
                                <IconButton
                                  size='small'
                                  onClick={() =>
                                    dispatch(
                                      setActionToConfirm({
                                        action: () => deleteUser(row.username),
                                        text: `${t('UserPanel.confirm_delete')} ${row.username}?`,
                                      })
                                    )
                                  }
                                >
                                  <Delete fontSize='small' />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          ) : (
                            <TableCell align={head.align} key={head.key} />
                          );

                        if (head.key === 'active')
                          return (
                            <TableCell align={head.align} key={head.key}>
                              <Tooltip
                                arrow
                                placement='bottom'
                                title={
                                  <>
                                    <Typography
                                      style={{
                                        letterSpacing: '0.1rem',
                                        textTransform: 'uppercase',
                                        fontSize: theme.fonts.responsiveMedium,
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {!!row.active
                                        ? t('UserPanel.enable')
                                        : t('UserPanel.disable')}
                                    </Typography>
                                    <Divider style={{ margin: '3% 0' }} />
                                    <Typography
                                      style={{
                                        letterSpacing: '0.1rem',
                                        fontSize: theme.fonts.responsiveMedium,
                                      }}
                                    >
                                      {!!row.active
                                        ? t('UserPanel.enable_click')
                                        : t('UserPanel.disable_click')}
                                    </Typography>
                                  </>
                                }
                              >
                                <span>
                                  {!!row.active ? (
                                    <Chip
                                      clickable
                                      variant='outlined'
                                      label={t('UserPanel.enable')}
                                      size='small'
                                      disabled={
                                        currentRole === 'supervisor' && row.role === 'supervisor'
                                      }
                                      onClick={() => updateUser(row.username, row.active, 'active')}
                                      style={{
                                        color: '#18cc73',
                                        border: `1px solid #18cc73`,
                                      }}
                                    />
                                  ) : (
                                    <Chip
                                      clickable
                                      variant='outlined'
                                      label={t('UserPanel.disable')}
                                      size='small'
                                      disabled={
                                        currentRole === 'supervisor' && row.role === 'supervisor'
                                      }
                                      onClick={() => updateUser(row.username, row.active, 'active')}
                                      style={{
                                        border: `1px solid ${theme.palette.primary.main}`,
                                        color: theme.palette.primary.main,
                                      }}
                                    />
                                  )}
                                </span>
                              </Tooltip>
                            </TableCell>
                          );

                        if (head.key === 'annotator')
                          return (
                            <TableCell align={head.align} key={head.key}>
                              <Tooltip
                                arrow
                                placement='bottom'
                                title={
                                  <>
                                    <Typography
                                      style={{
                                        letterSpacing: '0.1rem',
                                        textTransform: 'uppercase',
                                        fontSize: theme.fonts.responsiveMedium,
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {!!row.annotator
                                        ? t('UserPanel.annotating')
                                        : t('UserPanel.no_annotating')}
                                    </Typography>
                                    <Divider style={{ margin: '3% 0' }} />
                                    <Typography
                                      style={{
                                        letterSpacing: '0.1rem',
                                        fontSize: theme.fonts.responsiveMedium,
                                      }}
                                    >
                                      {!!row.annotator
                                        ? t('UserPanel.annotating_click')
                                        : t('UserPanel.no_annotating_click')}
                                    </Typography>
                                  </>
                                }
                                aria-label='add'
                              >
                                <span>
                                  <IconButton
                                    size='small'
                                    disabled={
                                      currentRole === 'supervisor' && row.role === 'supervisor'
                                    }
                                    onClick={() =>
                                      updateUser(row.username, row.annotator, 'annotator')
                                    }
                                    style={{
                                      color: !!row.annotator ? '#18cc73' : 'inherit',
                                    }}
                                  >
                                    <Spellcheck fontSize='small' />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </TableCell>
                          );

                        if (head.key === 'aliases')
                          if (editUser === row.username)
                            return (
                              <TableCell align={head.align} key={head.key}>
                                <TextField
                                  name='aliases'
                                  label='Aliases'
                                  size='small'
                                  color='secondary'
                                  value={aliases}
                                  onChange={handleAliasesChange}
                                  onKeyDown={(e) => handleKeyDown(row, e)}
                                  variant='outlined'
                                  fullWidth
                                  multiline
                                  helperText={t('users.helper_alias')}
                                  FormHelperTextProps={{ classes: { root: classes.helperText } }}
                                />
                              </TableCell>
                            );
                          else
                            return (
                              <TableCell align={head.align} key={head.key}>
                                <Grid item>
                                  {row?.aliases?.split(';').map((alias, i) => (
                                    <Chip
                                      key={i}
                                      className={classes.chipSmall}
                                      size='small'
                                      label={alias.trim() ? alias.trim() : '-'}
                                    />
                                  ))}
                                </Grid>
                              </TableCell>
                            );

                        if (head.key === 'role' && editUser === row.username)
                          return (
                            <TableCell
                              align={head.align}
                              key={head.key}
                              style={{ minWidth: '200px' }}
                            >
                              <FormControl style={{ marginTop: '3%', width: '90%', zIndex: 2000 }}>
                                <Select
                                  value={row.role}
                                  label='Role'
                                  onChange={(event) =>
                                    updateUser(row.username, event.target.value, 'role')
                                  }
                                  style={{ zIndex: 2200 }}
                                >
                                  <MenuItem value='technician'>{t('users.Technician')}</MenuItem>
                                  <MenuItem value='radiologist'>{t('users.Radiologist')}</MenuItem>
                                  {currentRole === 'admin' && (
                                    <MenuItem value='supervisor'>{t('users.Supervisor')}</MenuItem>
                                  )}
                                </Select>
                              </FormControl>
                            </TableCell>
                          );

                        if (head.key === 'status')
                          return row.active === 0 ? (
                            <TableCell align={head.align} key={head.key}>
                              <Tooltip
                                arrow
                                placement='top'
                                title={
                                  <Typography
                                    style={{
                                      letterSpacing: '0.1rem',
                                      textTransform: 'uppercase',
                                      fontSize: theme.fonts.responsiveMedium,
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {t('users.not_active')}
                                  </Typography>
                                }
                              >
                                <FiberManualRecordIcon
                                  style={{ fontSize: '0.8rem' }}
                                  color='primary'
                                />
                              </Tooltip>
                            </TableCell>
                          ) : (
                            <TableCell align={head.align} key={head.key} />
                          );

                        return (
                          <TableCell align={head.align} key={head.key}>
                            {row[head.key] !== undefined ? row[head.key] || 0 : '--'}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography className={classes.table_placeholder}>
                        {t('UserPanel.nothing')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Paper>
      )}

      <div className={classes.add_user_container}>
        <Tooltip
          title={
            <Typography className={classes.tooltip_text} color='inherit'>
              {t('users.add_user')}
            </Typography>
          }
          arrow
          interactive
          placement='left'
        >
          <span>
            <Fab
              size='small'
              color='secondary'
              aria-label='add'
              onClick={() => props.setNewRow(true)}
              disabled={props.newRow}
            >
              <AddIcon fontSize='small' />
            </Fab>
          </span>
        </Tooltip>
      </div>
    </Box>
  );
}

export default UsersPanel;
