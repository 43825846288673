import { createSlice } from '@reduxjs/toolkit';

import { fetchGet, fetchDelete, fetchPut } from '../../helpers/fetching';
import { setSnackbar } from '../index';
import history from '../../history';
import { setActiveDialog } from '../index';

const initialState = {
    modelsList: null,

    modelObject: null,

    compareModelObject: null,

    currentlyTrained: null,

    modelFetching: false,

    modelsStatistics: null,

    trainingProgress: {
        status: null
    }
  }

export const modelsSlice = createSlice({
  name: 'models',
  initialState,
  reducers: {
    setModelsList: (state, action) => {
      state.modelsList = action.payload
    },
    setModelObject: (state, action) => {
        state.modelObject = action.payload
    },
    setCompareModelObject: (state, action) => {
        state.compareModelObject = action.payload
    },
    setCurrentlyTrained: (state, action) => {
        state.currentlyTrained = action.payload
    },
    setModelFetching: (state, action) => {
        state.modelFetching = action.payload
    },
    setModelsStatistics: (state, action) => {
        state.modelsStatistics = action.payload
    },
    setTrainingProgress: (state, action) => {
        state.trainingProgress = action.payload
    },
  }
})

export const { setModelsList, setModelObject, setModelFetching,
               setCompareModelObject, setCurrentlyTrained, 
               setModelsStatistics, setTrainingProgress } = modelsSlice.actions

export default modelsSlice.reducer

// Asynchronous
export const fetchModelsList = modelsFilter => {
    return async (dispatch, _) => {
        const response = await fetchGet(`model_list/${modelsFilter}`, 'models');
        if (response.success) 
            dispatch(setModelsList(response.data))
        else
            dispatch(setSnackbar(
                {
                    msg: response.msg,
                    severity: "error"
                }
            ));
    }
}

export const fetchModelObject = modelName => {
    modelName = modelName.replaceAll(' ', '_');
    return async dispatch => {
        dispatch(setModelFetching(true));
        const response = await fetchGet(`model/${modelName}`, 'models');
        if (response.success) {
            dispatch(setModelFetching(false));
            dispatch(setModelObject(response.data));
        }
        else {
            dispatch(setModelFetching(false));
            dispatch(setSnackbar(
                {
                    msg: response.msg,
                    severity: "error"
                }
            ));
        }
    }
}

export const setSplitView = modelName => {
    return async dispatch => {
        const response = await fetchGet(`model/${modelName}`, 'models');
        if (response.success)
            dispatch(setCompareModelObject(response.data));
        else
            dispatch(setSnackbar(
                {
                    msg: response.msg,
                    severity: "error"
                }
            ));
    }
};

export const deleteModel = modelName => {
    return async (dispatch, getState) => {
        const modelsList = getState().models.modelsList;

        const response = await fetchDelete(`model/${modelName}`, {}, 'models');

        if (!response.success) {
            dispatch(setSnackbar(
              {
                  msg: response.msg,
                  severity: "error"
              }));
            return;
        }
        const newModelsList = { ...modelsList };
        for (let category of Object.keys(newModelsList))
            newModelsList[category] = newModelsList[category].filter(model => model.name !== modelName);
        dispatch(setModelsList(newModelsList));
        dispatch(setModelObject(null));
        
    }
};

export const onModelClick = model_name => {
    return async dispatch => {
        if (history.location.pathname.includes('dashboard'))
            history.push(`/bbox/training/details`);
        dispatch(fetchModelObject(model_name));
    }
}

export const fetchModelsStatistics = () => {
    return async dispatch => {
        dispatch(setModelsStatistics(null));
        const response = await fetchGet('statistics', 'models');
        if (response.success) {
            dispatch(setModelsStatistics(response.data));
        }
        else {
            dispatch(setSnackbar(
                {
                    msg: response.msg,
                    severity: "error"
                }
            ));
        }
    }
}

export const startTraining = modelName => {
    return async (dispatch, getState) => {
        const { currentlyTrained } = getState().models;
        if (!!currentlyTrained) {
          dispatch(setSnackbar(
            {
                msg: `Model ${currentlyTrained} is currently trained.
                      Only one model can be trained at a time.`,
                severity: "warning"
            }
          ));
          return;
        }
    
        dispatch(setTrainingProgress({ status: 'preparing' }));
        dispatch(setCurrentlyTrained(modelName));
        
        const response = await fetchPut('train', {
          userName: localStorage.getItem('username'),
          baseModelName: modelName,
        }, 'models');

        if (response.success) {
          dispatch(setTrainingProgress({
            status: response.status
          }))
          dispatch(setCurrentlyTrained(null));
          dispatch(fetchModelsList('all'));
          dispatch(setSnackbar(
            {
                msg: 'Training model finished',
                severity: "success"
            }
          ));
    
        } else {
          dispatch(setCurrentlyTrained(null));
          dispatch(setSnackbar(
            {
                msg: response.msg,
                severity: "error"
            }
          ));
          dispatch(setTrainingProgress({
            status: 'failed'
          }))
        }
      }
}

export const openTrainingDialog = modelName => {
    return dispatch => {
        dispatch(setTrainingProgress({ status: 'confirm',
                                       modelName: modelName }))
        dispatch(setActiveDialog('training'));
    }
}