import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import {
  CircularProgress,
  Typography,
  TableRow,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  Chip,
  Grid,
  Tooltip,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { fetchPost } from '../../../../../helpers/fetching';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../CustomFilter.style';
import { DropzoneArea } from 'material-ui-dropzone';
import PeopleIcon from '@material-ui/icons/People';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { setSnackbar } from '../../../../../store';
import SearchPatients from './SearchPatients.js/SearchPatients';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

export default function UploadCSV(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const examinationsFound = props?.patientList?.filter((patient) => patient?.found === true);
  const notFound = props?.patientList?.length - examinationsFound?.length;

  const tableHeader = [
    { name: 'patientId', label: t('Filters.patient_id'), align: 'left' },
    { name: 'name', label: t('Filters.name'), align: 'left' },
    { name: 'dob', label: t('Filters.dob'), align: 'left' },
    { name: 'date', label: t('Filters.date'), align: 'left' },
    { name: 'status', label: t('Filters.status'), align: 'center' },
    { name: 'actions', label: '', align: 'left' },
  ];

  function convertDate(dateString) {
    const [day, month, year] = dateString.split('.');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }

  function parseCsvString(csvContentString) {
    const lines = csvContentString.trim().split('\n');
    const result = [];

    const dateRegex = /\b\d{2}\.\d{2}\.\d{4}\b/g;

    const idSeen = new Set();

    for (const line of lines) {
      const trimmedLine = line.trim();
      if (!trimmedLine) continue;

      const parts = trimmedLine.split(/\s+/);
      const datesIter = trimmedLine.matchAll(dateRegex);
      const dates = [];
      for (let date of datesIter) dates.push(date[0]);
      if (!dates || dates.length !== 2) {
        continue;
      }

      const patientId = parts[0];
      if (idSeen.has(patientId)) continue;
      idSeen.add(patientId);

      const dateOfBirth = convertDate(dates[0]);
      const examDate = convertDate(dates[1]);
      const dateOfBirthIndex = trimmedLine.indexOf(dates[0]);
      const name = trimmedLine.slice(patientId.length, dateOfBirthIndex).trim();

      result.push({
        patientId,
        name,
        dateOfBirth,
        examDate,
      });
    }

    return result;
  }

  const fetchSanitizeData = async (studyList) => {
    const response = await fetchPost('patient_filter_sanitize', studyList);
    if (!response.success) {
      dispatch(setSnackbar({ msg: response.msg, severity: 'error' }));
      return null;
    }
    return response.data;
  };
  const handleFileUpload = async (selectedFile) => {
    if (!selectedFile || selectedFile.length === 0) return;
    setLoading(true);

    const file = selectedFile[0];

    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const base64String = e.target.result.split(',')[1];
        const decodedString = atob(base64String);
        const parsedData = parseCsvString(decodedString);
        const sanitizedData = await fetchSanitizeData(parsedData);

        if (sanitizedData) {
          props.setPatientList(sanitizedData);
          setUploadedFile(file);
        } else {
          props.setPatientList([]);
          dispatch(setSnackbar({ msg: t('Filters.rejected'), severity: 'error' }));
        }
      };

      reader.onerror = (error) => {
        dispatch(setSnackbar({ msg: t('Filters.error_processing_file'), severity: 'error' }));
        props.setPatientList([]);
      };

      reader.readAsDataURL(file);
    } catch (error) {
      dispatch(setSnackbar({ msg: t('Filters.error_processing_file'), severity: 'error' }));
      props.setPatientList([]);
    } finally {
      setLoading(false);
    }
  };

  const removePatient = (patientId) => {
    props.setPatientList((prevList) => prevList.filter((user) => user.patientId !== patientId));
    dispatch(setSnackbar({ msg: t('Filters.patient_removed'), severity: 'info', duration: 1000 }));
  };

  const handleDeleteFile = () => {
    setUploadedFile(null);
    props.setPatientList([]);
    dispatch(setSnackbar({ msg: t('Filters.file_removed'), severity: 'warning' }));
  };

  const deleteNotFound = () => {
    props?.setPatientList(examinationsFound);
  };

  return (
    <div className={classes.upload_container}>
      <DropzoneArea
        onChange={handleFileUpload}
        acceptedFiles={['.csv']}
        dropzoneText={uploadedFile ? '' : t('Filters.dropzone_text')}
        filesLimit={1}
        maxFileSize={20971520}
        showAlerts={false}
        fileObjects={uploadedFile}
        onDelete={handleDeleteFile}
        showPreviews={true}
        showFileNamesInPreview={true}
        previewText={t('Filters.uploaded_file')}
        useChipsForPreview={true}
        clearOnUnmount={false}
        dropzoneClass={uploadedFile ? classes.dropZone_hide : classes.dropZone}
        onDropRejected={(files) => {
          dispatch(
            setSnackbar({
              msg: `${t('Filters.rejected')}${files[0].name}`,
              severity: 'error',
            })
          );
        }}
      />

      {loading ? (
        <Grid xs={12} item className={classes.loading_container}>
          <CircularProgress />
        </Grid>
      ) : (
        uploadedFile && (
          <Grid container wrap='nowrap' direction='row' spacing={1}>
            <Grid xs={8} item className={classes.grid_container}>
              <div className={classes.table_title_container}>
                <Typography className={classes.table_title}>
                  {t('Filters.custom_list_title')}
                </Typography>
                <div className={classes.table_title_container}>
                  {!!notFound && (
                    <Tooltip
                      arrow
                      placement='top-end'
                      title={
                        <Typography className={classes.tooltip_text}>
                          {t('Filters.delete_not_found')}
                        </Typography>
                      }
                    >
                      <Chip
                        label={notFound}
                        variant='outlined'
                        size='small'
                        color='secondary'
                        icon={<DeleteForeverIcon />}
                        onClick={() => deleteNotFound()}
                        style={{ marginRight: '10px' }}
                      />
                    </Tooltip>
                  )}

                  <Tooltip
                    arrow
                    placement='top-end'
                    title={
                      <Typography className={classes.tooltip_text}>
                        {t('Filters.number_added')}
                      </Typography>
                    }
                  >
                    <Chip
                      label={`${props.patientList.length}`}
                      variant='outlined'
                      size='small'
                      color='secondary'
                      icon={<PeopleIcon />}
                    />
                  </Tooltip>
                </div>
              </div>
              <TableContainer>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {tableHeader.map((head, i) => (
                        <TableCell key={i} align={head.align} className={classes.table_row}>
                          {head.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.patientList.map((patient) => (
                      <TableRow key={patient.patientId}>
                        <TableCell className={classes.table_row}>{patient.patientId}</TableCell>
                        <TableCell className={classes.table_row}>{patient.name}</TableCell>
                        <TableCell className={classes.table_row}>{patient.dateOfBirth}</TableCell>
                        <TableCell className={classes.table_row}>{patient.examDate}</TableCell>
                        <TableCell align='center' className={classes.table_row}>
                          <Tooltip
                            arrow
                            placement='top-start'
                            title={
                              <Typography className={classes.tooltip_text}>
                                {patient.found === true
                                  ? t('Filters.found_helper')
                                  : t('Filters.not_found_helper')}
                              </Typography>
                            }
                          >
                            <Grid container className={classes.status_container}>
                              <Grid item xs={4}>
                                <FiberManualRecordIcon
                                  fontSize='small'
                                  style={{
                                    fontSize: theme.fonts.responsiveSmall,
                                    fill:
                                      patient.found === true
                                        ? theme.palette.dashboard.mild
                                        : theme.palette.dashboard.inadequate,
                                  }}
                                />
                              </Grid>

                              <Grid item xs={8}>
                                <Typography className={classes.exam_status}>
                                  {patient.found === true
                                    ? t('Filters.found')
                                    : t('Filters.not_found')}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Tooltip>
                        </TableCell>
                        <TableCell align='right' className={classes.table_row}>
                          <Tooltip
                            arrow
                            placement='top-start'
                            title={
                              <Typography className={classes.tooltip_text}>
                                {t('Filters.remove')}
                              </Typography>
                            }
                          >
                            <HighlightOffIcon
                              fontSize='small'
                              color='secondary'
                              onClick={() => removePatient(patient.patientId)}
                              style={{ cursor: 'pointer' }}
                            />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <SearchPatients
              setPatientList={props.setPatientList}
              patientList={props.patientList}
              size={4}
              style={{ borderLeft: '1px solid gray' }}
            />
          </Grid>
        )
      )}
    </div>
  );
}
