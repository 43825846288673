import { findNippleAngle, findPectNippleLineMLO, getProjectionPoint } from './vectorOperations';

export function addDerivedFeaturesCC(resultCC, projection, flip, NIPPLE_ANGLE, field) {
  try {
    if (!NIPPLE_ANGLE || !projection) return;

    const { location_nipple, PectoralisCC, PectoThickness, center_line } = resultCC;
    const { img_size_x, img_size_y } = projection;

    // PNL
    let pnl = location_nipple?.[0] * img_size_x;
    if (flip) pnl = img_size_x - pnl;
    if (PectoralisCC != 'insufficient' && !!PectoThickness) pnl -= PectoThickness * img_size_x;
    resultCC.PectoNippleLength = pnl;

    // Nipple angle and nipple centered
    if (field == 'PectoralisCC') return;
    
    const nippleAngle = findNippleAngle(center_line, location_nipple, flip, [
      img_size_x,
      img_size_y,
    ]);
    let nippleCentered;
    if (Math.abs(nippleAngle) < NIPPLE_ANGLE?.[0]) nippleCentered = 'correct';
    else if (Math.abs(nippleAngle) < NIPPLE_ANGLE?.[1]) nippleCentered = 'moderate';
    else nippleCentered = 'insufficient';

    resultCC.NippleCentered = nippleCentered;
    resultCC.NippleAngle = nippleAngle;
  } catch (err) {
    console.log(err);
  }
}

export function addDerivedFeaturesMLO(resultMLO, projection, flip, PNL_FROM_EDGE) {
  try {
    if (PNL_FROM_EDGE === undefined || PNL_FROM_EDGE === null || !projection || !resultMLO) return;

    const { location_nipple, location_pecto_top, location_pecto_bottom } = resultMLO;
    const { img_size_x, img_size_y } = projection;

    const pnl = findPectNippleLineMLO(
      location_pecto_top,
      location_pecto_bottom,
      location_nipple,
      img_size_x,
      img_size_y,
      flip
    );

    const projPoint = getProjectionPoint(
      location_pecto_top,
      location_pecto_bottom,
      location_nipple,
      img_size_x,
      img_size_y,
      flip,
      PNL_FROM_EDGE
    );

    let pectoLevel;
    const tolerance = 0.15;
    if (location_nipple?.[1] <= location_pecto_bottom?.[1] + tolerance) pectoLevel = 'correct';
    else if (projPoint?.[1] / img_size_y <= location_pecto_bottom?.[1] + tolerance) pectoLevel = 'moderate';
    else pectoLevel = 'insufficient';

    resultMLO.PectoNippleLength = pnl;
    resultMLO.PectLevel = pectoLevel;
  } catch (err) {
    console.log(err);
  }
}

export const addPNLEval = (resultCC, resultMLO, PNL_DIFF) => {
  try {
    if (PNL_DIFF === undefined || PNL_DIFF === null || !resultCC || !resultMLO) return;

    const pnlLengthCC = resultCC.PectoNippleLength;
    const pnlLengthMLO = resultMLO.PectoNippleLength;

    const pnlDiff = pnlLengthMLO - pnlLengthCC;
    if (isNaN(pnlDiff)) return;

    if (pnlDiff <= PNL_DIFF?.[0]) {
      resultCC.PNL = 'correct';
    } else if (pnlDiff <= PNL_DIFF?.[1]) {
      resultCC.PNL = 'moderate';
    } else if (pnlDiff > PNL_DIFF?.[1]) {
      resultCC.PNL = 'insufficient';
    }
  } catch (err) {
    console.log(err);
  }
};

export const addSymmetryEval = (resultLeft, resultRight, SYMMETRY) => {
  try {
    if (SYMMETRY === undefined || SYMMETRY === null || !resultLeft || !resultRight) return;

    let center_left;
    if ('center_line' in resultLeft) center_left = resultLeft.center_line;
    else center_left = resultLeft.location_nipple?.[1];

    let center_right;
    if ('center_line' in resultRight) center_right = resultRight.center_line;
    else center_right = resultRight.location_nipple?.[1];

    const diff = Math.abs(center_left - center_right);
    if (isNaN(diff)) return;

    if (diff <= SYMMETRY?.[0]) {
      resultLeft.symmetry = 'correct';
      resultRight.symmetry = 'correct';
    } else if (diff <= SYMMETRY?.[1]) {
      resultLeft.symmetry = 'moderate';
      resultRight.symmetry = 'moderate';
    } else if (diff > SYMMETRY?.[1]) {
      resultLeft.symmetry = 'insufficient';
      resultRight.symmetry = 'insufficient';
    }
  } catch (err) {
    console.log(err);
  }
};
