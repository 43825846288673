import { createSlice } from '@reduxjs/toolkit';
import { formatDate } from '../../helpers/formatDate';

const initialState = {
  dates: [formatDate(new Date(), '00:00'), formatDate(new Date(), '23:59')],
};

export const filterSlice = createSlice({
  name: 'filterDates',
  initialState,
  reducers: {
    setFilterProperty: (state, action) => {
      if (!!action.payload[1]) {
        state[action.payload[0]] = action.payload[1];
      } else {
        const newFilter = { ...state };
        delete newFilter[action.payload[0]];
        return newFilter;
      }
    },
    setFilter: (state, action) => {
      return {
        ...action.payload,
        dates: state.dates,
      };
    },
    resetFilter: (state, _) => {
      return {
        ...initialState,
        dates: state.dates,
      };
    },
  },
});

export const { setFilterProperty, setFilter, resetFilter } = filterSlice.actions;

export default filterSlice.reducer;
