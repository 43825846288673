import React, { useState, useEffect, useCallback } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import DraggableDialog from '../DraggableDialog/DraggableDialog';
import { fetchGet, fetchPost } from '../../../../helpers/fetching';
import {
  setActionToConfirm,
  setSnackbar,
  setActiveDialog,
  sendReports,
  sendSR,
} from '../../../../store';
import { useTranslation } from 'react-i18next';
import { DIAGNOSTICS_MICROCALC, DIAGNOSTICS_OPACITIES } from '../../../../config';
import { confirmEvaluation } from '../../../../store/slices/labeling';
import animation from '../../../../resources/processing_animation.gif';

const Report = (props) => {
  const { t } = useTranslation();
  const [qualitySrc, setQualitySrc] = useState(null);
  const [diagnosticsSrc, setDiagnosticsSrc] = useState(null);
  const [pending, setPending] = useState(null);
  const {
    confirmed_density,
    confirmed_quality,
    confirmed_opacities,
    confirmed_microcalc,
    pat_name,
    applied_density,
    applied_quality,
    applied_opacities,
    applied_microcalc,
  } = useSelector((state) => state.results);
  const currentExam = useSelector((state) => state.examinations.currentExam);
  const somePremiumDiagnostics =
    (DIAGNOSTICS_MICROCALC || DIAGNOSTICS_OPACITIES) &&
    localStorage.getItem('role') !== 'technician';
  const dispatch = useDispatch();

  const fetchReports = useCallback(async () => {
    setPending(t('report.generating'));

    const responseQuality = await fetchGet(`report_quality/${currentExam}`, 'bbox', false, 'blob');

    let responseDiagnostics = null;
    if (somePremiumDiagnostics) {
      responseDiagnostics = await fetchGet(
        `report_diagnostics/${currentExam}`,
        'bbox',
        false,
        'blob'
      );
    }
    if (
      responseQuality.status === 200 &&
      (!somePremiumDiagnostics || (responseDiagnostics && responseDiagnostics.status === 200))
    ) {
      const objectURLQuality = URL.createObjectURL(responseQuality.blob);
      setQualitySrc(objectURLQuality);

      if (responseDiagnostics) {
        const objectURLDiagnostics = URL.createObjectURL(responseDiagnostics.blob);
        setDiagnosticsSrc(objectURLDiagnostics);
      }
      setPending(null);
    } else {
      setQualitySrc('nothing');
      dispatch(
        setSnackbar({
          msg: `${t('report.failed_code')} ${responseQuality.status} ${
            responseDiagnostics ? responseDiagnostics.status : ''
          }`,
          severity: 'error',
        })
      );
      setPending(null);
    }
  }, [currentExam, dispatch]);

  const onSRSend = () => {
    if (pending) return;

    if (![confirmed_density, confirmed_quality, confirmed_opacities].every((item) => item))
      dispatch(
        setActionToConfirm({
          action: () => {
            setPending(t('report.send_structure_pacs'));
            dispatch(sendSR(currentExam, t));
            // Todo: change logic of sending report in long term
            // dispatch(confirmEvaluation(applied_quality, 'quality', true));
            // dispatch(confirmEvaluation(applied_density, 'density', true));
            // dispatch(confirmEvaluation(applied_microcalc, 'microcalc', true));
            // dispatch(confirmEvaluation(applied_opacities, 'opacities', true));
            // setPending(null);
          },
          text: t('report.not_confirmed'),
        })
      );
    else {
      setPending(t('report.send_structure_pacs'));
      dispatch(sendSR(currentExam));
      // setPending(null);
    }
  };

  const onQualityReportSend = () => {
    if (pending) return;
    if (![confirmed_density, confirmed_quality].every((item) => item))
      dispatch(
        setActionToConfirm({
          action: () => {
            setPending(t('report.send_img'));
            dispatch(sendReports('quality', currentExam, t));
          },
          text: t('report.not_confirmed'),
        })
      );
    else {
      setPending(t('report.send_img'));
      dispatch(sendReports('quality', currentExam, t));
      setPending(null);
    }
  };

  const onDiagnoseReportSend = () => {
    if (pending) return;
    if (![confirmed_density, confirmed_opacities, confirmed_microcalc].every((item) => item))
      dispatch(
        setActionToConfirm({
          action: () => {
            setPending(t('report.send_img'));
            dispatch(sendReports('diagnostics', currentExam, t));
            setPending(null);
          },
          text: t('report.not_confirmed'),
        })
      );
    else {
      setPending(t('report.send_img'));
      dispatch(sendReports('diagnostics', currentExam, t));
      setPending(null);
    }
  };

  useEffect(() => {
    fetchReports();
  }, []);

  return (
    <DraggableDialog
      maxWidth='xl'
      title={t('ResultView.report_title', { user: pat_name })}
      open={props.open}
      actions={[
        somePremiumDiagnostics && {
          onClick: onDiagnoseReportSend,
          color: 'primary',
          label: t('report.send_diagnose'),
        },
        {
          onClick: onQualityReportSend,
          color: 'primary',
          label: t('report.send_quality'),
        },

        {
          onClick: onSRSend,
          color: 'primary',
          label: t('report.send_sr'),
        },
      ]}
      onClose={() => dispatch(setActiveDialog(null))}
    >
      {!pending ? (
        <>
          <img src={qualitySrc} alt={t('report.quality_unavailable')} />
          {somePremiumDiagnostics && (
            <img src={diagnosticsSrc} alt={t('report.diagnostics_unavailable')} />
          )}
        </>
      ) : (
        <>
          <img
            style={{ width: '100px', height: '100px', borderRadius: '50%' }}
            src={animation}
            alt='logoanimation'
          />
          <p>{pending}</p>
        </>
      )}
    </DraggableDialog>
  );
};

export default Report;
