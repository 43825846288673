import React from 'react';
import { Menu, MenuItem } from "@material-ui/core";
import NestedMenuItem from "material-ui-nested-menu-item";


function ContextMenu(props) {

    function getMenuItems(items) {
        return items.map((item, i) => {
            if (!!item.children)
                return (
                    <NestedMenuItem
                        key={i}
                        label={item.label}
                        parentMenuOpen={props.isOpen}>
                        {getMenuItems(item.children)}
                    </NestedMenuItem>
                )
            else
                return (
                    <MenuItem
                        key={i}
                        onClick={() => {
                            item.onClick();
                            props.onClose();
                        }}>
                        {item.label}
                    </MenuItem>)
        });
    }

    const items = getMenuItems(props.items);

    return (
        <Menu
            open={props.isOpen}
            onClose={props.onClose}
            anchorReference="anchorPosition"
            anchorPosition={{
                top: props.top,
                left: props.left
            }}
        >
            {items}
        </Menu>
    )
}

export default ContextMenu;