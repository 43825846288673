import React from 'react';
import { useSelector } from 'react-redux';

import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../ExaminationTable.style';

export default function ExaminationPlaceholder(props) {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const { fetchingExamList } = useSelector((state) => state.examinations);
  return (
    <Table>
      <TableBody>
        <TableRow>
          {props.currentlyProcessed || fetchingExamList ? (
            <TableCell
              colSpan={6}
              style={{
                border: 'none',
                height: '50vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress color='primary' />
            </TableCell>
          ) : (
            <TableCell colSpan={6} style={{ border: 'none' }}>
              <Typography className={classes.table_placeholder}>
                {t('DatePicker.resetFilter')}
              </Typography>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                }}
              >
                <Typography className={classes.table_drag_placeholder}>
                  {t('DatePicker.drag_drop')}
                </Typography>
                <CloudUploadIcon fontSize='inherit' className={classes.drag_placeholder_icon} />
              </div>
            </TableCell>
          )}
        </TableRow>
      </TableBody>
    </Table>
  );
}
