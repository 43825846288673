import React, { useState } from 'react';
import * as actions from '../../../../../../store/index';
import { fetchPut } from '../../../../../../helpers/fetching';
import { setSnackbar } from '../../../../../../store/index';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Typography,
  TextField,
  IconButton,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../CustomFilter.style';
import CloseIcon from '@material-ui/icons/Close';

export default function SaveFilterDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const filter = useSelector((state) => state.filter);
  const [filterName, setFilterName] = useState('');

  const transformExamList = (patientList) => {
    const studyList = patientList
      .map((patient) => patient.studyId)
      .filter((studyId) => studyId != null);
    return { study_list: studyList };
  };

  const handleChange = (event) => {
    setFilterName(event.target.value);
  };

  const applyCustomFilter = () => {
    const studyListFilter = transformExamList(props.patientList);
    const nothingFound = !studyListFilter.study_list || studyListFilter?.study_list?.length <= 0;

    dispatch(actions.setFilter(studyListFilter));
    dispatch(actions.setActiveDialog(null));

    dispatch(
      setSnackbar({
        msg: nothingFound ? t('Filters.no_studies_found') : t('Filters.filter_applied'),
        severity: nothingFound ? 'warning' : 'success',
      })
    );

    props.onClose();
  };

  const saveCustomFilter = async () => {
    const response = await fetchPut('patient_filter', {
      name: filterName,
      settings: props.patientList,
    });
    if (response.success) {
      dispatch(
        actions.setFilter({
          study_list: props.patientList
            .filter((study) => study.found)
            .map((study) => study.studyId),
          study_name: filterName,
        })
      );
      const examsExist = props.patientList.filter((study) => study.found);
      dispatch(
        setSnackbar({
          msg:
            examsExist && examsExist?.length > 0
              ? t('Filters.filter_saved')
              : t('Filters.save_no_studies_found'),
          severity: examsExist && examsExist?.length > 0 ? 'success' : 'warning',
        })
      );

      props.onClose();
      dispatch(actions.setActiveDialog(null));
    } else {
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
    }
  };

  const cancelAction = () => {
    props.onClose();
  };
  const discardList = () => {
    props.setPatientList([]);
    props.setValue(props.pendingTab);
    props.onClose();
  };

  return (
    <Dialog
      onClose={props.onClose}
      open={props.open}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle
        id='draggable-dialog-title'
        onClose={props.onClose}
        className={classes.save_title_container}
      >
        <div className={classes.draggable_title_container}>
          <Typography className={classes.save_title}>{t('Filters.save_filter_title')}</Typography>

          <IconButton aria-label='close' className={classes.close_button} onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent className={classes.save_filter_container}>
        <Typography className={classes.save_filter_text}>
          {props.dialogText ? props.dialogText : t('Filters.name_filter')}
        </Typography>
        {!props.changingTabs && (
          <TextField
            id='filter-name'
            label={t('Filters.filter_name')}
            type='search'
            size='small'
            onChange={handleChange}
            value={filterName}
            autoComplete='off'
          />
        )}
      </DialogContent>
      <DialogActions>
        {props.changingTabs ? (
          <>
            <Button
              className={classes.action_button}
              onClick={() => cancelAction()}
              color='primary'
              autoFocus
            >
              {t('Filters.go_back')}
            </Button>
            <Button
              className={classes.action_button}
              onClick={() => discardList()}
              color='primary'
              autoFocus
            >
              {t('Filters.discard')}
            </Button>
          </>
        ) : (
          <>
            <Button
              className={classes.action_button}
              onClick={() => applyCustomFilter()}
              color='primary'
            >
              {t('Filters.apply_only')}
            </Button>
            <Button
              className={classes.action_button}
              onClick={() => saveCustomFilter()}
              color='primary'
              autoFocus
              disabled={!filterName}
            >
              {t('Filters.save_apply')}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
