import { Tooltip, Typography, IconButton, Divider } from '@material-ui/core';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import DoneAllIcon from '@material-ui/icons/DoneAll';

export const summaryModuleStatus = (
  isConfirmed,
  isRelabeled,
  handleConfirmClick,
  handleTitleClick,
  handleEditButtonClick,
  theme,
  classes,
  t
) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {handleTitleClick && (
        <Tooltip
          arrow
          placement='left-end'
          title={
            <Typography className={classes.tooltip_text} color='inherit'>
              {t('ResultView.tooltip_revise')}
            </Typography>
          }
        >
          <IconButton
            size='small'
            onClick={() => handleEditButtonClick()}
            className={classes.summary_edit_fabs}
          >
            <EditIcon
              fontSize='medium'
              style={{
                width: '18px',
                height: '18px',
                color: 'white',
              }}
            />
          </IconButton>
        </Tooltip>
      )}
      {handleConfirmClick && (
        <>
          {!isConfirmed && !isRelabeled && (
            <Tooltip
              arrow
              placement='bottom-end'
              title={
                <Typography className={classes.tooltipText} color='inherit'>
                  {t('ResultView.tooltip_confirm')}
                </Typography>
              }
            >
              <CheckIcon
                className={classes.summary_edit_fabs}
                fontSize='small'
                onClick={() => {
                  handleConfirmClick();
                }}
                style={{ cursor: 'pointer', marginLeft: '6px' }}
              />
            </Tooltip>
          )}
          {isConfirmed && (
            <Tooltip
              arrow
              placement='bottom-end'
              title={
                <>
                  <Typography className={classes.tooltipText} color='inherit'>
                    {t('ResultView.confirmedBy', { user: isConfirmed })}
                  </Typography>
                  <Divider />
                  <Typography>{t('ResultView.label_discard_icon')}</Typography>
                </>
              }
            >
              <DoneAllIcon
                fontSize='small'
                onClick={() => {
                  handleConfirmClick();
                }}
                className={classes.summary_edit_fabs}
                style={{ cursor: 'pointer', marginLeft: '6px', color: '#60AA4D' }}
              />
            </Tooltip>
          )}
          {isRelabeled && (
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Tooltip
                  arrow
                  placement='bottom-end'
                  title={
                    <>
                      <Typography className={classes.tooltipText} color='inherit'>
                        {t('ResultView.appliedRevision')}
                      </Typography>
                      <Divider />
                      <Typography>{t('ResultView.label_discard_icon')}</Typography>
                    </>
                  }
                >
                  <SpellcheckIcon
                    fontSize='small'
                    onClick={() => {
                      handleConfirmClick();
                    }}
                    className={classes.summary_edit_fabs}
                    style={{ cursor: 'pointer', marginLeft: '6px', color: '#60AA4D' }}
                  />
                </Tooltip>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export const getCurrentModuleStatus = (
  appliedResult,
  shownResult,
  isConfirmed,
  isRelabeled,
  moduleToConfirm,
  theme,
  t
) => {
  if (isRelabeled) {
    return {
      buttonText: 'Revised',
      currentApplied: `${t('ResultView.appliedRevision')}: ${appliedResult}`,
      tooltipAction:
        appliedResult === shownResult
          ? t('ResultView.label_discard_icon')
          : t('ResultView.confirm_current'),
      fabIcon:
        appliedResult === shownResult ? (
          <SpellcheckIcon fontSize='medium' style={{ width: '18px', height: '18px' }} />
        ) : (
          <CheckIcon
            fontSize='medium'
            style={{
              width: '18px',
              height: '18px',
              fill: 'white',
            }}
          />
        ),
      color: appliedResult === shownResult ? theme.palette.success.main : '#6d6d6d',
    };
  } else if (isConfirmed)
    return {
      buttonText: 'Confirmed',
      currentApplied: `${t('ResultView.label_confirmed')}: ${appliedResult}`,
      tooltipAction:
        appliedResult === shownResult
          ? t('ResultView.label_discard_icon')
          : t('ResultView.confirm_current'),
      fabIcon:
        appliedResult === shownResult ? (
          <DoneAllIcon fontSize='medium' style={{ width: '18px', height: '18px' }} />
        ) : (
          <CheckIcon
            fontSize='medium'
            style={{
              width: '18px',
              height: '18px',
              fill: 'white',
            }}
          />
        ),
      color: appliedResult === shownResult ? theme.palette.success.main : '#6d6d6d',
    };
  else
    return {
      buttonText: 'Confirm',
      currentApplied:
        shownResult === 'bbox'
          ? t('ResultView.org_pred')
          : `${t('ResultView.relabeled')}: ${shownResult}`,
      tooltipAction: t('ResultView.btn_confirm', { module: moduleToConfirm }),
      fabIcon: (
        <CheckIcon
          fontSize='medium'
          style={{
            width: '18px',
            height: '18px',
            fill: 'white',
          }}
        />
      ),
      color: '#6d6d6d',
    };
};
