import { Divider, Fab, Tooltip, Typography, Zoom } from '@material-ui/core';
import arrowCollapseVertical from '@iconify-icons/mdi/arrow-collapse-vertical';
import patch20Regular from '@iconify/icons-fluent/patch-20-regular';
import patch20Filled from '@iconify/icons-fluent/patch-20-filled';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import { Icon } from '@iconify/react';
import {
COMPRESSION_HIGH,
COMPRESSION_LOW,
COMPRESSION_TOLERANCE
} from '../../../../../../config';

const compressionColor = (compClass, theme) => {
  if (compClass === 'correct') return theme.palette.projections.compOptimal;
  if (compClass === 'moderate') return theme.palette.projections.compHigh;
  if (compClass === 'insufficient') return theme.palette.projections.compLow;
  return theme.palette.projections.not_evaluated;
};

const getCompressionChipData = (
  currentCompression,
  theme, t
) => {
  const lowToleranceDown = COMPRESSION_LOW - COMPRESSION_TOLERANCE;
  const higherToleranceUp = COMPRESSION_HIGH + COMPRESSION_TOLERANCE;
  if (!currentCompression) {
    return (
      <Typography
        style={{
          letterSpacing: '0.1rem',
          textTransform: 'uppercase',
          fontSize: theme.fonts.responsiveMedium,
          fontWeight: 'bold',
        }}
      >
        {t('projections.no_compression')}
      </Typography>
    );
  }

  if (currentCompression >= COMPRESSION_LOW && currentCompression <= COMPRESSION_HIGH)
    return (
      <>
        <Typography
          style={{
            letterSpacing: '0.1rem',
            textTransform: 'uppercase',
            fontSize: theme.fonts.responsiveMedium,
            fontWeight: 'bold',
          }}
        >
          {`${t('ResultView.cmp')}: ${currentCompression?.toFixed()} N`}
        </Typography>
        <Divider style={{ margin: '3% 0' }} />
        <Typography
          style={{
            letterSpacing: '0.1rem',
            fontSize: theme.fonts.responsiveMedium,
          }}
        >
          {t('ResultView.comp_ok', {
            compression_low: `${COMPRESSION_LOW}`,
            compression_high: `${COMPRESSION_HIGH}`,
          })}
        </Typography>
      </>
    );

  if (currentCompression >= lowToleranceDown && currentCompression <= higherToleranceUp) {
    if (currentCompression < COMPRESSION_LOW)
      return (
        <>
          <Typography
            style={{
              letterSpacing: '0.1rem',
              textTransform: 'uppercase',
              fontSize: theme.fonts.responsiveMedium,
              fontWeight: 'bold',
            }}
          >
            {`${t('ResultView.cmp')}: ${currentCompression?.toFixed()} N`}
          </Typography>
          <Divider style={{ margin: '3% 0' }} />
          <Typography
            style={{
              letterSpacing: '0.1rem',
              fontSize: theme.fonts.responsiveMedium,
            }}
          >
            {t('ResultView.comp_low', {
              compression: `${COMPRESSION_LOW} ${t(
                'ResultView.limits_tolerance'
              )} ${COMPRESSION_TOLERANCE}`,
            })}
          </Typography>
        </>
      );
    else if (currentCompression > COMPRESSION_HIGH)
      return (
        <>
          <Typography
            style={{
              letterSpacing: '0.1rem',
              textTransform: 'uppercase',
              fontSize: theme.fonts.responsiveMedium,
              fontWeight: 'bold',
            }}
          >
            {`${t('ResultView.cmp')}: ${currentCompression?.toFixed()} N`}
          </Typography>
          <Divider style={{ margin: '3% 0' }} />
          <Typography
            style={{
              letterSpacing: '0.1rem',
              fontSize: theme.fonts.responsiveMedium,
            }}
          >
            {t('ResultView.comp_high', {
              compression: `${COMPRESSION_HIGH}N ${t(
                'ResultView.limits_tolerance'
              )} ${COMPRESSION_TOLERANCE}`,
            })}
          </Typography>
        </>
      );
  }

  if (currentCompression < lowToleranceDown)
    return (
      <>
        <Typography
          style={{
            letterSpacing: '0.1rem',
            textTransform: 'uppercase',
            fontSize: theme.fonts.responsiveMedium,
            fontWeight: 'bold',
          }}
        >
          {`${t('ResultView.cmp')}: ${currentCompression?.toFixed()} N`}
        </Typography>
        <Divider style={{ margin: '3% 0' }} />
        <Typography
          style={{
            letterSpacing: '0.1rem',
            fontSize: theme.fonts.responsiveMedium,
          }}
        >
          {t('ResultView.comp_low', {
            compression: `${COMPRESSION_LOW} and below the tolerance range of ${COMPRESSION_TOLERANCE}`,
          })}
        </Typography>
      </>
    );

  if (currentCompression > higherToleranceUp)
    return (
      <>
        <Typography
          style={{
            letterSpacing: '0.1rem',
            textTransform: 'uppercase',
            fontSize: theme.fonts.responsiveMedium,
            fontWeight: 'bold',
          }}
        >
          {`${t('ResultView.cmp')}: ${currentCompression?.toFixed()} N`}
        </Typography>
        <Divider style={{ margin: '3% 0' }} />

        <Typography
          style={{
            letterSpacing: '0.1rem',
            fontSize: theme.fonts.responsiveMedium,
          }}
        >
          {t('ResultView.comp_high', {
            compression: `${COMPRESSION_HIGH}N ${t(
              'ResultView.above_tolerance'
            )} ${COMPRESSION_TOLERANCE}`,
          })}
        </Typography>
      </>
    );

  if (currentCompression > COMPRESSION_HIGH && currentCompression <= higherToleranceUp)
    return (
      <>
        <Typography
          style={{
            letterSpacing: '0.1rem',
            textTransform: 'uppercase',
            fontSize: theme.fonts.responsiveMedium,
            fontWeight: 'bold',
          }}
        >
          {`${t('ResultView.cmp')}: ${currentCompression?.toFixed()} N`}
        </Typography>
        <Divider style={{ margin: '3% 0' }} />

        <Typography
          style={{
            letterSpacing: '0.1rem',
            fontSize: theme.fonts.responsiveMedium,
          }}
        >
          {t('ResultView.comp_high', {
            compression: `${COMPRESSION_HIGH}N ${t(
              'ResultView.limits_tolerance'
            )} ${COMPRESSION_TOLERANCE}`,
          })}
        </Typography>
      </>
    );
};

/* Compression */
export const compressionChip = (
  compression,
  compressionClass,
  left,
  classes,
  theme, t
) => {
  return (
    <Tooltip
      arrow
      title={
        getCompressionChipData(
          compression,
          theme, t
        ) ?? (
          <Typography
            style={{
              letterSpacing: '0.1rem',
              textTransform: 'uppercase',
              fontSize: theme.fonts.responsiveMedium,
              fontWeight: 'bold',
            }}
          >
            {t('projections.no_compression')}
          </Typography>
        )
      }
      placement='right-start'
    >
      <Fab
        size='small'
        style={{
          background: 'transparent',
          border: `1px solid ${compressionColor(compressionClass, theme)}`,
        }}
        className={left ? classes.compression_left : classes.compression_right}
      >
        <Icon icon={arrowCollapseVertical} color={compressionColor(compressionClass, theme)} />
      </Fab>
    </Tooltip>
  );
};

/* POSTOP Clips */
export const postOpChip = (
  postopClips,
  showClips,
  qualityOverlaysToShow,
  proj_name,
  left,
  classes,
  theme,
  t
) => {
  return (
    <Tooltip
      TransitionComponent={Zoom}
      title={
        <Typography
          style={{
            letterSpacing: '0.1rem',
            textTransform: 'uppercase',
            fontSize: theme.fonts.responsiveMedium,
            fontWeight: 'bold',
          }}
          color='inherit'
        >
          {postopClips ? t('ResultView.postop_clips') : t('ResultView.no_clips')}
        </Typography>
      }
      arrow
      interactive
      placement='right-end'
    >
      <Fab
        size='small'
        onMouseEnter={() => {
          postopClips && showClips(proj_name);
        }}
        onMouseLeave={() => {
          postopClips && showClips(proj_name);
        }}
        style={{
          background: 'transparent',
          border: `1px solid ${postopClips ? theme.palette.lesions.clips : '#3e3e3e'}`,
        }}
        className={left ? classes.clipsPatchIconLeft : classes.clipsPatchIconRight}
      >
        <Icon
          icon={
            postopClips
              ? qualityOverlaysToShow['clips' + proj_name.toUpperCase()]
                ? patch20Filled
                : patch20Regular
              : patch20Regular
          }
          style={{
            width: 30,
            height: 30,
            color: postopClips ? 'inherit' : '#3e3e3e',
          }}
        />
      </Fab>
    </Tooltip>
  );
};

/* POSTOP Reduction Scars */
export const reductionScarsChip = (evaluation, left, classes, postopHandler, theme, t) => {
  return (
    <Tooltip
      title={
        <>
          <Typography
            style={{
              letterSpacing: '0.1rem',
              textTransform: 'uppercase',
              fontSize: theme.fonts.responsiveMedium,
              fontWeight: 'bold',
            }}
          >
            {evaluation?.PostSurgery?.includes('reductionScars')
              ? t('ResultView.reductionScars')
              : t('ResultView.no_reductionScars')}
          </Typography>
          <Divider style={{ margin: '3%' }} />
          <Typography
            style={{
              letterSpacing: '0.1rem',
              fontSize: theme.fonts.responsiveMedium,
              display: 'flex',
              alignItems: 'center',
            }}
            color='inherit'
          >
            <TouchAppIcon fontSize='small' />
            {evaluation?.PostSurgery?.includes('reductionScars')
              ? t('ResultView.remove_operated', {
                  postop: t('projections.reductionScars'),
                })
              : t('ResultView.set_operated', {
                  postop: t('projections.reductionScars'),
                })}
          </Typography>
        </>
      }
      arrow
      placement='right-end'
      TransitionComponent={Zoom}
    >
      <Fab
        size='small'
        style={{
          background: 'transparent',
          border: `1px solid ${
            evaluation?.PostSurgery?.includes('reductionScars') ? '#a48023' : '#3e3e3e'
          }`,
        }}
        className={left ? classes.postOpLeft : classes.postOpRight}
        onClick={() => postopHandler(evaluation?.PostSurgery)}
      >
        {evaluation?.PostSurgery?.includes('reductionScars') ? (
          <LocalHospitalIcon
            fontSize='small'
            style={{
              width: '20px',
              height: '20px',
              cursor: 'pointer',
              color: '#a48023',
            }}
          />
        ) : (
          <LocalHospitalIcon
            fontSize='small'
            style={{
              width: '20px',
              height: '20px',
              cursor: 'pointer',
              color: '#3e3e3e',
            }}
          />
        )}
      </Fab>
    </Tooltip>
  );
};
