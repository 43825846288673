import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import {
  Badge,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Search, Clear } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import TuneIcon from '@material-ui/icons/Tune';
import { useTranslation } from 'react-i18next';
import * as actions from '../../../../store/index';

const useStyles = makeStyles((theme) => ({
  SearchBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },

  textField: {
    margin: theme.spacing(1),
    width: '100%',
    border: 'none !important',
    '&.MuiInput-underline': {
      borderBottom: 'none !important',
    },
  },

  iconWrapper: {
    backgroundColor: theme.palette.complementary.mainLighter,
    borderRadius: '15%',
    display: 'flex',
    '&:hover': {
      backgroundColor: theme.palette.complementary.mainDark,
      cursor: 'pointer',
    },
  },
}));

function SearchBar(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  // Store state
  const filterPrompt = useSelector(state => state.filter.search);

  // local state
  const [localPrompt, setLocalPrompt] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(null);

  // Effects
  useEffect(() => {
    if (typingTimeout) 
      clearTimeout(typingTimeout);

    const timeout = setTimeout(() => {
      dispatch(actions.setFilterProperty(['search', localPrompt || null]));
    }, 800);

    setTypingTimeout(timeout);
  }, [localPrompt])

  useEffect(() => {
    if (filterPrompt !== localPrompt) {
      setLocalPrompt(filterPrompt || '');
    }
  }, [filterPrompt]);

  return (
    <div className={[classes.SearchBar, props.className].join(' ')}>
      <TextField
        size="small"
        id="search-examinations"
        placeholder={t('DatePicker.search')}
        className={classes.textField}
        value={localPrompt}
        onChange={event => setLocalPrompt(event.target.value)}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <>
              <InputAdornment
                style={{ cursor: 'pointer' }}
                position="end"
                onClick={() => setLocalPrompt('')}
              >
                {localPrompt && <Clear />}
              </InputAdornment>
              <InputAdornment
                style={{ cursor: 'pointer' }}
                position="end"
                onClick={() => dispatch(actions.setActiveDialog('filter'))}
              >
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    <Typography
                      color="inherit"
                      className={classes.tooltip_text}
                    >
                      {t('Menu.filter')}
                    </Typography>
                  }
                  aria-label="add"
                >
                  <Badge
                    color="primary"
                    overlap="rectangular"
                    variant="dot"
                    invisible={!props.activeFilter}
                    data-test-id="patient-list-filter"
                  >
                    <div className={classes.iconWrapper}>
                      <TuneIcon htmlColor="#54c4c4" />
                    </div>
                  </Badge>
                </Tooltip>
              </InputAdornment>
            </>
          ),
        }}
      />
    </div>
  );
}

export default SearchBar;
