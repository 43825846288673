import { useSelector } from 'react-redux';
import {
  red,
  pink,
  grey,
  purple,
  cyan,
  orange,
  blueGrey,
} from '@material-ui/core/colors';

export function getColorPalette() {
  let mainPrimaryColor;
  let mainPrimaryColorDark;
  let mainSecondaryColor;
  let mainSecondaryColorDark;
  let mainBackgroundColor;
  let mainPaperColor;
  let accentColor;

  const darkState = useSelector((store) => store.gui.darkState);

  if (window.location.pathname.includes('training')) {
    mainPrimaryColor = darkState ? cyan[500] : pink[600];
    mainPrimaryColorDark = darkState ? cyan.A700 : pink.A700;
    mainSecondaryColor = darkState ? cyan[100] : pink[300];
    mainSecondaryColorDark = darkState ? cyan[200] : pink[400];
    mainBackgroundColor = darkState ? '#303030' : grey[300];
    mainPaperColor = darkState ? grey[800] : grey[200];
    accentColor = darkState ? '#2979ff' : purple[600];
  } else {
    mainPrimaryColor = darkState ? red[500] : '#E54C2E';
    mainPrimaryColorDark = darkState ? red.A700 : '#832624';
    mainSecondaryColor = darkState ? red[100] : '#EE847A';
    mainSecondaryColorDark = darkState ? red[200] : '#A53836';
    mainBackgroundColor = darkState ? '#303030' : grey[300];
    mainPaperColor = darkState ? grey[800] : grey[200];
    accentColor = darkState ? '#ffe8e8' : '#ffe8e8';
  }

  return [
    mainPrimaryColor,
    mainPrimaryColorDark,
    mainSecondaryColor,
    mainSecondaryColorDark,
    mainBackgroundColor,
    mainPaperColor,
    accentColor,
  ];
}
