import React from 'react';
import { Divider, Grid, Typography, useTheme } from '@material-ui/core';
import cardAccountDetailsOutline from '@iconify-icons/mdi/card-account-details-outline';
import { useTranslation } from 'react-i18next';
import PatientDOB from './PatientDOB';
import PatientID from './PatientID';
import PatientPurpose from './PatientPurpose';
import PatientDevice from './PatientDevice';
import PatientModality from './PatientIModality';
import PatientOperator from './PatientOperator';
import PatientReferring from './PatientReferring';
import PatientAge from './PatientAge';
import PatientExamDate from './PatientExamDate';

export default function PatientSummaryTooltip(props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const { pat_name, birth, device, operator, referring, screening, patient_id, date } =
    props.results;

  const today = new Date();
  const birthDate = new Date(birth);
  const getAge = () => {
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  return (
    <div>
      <Typography
        style={{
          letterSpacing: '0.1rem',
          color: theme.palette.texts.primary,
          fontSize: theme.fonts.responsiveBig,
          fontWeight: 'bold',
        }}
      >
        {pat_name}
      </Typography>

      <Divider style={{ margin: '3% 0' }} />

      <div
        style={{
          marginBottom: '6px',
          padding: '6px',
          borderRadius: '5px',
        }}
      >
        <PatientDOB
          result={birth.slice(0, 10)}
          style={{
            fontSize: theme.fonts.responsiveMedium,
            margin: '3px 0',
          }}
        />

        <PatientAge
          title={t('ResultView.age')}
          result={getAge()}
          style={{
            fontSize: theme.fonts.responsiveMedium,
            margin: '3px 0',
          }}
        />

        <PatientExamDate
          title={t('ResultView.date')}
          result={date}
          style={{
            fontSize: theme.fonts.responsiveMedium,
            margin: '3px 0',
          }}
        />

        <PatientID
          result={patient_id}
          style={{ fontSize: theme.fonts.responsiveMedium, margin: '3px 0' }}
        />

        <PatientPurpose
          result={screening ? 'Screening' : t('ResultView.diagnostic')}
          style={{ fontSize: theme.fonts.responsiveMedium, margin: '3px 0' }}
        />

        <PatientDevice
          result={device}
          style={{ fontSize: theme.fonts.responsiveMedium, margin: '3px 0' }}
        />

        <PatientOperator
          result={operator === 'Unknown Operator' ? 'unknown' : operator}
          style={{ fontSize: theme.fonts.responsiveMedium, margin: '3px 0' }}
        />

        <PatientReferring
          result={referring}
          style={{ fontSize: theme.fonts.responsiveMedium, margin: '3px 0' }}
        />

        <PatientModality
          result={referring}
          style={{ fontSize: theme.fonts.responsiveMedium, margin: '3px 0' }}
        />
      </div>
    </div>
  );
}
