import { Typography } from '@material-ui/core';
import DraggableDialog from '../DraggableDialog/DraggableDialog';
import { useStyles } from './Premium.style';
import Logo from '../../../../resources/coorporate_images/brayz_logo_small.png';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const Premium = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const getPremiumText = () => {
    if (props.section === 'export') return t('export.text_premium');
    if (props.section === 'upload') return t('Upload.dropzone_text_premium');
  };

  return (
    <DraggableDialog
      maxWidth="md"
      title={t('export.exportTitle')}
      open={props.open}
      actions={[
        {
          onClick: props.onClose,
          color: 'primary',
          label: t('export.cancel'),
        },
      ]}
      onClose={props.onClose}
    >
      <div className={classes.premium_container}>
        <img src={Logo} className={classes.logo} alt="b-rayZ logo"></img>
        <Typography className={classes.premium}>
          {t('Layout.premium')}
        </Typography>
        <Typography>{getPremiumText()}</Typography>
        <Typography>{t('Layout.premium_contact')}</Typography>
        <a className={classes.premium_cta} href="mailto:contact@b-rayz.ch">
          contact@b-rayz.ch
        </a>
      </div>
    </DraggableDialog>
  );
};

export default Premium;
