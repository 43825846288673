import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  buttonBase: {
    position: 'absolute',
    width: '18%',
    height: '15%',
    background: 'transparent',
    borderStyle: 'solid',
    borderRadius: '5px',
    zIndex: '10',
    outline: 'none',
    transition: 'all 0.2s ease-in-out',
  },

  parenLateralCCBase: {
    borderStyle: 'solid',
    position: 'absolute',
    background: 'transparent',
    zIndex: '10',
    height: '29%',
    width: '44%',
    borderRadius: '100% / 100%',
    left: '32%',
    top: '20%',
    borderBottomLeftRadius: '0',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
    borderBottomStyle: 'none  !important',
  },

  parenCCMedialBase: {
    borderStyle: 'solid',
    position: 'absolute',
    background: 'transparent',
    zIndex: '10',
    height: '29%',
    width: '44%',
    borderRadius: '100% / 100%',
    left: '32%',
    bottom: '20%',
    borderBottomRightRadius: '0',
    borderTopRightRadius: '0',
    borderTopLeftRadius: '0',
    borderTopStyle: 'none  !important',
  },

  parenMLOBase: {
    position: 'absolute',
    background: 'transparent',
    zIndex: '10',
    height: '50%',
    width: '42%',
    borderRadius: '100% / 50%',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
    borderStyle: 'solid',
    left: '33%',
    bottom: '18%',
    transform: 'rotate(-25deg)',
  },

  pnlBase: {
    strokeDasharray: '8, 6',
  },

  inframBase: {
    right: '0%',
    bottom: ' 1%',
  },

  nippleCCBase: {
    position: 'absolute',
    width: '18%',
    height: '15%',
    background: 'transparent',
    borderStyle: 'solid',
    borderRadius: '5px',
    zIndex: '10',
    outline: 'none',
    left: '10%',
  },

  nippleMLOBase: {
    position: 'absolute',
    width: '18%',
    height: '15%',
    background: 'transparent',
    borderStyle: 'solid',
    borderRadius: '5px',
    zIndex: '10',
    outline: 'none',
    left: ' 7%',
    top: '58%',
  },

  pectMuscleBase: {
    position: 'absolute',
    background: 'transparent',
    zIndex: '10',
    height: ' 79.2%',
    width: '11.5%',
    borderRadius: '100% / 50%',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderStyle: 'solid',
    outline: 'none',
  },

  pectAngleBase: {
    width: '18%',
    height: '15%',
    right: '0%',
    top: ' 23%',
  },

  none: {
    borderColor: theme.palette.projections.not_evaluated,
    stroke: theme.palette.projections.not_evaluated,
  },

  correct: {
    borderColor: theme.palette.projections.good,
    stroke: theme.palette.projections.good,
    '&:hover': {
      boxShadow: '0 2px 15px rgba(76, 175, 80, 0.6)',
    },
  },

  moderate: {
    borderColor: theme.palette.projections.moderate,
    stroke: theme.palette.projections.moderate,
    '&:hover': {
      boxShadow: '0 2px 15px orange',
    },
  },

  insufficient: {
    borderColor: theme.palette.projections.insufficient,
    stroke: theme.palette.projections.insufficient,
    '&:hover': {
      boxShadow: '0 2px 15px rgba(244, 67, 54, 0.6)',
    },
  },

  neutral: {
    strokeDasharray: '8, 6',
    borderColor: '#b9b9b9',
    stroke: '#b9b9b9',
  },

  goodNippleIconHover: {
    '&:hover': {
      fill: theme.palette.projections.perfect,
    },
  },

  tiltedNippleIconHover: {
    '&:hover': {
      fill: '#b60d00',
    },
  },

  tiltedModerateNippleIconHover: {
    '&:hover': {
      fill: '#e0ad62',
    },
  },

  PectLevelBase: {
    strokeDasharray: '8, 6',
  },

  pnlSvgCC: {
    position: 'absolute',
    width: ' 68%',
    height: '10%',
    zIndex: 200,
    left: '20%',
    strokeWidth: '2%',
  },

  pnlSvgMLO: {
    position: 'absolute',
    width: '76%',
    height: '15%',
    zIndex: 200,
    left: '14%',
    top: '49%',
    strokeWidth: '2%',
    transform: 'rotate(-19deg)',
  },
}));
