import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useAppliedEvaluation } from '../../../../../helpers/hooks/useEvaluation';

const useStyles = makeStyles((theme) => ({
  parenchyma_overlay: {
    zIndex: 3,
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  canvas_overlay: {
    position: 'absolute',
    maxWidth: '100%',

    borderRadius: '5px',
    userSelect: 'none',
  },
  boundingLine: {
    border: '1px solid',
    background: 'transparent',
    position: 'absolute',
    zIndex: 400,
  },
}));

export default function ParenchymaCuts(props) {
  const classes = useStyles();
  const theme = useTheme();

  // Selectors
  const { applied_quality, shown_quality } = useSelector((state) => state.results);
  const { qualityInRevisionMode } = useSelector((state) => state.labeling);
  const { landscape, tabsIndex } = useSelector((state) => state.gui);

  const shownQuality = shown_quality || applied_quality;

  const evaluation = useAppliedEvaluation('quality', applied_quality, qualityInRevisionMode);

  const getCuts = (cutType) => {
    const evalCuts = evaluation?.[props.proj_name];
    return evalCuts?.[cutType];
  };

  const cutsListMedial = getCuts('CutsListMedial');
  const cutsListLateral = getCuts('CutsListLateral');
  const cutsListMlo = getCuts('CutsList');
  const medialCutsClass = getCuts('ParenchymaCutsMedial');
  const lateralCutsClass = getCuts('ParenchymaCutsLateral');
  const mloCutsClass = getCuts('ParenchymaCuts');

  const cutsColor = (cutsSeverity) => {
    if (cutsSeverity === 'insufficient') return theme.palette.lesions.cutsInsufficient;
    if (cutsSeverity === 'moderate') return theme.palette.lesions.cutsModerate;
    return theme.palette.lesions.cutsNoEval;
  };

  const drawCuts = (parenchymaCuts, borderStyle, color) => {
    return (
      parenchymaCuts?.length > 0 && (
        <div>
          {parenchymaCuts.map((parenchymaLine, i) => (
            <div
              key={`${parenchymaCuts}${i}`}
              className={classes.boundingLine}
              style={{
                border: borderStyle,
                left: props.proj_name.includes('r')
                  ? `${100 * (parenchymaLine[2] - 0.04)}%`
                  : `${100 * parenchymaLine[2]}%`,
                top: `${100 * parenchymaLine[0]}%`,
                width: props.proj_name.includes('r')
                  ? `${100 - 100 * (parenchymaLine[2] - 0.04)}%`
                  : `${100 * (parenchymaCuts[0][2] + 0.04)}%`,
                height: `${100 * (parenchymaLine[1] - parenchymaLine[0])}%`,
              }}
            />
          ))}
        </div>
      )
    );
  };

  return (
    <div className={classes.parenchyma_overlay}>
      <canvas
        key='parenchyma_cuts'
        className={classes.canvas_overlay}
        width={props.imgSize[0]}
        height={props.imgSize[1]}
        style={{
          opacity: 0,
          height: landscape ? (tabsIndex === 2 ? '46vh' : '43vh') : 'calc(25vh - 100px)',
        }}
      />
      {props.showMedial &&
        drawCuts(
          cutsListMedial,
          `2px dashed ${cutsColor(medialCutsClass)}`,
          cutsColor(medialCutsClass)
        )}

      {props.showLateral &&
        drawCuts(
          cutsListLateral,
          `2px dashed ${cutsColor(lateralCutsClass)}`,
          cutsColor(lateralCutsClass)
        )}

      {props.showMLO &&
        drawCuts(cutsListMlo, `2px dashed ${cutsColor(mloCutsClass)}`, cutsColor(mloCutsClass))}
    </div>
  );
}
