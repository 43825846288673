import React from 'react';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_material from '@amcharts/amcharts4/themes/material';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { connect } from 'react-redux';

import PurposeLight from '../../../../resources/ChartIcons/PurposeLight.svg';
import PurposeDark from '../../../../resources/ChartIcons/PurposeDark.svg';
import SurgeryLight from '../../../../resources/ChartIcons/SurgeryLight.svg';
import SurgeryDark from '../../../../resources/ChartIcons/SurgeryDark.svg';
import ModalityLight from '../../../../resources/ChartIcons/ModalityLight.svg';
import ModalityDark from '../../../../resources/ChartIcons/ModalityDark.svg';

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_material);

class BarChartTwo extends React.Component {
  componentDidMount() {
    this.initChart();
  }

  initChart() {
    const chart = am4core.create(this.props.chartdiv, am4charts.XYChart);
    chart.data = this.props.data;

    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    chart.padding(0, 50, 0, 30);

    chart.zoomOutButton.disabled = true;

    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    categoryAxis.fontSize = this.props.theme.fonts.responsiveMedium;
    categoryAxis.renderer.grid.template.disabled = true;

    categoryAxis.renderer.labels.template.disabled = true;
    // categoryAxis.renderer.inside = true;
    categoryAxis.renderer.labels.template.rotation = 90;
    categoryAxis.renderer.labels.template.horizontalCenter = 'middle';
    categoryAxis.renderer.labels.template.fill = am4core.color(
      this.props.theme.palette.texts.secondary
    );

    let image = new am4core.Image();
    image.horizontalCenter = 'middle';
    image.dx = 0;
    image.width = 25;
    image.height = 25;
    image.fill = am4core.color(this.props.theme.palette.primary.main);
    image.verticalCenter = 'middle';

    if (this.props.darkState === true) {
      image.adapter.add('href', (href, target) => {
        let category = target.dataItem.category;
        if (category === 'PURP.') {
          return PurposeDark;
        }
        if (category === 'OP.') {
          return SurgeryDark;
        }
        if (category === 'MOD.') {
          return ModalityDark;
        }
        return href;
      });
    } else {
      image.adapter.add('href', (href, target) => {
        let category = target.dataItem.category;
        if (category === 'PURP.') {
          return PurposeLight;
        }
        if (category === 'OP.') {
          return SurgeryLight;
        }
        if (category === 'MOD.') {
          return ModalityLight;
        }
        return href;

        // if(category){
        //   return "https://www.amcharts.com/wp-content/uploads/flags/" + category.split(" ").join("-").toLowerCase() + ".svg";
        // }
      });
    }
    categoryAxis.dataItems.template.bullet = image;

    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;
    valueAxis.calculateTotals = true;
    valueAxis.renderer.maxWidth = 0;
    valueAxis.visible = false;
    valueAxis.renderer.grid.template.disabled = true;

    let series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.dx = 20;
    series1.dataFields.categoryY = 'category';
    series1.dataFields.valueX = 'value1';
    series1.dataFields.valueYShow = 'totalPercent';
    series1.stacked = true;
    series1.columns.template.tooltipText = '{label1} {value1}%';
    series1.columns.template.fill = am4core.color(this.props.theme.palette.projections.optionOne);
    series1.columns.template.strokeWidth = 1;
    series1.columns.template.stroke = am4core.color(this.props.theme.palette.background.paper);
    series1.columns.template.strokeOpacity = 1;
    series1.columns.template.column.cornerRadiusTopLeft = 3;
    series1.columns.template.column.cornerRadiusTopRight = 3;
    series1.columns.template.column.cornerRadiusBottomLeft = 3;
    series1.columns.template.column.cornerRadiusBottomRight = 3;

    // let fillModifier = new am4core.LinearGradientModifier();
    // fillModifier.brightnesses = [0, 1, 1, 0];
    // fillModifier.offsets = [0, 0.45, 0.55, 1];
    // fillModifier.gradient.rotation = 90;
    // series1.columns.template.fillModifier = fillModifier;

    let series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.dx = 20;
    series2.dataFields.categoryY = 'category';
    series2.dataFields.valueX = 'value2';
    series2.dataFields.valueXShow = 'totalPercent';
    series2.stacked = true;
    series2.columns.template.tooltipText = '{label2} {value2}%';
    series2.columns.template.fill = am4core.color(this.props.theme.palette.projections.optionTwo);
    series2.columns.template.strokeWidth = 1;
    series2.columns.template.stroke = am4core.color(this.props.theme.palette.background.paper);
    series2.columns.template.strokeOpacity = 1;
    series2.columns.template.column.cornerRadiusTopLeft = 3;
    series2.columns.template.column.cornerRadiusTopRight = 3;
    series2.columns.template.column.cornerRadiusBottomLeft = 3;
    series2.columns.template.column.cornerRadiusBottomRight = 3;

    // fillModifier = new am4core.LinearGradientModifier();
    // fillModifier.brightnesses = [0, 1, 1, 0];
    // fillModifier.offsets = [0, 0.45, 0.55, 1];
    // fillModifier.gradient.rotation = 90;
    // series2.columns.template.fillModifier = fillModifier;

    const hideSmall = (ev) => {
      if (
        ev.target.dataItem &&
        ev.target.dataItem.bullets &&
        ev.target.dataItem.values.valueX.value < 10
      ) {
        ev.target.dataItem.bullets.each((id, bullet) => bullet.hide());
      } else {
        // ev.target.dataItem.show();
      }
    };

    series1.columns.template.events.on('ready', hideSmall);
    series1.columns.template.events.on('visibilitychanged', hideSmall);
    series2.columns.template.events.on('ready', hideSmall);
    series2.columns.template.events.on('visibilitychanged', hideSmall);

    const label1 = series1.bullets.push(new am4charts.LabelBullet());
    label1.label.fill = am4core.color(this.props.theme.palette.texts.secondary);

    label1.label.text = '{label1}';
    label1.label.fontSize = this.props.theme.fonts.responsiveSmall;

    label1.label.horizontalCenter = 'right';
    label1.label.dx = 15;

    const label2 = series2.bullets.push(new am4charts.LabelBullet());
    label2.label.fill = am4core.color(this.props.theme.palette.texts.secondary);

    label2.label.text = '{label2}';
    label2.label.fontSize = this.props.theme.fonts.responsiveSmall;
    label2.label.horizontalCenter = 'left';
    label2.locationX = 1;
    label2.label.dx = 25;

    this.chart = chart;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.darkState !== this.props.darkState ||
      prevProps.theme.palette.primary.main !== this.props.theme.palette.primary.main
    ) {
      this.chart.dispose();
      this.chart = null;
      this.initChart();
    } else {
      this.chart.data = this.props.data;
      this.chart.validateData();
    }
  }

  componentWillUnmount() {
    if (this.chart) this.chart.dispose();
  }

  render() {
    return <div id={this.props.chartdiv} style={{ width: '100%', height: '100%' }}></div>;
  }
}

function mapStateToProps(state) {
  return { data: state.statistics.barExamsData };
}

export default connect(mapStateToProps)(BarChartTwo);
