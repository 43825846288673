import React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { connect } from 'react-redux';

import { format_lesion_data } from '../../../../helpers/formatChartsData';
import { useQualityTranslation } from '../../../../helpers/hooks/useQualityTranslation';

am4core.useTheme(am4themes_animated);

class PieChart extends React.Component {
  componentDidMount() {
    this.initChart();
  }
  initChart() {
    const chart = am4core.create(this.props.chartdiv, am4charts.PieChart);

    // Add data
    chart.data = this.props.data;
    chart.padding(0, 0, 5, 0);
    const filterChartHandler = this.props.setFilterChartHandler;

    // Set radius
    chart.radius = am4core.percent(75);
    chart.innerRadius = am4core.percent(60);

    // Add and configure Series
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'value';

    pieSeries.dataFields.category = this.props.examinationListDrawer
      ? 'category_short'
      : 'category';
    pieSeries.dataFields.id = 'category';
    pieSeries.dataFields.text = 'categoryFilter';
    pieSeries.slices.template.propertyFields.fill = 'color';
    pieSeries.slices.template.stroke = am4core.color(this.props.theme.palette.background.paper);
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.defaultState.transitionDuration = 400;
    pieSeries.hiddenState.transitionEasing = am4core.ease.elasticInOut;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.cursorOverStyle = am4core.MouseCursorStyle.pointer;

    // onClick to filter
    pieSeries.slices.template.events.on('hit', (ev) => {
      const filterKey = ev.target.dataItem.text;
      const chartKey = this.props.dataKey;
      filterChartHandler(filterKey, chartKey);
    });

    //Hide labels
    const hideSmall = (ev) => {
      if (ev.target.dataItem && ev.target.dataItem.values.value.percent < 1) {
        ev.target.hide();
      } else {
        ev.target.show();
      }
    };
    pieSeries.ticks.template.events.on('ready', hideSmall);
    pieSeries.ticks.template.events.on('visibilitychanged', hideSmall);
    pieSeries.labels.template.events.on('ready', hideSmall);
    pieSeries.labels.template.events.on('visibilitychanged', hideSmall);

    // Ticks and labels
    pieSeries.alignLabels = false;
    pieSeries.labels.template.propertyFields.fill = 'color';
    pieSeries.ticks.template.propertyFields.fill = 'color';
    pieSeries.ticks.template.propertyFields.stroke = 'color';

    pieSeries.labels.template.fontSize = this.props.examinationListDrawer ? '1.1vh' : '1.4vh';
    pieSeries.ticks.template.strokeWidth = 1;
    pieSeries.labels.template.text = '{category}';
    pieSeries.labels.template.bent = false;
    pieSeries.slices.template.cornerRadius = 2;
    pieSeries.slices.template.innerCornerRadius = 2;

    pieSeries.slices.template.tooltipText = '{id}: {value}% ({projections} proj)';

    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 0;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    this.chart = chart;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.darkState !== this.props.darkState ||
      prevProps.theme.palette.primary.main !== this.props.theme.palette.primary.main ||
      prevProps.examinationListDrawer !== this.props.examinationListDrawer
    ) {
      this.chart.dispose();
      this.chart = null;
      this.initChart();
    } else {
      if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
        this.chart.data = this.props.data;
        this.chart.validateData();
      }
    }
  }

  componentWillUnmount() {
    if (this.chart) this.chart.dispose();
  }

  render() {
    return <div id={this.props.chartdiv} style={{ width: '100%', height: '100%' }}></div>;
  }
}

function mapStateToProps(state, { dataKey, theme, t }) {
  let data = state.statistics[dataKey];
  if (dataKey === 'opacities' || dataKey === 'microcalc') {
    data = format_lesion_data(data, theme, t);
  }

  return { data };
}

export default connect(mapStateToProps)(PieChart);
