/* eslint no-eval: 0 */
import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import * as actions from '../../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Divider,
  Grid,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Fab,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Table,
  SvgIcon,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import { getCurrentModuleStatus } from '../../../Summary/SummaryCommon/SummaryCommon';
import DoneIcon from '@material-ui/icons/Done';
import SaveIcon from '@material-ui/icons/Save';
import ReplayIcon from '@material-ui/icons/Replay';
import { ReactComponent as AutoAwesome } from '../../../../../../resources/icons/auto_awesome.svg';
import PredictedIcon from '../../../../../../resources/icons/logo_full_color.png';
import {
  getBoxes,
  lesionExists,
  tableRowClickHandler,
  handleMouseEnterRow,
  handleMouseLeaveRow,
} from '../Helpers/DiagnosticRevisionTableHelpers';
import { useStyles } from '../DiagnosticRevisionTable.style';
import { ModuleTableHead, RowIconStatus } from './ModulesCommon.js/TableCommon';
import { deleteFinding, severityColor } from '../Helpers/DiagnosticRevisionTableCommon';
import ModuleTableActions from './ModulesCommon.js/ModuleTableActions';

const MicrocalcificationsTable = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const results = useSelector((state) => state.results);
  const labeling = useSelector((state) => state.labeling);
  const { applied_microcalc, confirmed_microcalc, shown_microcalc } = useSelector(
    (state) => state.results
  );
  const { singleBoxHover, localChangesMicrocalc, localChangesOpacities } = useSelector(
    (state) => state.labeling
  );
  const { zoomedProjection, darkState } = useSelector((state) => state.gui);
  const microcalcificationsResult = labeling?.customMicrocalc;
  const biradsClasses = ['birads2', 'birads3', 'birads4', 'birads5', 'lesionKnown'];
  const microcalcEmpty = lesionExists(microcalcificationsResult);

  const resultMicrocalcEvaluation = results?.evaluations?.microcalc?.[shown_microcalc];
  const microcalcShown = shown_microcalc || applied_microcalc;
  const microcalcState = shown_microcalc === applied_microcalc && confirmed_microcalc;
  const isMicrocalcConfirmed = applied_microcalc === 'bbox' && confirmed_microcalc;
  const isMicrocalcRelabeled = confirmed_microcalc && applied_microcalc !== 'bbox';

  const tableRowData = (box, index, confirmed) => {
    const boxArray = [box.breast, box.classType, box.locationIndex];
    const reassignedInfo = box?.reassigned
      ? box?.reassigned?.prevType === 'opacities'
        ? `BI-RADS ${box?.reassigned?.prevClass?.slice(6, 7)}`
        : box?.reassigned?.prevClass === 'birads5' || box?.reassigned?.prevClass === 'birads4'
        ? t('diagnose.type_microcalc_malignant')
        : t('diagnose.type_microcalc_benign')
      : null;

    return (
      <TableRow
        hover
        key={box.box + index}
        className={singleBoxHover?.box === box.box ? classes.table_row_selected : classes.table_row}
        onClick={() => tableRowClickHandler(box?.breast, dispatch, actions, zoomedProjection)}
        onDoubleClick={() => tableRowClickHandler(box?.breast, dispatch, actions, zoomedProjection)}
        onMouseEnter={() => handleMouseEnterRow('microcalc', box?.box, dispatch, actions)}
        onMouseLeave={() => handleMouseLeaveRow(dispatch, actions)}
        style={{ cursor: zoomedProjection ? 'zoom-out' : 'zoom-in' }}
      >
        <TableCell className={classes.table_cell_projection}>{box.breast?.slice(1)}</TableCell>
        <TableCell
          align='center'
          className={classes.table_cell_row}
          style={{
            color: severityColor(box?.classType, theme) || 'white',
          }}
        >
          {box.type}
        </TableCell>

        {/* {box?.score ? (
          <TableCell align='center' className={classes.table_cell_row}>{`${Math.floor(
            box?.score
          )}%`}</TableCell>
        ) : (
          <TableCell size='medium' />
        )} */}

        <TableCell className={classes.table_cell_info} align='center'>
          <RowIconStatus
            lesionAssigned={box?.assigned}
            lesionReassigned={box?.reassigned}
            confirmed={confirmed_microcalc}
            labeling={localChangesMicrocalc}
            reassigned={reassignedInfo}
            classes={classes}
            t={t}
          />
        </TableCell>

        <TableCell className={classes.delete_cell_row}>
          <Tooltip
            title={
              <Typography className={classes.tooltip_text}> {t('projections.delete')} </Typography>
            }
            placement={'right-start'}
          >
            <DeleteIcon
              fontSize='small'
              style={{ width: '16px', height: '16px' }}
              className={classes.row_actions_icon}
              onClick={(e) =>
                deleteFinding(
                  'microcalc',
                  boxArray,
                  microcalcificationsResult,
                  dispatch,
                  actions,
                  e
                )
              }
            />
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  };

  const getMicrocalcBoxes = (breast, type) =>
    getBoxes(microcalcificationsResult, breast, type, 'microcalc', theme, t);

  const renderTableRows = (breast, typeList, getBoxesFunc, confirmedData) => {
    let rows = [];
    typeList.forEach((type) => {
      rows = rows.concat(
        getBoxesFunc(breast, type)?.map((box, index) => tableRowData(box, index, confirmedData)) ||
          []
      );
    });
    return rows;
  };

  const rightMicrocalcBreastRows = [
    ...renderTableRows('rcc', biradsClasses, getMicrocalcBoxes, confirmed_microcalc),
    ...renderTableRows('rmlo', biradsClasses, getMicrocalcBoxes, confirmed_microcalc),
  ];
  const leftMicrocalcBreastRows = [
    ...renderTableRows('lcc', biradsClasses, getMicrocalcBoxes, confirmed_microcalc),
    ...renderTableRows('lmlo', biradsClasses, getMicrocalcBoxes, confirmed_microcalc),
  ];

  return (
    <Accordion
      square
      className={classes.accordion_container}
      expanded={singleBoxHover?.module === 'microcalc' || props.expandMicrocalcAccordion}
      onChange={(event, expanded) => props.handleAccordionModule('microcalc', expanded)}
    >
      <AccordionSummary
        aria-controls='panel1d-content'
        id='panel1d-header'
        expandIcon={<ExpandMore />}
        className={classes.diagnose_title_container}
        style={{ background: darkState ? '#545353' : '#818181' }}
      >
        <Typography className={classes.diagnose_title}>
          {t('ResultView.diagnosticsMicrocalc')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        className={classes.accordion_details}
        style={{ minHeight: microcalcEmpty ? '2vh' : '25vh' }}
      >
        <Grid
          container
          className={classes.breast_table_container}
          wrap='nowrap'
          justifyContent='flex-start'
          spacing={1}
        >
          {microcalcEmpty ? (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Typography style={{ fontSize: '10px', fontStyle: 'italic', color: 'gray' }}>
                {t('diagnose.no_results')}
              </Typography>
            </div>
          ) : (
            <>
              <Grid
                item
                xs={6}
                className={classes.breast_container}
                style={{ borderRight: '1px solid gray' }}
              >
                <div className={classes.title_container}>
                  <Typography className={classes.breast_text}>
                    {t('diagnose.right_breast')}
                  </Typography>
                </div>
                <div className={classes.table_container}>
                  <Table>
                    <ModuleTableHead module='microcalc' classes={classes} t={t} />

                    <TableBody>
                      {rightMicrocalcBreastRows.length > 0 ? (
                        rightMicrocalcBreastRows
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={5}
                            align='center'
                            className={classes.table_placeholder}
                          >
                            {t('diagnose.no_r_calc_found')}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              </Grid>
              <Grid item xs={6} className={classes.breast_container}>
                <div className={classes.title_container}>
                  <Typography className={classes.breast_text}>
                    {t('diagnose.left_breast')}
                  </Typography>
                </div>
                <div className={classes.table_container}>
                  <Table>
                    <ModuleTableHead module='microcalc' classes={classes} t={t} />

                    <TableBody>
                      {leftMicrocalcBreastRows.length > 0 ? (
                        leftMicrocalcBreastRows
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={5}
                            align='center'
                            className={classes.table_placeholder}
                          >
                            {t('diagnose.no_l_calc_found')}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              </Grid>
            </>
          )}
        </Grid>
        <div className={classes.module_status_container}>
          <ModuleTableActions
            module={'microcalc'}
            resultEvaluation={resultMicrocalcEvaluation}
            moduleShown={microcalcShown}
            moduleState={microcalcState}
            isModuleConfirmed={isMicrocalcConfirmed}
            isModuleRelabeled={isMicrocalcRelabeled}
            appliedRevision={applied_microcalc}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default MicrocalcificationsTable;
