import React, { useState, useEffect } from 'react';
import {
  Grid,
  Divider,
  Button,
  useTheme,
  LinearProgress,
  Typography,
  Avatar,
  IconButton,
  CardContent,
  CardHeader,
  Fab,
  Tooltip,
  Card,
  CardActions,
} from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as actions from '../../../../../store/index';
import { useStyles } from './RiskSummary.style';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import SummaryParagraphHeader from '../SummaryParagraphHeader';
import TimelineIcon from '@material-ui/icons/Timeline';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import { catalog } from '../../../../../language/catalog';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Error } from '@material-ui/icons';
import BRisk from '../../../../../resources/icons/BRisk.png';
import { highDensity } from '../../../../../helpers/highDensity';
import { DIAGNOSTICS_MICROCALC, DIAGNOSTICS_OPACITIES } from '../../../../../config';

const RiskSummary = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const risk_evaluation = useSelector((state) => state.results.risk_evaluation);
  const riskManagementCatalog = catalog.resources.en.translation.risk_management;
  const { age, results } = props;

  const darkState = useSelector((store) => store.gui.darkState);

  const patientRelativeRisk = () => {
    if (risk_evaluation?.life_time_risk >= 30) {
      return (
        <div className={classes.life_risk_container}>
          <Error fontSize='medium' color='primary' />

          <Typography
            className={[classes.patient_relative_risk, classes.patient_risk_high].join(' ')}
          >
            {t('risk_management.high_average_summary')}
          </Typography>
        </div>
      );
    }
    if (risk_evaluation?.life_time_risk >= 17 && risk_evaluation?.life_time_risk <= 30) {
      return (
        <div className={classes.life_risk_container}>
          <Avatar className={[classes.risk_avatar, classes.patient_risk_moderate_icon].join(' ')}>
            <TimelineIcon className={classes.risk_icon} fontSize='inherit' />
          </Avatar>
          <Typography
            className={[classes.patient_relative_risk, classes.patient_risk_moderate].join(' ')}
          >
            {t('risk_management.sligh_high_average_summary')}
          </Typography>
        </div>
      );
    }
    if (risk_evaluation?.life_time_risk < 17) {
      return (
        <div className={classes.life_risk_container}>
          <Avatar className={[classes.risk_avatar, classes.patient_risk_low_icon].join(' ')}>
            <BubbleChartIcon className={classes.risk_icon} fontSize='inherit' />
          </Avatar>
          <Typography
            className={[classes.patient_relative_risk, classes.patient_risk_low].join(' ')}
          >
            {t('risk_management.normal_average_summary')}
          </Typography>
        </div>
      );
    }

    return '';
  };

  const patientRecommendations = () => {
    if (risk_evaluation?.life_time_risk >= 30) {
      if (age >= 60) {
        return riskManagementCatalog.recomendation_high_sixtyplus?.map((e, i) => (
          <div className={classes.recommendations} key={i}>
            <Typography
              key={e}
              className={classes.recomendation_text}
              style={{ color: darkState ? '#ffffffb3' : 'gray' }}
            >
              <ArrowRightIcon fontSize='small' />
              {t(`risk_management.recomendation_high_sixtyplus.${i}`)}
            </Typography>
          </div>
        ));
      }
      if (age < 60 && age >= 50) {
        if (highDensity(results) === 'C' || highDensity(results) === 'D')
          return riskManagementCatalog.recomendation_high_fifty_dense?.map((e, i) => (
            <div className={classes.recommendations} key={i}>
              <Typography
                key={e}
                className={classes.recomendation_text}
                style={{ color: darkState ? '#ffffffb3' : 'gray' }}
              >
                <ArrowRightIcon fontSize='small' />
                {t(`risk_management.recomendation_high_fifty_dense.${i}`)}
              </Typography>
            </div>
          ));
        else
          return riskManagementCatalog.recomendation_high_fifty?.map((e, i) => (
            <div className={classes.recommendations} key={i}>
              <Typography
                key={e}
                className={classes.recomendation_text}
                style={{ color: darkState ? '#ffffffb3' : 'gray' }}
              >
                <ArrowRightIcon fontSize='small' />
                {t(`risk_management.recomendation_high_fifty.${i}`)}
              </Typography>
            </div>
          ));
      }
      if (age < 50 && age > 40) {
        return riskManagementCatalog.recomendation_high_fourty?.map((e, i) => (
          <div className={classes.recommendations} key={i}>
            <Typography
              key={e}
              className={classes.recomendation_text}
              style={{ color: darkState ? '#ffffffb3' : 'gray' }}
            >
              <ArrowRightIcon fontSize='small' />
              {t(`risk_management.recomendation_high_fourty.${i}`)}
            </Typography>
          </div>
        ));
      }
      if (age < 40) {
        return riskManagementCatalog.recomendation_high_thirty?.map((e, i) => (
          <div className={classes.recommendations} key={i}>
            <Typography
              key={e}
              className={classes.recomendation_text}
              style={{ color: darkState ? '#ffffffb3' : 'gray' }}
            >
              <ArrowRightIcon fontSize='small' />
              {t(`risk_management.recomendation_high_thirty.${i}`)}
            </Typography>
          </div>
        ));
      } else
        return riskManagementCatalog.recomendation_high?.map((e, i) => (
          <div className={classes.recommendations} key={i}>
            <Typography
              key={e}
              className={classes.recomendation_text}
              style={{ color: darkState ? '#ffffffb3' : 'gray' }}
            >
              <ArrowRightIcon fontSize='small' />
              {t(`risk_management.recomendation_high.${i}`)}
            </Typography>
          </div>
        ));
    }

    if (risk_evaluation?.life_time_risk >= 17 && risk_evaluation?.life_time_risk <= 30) {
      if (age >= 60) {
        return riskManagementCatalog.recomendation_moderate_sixtyplus?.map((e, i) => (
          <div className={classes.recommendations} key={i}>
            <Typography
              key={e}
              className={classes.recomendation_text}
              style={{ color: darkState ? '#ffffffb3' : 'gray' }}
            >
              <ArrowRightIcon fontSize='small' />
              {t(`risk_management.recomendation_moderate_sixtyplus.${i}`)}
            </Typography>
          </div>
        ));
      }
      if (age < 60 && age >= 50) {
        return riskManagementCatalog.recomendation_moderate_fifty?.map((e, i) => (
          <div className={classes.recommendations} key={i}>
            <Typography
              key={e}
              className={classes.recomendation_text}
              style={{ color: darkState ? '#ffffffb3' : 'gray' }}
            >
              <ArrowRightIcon fontSize='small' />
              {t(`risk_management.recomendation_moderate_fifty.${i}`)}
            </Typography>
          </div>
        ));
      }
      if (age > 40) {
        return riskManagementCatalog.recomendation_moderate_fourty?.map((e, i) => (
          <div className={classes.recommendations} key={i}>
            <Typography
              key={e}
              className={classes.recomendation_text}
              style={{ color: darkState ? '#ffffffb3' : 'gray' }}
            >
              <ArrowRightIcon fontSize='small' />
              {t(`risk_management.recomendation_moderate_fourty.${i}`)}
            </Typography>
          </div>
        ));
      } else
        return riskManagementCatalog.recomendation_moderate?.map((e, i) => (
          <div className={classes.recommendations} key={i}>
            <Typography
              key={e}
              className={classes.recomendation_text}
              style={{ color: darkState ? '#ffffffb3' : 'gray' }}
            >
              <ArrowRightIcon fontSize='small' />
              {t(`risk_management.recomendation_moderate.${i}`)}
            </Typography>
          </div>
        ));
    }

    if (risk_evaluation?.life_time_risk < 17) {
      return riskManagementCatalog.recomendation_normal?.map((e, i) => (
        <div className={classes.recommendations} key={i}>
          <Typography
            key={e}
            className={classes.recomendation_text}
            style={{ color: darkState ? '#ffffffb3' : 'gray' }}
          >
            <ArrowRightIcon fontSize='small' />
            {t(`risk_management.recomendation_normal.${i}`)}
          </Typography>
        </div>
      ));
    }
  };

  const openReport = () => {
    dispatch(actions.setActiveDialog('risk_management'));
  };
  const openForm = () => {
    dispatch(actions.setActiveDialog('risk_management_form'));
  };

  return (
    <Card style={{ margin: '0', borderRadius: '5px' }}>
      <CardHeader
        style={{ padding: '0', background: darkState ? '#535353' : '#b9b9b9' }}
        title={
          <div
            style={{
              display: 'flex',
              width: '100%',
              padding: '0 16px',
              justifyContent: 'space-between',
            }}
          >
            <SummaryParagraphHeader header={t('b_box.b_risk')} />
          </div>
        }
      />
      <CardContent style={{ padding: '0' }}>
        {risk_evaluation ? (
          <div
            className={classes.risk_assessment_container}
            style={{
              paddingTop: '20px',
              minHeight:
                !(
                  (DIAGNOSTICS_MICROCALC || DIAGNOSTICS_OPACITIES) &&
                  localStorage.getItem('role') !== 'technician'
                ) && '16vh',
            }}
          >
            <div className={classes.risk_content_container}>{patientRelativeRisk()}</div>

            <div className={classes.recommendation_container}>
              <Typography className={classes.patient_recommendations_title}>
                {t('risk_management.recommendations')}
              </Typography>
              <div className={classes.recommendation_list}>{patientRecommendations()}</div>
            </div>
          </div>
        ) : (
          <div
            className={classes.risk_assessment_container_filled}
            style={{
              paddingTop: '20px',
              minHeight:
                (DIAGNOSTICS_MICROCALC || DIAGNOSTICS_OPACITIES) &&
                localStorage.getItem('role') !== 'technician'
                  ? '100px'
                  : '17vh',
            }}
          >
            <div className={classes.risk_content_container_empty}>
              <Typography
                className={classes.risk_info_placeholder}
                style={{ lineHeight: 2, color: darkState ? '#ffffffb3' : 'gray' }}
              >
                {t('risk_management.patient_risk_disclaimer')}
              </Typography>
            </div>
          </div>
        )}
      </CardContent>
      <CardActions className={classes.risk_btn_container}>
        {risk_evaluation ? (
          <Tooltip
            title={
              <Typography className={classes.tooltip_title}>
                {t('risk_management.button_complete')}
              </Typography>
            }
          >
            <Fab
              className={classes.summary_fabs}
              onClick={() => (risk_evaluation !== null ? openReport() : openForm())}
            >
              <TrendingUpIcon style={{ color: 'white', width: '20px', height: '20px' }} />
            </Fab>
          </Tooltip>
        ) : (
          <Tooltip
            title={
              <Typography className={classes.tooltip_title}>
                {t('risk_management.button_incomplete')}
              </Typography>
            }
          >
            <Fab className={classes.summary_fabs} onClick={openForm}>
              <NoteAddIcon style={{ color: 'white', width: '20px', height: '20px' }} />
            </Fab>
          </Tooltip>
        )}
      </CardActions>
    </Card>
  );
};

export default RiskSummary;
