/* eslint no-eval: 0 */
import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as actions from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tooltip, Fab, Dialog, Menu, MenuItem, Typography } from '@material-ui/core';
import { Icon } from '@iconify/react';
import eraseIcon from '@iconify/icons-gg/erase';
import closeIcon from '@iconify/icons-gg/close';
import drawIcon from '@iconify/icons-mdi/draw';
import cancelIcon from '@iconify/icons-mdi/exit-to-app';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { setCustomResults } from '../../../../store/slices/labeling';
import DoneIcon from '@material-ui/icons/Done';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ReplayIcon from '@material-ui/icons/Replay';

const useStyles = makeStyles((theme) => ({
  tools_fab: {
    margin: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  draggableHandle: {
    cursor: 'move',
  },
  tooltipText: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    fontSize: theme.fonts.responsiveMedium,
  },
}));

const RevisionTools = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { tabsIndex } = useSelector((state) => state.gui);
  const results = useSelector((state) => state.results);
  const { microcalcOverlaysToShow, opacitiesOverlaysToShow } = useSelector((state) => state.images);
  const { noTypeRectangles } = useSelector((state) => state.labeling);

  const [menuAnchor, setMenuAnchor] = useState(false);
  const [currentModuleToClear, setCurrentModuleToClear] = useState(null);

  const {
    drawing,
    shownQualityOverlayType,
    localChangesQuality,
    diagnosticsInRevisionMode,
    qualityInRevisionMode,
    qualityInDrawingMode,
  } = useSelector((state) => state.labeling);

  const microcalcTwoShown = Object.values(microcalcOverlaysToShow['birads2']).some(
    (value) => value === true
  );
  const microcalcThreeShown = Object.values(microcalcOverlaysToShow['birads3']).some(
    (value) => value === true
  );
  const microcalcFourShown = Object.values(microcalcOverlaysToShow['birads4']).some(
    (value) => value === true
  );
  const microcalcTFiveShown = Object.values(microcalcOverlaysToShow['birads5']).some(
    (value) => value === true
  );

  const biradsTwoShown = Object.values(opacitiesOverlaysToShow['birads2']).some(
    (value) => value === true
  );
  const biradsThreeShown = Object.values(opacitiesOverlaysToShow['birads3']).some(
    (value) => value === true
  );
  const biradsFourShown = Object.values(opacitiesOverlaysToShow['birads4']).some(
    (value) => value === true
  );
  const biradsFiveShown = Object.values(opacitiesOverlaysToShow['birads5']).some(
    (value) => value === true
  );

  const allMicrocalcShown =
    microcalcTwoShown && microcalcThreeShown && microcalcFourShown && microcalcTFiveShown;
  const allOpacitiesShown =
    biradsTwoShown && biradsThreeShown && biradsFourShown && biradsFiveShown;
  const noTempRectangles = Object.values(noTypeRectangles).every((array) => array.length === 0);

  const onToolModeChange = (action) => {
    let newSettings = { ...drawing };
    newSettings.mode = action;
    dispatch(actions.setDrawing(newSettings));
  };

  function onClear(projections) {
    if (qualityInRevisionMode) {
      for (let proj of projections) {
        if (results.projections?.[proj]) {
          dispatch(actions.updateCustomQualityOverlays(shownQualityOverlayType, proj, 'clear_me'));
        }
      }

      dispatch(actions.setQualityLocalChanges(true));
    }
    if (allMicrocalcShown || allOpacitiesShown) {
      for (let proj of projections) {
        if (results.projections?.[proj]) {
          dispatch(actions.updateCustomDiagnostics(allMicrocalcShown, allOpacitiesShown, proj));
        }
      }
      if (allOpacitiesShown) dispatch(actions.setOpacitiesLocalChanges(true));
      if (allMicrocalcShown) dispatch(actions.setMicrocalcLocalChanges(true));
    }
    setMenuAnchor(null);
  }

  const onSaveRevision = () => {
    if (qualityInRevisionMode) {
      dispatch(actions.hideAllLesions());
      dispatch(actions.setShownQualityOverlayType(''));
      dispatch(actions.setQualityInDrawingMode(false));
    }

    if (diagnosticsInRevisionMode) {
      dispatch(actions.setDiagnosticsInRevisionMode(true));
    }
  };

  const onDiscardRevision = () => {
    if (diagnosticsInRevisionMode) {
      dispatch(actions.setInitialRectangles());
    }
    if (qualityInRevisionMode) {
      if (localChangesQuality) {
        dispatch(setCustomResults(results));
      }
      dispatch(actions.setCustomQualityOverlays({}));
      dispatch(actions.hideAllLesions());
      dispatch(actions.setShownQualityOverlayType(''));
      dispatch(actions.setQualityInDrawingMode(false));
    }
  };

  useEffect(() => {
    let newSettings = { ...drawing };
    if (diagnosticsInRevisionMode) {
      newSettings.mode = 'draw_boxes';
    } else newSettings.mode = 'draw';
    dispatch(actions.setDrawing(newSettings));
  }, []);

  useEffect(() => {
    if (allMicrocalcShown && allOpacitiesShown) {
      setCurrentModuleToClear('microcalc and opacities');
    } else if (allMicrocalcShown) {
      setCurrentModuleToClear('microcalc');
    } else if (allOpacitiesShown) {
      setCurrentModuleToClear('opacities');
    }
  }, [allMicrocalcShown, allOpacitiesShown]);

  return (
    <>
      {/* DRAW BOX TOOL */}
      {diagnosticsInRevisionMode && (
        <Tooltip
          title={
            <Typography className={classes.tooltipText}>{t('revision.draw_boxes')}</Typography>
          }
          arrow
          placement='right-end'
        >
          <Fab
            className={classes.tools_fab}
            size='small'
            style={{
              width: '32px',
              height: '32px',
              minWidth: '32px',
              minHeight: '32px',
              padding: 0,
              color: 'white',
              backgroundColor: theme.palette.complementary.turquoise,
              border: `1px solid ${theme.palette.complementary.turquoise}`,
              boxShadow: 'rgb(70 176 176) 0px 0px 4px 1px',
            }}
          >
            <CheckBoxOutlineBlankIcon fontSize='small' />
          </Fab>
        </Tooltip>
      )}
      {/* DRAW QUALITY TOOL */}
      {!diagnosticsInRevisionMode && (
        <Tooltip
          title={<Typography className={classes.tooltipText}>{t('ResultView.draw')}</Typography>}
          arrow
          placement='right-end'
        >
          <Fab
            className={classes.tools_fab}
            onClick={() => onToolModeChange('draw')}
            size='small'
            style={{
              width: '32px',
              height: '32px',
              minWidth: '32px',
              minHeight: '32px',
              padding: 0,
              color: drawing.mode === 'draw' ? 'white' : theme.palette.complementary.turquoise,
              backgroundColor:
                drawing.mode === 'draw' ? theme.palette.complementary.turquoise : 'transparent',
              border:
                drawing.mode === 'draw'
                  ? `1px solid ${theme.palette.complementary.turquoise}`
                  : `1px solid ${theme.palette.complementary.turquoise}`,
              boxShadow: drawing.mode === 'draw' ? 'rgb(70 176 176) 0px 0px 4px 1px' : 'none',
            }}
          >
            {<Icon icon={drawIcon} />}
          </Fab>
        </Tooltip>
      )}
      {/* ERASE TOOL */}
      {!diagnosticsInRevisionMode && (
        <Tooltip
          title={<Typography className={classes.tooltipText}>{t('ResultView.erase')}</Typography>}
          arrow
          placement='right-end'
        >
          <Fab
            className={classes.tools_fab}
            onClick={() => onToolModeChange('erase')}
            size='small'
            style={{
              width: '32px',
              height: '32px',
              minWidth: '32px',
              minHeight: '32px',
              padding: 0,
              color: drawing.mode === 'erase' ? 'white' : theme.palette.complementary.turquoise,
              backgroundColor:
                drawing.mode === 'erase' ? theme.palette.complementary.turquoise : 'transparent',
              border:
                drawing.mode === 'erase'
                  ? `1px solid ${theme.palette.complementary.turquoise}`
                  : `1px solid ${theme.palette.complementary.turquoise}`,
              boxShadow: drawing.mode === 'erase' && 'rgb(70 176 176) 0px 0px 4px 1px',
            }}
          >
            <Icon icon={eraseIcon} />
          </Fab>
        </Tooltip>
      )}
      {/* CLEAR TOOL */}
      <Tooltip
        title={
          <Typography className={classes.tooltipText}>
            {diagnosticsInRevisionMode
              ? currentModuleToClear
                ? `${t('ResultView.clear_diagnose', { modules: `${currentModuleToClear}` })}`
                : 'Please select a lesion type'
              : `${t('ResultView.clear')}`}
          </Typography>
        }
        arrow
        placement='right-end'
      >
        <span>
          <Fab
            className={classes.tools_fab}
            onClick={(event) => setMenuAnchor(event.target)}
            size='small'
            style={{
              width: '32px',
              height: '32px',
              minWidth: '32px',
              minHeight: '32px',
              padding: 0,
              color:
                (diagnosticsInRevisionMode && !allMicrocalcShown && !allOpacitiesShown) ||
                (qualityInDrawingMode && !qualityInDrawingMode)
                  ? 'gray '
                  : theme.palette.complementary.turquoise,
              backgroundColor: 'transparent',
              border:
                (diagnosticsInRevisionMode && !allMicrocalcShown && !allOpacitiesShown) ||
                (qualityInDrawingMode && !qualityInDrawingMode)
                  ? '1px solid gray'
                  : `1px solid ${theme.palette.complementary.turquoise}`,
            }}
            disabled={diagnosticsInRevisionMode && !allMicrocalcShown && !allOpacitiesShown}
          >
            <DeleteOutlineIcon fontSize='small' />
          </Fab>
        </span>
      </Tooltip>
      <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClose={() => setMenuAnchor(null)}>
        <MenuItem disabled={!results.projections?.rcc} onClick={() => onClear(['rcc'])}>
          RCC
        </MenuItem>
        <MenuItem disabled={!results.projections?.lcc} onClick={() => onClear(['lcc'])}>
          LCC
        </MenuItem>
        <MenuItem disabled={!results.projections?.rmlo} onClick={() => onClear(['rmlo'])}>
          RMLO
        </MenuItem>
        <MenuItem disabled={!results.projections?.lmlo} onClick={() => onClear(['lmlo'])}>
          LMLO
        </MenuItem>
        <MenuItem onClick={() => onClear(['rcc', 'lcc', 'rmlo', 'lmlo'])}>
          {t('ResultView.all')}
        </MenuItem>
      </Menu>
      {/* SAVE DRAWING */}
      {qualityInDrawingMode && (
        <Tooltip
          title={
            <Typography className={classes.tooltipText}>
              {t('revision.done').toUpperCase()}
            </Typography>
          }
          arrow
          placement='right-end'
        >
          <Fab
            className={classes.tools_fab}
            onClick={() => onSaveRevision('save')}
            size='small'
            style={{
              width: '32px',
              height: '32px',
              minWidth: '32px',
              minHeight: '32px',
              padding: 0,
              color: localChangesQuality ? theme.palette.complementary.turquoise : 'gray',
              backgroundColor: 'transparent',
              border: localChangesQuality
                ? `1px solid ${theme.palette.complementary.turquoise}`
                : '1px solid gray',
            }}
          >
            <DoneIcon fontSize='small' />
          </Fab>
        </Tooltip>
      )}
      {/* DISCARD TOOL */}
      <Tooltip
        title={
          <Typography className={classes.tooltipText}>
            {diagnosticsInRevisionMode
              ? t('revision.discard_temporal_boxes').toUpperCase()
              : t('ResultView.discard').toUpperCase()}
          </Typography>
        }
        arrow
        placement='right-end'
      >
        <span>
          <Fab
            className={classes.tools_fab}
            onClick={() => {
              onDiscardRevision();
            }}
            size='small'
            style={{
              width: '32px',
              height: '32px',
              minWidth: '32px',
              minHeight: '32px',
              padding: 0,
              color:
                tabsIndex === 2 && diagnosticsInRevisionMode && noTempRectangles
                  ? 'gray'
                  : theme.palette.complementary.turquoise,
              backgroundColor: 'transparent',
              border:
                tabsIndex === 2 && diagnosticsInRevisionMode && noTempRectangles
                  ? '1px solid gray'
                  : `1px solid ${theme.palette.complementary.turquoise}`,
            }}
            disabled={tabsIndex === 2 && diagnosticsInRevisionMode && noTempRectangles}
          >
            <ReplayIcon fontSize='small' />
          </Fab>
        </span>
      </Tooltip>
    </>
  );
};

export default RevisionTools;
