import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({

  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  Button: {
    margin: '2% 1% 0.5% 1%',
  }
}));

export default function ButtonGroup(props) {
  const classes = useStyles();

  return (
    <div className={classes.buttonGroup} >
      <Button
          className={classes.Button}
          onClick={() => props.setFilter('density')}
          variant={props.filter === "density" ? "contained" : "outlined"}
          color="primary"
          size="medium"
        >
          dens
        </Button>

        <Button
          className={classes.Button}
          onClick={() => props.setFilter('quality')}
          variant={props.filter === "quality" ? "contained" : "outlined"}
          color="primary"
          size="medium"
        >
          qual
        </Button>

        <Button
          className={classes.Button}
          onClick={() => props.setFilter('localization')}
          variant={props.filter === "localization" ? "contained" : "outlined"}
          color="primary"
          size="medium"
        >
          local
        </Button>

        <Button
          className={classes.Button}
          onClick={() => props.setFilter('diagnostics')}
          variant={props.filter === "diagnostics" ? "contained" : "outlined"}
          color="primary"
          size="medium"
        >
          diagn
        </Button>

        <Button
          className={classes.Button}
          onClick={() => props.setFilter('all')}
          variant={props.filter === "all" ? "contained" : "outlined"}
          color="primary"
          size="medium"
        >
          All
        </Button>
    </div>
  );
}
