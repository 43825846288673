import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import DraggableDialog from '../DraggableDialog/DraggableDialog';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  dialogText: {
    letterSpacing: '0.1rem',
    fontSize: theme.typography.h6.fontSize,
    color: theme.palette.texts.secondary,
  },
}));

function TextInfo(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <DraggableDialog
      maxWidth='sm'
      title={t('Settings.warning')}
      open={props.open}
      actions={[{ onClick: props.onClose, color: 'primary', label: t('Settings.dialog_ok') }]}
      onClose={props.onClose}
    >
      <Typography className={classes.dialogText}>{props.text}</Typography>
    </DraggableDialog>
  );
}

export default TextInfo;
