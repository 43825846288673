import React, { useState, useEffect, useCallback } from 'react';
import ArrowIcon from '@material-ui/icons/ArrowForward';
import {
  TextField,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { setSnackbar, fetchDevices } from '../../../../../../store/index';
import { fetchGet, fetchPut } from '../../../../../../helpers/fetching';
import { useStyles } from './InstitutionNameRules.style';
import { useTranslation } from 'react-i18next';

export default function InstitutionNameRules(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // State
  const [fetchedRules, setFetchedRules] = useState([]);
  const [currentRules, setCurrentRules] = useState(null);

  // Handlers
  const onRuleChange = (name, newName) => {
    const newRules = { ...currentRules };
    newRules[name] = newName;
    setCurrentRules(newRules);
  };

  // Fetching
  const fetchRules = useCallback(async () => {
    const response = await fetchGet('inst_rules');
    if (!response.success) {
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
      return;
    }

    setCurrentRules(response.data);
    setFetchedRules(Object.values(response.data));
  }, [dispatch]);

  const postRules = async () => {
    if (Object.keys(currentRules).length === 0) {
      const msgSaved = t('Filters.no_changes');
      dispatch(
        setSnackbar({
          msg: msgSaved,
          severity: 'warning',
        })
      );
      closeDialog();
      return;
    }
    const response = await fetchPut('inst_rules', currentRules);
    if (response.success) {
      const msgSaved = t('Filters.rulesSaved');
      dispatch(
        setSnackbar({
          msg: msgSaved,
          severity: 'success',
        })
      );
      fetchRules();
      dispatch(fetchDevices());
    } else
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
    closeDialog();
  };

  // Effects
  useEffect(() => {
    fetchRules();
  }, [fetchRules]);

  const closeDialog = () => props.onClose();

  let instList = [];
  if (currentRules) {
    const set1 = new Set(Object.keys(currentRules));
    const set2 = new Set(
      props.institutions.filter((inst) => !fetchedRules.includes(inst))
    );
    let union = new Set([...set1, ...set2]);
    instList = Array.from(union).sort();
  }

  return (
    <Dialog
      maxWidth="lg"
      className={classes.institutions_dialog}
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{t('Filters.nameRules')}</DialogTitle>
      <DialogContent>
        <>
          {currentRules ? (
            instList.map((inst) => (
              <div key={inst} className={classes.RuleItem}>
                <p className={classes.left}>{inst}</p>
                <ArrowIcon className={classes.center} />
                <TextField
                  variant="standard"
                  value={
                    currentRules[inst] === undefined ? inst : currentRules[inst]
                  }
                  className={classes.right}
                  onChange={(e) => onRuleChange(inst, e.target.value)}
                />
              </div>
            ))
          ) : (
            <>
              <CircularProgress />
            </>
          )}
        </>
      </DialogContent>

      <DialogActions>
        <Button onClick={postRules} color="primary" variant="contained">
          {t('Filters.apply')}
        </Button>
        <Button onClick={props.onClose} color="primary" variant="contained">
          {t('Filters.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
