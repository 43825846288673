import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, CircularProgress } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import CaptureProjection from './CaptureProjection';
import { fetchPut } from '../../../../../helpers/fetching';
import { fetchExaminations, fetchResults, setInfo, setSnackbar } from '../../../../../store';
import { API_ENDPOINT } from '../../../../../config';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  CollapseImage: {
    position: 'relative',
  },

  spinner: {
    position: 'absolute',
    zIndex: 9,
    left: 'calc(50% - 40px)',
    top: 'calc(50% - 40px)',
  },
}));

function CollapseImage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // Store state
  const currentExam = useSelector((state) => state.examinations.currentExam);
  const results = useSelector((state) => state.results);

  // Local state
  const [flipState, setFlipState] = useState(new Array(props.uids.length).fill(false));
  const [capturesLoaded, setCapturesLoaded] = useState(0);

  const cardFlipHandler = (idx, state) => {
    const flipped = [...flipState];
    flipped[idx] = state;
    setFlipState(flipped);
  };

  async function setRelevantCapture(proj_name, uid) {
    const response = await fetchPut(
      `relevant_projection/${currentExam}`,
      {
        proj: proj_name,
        new_relevant: uid,
      },
      'bbox'
    );
    if (response.success) {
      dispatch(fetchResults());
      dispatch(
        setSnackbar({
          msg: t('api_messages.relevant_capture_change'),
          severity: 'success',
        })
      );
      // dispatch(setInfo(t("api_messages.alternative_capture")));
    } else
      dispatch(
        setSnackbar({
          msg: t('api_messages.set_relevant_capture_fail'),
          severity: 'error',
        })
      );
  }

  if (props.uids.length < 2) return null;

  const left = props.proj_name === 'lmlo' || props.proj_name === 'lcc';

  return (
    <div className={[classes.CollapseImage, props.className].join(' ')}>
      <div style={{ cursor: 'pointer' }}>
        <Grid spacing={2} container direction='row'>
          {capturesLoaded < props.uids.length && (
            <>
              <CircularProgress size={60} className={classes.spinner} />
            </>
          )}
          {props.uids.sort().map((uid, i) => (
            <Grid
              key={uid}
              xs={6}
              sm={6}
              md={6}
              item
              style={{
                opacity: capturesLoaded < props.uids.length ? 0 : 1,
              }}
            >
              <CaptureProjection
                src={`${API_ENDPOINT}/static_images/${results.folder}/${uid}.png`}
                proj_name={props.proj_name}
                setAsRelevant={() => setRelevantCapture(props.proj_name, uid, t)}
                onLoad={() => {
                  setCapturesLoaded((count) => count + 1);
                }}
                left={left}
                isRelevant={uid === results.projections[props.proj_name].uid}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}

export default CollapseImage;
