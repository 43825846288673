import React from "react";
import { makeStyles } from "@material-ui/core";
import {
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Tooltip,
    IconButton
} from "@material-ui/core";
import { Icon } from '@iconify/react';
import screenSplitH from '@iconify/icons-gis/screen-split-h';
import { useSelector, useDispatch } from 'react-redux';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import history from "../../../history";

import {
    setSplitView, onModelClick, setCompareModelObject,
    setSnackbar, fetchModelsList, setActionToConfirm,
    deleteModel
} from './../../../store/';
import { fetchPut } from "../../../helpers/fetching";
import { formatModelName } from "../../../helpers/misc";

const useStyles = makeStyles(theme => (
    {
        paper: {
            width: "100%",
            marginBottom: theme.spacing(0),
            borderRadius: 0,
            display: "block",
            overflow: "hidden",
        },

        tableRow: {
            cursor: "pointer",
            "&.Mui-selected": {
              background: theme.palette.primary.main,
            },
            "&.MuiTableRow-root:hover": {
                background: theme.palette.secondary.dark,
            },
        },

        CheckIcon: {
            color: theme.palette.success.light
        }

    }
))

const ModelsTable = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { modelObject, compareModelObject } = useSelector(state => state.models);

    function onApplyClick(model) {
        if (model.applied) {
            dispatch(setSnackbar({
                msg: `${model.name} already applied`,
                severity: "info",
            }));
            return
        }

        dispatch(setActionToConfirm({
            text: `Do you want to apply ${model.name}?`,
            action: () => setAppliedModel(model)
        }));
    }

    function onSplitClick(modelName) {
        if (!!compareModelObject && (modelName === compareModelObject.name ||
                                    (!!modelObject && modelName === modelObject.name)))
            dispatch(setCompareModelObject(null));
        else
            dispatch(setSplitView(modelName));
    }

    async function setAppliedModel(model) {
        const response = await fetchPut(`model/${model.name}`, {}, 'models');
        if (!response.success) {
            dispatch(setSnackbar({
                msg: response.msg,
                severity: "error",
            }));
            return
        }

        dispatch(fetchModelsList(props.modelsFilter));

        dispatch(setSnackbar({
            msg: `${model.name} applied`,
            severity: "success",
        }));
    }

    return (
        <Paper className={classes.paper}>
            <TableContainer className={classes.container}>
                <Table stickyHeader size="medium">
                    <TableHead>
                        <TableRow key='head' >

                            <TableCell key='name'
                                align='left'
                                padding='normal' >
                                Model Name
                            </TableCell>

                            <TableCell key='applied'
                                align='center'
                                padding='normal' >
                                Applied
                            </TableCell>

                            <TableCell key='type'
                                align='center'
                                padding='normal' >
                                Type
                            </TableCell>
                            
                            {history.location.pathname.includes('details') &&
                            <TableCell key='split'
                                align='center'
                                padding='normal' />}

                            <TableCell key='delete'
                                align='right'
                                padding='normal' />

                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {props.modelsList.map(model => {
                            return (
                                <TableRow
                                    className={classes.tableRow}
                                    key={model.name}
                                    selected={(!!modelObject && model.name === modelObject.name) ||
                                              (!!compareModelObject && model.name === compareModelObject.name)}
                                >

                                    <TableCell key="name"
                                        align="left"
                                        onClick={() => dispatch(onModelClick(model.name))}
                                    >
                                        {formatModelName(model.name)}
                                    </TableCell>

                                    <TableCell key="applied"
                                        align="left"
                                        onClick={() => onApplyClick(model)}
                                    >
                                        <IconButton >
                                            <CheckIcon className={classes.CheckIcon}
                                                style={model.applied ? null : {
                                                    color: 'gray'
                                                }} />
                                        </IconButton>
                                    </TableCell>

                                    <TableCell key="type"
                                        align="center"
                                        onClick={() => dispatch(onModelClick(model.name))}
                                    >
                                        {model.custom ? 'Custom' : 'Built In'}
                                    </TableCell>

                                    {history.location.pathname.includes('details') &&
                                    <Tooltip title={<p style={{ fontSize: '1.5vh' }}>
                                        {(!!compareModelObject && (model.name === compareModelObject.name ||
                                                                   (!!modelObject && model.name === modelObject.name))) ?
                                            'Close the split view' :
                                             'Show this model in a split view'}
                                    </p>}
                                        arrow
                                        interactive
                                        placement="top" >
                                        <TableCell key="split"
                                            align="right" >
                                            <IconButton onClick={() => onSplitClick(model.name)}
                                                        disabled={!!modelObject && model.name === modelObject.name} >
                                                {(!!compareModelObject && (model.name === compareModelObject.name ||
                                                                          (!!modelObject && model.name === modelObject.name))) ?
                                                <CloseIcon style={{
                                                    color: 'red',
                                                    width: '20',
                                                    height: '20'
                                                }}/> :
                                                <Icon icon={screenSplitH} width="20" height="20" 
                                                      style={{
                                                        color: 'inherit'
                                                      }} />}
                                            </IconButton>
                                        </TableCell>
                                    </Tooltip>}

                                    <TableCell key="delete"
                                        align="center">
                                        {!!model && !!model.custom &&
                                            <IconButton
                                                onClick={() => dispatch(setActionToConfirm({
                                                    action: () => dispatch(deleteModel(model.name)),
                                                    text: `Delete ${model.name}?`
                                                }))}>
                                                <DeleteIcon color='secondary' />
                                            </IconButton>}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default ModelsTable;
