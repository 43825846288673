import React from 'react';
import { Redirect } from 'react-router-dom';
import { fetchPost } from '../../helpers/fetching';
import { useDispatch } from 'react-redux';

import { resetDatesFilters, resetFilter, setInitialExamState,
         setFilterProperty, setInitialLabeling } from '../../store';
import { formatDate } from '../../helpers/formatDate';

const Logout = () => {
  const dispatch = useDispatch();

  dispatch(setInitialExamState());
  dispatch(resetDatesFilters());
  dispatch(setFilterProperty(['dates', [
    formatDate(new Date(), '00:00'),
    formatDate(new Date(), '23:59'),
  ]]))
  dispatch(resetFilter());
  dispatch(setInitialLabeling());

  localStorage.removeItem('username');
  localStorage.removeItem('role');
  localStorage.removeItem('annotator');
  localStorage.removeItem('aliases');
  console.log('Adios!');

  fetchPost('logout');

  return <Redirect to='/login' />;
};

export default Logout;
