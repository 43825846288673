import { Tooltip, Typography, Zoom } from '@material-ui/core';

export const examinationTooltips = (title, children, placement) => {
  return (
    <Tooltip
      key={title}
      title={<>{title}</>}
      arrow
      interactive
      placement={placement ? placement : 'top-start'}
      TransitionComponent={Zoom}
    >
      {children && children}
    </Tooltip>
  );
};
