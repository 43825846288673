import { formatDate } from './formatDate';
import { useQualityTranslation } from './hooks/useQualityTranslation';

function format_pie_dens_data(dens, theme, t) {
  const colors = {
    A: theme.palette.dashboard.acrA,
    B: theme.palette.dashboard.acrB,
    C: theme.palette.dashboard.acrC,
    D: theme.palette.dashboard.acrD,
    not_eval: theme.palette.dashboard.none,
  };

  const sum = Object.values(dens).reduce((total, value) => total + value, 0);
  const data = Object.keys(dens).map((acr) => {
    let category;
    if (acr === 'not_eval') category = t(`Dashboard.not_eval`);
    else category = acr;
    return {
      category: category,
      value: sum ? (dens[acr] / sum).toFixed(3) * 100 : 0,
      color: colors[acr],
      projections: dens[acr],
      categoryFilter: [acr?.toUpperCase()],
    };
  });
  return data;
}

function capitalizedQuality(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
}

function format_quality_pie_data(qual_stats, theme, t) {
  const n_classes = Object.keys(qual_stats).length;
  if (n_classes === 0) return [];

  const colors = {
    perfect: theme.palette.dashboard.perfect,
    good: theme.palette.dashboard.good,
    moderate: theme.palette.dashboard.moderate,
    inadequate: theme.palette.dashboard.inadequate,
    adequate: theme.palette.dashboard.adequate,
    null: theme.palette.dashboard.none,
    limited: theme.palette.dashboard.moderate,
    insufficient: theme.palette.dashboard.inadequate,
    correct: theme.palette.dashboard.perfect,
  };

  const sum = Object.values(qual_stats).reduce((total, value) => total + value.count, 0);

  const data = Object.values(qual_stats).map(({ quality_class, count }, i) => {
    const translatedQuality = quality_class
      ? useQualityTranslation(quality_class, t)
      : t('Dashboard.not_evaluated');

    const category = capitalizedQuality(translatedQuality);
    const category_short = category !== t('Dashboard.not_eval') ? category.charAt(0) : category;

    return {
      category,
      category_short,
      projections: count,
      value: sum ? (count / sum).toFixed(3) * 100 : 0,
      color: colors[qual_stats[i]?.quality_class?.toLowerCase()] || theme.palette.dashboard.none,
      categoryFilter: [quality_class?.toLowerCase()],
    };
  });

  return data;
}

function format_quality_bar_data(data, t) {
  const formatedData = {};

  const featureLabelMapping = {
    NippleCentered: t('Dashboard.NippleCentered'),
    PectoralisCC: t('Dashboard.PectoralisCC'),
    Infram: t('Dashboard.Infram'),
    PectAngle: t('Dashboard.PectAngle'),
    PectLevel: t('Dashboard.PectLevel'),
    PectShape: t('Dashboard.PectShape'),
    skinFolds: t('Dashboard.skinFolds'),
    ParenchymaCuts: t('Dashboard.ParenchymaCuts'),
    ParenchymaCutsMedial: t('Dashboard.ParenchymaCutsMedial'),
    ParenchymaCutsLateral: t('Dashboard.ParenchymaCutsLateral'),
    PectoralisCC: t('Dashboard.pectoralis'),
    Nipple: t('Dashboard.nipple'),
    PNL: t('Dashboard.pnl'),
    elevation: t('ResultView.bucky_height'),
    symmetry: t('ResultView.symmetry'),
  };

  for (let viewPosition in data) {
    if (!formatedData[viewPosition]) formatedData[viewPosition] = [];

    for (let featureName in data[viewPosition]) {
      const item = {
        category: featureLabelMapping[featureName] || featureName,
        filterKey: data[viewPosition][featureName].filterKey,
        ...data[viewPosition][featureName].values,
      };

      const sum = Object.values(data[viewPosition][featureName].values).reduce(
        (acc, cur) => acc + cur
      );

      for (let k in data[viewPosition][featureName].values)
        item[k + 'Percent'] = ((item[k] / sum) * 100).toFixed(0);

      formatedData[viewPosition].push(item);
    }
  }

  return formatedData;
}

function format_bar_exams_data(exam_stats, t) {
  const { screening, tomo, postop } = exam_stats;
  if (!screening || !tomo || !postop) return [];

  const denoms = [
    screening.screening + screening.diagnostic,
    tomo.tomo + tomo.mammo,
    postop.postop + postop.normal,
  ];

  return [
    {
      category: 'PURP.',
      label1: t('Dashboard.screening'),
      label2: t('Dashboard.diagnostic'),
      value1: ((screening.screening / denoms[0]) * 100).toFixed(2),
      value2: ((screening.diagnostic / denoms[0]) * 100).toFixed(2),
    },
    {
      category: 'MOD.',
      label1: t('Dashboard.tomo'),
      label2: t('Dashboard.mammo'),
      value1: ((tomo.tomo / denoms[1]) * 100).toFixed(2),
      value2: ((tomo.mammo / denoms[1]) * 100).toFixed(2),
    },
    {
      category: 'OP.',
      label1: t('Dashboard.postop'),
      label2: t('Dashboard.not_operated'),
      value1: ((postop.postop / denoms[2]) * 100).toFixed(2),
      value2: ((postop.normal / denoms[2]) * 100).toFixed(2),
    },
  ];
}

function format_lesion_data(data, theme, t) {
  try {
    let { lesionKnown, birads2, birads3, birads4, birads5, total, vessel, not_eval } = data;

    lesionKnown = lesionKnown || 0;
    birads2 = birads2 || 0;
    birads3 = birads3 || 0;
    birads4 = birads4 || 0;
    birads5 = birads5 || 0;
    vessel = vessel || 0;
    total = total || 0;
    not_eval = not_eval || 0;

    return [
      {
        category: t('Dashboard.none'),
        category_short: t('Dashboard.none'),
        projections:
          total - birads2 - birads3 - birads4 - birads5 - vessel - not_eval - lesionKnown,
        value: (
          (100 *
            (total - birads2 - birads3 - birads4 - birads5 - vessel - not_eval - lesionKnown)) /
          total
        ).toFixed(1),
        color: theme.palette.dashboard.perfect,
        categoryFilter: ['none'],
      },
      {
        category: 'Known Mass',
        category_short: 'Known',
        projections: lesionKnown,
        value: ((100 * lesionKnown) / total).toFixed(1),
        color: theme.palette.lesions.lesionKnown,
        categoryFilter: ['lesionKnown'],
      },
      {
        category: 'BI-RADS 2',
        category_short: 'BI-RADS 2',
        projections: birads2,
        value: ((100 * birads2) / total).toFixed(1),
        color: theme.palette.lesions.birads2,
        categoryFilter: ['birads2'],
      },
      {
        category: 'BI-RADS 3',
        category_short: 'BI-RADS 3',
        projections: birads3,
        value: ((100 * birads3) / total).toFixed(1),
        color: theme.palette.lesions.birads3,
        categoryFilter: ['birads3'],
      },
      {
        category: 'BI-RADS 4',
        category_short: 'BI-RADS 4',
        projections: birads4,
        value: ((100 * birads4) / total).toFixed(1),
        color: theme.palette.lesions.birads4,
        categoryFilter: ['birads4'],
      },
      {
        category: 'BI-RADS 5',
        category_short: 'BI-RADS 5',
        projections: birads5,
        value: ((100 * birads5) / total).toFixed(1),
        color: theme.palette.lesions.birads5,
        categoryFilter: ['birads5'],
      },
      {
        category: 'Vessel',
        category_short: 'Vessel',
        projections: vessel,
        value: ((100 * vessel) / total).toFixed(1),
        color: theme.palette.lesions.vessel,
        categoryFilter: ['vessel'],
      },
      {
        category: t('Dashboard.not_eval'),
        category_short: t('Dashboard.not_eval'),
        projections: not_eval,
        value: ((100 * not_eval) / total).toFixed(1),
        color: theme.palette.dashboard.none,
        categoryFilter: ['not_eval'],
      },
    ];
  } catch {
    return [];
  }
}

function format_histogram_dose(doses, theme) {
  if (doses.length === 0) return [];

  const data = doses.map((bar) => {
    let color;
    if (bar.category < 1.8) {
      color = theme.palette.dashboard.perfect;
    } else if (bar.category < 2.5) {
      color = theme.palette.dashboard.moderate;
    } else {
      color = theme.palette.dashboard.inadequate;
    }

    return {
      category: bar.category,
      value: bar.value ? bar.value : 0.5,
      color,
    };
  });
  return data;
}

function addColorData(data, theme) {
  if (data['Revised'] === 0 && data['Approved'] === 0 && data['Remaining'] === 0) {
    return [
      {
        category: 'none',
        value: -1,
        color: 'rgba(255, 255, 255, 0.2)',
      },
    ];
  }
  return Object.keys(data).map((k) => {
    if (k === 'Revised')
      return {
        category: k,
        value: data[k],
        color: theme.palette.primary.accent,
      };
    if (k === 'Approved')
      return {
        category: k,
        value: data[k],
        color: theme.palette.secondary.main,
      };
    if (k === 'Remaining')
      return {
        category: k,
        value: data[k],
        color: theme.palette.primary.dark,
      };
    else
      return {
        category: '',
        value: 0,
        color: 'gray',
      };
  });
}

function format_line_qual_data(data) {
  const n = data.length;
  const data_qual = new Array(n);

  for (let i = 0; i < n; i++) {
    data_qual[i] = {
      date: formatDate(data[i].period),
      uid: data[i].study_id,
      rcc: data[i].score_rcc,
      lcc: data[i].score_lcc,
      rmlo: data[i].score_rmlo,
      lmlo: data[i].score_lmlo,
      name: data[i].name,
    };
  }

  return data_qual;
}

function format_line_comp_data(data) {
  const n = data.length;
  const data_comp = new Array(n);

  for (let i = 0; i < n; i++) {
    data_comp[i] = {
      date: formatDate(data[i].period),
      rcc: data[i].rcc || null,
      lcc: data[i].lcc || null,
      rmlo: data[i].rmlo || null,
      lmlo: data[i].lmlo || null,
      name: data[i].name,
      uid: data[i].exam_id,
    };
  }

  return data_comp;
}

export {
  format_pie_dens_data,
  format_quality_pie_data,
  format_line_comp_data,
  format_quality_bar_data,
  format_bar_exams_data,
  format_histogram_dose,
  format_lesion_data,
  addColorData,
  format_line_qual_data,
};
