import React from 'react';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../../../../store';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../CustomFilter.style';
import {
  Typography,
  Grid,
  TableCell,
  TableRow,
  TableBody,
  Tooltip,
  Chip,
  TableContainer,
  Table,
  TableHead,
  useTheme,
} from '@material-ui/core';
import SearchPatients from './SearchPatients.js/SearchPatients';
import PeopleIcon from '@material-ui/icons/People';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

export default function CreateList(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const tableHeader = [
    { name: 'patientId', label: t('Filters.patient_id'), align: 'left' },
    { name: 'name', label: t('Filters.name'), align: 'left' },
    { name: 'dob', label: t('Filters.dob'), align: 'left' },
    { name: 'date', label: t('Filters.date'), align: 'left' },
    { name: 'actions', label: '', align: 'left' },
  ];

  const removePatient = (patientId) => {
    props.setPatientList((prevList) => prevList.filter((user) => user.patientId !== patientId));
    dispatch(setSnackbar({ msg: t('Filters.patient_removed'), severity: 'info', duration: 1000 }));
  };

  return (
    <div className={classes.search_container}>
      <Grid container wrap='nowrap' direction='row' spacing={1}>
        <SearchPatients
          setPatientList={props.setPatientList}
          patientList={props.patientList}
          size={5}
          style={{ borderRight: '1px solid gray' }}
        />

        <Grid xs={7} item className={classes.grid_container}>
          <div className={classes.table_title_container}>
            <Typography className={classes.table_title}>
              {t('Filters.custom_list_title')}
            </Typography>
            <div className={classes.table_title_container}>
              <Tooltip
                arrow
                placement='top-end'
                title={
                  <Typography className={classes.tooltip_text}>
                    {t('Filters.number_added')}
                  </Typography>
                }
              >
                <Chip
                  label={`${props.patientList.length}`}
                  variant='outlined'
                  size='small'
                  color='secondary'
                  icon={<PeopleIcon />}
                />
              </Tooltip>
            </div>
          </div>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {tableHeader.map((head, i) => (
                    <TableCell key={i} align={head.align} className={classes.table_row}>
                      {head.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {props.patientList.length > 0 ? (
                <TableBody>
                  {props.patientList.map((patient) => (
                    <TableRow key={patient.patientId}>
                      <TableCell className={classes.table_row}>{patient.patientId}</TableCell>
                      <TableCell className={classes.table_row}>{patient.name}</TableCell>
                      <TableCell className={classes.table_row}>{patient.dateOfBirth}</TableCell>
                      <TableCell className={classes.table_row}>{patient.examDate}</TableCell>

                      <TableCell align='right' className={classes.table_row}>
                        <Tooltip
                          arrow
                          placement='top-start'
                          title={
                            <Typography className={classes.tooltip_text}>
                              {t('Filters.remove')}
                            </Typography>
                          }
                        >
                          <HighlightOffIcon
                            fontSize='small'
                            color='secondary'
                            onClick={() => removePatient(patient.patientId)}
                            style={{ cursor: 'pointer' }}
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align={'center'}
                      colSpan={tableHeader.length}
                      variant='footer'
                      style={{ borderBottom: 'unset' }}
                    >
                      <div
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                      >
                        <Typography
                          className={classes.table_placeholder}
                          style={{ fontSize: theme.fonts.responsiveMediumBig }}
                        >
                          {t('Filters.placeholder_empty')}
                        </Typography>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
}
