import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  diagnostic_lesion_module_container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '100%',
    padding: '2%',
    borderRadius: '5px',
  },

  diagnostic_chips_container: {
    maxWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    margin: '2%',
  },

  accordion_container: {
    width: '100%',
  },
  accordion_details: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    background: '#5151517a',
  },

  diagnostics_type_chips: {
    minWidth: '40%',
    margin: '6px auto',
    height: '22px',
  },

  diagnostics_classes_chips: {
    minWidth: '25%',
    margin: '4px ',
    height: '18px',
    border: `1px solid ${theme.palette.complementary.turquoise}`,
    color: theme.palette.complementary.turquoise,
  },

  diagnose_title: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    fontSize: theme.fonts.responsiveMediumBig,
    fontWeight: 'bold',
  },

  diagnose_title_container: {
    borderRadius: '5px 5px 0 0 ',
    '&.Mui-expanded': {
      minHeight: '40px',
      margin: '0px',
    },
  },

  breast_table_container: {
    margin: '4px',
  },

  breast_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },

  breast_text: {
    fontSize: theme.fonts.responsiveMedium,
    color: 'gray',
  },

  title_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  table_container: {
    minHeight: '120px',
    width: '100%',
  },

  module_status_container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },

  table_cell_boxes_title: {
    padding: '4px 0',
    fontWeight: 'bold',
  },

  table_cell_projection: {
    padding: '4px 0 4px 4px',
    textTransform: 'uppercase',
    maxWidth: '5vw',
    whiteSpace: 'pre-wrap',
    // overflow: 'hidden',
  },

  table_cell_row: {
    padding: '4px 0',
    textTransform: 'capitalize',
  },

  empty_table_cell_row: {
    padding: '4px 0',
    width: '14px',
  },

  delete_cell_row: {
    cursor: 'pointer',
    padding: '4px 0',
  },

  table_cell_info: {
    padding: '4px 0',
    textTransform: 'capitalize',
    cursor: 'help',
  },

  table_row_selected: {
    background: '#375a5a',
    boxShadow: '0px 0px 5px 0px rgb(180 180 180 / 78%)',
    '&:hover': {
      backgroundColor: '#367c7c !important',
    },
  },

  row_actions_icon: {
    margin: '0 2px',
    zIndex: 200,

    '&:hover': {
      background: '#367c7c',
      borderRadius: '20%',
      boxShadow: '0px 0px 5px 0px rgb(180 180 180 / 78%)',
    },
  },

  row_reassign_icon: {
    margin: '0 2px',
    width: '16px',
    height: '16px',
  },

  table_cell_color: {
    padding: '6px',
  },

  confirm_action_fabs: {
    height: '26px',
    width: '26px',
    minHeight: '26px',
    minWidth: '26px',
    marginLeft: '8px',
    backgroundColor: theme.palette.primary.main,

    '&:hover': {
      background: `${theme.palette.complementary.turquoiseMedium} !important`,
      boxShadow: '0px 0px 5px 0px #d7c2c2',
    },
  },

  save_action_fabs: {
    height: '26px',
    width: '26px',
    minHeight: '26px',
    minWidth: '26px',
    marginLeft: '8px',
    backgroundColor: theme.palette.complementary.turquoise,
    '&:hover': {
      backgroundColor: theme.palette.complementary.turquoiseDark,
      boxShadow: '0px 0px 5px 0px #d7c2c2',
    },
  },

  discard_action_fabs: {
    height: '26px',
    width: '26px',
    minHeight: '26px',
    minWidth: '26px',
    marginLeft: '8px',
    backgroundColor: 'rgb(109, 109, 109)',
    '&:hover': {
      backgroundColor: theme.palette.complementary.turquoiseDark,
      boxShadow: '0px 0px 5px 0px #d7c2c2',
    },
  },

  table_placeholder: {
    fontSize: '10px',
    fontStyle: 'italic',
    color: 'gray',
    padding: '14px 10px 10px',
    borderBottom: 'unset',
  },

  table_empty_row: {
    height: '32px',
  },

  table_empty_match_row: {
    height: '32px',
  },

  table_index_head_row: {
    height: '26px',
    background: theme.palette.complementary.mainLight,
    cursor: 'help',
    padding: '0',
    '&:hover': {
      boxShadow: '0px 0px 5px 0px rgb(180 180 180 / 78%)',
    },
  },

  table_empty_row_text: {
    fontSize: theme.fonts.responsiveMedium,
    color: 'gray',
    padding: '4px',
    fontStyle: 'italic',
  },

  table_empty_drop_text: {
    fontSize: theme.fonts.responsiveMedium,
    color: 'white',
    padding: '4px 0',
  },

  drop_container: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    background: theme.palette.complementary.mainLight,
  },

  drop_box_container: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    background: theme.palette.complementary.turquoiseDark,
  },

  table_index_head_title: {
    fontSize: theme.fonts.responsiveMediumBig,
    color: 'white',
    fontWeight: 'bold',
    marginLeft: '4px',
  },

  tooltip_text: {
    letterSpacing: '0.1rem',
    fontSize: theme.fonts.responsiveMedium,
  },

  icons_container: {
    display: 'flex',
    alignItems: 'center',
  },
}));
