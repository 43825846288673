import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import { qualityColor } from '../../../../../helpers/qualityProjection';
import { useQualityTranslation } from '../../../../../helpers/hooks/useQualityTranslation';
import * as actions from '../../../../../store/index';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  ClickAwayListener,
  Divider,
  Fab,
  Grid,
  IconButton,
  SvgIcon,
  Tooltip,
  Typography,
  Zoom,
} from '@material-ui/core';
import SummaryParagraphHeader from '../SummaryParagraphHeader';
import SummaryParagraphSubHeader from '../SummaryParagraphSubHeader';
import Rating from '@material-ui/lab/Rating';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ReactComponent as QualityIcon } from '../../../../../resources/icons/b_quality_meter.svg';
import EditIcon from '@material-ui/icons/Edit';
import { getCurrentModuleStatus, summaryModuleStatus } from '../SummaryCommon/SummaryCommon';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import TooltipBadgeFab from '../../DanaiView/Common/DiagnoseViewCommon';
import ReviewSelect from '../../CardActions/ReviewSelect/ReviewSelect';
import {
  DIAGNOSTICS_MICROCALC,
  DIAGNOSTICS_OPACITIES,
  PREMIUM_DATA_ANNOTATION,
  PREMIUM_DENSITY,
} from '../../../../../config';

const useStyles = makeStyles((theme) => ({
  projection_grid_container: {
    display: 'flex',
    overflow: 'hidden',
    height: '50%',
  },

  projection_text: {
    fontSize: '0.6rem',
    minWidth: '35px',
    color: '#c2c2c2',
    padding: '4%',
    width: '45px',
  },

  proj_left: {
    marginLeft: '30%',
  },

  proj_right: {
    marginLeft: '30%',
  },

  summary_edit_fabs: {
    height: '26px',
    width: '26px',
    minWidth: '26px',
    minHeight: '26px',
    borderRadius: '50%',

    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: '0px 0px 5px 0px #d7c2c2',
    },
  },

  quality_fabs: {
    height: '26px',
    width: '26px',
    minWidth: '26px',
    minHeight: '26px',
    margin: '4px',
    cursor: 'help',
  },

  quality_module_annotator_fabs: {
    height: '26px',
    width: '26px',
    minWidth: '26px',
    minHeight: '26px',
    margin: '4px',
    '&:hover': {
      background: `${theme.palette.complementary.turquoise} !important`,
      boxShadow: '0px 0px 5px 0px #d7c2c2',
    },
  },

  tooltip_text: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    fontSize: '12px',
  },

  reviewer_badge: {
    height: '14px',
    minHeight: '14px',
    fontSize: theme.fonts.responsiveSmall,
    marginBottom: '2px',
    minWidth: '70px',
  },

  reviewer_title_shape: {
    marginLeft: '10px',
  },

  module_fab_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '4px',
  },

  card_main_container: {
    margin: '0',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  tooltipText: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    fontSize: theme.fonts.responsiveMedium,
  },
}));

export default function QualitySummary(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const anchorQualityRef = useRef();
  const annotatorRole = PREMIUM_DATA_ANNOTATION && localStorage.getItem('annotator') > 0;

  const darkState = useSelector((store) => store.gui.darkState);
  const { tabsIndex, zoomedProjection, landscape } = useSelector((state) => state.gui);
  const { pgmi_right, pgmi_left, stars_right, stars_left } = props?.results?.exam || {};
  const { applied_quality, confirmed_quality, shown_quality } = useSelector(
    (state) => state.results
  );

  const isConfirmed = applied_quality === 'bbox' && confirmed_quality;
  const isRelabeled = confirmed_quality && applied_quality !== 'bbox';
  const shownQuality = shown_quality || applied_quality;

  const [reviewerTooltip, setReviewerTooltip] = useState(false);
  const [qualityPopperOpen, setQualityPopperOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // Effects
  useEffect(() => {
    setQualityPopperOpen(false);
  }, []);
  const handlePopperToggle = () => {
    setReviewerTooltip(false);
    setQualityPopperOpen((prevOpen) => !prevOpen);
  };

  const handlePopperClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setQualityPopperOpen(false);
  };

  const clickAwayHandler = () => {
    setReviewerTooltip(false);
    setQualityPopperOpen(false);
    setAnchorEl(null);
  };

  const projectionsGridItems = [
    {
      label: t('Dashboard.rcc'),
      key: 'rcc',
      borderRight: true,
      borderBottom: true,
    },
    {
      label: t('Dashboard.lcc'),
      key: 'lcc',
      borderRight: false,
      borderBottom: true,
    },
    {
      label: t('Dashboard.rmlo'),
      key: 'rmlo',
      borderRight: true,
      borderBottom: false,
    },
    {
      label: t('Dashboard.lmlo'),
      key: 'lmlo',
      borderRight: false,
      borderBottom: false,
    },
  ];

  const QualityCustomChip = ({ result, height }) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Chip
          variant={result ? 'default' : 'outlined'}
          label={
            useQualityTranslation(result, t)?.toUpperCase() ?? t('ResultView.is_not_evaluated')
          }
          style={{
            cursor: 'pointer',
            height: height || '20px',
            minWidth: '70%',
            maxWidth: '95%',
            background: result ? qualityColor(result, theme) : 'transparent',
            color: result ? theme.palette.texts.white : theme.palette.projections.not_evaluated,
            fontSize: theme.fonts.responsiveMedium,
          }}
        />
      </div>
    );
  };

  const handleEditButtonClick = () => {
    dispatch(actions.setTabsIndex(2));
    dispatch(actions.setQualityInRevisionMode(true));
  };

  const handleTitleClick = () => {
    dispatch(actions.setTabsIndex(1));
  };

  const onConfirmClick = () => {
    const state = shown_quality === applied_quality && confirmed_quality;
    dispatch(actions.confirmEvaluation(shownQuality, 'quality', !state));
  };

  const generalQualityChip = () => {
    const projection =
      props?.results?.rcc || props?.results?.lcc || props?.results?.rmlo || props?.results?.lmlo;

    if (projection?.study_quality) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 15% 0 6%',
          }}
        >
          <Typography
            className={classes.projection_text}
            style={{
              marginRight: '7%',
            }}
          >
            {t('ResultView.general_quality')}
          </Typography>
          <QualityCustomChip result={projection.study_quality} height='22px' />
        </div>
      );
    }
    return null;
  };

  return (
    <Card
      className={classes.card_main_container}
      style={{
        width:
          (props.allPremiumShown || props.diagnosticsShown) && PREMIUM_DENSITY ? '65%' : '100%',
        height: !props.allPremiumShown && '100%',
      }}
    >
      <CardHeader
        style={{ padding: '0', background: darkState ? '#535353' : '#b9b9b9' }}
        title={
          <div
            style={{
              display: 'flex',
              width: '100%',
              padding: '0px 8px 0 16px',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <SummaryParagraphHeader
                header={t('b_box.b_quality')}
                clickable={true}
                onClick={() => handleTitleClick()}
                tootlipTitle={t('ResultView.view_more_quality')}
              />
              <ClickAwayListener
                onClickAway={() => clickAwayHandler('quality')}
                mouseEvent='onClick'
              >
                <div className={classes.reviewer_title_shape}>
                  <TooltipBadgeFab
                    content={
                      <>
                        <Typography className={classes.tooltip_text} color='inherit'>
                          {t('ResultView.current_results_shown')}: {shownQuality}
                        </Typography>
                        <Divider />
                        <Typography className={classes.tooltip_text} color='inherit'>
                          {t('ResultView.current_applied')}: {applied_quality}
                        </Typography>
                        <p>{t('ResultView.change_reviewer')}</p>
                      </>
                    }
                    open={reviewerTooltip}
                    badgeContent={shownQuality}
                    onClick={() => handlePopperToggle('quality')}
                    onMouseEnter={() => setReviewerTooltip(true)}
                    onMouseLeave={() => setReviewerTooltip(false)}
                    popperContent={
                      <ReviewSelect type='quality' setQualityPopperOpen={setQualityPopperOpen} />
                    }
                    anchorRef={anchorQualityRef}
                    popperOpen={qualityPopperOpen}
                    classes={classes}
                    theme={theme}
                    t={t}
                  />
                </div>
              </ClickAwayListener>
            </div>

            {annotatorRole && (
              <Tooltip
                arrow
                placement='left-end'
                title={
                  <Typography className={classes.tooltip_text} color='inherit'>
                    {t('ResultView.tooltip_revise')}
                  </Typography>
                }
              >
                <IconButton
                  size='small'
                  onClick={() => handleEditButtonClick()}
                  className={classes.summary_edit_fabs}
                >
                  <EditIcon
                    fontSize='medium'
                    style={{
                      width: '18px',
                      height: '18px',
                      color: 'white',
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>
        }
      />

      <CardContent style={{ padding: '0' }}>
        {generalQualityChip()}
        <Grid
          container
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '22px',
            height: !landscape
              ? '6vh'
              : (DIAGNOSTICS_MICROCALC || DIAGNOSTICS_OPACITIES) &&
                localStorage.getItem('role') !== 'technician'
              ? '9vh'
              : '11vh',
            cursor: 'pointer',
          }}
          onClick={() => annotatorRole && handleTitleClick()}
        >
          {zoomedProjection ? (
            <Grid item xs={10} className={classes.projection_grid_container}>
              <Typography className={classes.projection_text}>
                {zoomedProjection.toUpperCase()}
              </Typography>
              <QualityCustomChip result={props?.results?.[zoomedProjection]?.quality} />
            </Grid>
          ) : (
            projectionsGridItems.map((item) => (
              <Grid
                item
                xs={5}
                key={item.key}
                className={classes.projection_grid_container}
                style={{
                  borderRight: item.borderRight ? '1px solid rgba(255, 255, 255, 0.08)' : 'none',
                  borderBottom: item.borderBottom ? '1px solid rgba(255, 255, 255, 0.08)' : 'none',
                }}
              >
                <Typography className={classes.projection_text}>{item.label}</Typography>
                <QualityCustomChip result={props?.results?.[item.key]?.quality} />
              </Grid>
            ))
          )}
        </Grid>
      </CardContent>
      <CardActions className={classes.module_fab_container}>
        <Tooltip
          title={
            <>
              <Typography className={classes.tooltipText} style={{ fontWeight: 'bold' }}>
                {
                  getCurrentModuleStatus(
                    applied_quality,
                    shownQuality,
                    isConfirmed,
                    isRelabeled,
                    t('ResultView.quality'),
                    theme,
                    t
                  ).currentApplied
                }
              </Typography>
              {annotatorRole && (
                <>
                  <Divider style={{ margin: '3% 0' }} />
                  <Typography className={classes.tooltipText}>
                    {
                      getCurrentModuleStatus(
                        applied_quality,
                        shownQuality,
                        isConfirmed,
                        isRelabeled,
                        t('ResultView.quality'),
                        theme,
                        t
                      ).tooltipAction
                    }
                  </Typography>
                </>
              )}
            </>
          }
          arrow
          placement='top-start'
        >
          <span>
            <Fab
              size='small'
              disabled={!annotatorRole}
              onClick={() => onConfirmClick()}
              color='primary'
              className={
                annotatorRole ? classes.quality_module_annotator_fabs : classes.quality_fabs
              }
              style={{
                color: '#fff',
                background: `${
                  getCurrentModuleStatus(
                    applied_quality,
                    shownQuality,
                    isConfirmed,
                    isRelabeled,
                    t('ResultView.quality'),
                    theme,
                    t
                  ).color
                }`,
              }}
            >
              {
                getCurrentModuleStatus(
                  applied_quality,
                  shownQuality,
                  isConfirmed,
                  isRelabeled,
                  t('ResultView.quality'),
                  theme,
                  t
                ).fabIcon
              }
            </Fab>
          </span>
        </Tooltip>
      </CardActions>
    </Card>
  );
}
