import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  title_red_sub_paragraph: {
    textTransform: 'uppercase',
    color: '#e76666',
    whiteSpace: 'nowrap',
    letterSpacing: '0.1rem',
    fontSize: theme.fonts.responsiveMediumBig,
    paddingTop: '4px',
  },

  title_dark_sub_paragraph: {
    textTransform: 'uppercase',
    color: '#b21818',
    whiteSpace: 'nowrap',
    letterSpacing: '0.1rem',
    fontSize: theme.fonts.responsiveMediumBig,
    paddingTop: '2px',
  },
}));

export default function SummaryParagraph(props) {
  const classes = useStyles();
  const breasts = props.subHeader === 'right' || props.subHeader === 'left';
  const darkState = useSelector((store) => store.gui.darkState);

  return (
    <>
      <Typography
        className={darkState ? classes.title_red_sub_paragraph : classes.title_dark_sub_paragraph}
        style={{
          color: props.color,
        }}
      >
        {props.subHeader}
      </Typography>
    </>
  );
}
