import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import SummaryParagraphSubHeader from '../SummaryParagraphSubHeader';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    marginRight: '1vw',
    whiteSpace: 'nowrap',
  },

  paragraph: {
    letterSpacing: '0.1rem',
    color: theme.palette.texts.primary,
    fontSize: theme.fonts.responsiveBig,
  },
}));

export default function PatientDevice(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <SummaryParagraphSubHeader subHeader={t('ResultView.device')} />
      <p
        className={classes.paragraph}
        style={{
          ...props.style,
          maxWidth: '98%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {props.result}
      </p>
    </>
  );
}
