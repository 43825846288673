import { updateNoTypeRectangles } from '../../../../../../store';
import {
  deleteFinding,
  getMatchedProj,
} from '../../../DanaiView/Diagnostic/Helpers/DiagnosticRevisionTableCommon';
import {
  handleMatchedLesion,
  removeLesionAndUpdateIndices,
} from '../../../DanaiView/Diagnostic/Helpers/DiagnosticRevisionTableHelpers';

// TODO: FINISH MIGRATION OF FUNCTIONS
export const handleDeleteButtonClick = (
  contextMenu,
  setContextMenu,
  customResults,
  noTypeRectangles,
  dispatch,
  actions
) => {
  const { rectangleType, rectangleProjection, rectangleClass, rectangleId } = contextMenu;

  if (!rectangleType) {
    const updatedNoTypeRectangles = noTypeRectangles?.[rectangleProjection]?.filter(
      (item) => item.id !== rectangleId
    );
    dispatch(updateNoTypeRectangles(rectangleProjection, updatedNoTypeRectangles));
  } else {
    const boxIndex = Number(contextMenu.rectangleId.slice(0, 1));
    const lesionInfo = [rectangleProjection, rectangleClass, boxIndex];
    deleteFinding(rectangleType, lesionInfo, customResults, dispatch, actions);
  }
  setContextMenu(null);
};

export const handleReassignButtonClick = (
  newSelectedBoxType,
  newSelectedBoxClass,
  contextMenu,
  resultFindings,
  customMicrocalc,
  customOpacities,
  noTypeRectangles,
  setContextMenu,
  setSelectedOption,
  setSelectedNewBox,
  dispatch,
  actions
) => {
  const boxIndex = Number(contextMenu.rectangleId.slice(0, 1));
  const matchingProjection = getMatchedProj(contextMenu.rectangleProjection);
  const proj_name = contextMenu.rectangleProjection;

  let boxToMove =
    resultFindings?.[contextMenu.rectangleProjection]?.[contextMenu.rectangleClass]?.[boxIndex];

  if (boxToMove) {
    boxToMove = {
      ...boxToMove,
      score: 1,
      reassigned: {
        prevClass: contextMenu.rectangleClass,
        prevType: contextMenu.rectangleType,
      },
    };
  }

  // New rectangle
  if (!contextMenu.rectangleType) {
    const boxToAdd = noTypeRectangles?.[proj_name]?.find(
      (rectangle) => rectangle.id === contextMenu.rectangleId
    );

    const adaptedBoxToAdd = {
      box: [boxToAdd.box[0], boxToAdd.box[1], boxToAdd.box[2], boxToAdd.box[3]],
      score: 1,
      assigned: true,
    };

    if (newSelectedBoxType === 'microcalc') {
      if (adaptedBoxToAdd) {
        const newMicrocalcState = {
          ...customMicrocalc,
          [contextMenu.rectangleProjection]: {
            ...customMicrocalc[contextMenu.rectangleProjection],
            [newSelectedBoxClass]: customMicrocalc[contextMenu.rectangleProjection]?.[
              newSelectedBoxClass
            ]
              ? [
                  { ...adaptedBoxToAdd },
                  ...customMicrocalc[contextMenu.rectangleProjection][newSelectedBoxClass],
                ]
              : [{ ...adaptedBoxToAdd }],
          },
        };

        const updatedNoTypeRectangles = noTypeRectangles?.[proj_name]?.filter(
          (rectangle) => rectangle.id !== contextMenu.rectangleId
        );
        dispatch(actions.setCustomMicrocalc(newMicrocalcState));
        dispatch(actions.setMicrocalcLocalChanges(true));
        dispatch(updateNoTypeRectangles(proj_name, updatedNoTypeRectangles));
      } else return;
    } else {
      if (adaptedBoxToAdd) {
        const newOpacityState = {
          ...customOpacities,
          [contextMenu.rectangleProjection]: {
            ...customOpacities[contextMenu.rectangleProjection],
            [newSelectedBoxClass]: customOpacities[contextMenu.rectangleProjection]?.[
              newSelectedBoxClass
            ]
              ? [
                  ...customOpacities[contextMenu.rectangleProjection][newSelectedBoxClass],
                  { ...adaptedBoxToAdd },
                ]
              : [{ ...adaptedBoxToAdd }],
          },
        };

        const updatedNoTypeRectangles = noTypeRectangles?.[proj_name]?.filter(
          (rectangle) => rectangle.id !== contextMenu.rectangleId
        );

        dispatch(actions.setCustomOpacities(newOpacityState));
        dispatch(actions.setOpacitiesLocalChanges(true));
        dispatch(updateNoTypeRectangles(proj_name, updatedNoTypeRectangles));
      } else return;
    }
  }
  // Microcalc rectangle
  else if (contextMenu.rectangleType === 'microcalc') {
    const prevTypeBoxesUpdated = resultFindings?.[contextMenu.rectangleProjection]?.[
      contextMenu.rectangleClass
    ].filter((_, index) => index !== boxIndex);
    if (newSelectedBoxType === 'microcalc') {
      const newMicrocalcState = {
        ...customMicrocalc,
        [contextMenu.rectangleProjection]: {
          ...customMicrocalc[contextMenu.rectangleProjection],
          [newSelectedBoxClass]: customMicrocalc[contextMenu.rectangleProjection]?.[
            newSelectedBoxClass
          ]
            ? [
                { ...boxToMove },
                ...customMicrocalc[contextMenu.rectangleProjection][newSelectedBoxClass],
              ]
            : [{ ...boxToMove }],
          [contextMenu.rectangleClass]:
            prevTypeBoxesUpdated.length === 0 ? null : prevTypeBoxesUpdated,
        },
      };
      dispatch(actions.setCustomMicrocalc(newMicrocalcState));
      dispatch(actions.setMicrocalcLocalChanges(true));
    } else {
      const newOpacitiesState = {
        ...customOpacities,
        [contextMenu.rectangleProjection]: {
          ...customOpacities[contextMenu.rectangleProjection],
          [newSelectedBoxClass]: customOpacities[contextMenu.rectangleProjection]?.[
            newSelectedBoxClass
          ]
            ? [
                { ...boxToMove },
                ...customOpacities[contextMenu.rectangleProjection][newSelectedBoxClass],
              ]
            : [{ ...boxToMove }],
        },
      };
      const prevMicrocalcState = {
        ...customMicrocalc,
        [contextMenu.rectangleProjection]: {
          ...customMicrocalc[contextMenu.rectangleProjection],
          [contextMenu.rectangleClass]: prevTypeBoxesUpdated,
        },
      };
      dispatch(actions.setCustomOpacities(newOpacitiesState));
      dispatch(actions.setCustomMicrocalc(prevMicrocalcState));
      dispatch(actions.setMicrocalcLocalChanges(true));
      dispatch(actions.setOpacitiesLocalChanges(true));
    }
  }
  // Opacity rectangle
  else if (contextMenu.rectangleType === 'opacities') {
    const boxIndex = Number(contextMenu.rectangleId.slice(0, 1));

    if (newSelectedBoxType === 'opacities') {
      // Change matching class and index in previous matched projection
      if (contextMenu.matchBox) {
        const [matchClass, matchIndex] = contextMenu.matchBox;
        const matchedLesionCopy = customOpacities[matchingProjection][matchClass][matchIndex];
        // Upscale/downscale selected lesion to new class
        let newState = JSON.parse(JSON.stringify(customOpacities));
        if (!newState[contextMenu.rectangleProjection][newSelectedBoxClass]) {
          newState[contextMenu.rectangleProjection][newSelectedBoxClass] = [];
        }

        newState[contextMenu.rectangleProjection][newSelectedBoxClass].push(boxToMove);

        const newSelectedLesionIndex =
          newState[contextMenu.rectangleProjection][newSelectedBoxClass]?.length - 1 || 0;

        removeLesionAndUpdateIndices(
          newState,
          contextMenu.rectangleProjection,
          contextMenu.rectangleClass,
          contextMenu.rectangleId[0]
        );

        if (!newState[matchingProjection][newSelectedBoxClass]) {
          newState[matchingProjection][newSelectedBoxClass] = [];
        }

        newState[matchingProjection][newSelectedBoxClass].push(matchedLesionCopy);

        const matchLesionIndex = newState[matchingProjection][newSelectedBoxClass]?.length - 1 || 0;

        removeLesionAndUpdateIndices(newState, matchingProjection, matchClass, matchIndex);

        // Update indexes
        newState[matchingProjection][newSelectedBoxClass][matchLesionIndex] = {
          ...newState[matchingProjection][newSelectedBoxClass][matchLesionIndex],
          match: [newSelectedBoxClass, newSelectedLesionIndex],
          predicted_class: matchClass,
        };

        newState[contextMenu.rectangleProjection][newSelectedBoxClass][newSelectedLesionIndex] = {
          ...newState[contextMenu.rectangleProjection][newSelectedBoxClass][newSelectedLesionIndex],
          match: [newSelectedBoxClass, matchLesionIndex],
        };

        dispatch(actions.setCustomOpacities(newState));
        dispatch(actions.setOpacitiesLocalChanges(true));
      } else {
        let newState = JSON.parse(JSON.stringify(customOpacities));
        if (!newState[contextMenu.rectangleProjection]) {
          newState[contextMenu.rectangleProjection] = {};
        }

        if (!Array.isArray(newState[contextMenu.rectangleProjection][newSelectedBoxClass])) {
          newState[contextMenu.rectangleProjection][newSelectedBoxClass] = [];
        }
        newState[contextMenu.rectangleProjection][newSelectedBoxClass].push(boxToMove);

        removeLesionAndUpdateIndices(
          newState,
          contextMenu.rectangleProjection,
          contextMenu.rectangleClass,
          contextMenu.rectangleId[0]
        );

        dispatch(actions.setCustomOpacities(newState));
        dispatch(actions.setOpacitiesLocalChanges(true));
      }
    } else {
      let newState = JSON.parse(JSON.stringify(customOpacities));

      // Update Microcalc with new lesion
      if (contextMenu.matchBox) {
        boxToMove = {
          ...boxToMove,
          match: null,
        };
        const selectedLesion = [contextMenu.rectangleClass, contextMenu.rectangleId[0]];
        const prevClass =
          customOpacities[matchingProjection][contextMenu.matchBox?.[0]][contextMenu.matchBox?.[1]]
            ?.predicted_class;

        const lesionInfo =
          customOpacities[matchingProjection][contextMenu.matchBox?.[0]][contextMenu.matchBox?.[1]];

        handleMatchedLesion(
          newState,
          selectedLesion,
          matchingProjection,
          contextMenu.rectangleProjection
        );
        removeLesionAndUpdateIndices(
          newState,
          contextMenu.rectangleProjection,
          contextMenu.rectangleClass,
          contextMenu.rectangleId[0]
        );
      } else {
        removeLesionAndUpdateIndices(
          newState,
          contextMenu.rectangleProjection,
          contextMenu.rectangleClass,
          contextMenu.rectangleId[0]
        );
      }

      let updatedMicrocalcState = {
        ...customMicrocalc,
        [contextMenu.rectangleProjection]: {
          ...customMicrocalc[contextMenu.rectangleProjection],
          [newSelectedBoxClass]: customMicrocalc[contextMenu.rectangleProjection]?.[
            newSelectedBoxClass
          ]
            ? [
                { ...boxToMove },
                ...customMicrocalc[contextMenu.rectangleProjection][newSelectedBoxClass],
              ]
            : [{ ...boxToMove }],
        },
      };

      dispatch(actions.setCustomMicrocalc(updatedMicrocalcState));
      dispatch(actions.setCustomOpacities(newState));
      dispatch(actions.setMicrocalcLocalChanges(true));
      dispatch(actions.setOpacitiesLocalChanges(true));
    }
  }

  setContextMenu(null);
  setSelectedOption({});
  setSelectedNewBox({
    proj: contextMenu.rectangleProjection,
    boxtype: newSelectedBoxType,
    boxclass: newSelectedBoxClass,
    box: boxToMove,
  });
};
