import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  form_container: {
    background: '#b3b3b329',
  },

  draggable_title_container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    padding: '6px',
    alignItems: 'center',
    background: '#fff7f73d',
    minHeight: '100px',
  },

  patient_container: {
    marginLeft: '10px',
  },

  header_right_container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  density_container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: '30px',
  },

  form_helper: {
    fontStyle: 'italic',
    fontSize: '0.6rem',
    margin: '6px',
    padding: '0 20px',
  },

  question_container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: '12px 4px',

    '&:nth-of-type(odd)': {
      background: 'lightgrey',
    },

    '&:nth-of-type(even)': {
      background: '#aaaaaa',
    },

    '&:last-of-type': {
      marginBottom: '26px',
    },
  },

  question_container_multiple: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  question_title_container: {
    width: '40%',
  },

  question_title_list: {},

  question_title: {
    color: '#272727',
    margin: '6px',
    fontWeight: '600',
    fontSize: theme.fonts.responsiveMediumBig,
  },

  answer_container: {
    width: '50%',
    display: 'flex',
    justifyContent: ' center',
    marginLeft: '10px',
    flexDirection: 'column',
  },

  answer_radio: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },

  radio_button_circle: {
    transform: 'scale(1.4)',
    marginRight: 10,
    cursor: 'pointer',
  },

  risk_answers_container: {
    display: 'flex',
    justifyContent: ' center',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },

  answer_container_list: {
    width: '100%',
    display: 'flex',
    justifyContent: ' flex-start',
  },

  answer_container_multiple_list: {
    width: '100%',
    display: 'grid !important',
    gridTemplateColumns: 'repeat(2, 1fr) !important',
    marginLeft: '20px',
  },

  form_input: {
    width: '85%',
    margin: '5px 10px 5px 0px',
    color: 'black',
    '& .MuiFilledInput-inputMarginDense': {
      padding: '6px 12px',
    },
  },

  form_input_family_select: {
    backgroundColor: '#e2e2e2',
    borderRadius: '5px',
    marginRight: '10px',
  },

  form_input_family: {
    margin: '5px 10px 5px 0px',
    backgroundColor: 'rgb(147, 147, 147)',
    borderRadius: '5px',
    color: 'black',
    '& .MuiFilledInput-inputMarginDense': {
      padding: '6px 12px',
    },
  },

  form_input_small: {
    backgroundColor: 'rgb(147, 147, 147)',
    borderRadius: '5px',
    width: '45%',
    margin: '5px 10px 5px 0px',
    color: 'black',
    '& .MuiFilledInput-inputMarginDense': {
      padding: '6px 12px',
    },
  },

  radio_group_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  radio_group: {
    display: 'flex',
    flexDirection: 'row !important',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },

  radio_buttons: {
    marginRight: '2rem',
    color: '#272727',
    fontSize: theme.fonts.responsiveMediumBig,
  },

  question_helper_container: {
    display: 'flex',
  },

  question_helper: {
    color: '#272727',
    fontWeight: '600',
    fontSize: theme.fonts.responsiveMediumBig,
    marginRight: '6px',
  },

  date_picker: {
    minWidth: '12rem',
  },

  form_control: {
    width: '5rem',
    marginBottom: '10px',
  },

  radio_label: {
    color: '#272727',
    width: 'max-content',
    minWidth: '100px',
    marginRight: '6px',
  },

  checkbox_label: {
    color: '#272727',
    display: 'flex',
    alignItems: 'center',
  },

  checkbox_label_option_container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  checkbox_label_option: {
    color: '#272727',
    paddingLeft: '30px',
  },

  checkbox_container: {
    display: 'grid !important',
    gridTemplateColumns: 'repeat(2, 1fr) !important',
    width: '100%',
    marginLeft: '20px',
  },

  checkbox_options: {
    display: 'flex',
    flexDirection: 'column',
  },

  text_with_options: {
    color: '#272727',
    paddingLeft: '32px',
    marginRight: '10px',
  },

  text_with_multiple_options: {
    color: '#272727',
    marginRight: '10px',
    fontSize: theme.fonts.responsiveMediumBig,
  },

  error_msg: {
    color: theme.palette.complementary.mainActions,
    marginRight: '300px',
    fontStyle: 'italic',
  },

  multiple_answers: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    margin: '8px',
    flexDirection: 'column',
  },

  checkbox_multiple_options: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: ' 0.5px solid #bbbbbb',
    padding: '6px',
  },

  selectLabel: {
    color: '#272727',
    fontSize: theme.fonts.responsiveMediumBig,
    height: '35px',
    borderRadius: '5px',
  },

  selectLabelTexts: {
    color: '#272727',
    fontSize: theme.fonts.responsiveMediumBig,
    borderRadius: '5px',
  },

  select_options: {
    color: '#272727',
    fontSize: theme.fonts.responsiveMediumBig,
  },

  risk_dialog_container: { background: '#b3b3b329' },

  content_container: { background: '#aaaaaa' },

  form_container: { background: '#b3b3b329' },

  draggable_risk_container: {
    display: 'flex',
    justifyContent: 'space-between',
    background: '#cccccc',
    flexDirection: 'column',
    marginBottom: '10px',
  },
  average_results_text: {
    color: '#3a3a3ade',
    fontWeight: '100',
    fontStyle: 'italic',
    fontSize: 'small',
    margin: '20px',
  },

  InfoIcon: {
    color: '#6c6c6c',
    height: '30px',
  },

  draggable_risk_info: {
    display: 'flex',
    justifyContent: 'space-between',
    background: '#cccccc',
    padding: '10px',
    alignItems: 'center',
  },

  patient_risk_title: {
    color: '#1e1e1ede',
    fontSize: 'x-large',
    fontWeight: '800',
  },

  lifetime_risk_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '20px',
  },

  title_container: {
    display: 'flex',
    marginTop: '10px',
    background: '#424242',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  risk_options_container: {
    display: 'flex',
    justifyContent: 'space-between',
    '&:nth-of-type(odd)': {
      background: 'lightgrey',
    },

    '&:nth-of-type(even)': {
      background: '#aaaaaa',
    },
  },

  patient_info: {
    marginLeft: '20px',
  },

  risk_results_title: {
    marginLeft: '20px',
    fontSize: '30px',
    fontWeight: '600',
  },

  risk_info: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  remember_container: {
    padding: '10px',
  },

  questions_container: {
    width: '95%',
  },

  questions_extra_container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '14px',
    paddingTop: '20px',
  },

  recomendations_container: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    background: '#cccccc',
    flexDirection: 'column',
    marginBottom: '10px',
    borderLeft: '4px solid #F44336',
  },

  answers_container: {
    display: 'flex',
    justifyContent: 'space-between',
    background: '#7e7e7e',
    flexDirection: 'column',
  },

  remember_text: {
    color: '#272727',
    margin: '6px',
    fontSize: '14px',
  },

  risk_title_text: {
    color: '#272727',
    margin: '6px',
    fontWeight: '400',
  },

  risk_text: {
    color: '#272727',
    margin: '6px',
    fontWeight: '600',
  },

  risk_text_answers: {
    color: '#272727',
    margin: '6px 26px 6px 6px',
    fontWeight: '400',
  },

  patient_relative_risk: {
    marginLeft: '20px',
    fontWeight: 'bold',
    fontSize: theme.typography.h4.fontSize,
  },

  patient_risk_high: {
    color: '#DD302F',
  },

  patient_risk_moderate: {
    color: '#f3ab06',
  },

  patient_risk_low: {
    color: '#4CAF50',
  },
  recommendations: {
    display: 'flex',
  },

  recomendation_text: {
    color: '#343434',
  },

  add_more_fab: {
    width: '20px',
    height: '20px',
    minHeight: '20px',
    minWidth: '20px',
  },

  family_container: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '20px',
    width: '40%',
    flexWrap: 'wrap',
  },

  required_msg_container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  required_msg: {
    color: '#a0a0a0',
    fontSize: theme.fonts.responsiveMedium,
    fontStyle: 'italic',
  },
}));
