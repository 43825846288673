import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Tooltip, MenuList, MenuItem, IconButton, FormControl } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Check, Delete } from '@material-ui/icons';
import {
  deleteEvaluation,
  confirmEvaluation,
  setActionToConfirm,
  setShownQuality,
  setShownDensity,
  setShownMicrocalc,
  setShownOpacities,
} from '../../../../../store';
import { PREMIUM_DATA_ANNOTATION } from '../../../../../config';

const useStyles = makeStyles((theme) => {
  return {
    menu_list_item: {
      display: 'grid',
      gridTemplateColumns: '2fr 1fr 1fr ',
      padding: '1px',
      borderRadius: '5px',
      '&:hover': {
        background: theme.palette.primary.main,
      },
    },

    list_item_text: {
      display: 'flex',
      alignItems: 'center',
      padding: '2px',
      margin: '2px 6px',
      borderRadius: '5px',
      fontSize: theme.fonts.responsiveMedium,
    },
  };
});

export default function ReviewSelect(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const ref = useRef(null);
  const results = useSelector((state) => state.results);
  const { type } = props;
  const annotatorRole = PREMIUM_DATA_ANNOTATION && localStorage.getItem('annotator') > 0;

  // Handlers
  const onDelete = (created_by) => {
    dispatch(
      setActionToConfirm({
        action: () => dispatch(deleteEvaluation(type, created_by, t)),
        text: `${t('revision.delete_eval', {
          user: `${created_by}`,
        })}`,
      })
    );
    handlePopperClose(type);
  };

  const onConfirmReviewer = (created_by) => {
    const state = !results[`confirmed_${type}`] || created_by !== results[`applied_${type}`];
    dispatch(confirmEvaluation(created_by, type, state));
    handlePopperClose(type);
  };

  const handlePopperClose = (currentModule) => {
    if (currentModule === 'quality') props.setQualityPopperOpen(false);
    else if (currentModule === 'density') props.setDensityPopperOpen(false);
    else if (currentModule === 'microcalc') props.setMicrocalcPopperOpen(false);
    else if (currentModule === 'opacities') props.setOpacitiesPopperOpen(false);
  };

  const checkTooltip = (created_by) => {
    const confirmedType = results[`confirmed_${type}`];
    const appliedType = results[`applied_${type}`];

    if (confirmedType === created_by) {
      if (!confirmedType) return t('ResultView.label_accept_icon');
      else if (appliedType === confirmedType) return t('ResultView.label_discard_icon');
      else return t('ResultView.label_accept_icon');
    } else if (!confirmedType) {
      return t('ResultView.label_accept_icon');
    } else if (appliedType !== confirmedType) {
      return t('ResultView.label_discard_icon');
    } else {
      return t('ResultView.label_accept_icon');
    }
  };

  const changeShownResults = (created_by, currentModule, close = false) => {
    if (close) {
      handlePopperClose(type);
    }
    if (currentModule === 'quality') dispatch(setShownQuality(created_by));
    else if (currentModule === 'density') dispatch(setShownDensity(created_by));
    else if (currentModule === 'microcalc') dispatch(setShownMicrocalc(created_by));
    else if (currentModule === 'opacities') dispatch(setShownOpacities(created_by));
  };

  if (!results || !results.evaluations || !results.evaluations[type]) {
    return null;
  }

  return (
    <MenuList variant='menu' id='reviewer-list'>
      {Object.keys(results.evaluations[type]).map((created_by) => (
        <MenuItem
          ref={ref}
          className={classes.menu_list_item}
          style={{
            fontSize: theme.fonts.responsiveMedium,
            background: results[`shown_${type}`] === created_by && '#7b3f3a',
            '&:hover': {
              background: results[`shown_${type}`] === created_by && theme.palette.primary.main,
            },
          }}
          key={created_by}
          onMouseEnter={() => changeShownResults(created_by, type)}
          onClick={() => changeShownResults(created_by, type, true)}
        >
          <Tooltip
            placement='left-start'
            arrow
            title={
              results[`applied_${type}`] === created_by
                ? 'Currently Selected'
                : t('ResultView.label_reviewer')
            }
          >
            <span className={classes.list_item_text}>{created_by}</span>
          </Tooltip>
          <Tooltip
            placement='right-end'
            arrow
            title={annotatorRole ? checkTooltip(created_by) : t('UserPanel.no_annotating')}
          >
            <span>
              <IconButton
                disabled={!annotatorRole}
                onClick={() => {
                  onConfirmReviewer(created_by);
                }}
              >
                <Check
                  fontSize='small'
                  style={{
                    color:
                      !!results[`confirmed_${type}`] && created_by === results[`applied_${type}`]
                        ? '#4CAF50'
                        : 'gray',
                  }}
                />
              </IconButton>
            </span>
          </Tooltip>
          {created_by !== 'bbox' && (
            <Tooltip
              placement='right-end'
              arrow
              title={
                annotatorRole ? t('ResultView.label_delete_icon') : t('UserPanel.no_annotating')
              }
            >
              <span>
                <IconButton
                  disabled={!annotatorRole}
                  onClick={() => {
                    onDelete(created_by);
                  }}
                >
                  <Delete color='secondary' fontSize='small' />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </MenuItem>
      ))}
    </MenuList>
  );
}
