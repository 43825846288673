import React from 'react';
import { Grid, useTheme } from '@material-ui/core';
import SummaryParagraphSubHeader from '../SummaryParagraphSubHeader';
import { useTranslation } from 'react-i18next';

export default function PatientReferring(props) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <SummaryParagraphSubHeader subHeader={t('ResultView.referring')}></SummaryParagraphSubHeader>

      <p
        style={{
          ...props.style,
          letterSpacing: '0.1rem',
          color: theme.palette.texts.primary,
          whiteSpace: 'break-spaces',
          textOverflow: 'ellipsis',
        }}
      >
        {props.result}
      </p>
    </>
  );
}
