import { createSlice } from '@reduxjs/toolkit';
import { setSingleBoxHover } from './labeling';

const initialState = {
  loadedProjections: 0,

  qualityOverlaysToShow: {
    parenchymaRCC: false,
    parenchymaLCC: false,
    parenchymaRMLO: false,
    parenchymaLMLO: false,

    skinFoldsRCC: false,
    skinFoldsLCC: false,
    skinFoldsRMLO: false,
    skinFoldsLMLO: false,

    pectoralisRCC: false,
    pectoralisLCC: false,
    pectoralisRMLO: false,
    pectoralisLMLO: false,

    clipsRCC: false,
    clipsLCC: false,
    clipsRMLO: false,
    clipsLMLO: false,

    scarsRCC: false,
    scarsLCC: false,
    scarsRMLO: false,
    scarsLMLO: false,
  },

  parenchymaCutsToShow: {
    rccMedial: false,
    rccLateral: false,
    lccMedial: false,
    lccLateral: false,
    rmlo: false,
    lmlo: false,
  },

  linesToShow: { rcc: false, rmlo: false, lcc: false, lmlo: false },

  pectAngleLinesToShow: { rcc: false, rmlo: false, lcc: false, lmlo: false },

  nippleProfileToShow: { rcc: false, rmlo: false, lcc: false, lmlo: false },

  IMFToShow: { rmlo: false, lmlo: false },

  pectLevelToShow: { rmlo: false, lmlo: false },

  microcalcOverlaysToShow: {
    birads2: {
      rcc: false,
      lcc: false,
      rmlo: false,
      lmlo: false,
    },
    birads3: {
      rcc: false,
      lcc: false,
      rmlo: false,
      lmlo: false,
    },
    birads4: {
      rcc: false,
      lcc: false,
      rmlo: false,
      lmlo: false,
    },
    birads5: {
      rcc: false,
      lcc: false,
      rmlo: false,
      lmlo: false,
    },
  },
  opacitiesOverlaysToShow: {
    birads2: {
      rcc: false,
      lcc: false,
      rmlo: false,
      lmlo: false,
    },
    birads3: {
      rcc: false,
      lcc: false,
      rmlo: false,
      lmlo: false,
    },
    birads4: {
      rcc: false,
      lcc: false,
      rmlo: false,
      lmlo: false,
    },
    birads5: {
      rcc: false,
      lcc: false,
      rmlo: false,
      lmlo: false,
    },
    lesionKnown: {
      rcc: false,
      lcc: false,
      rmlo: false,
      lmlo: false,
    },
  },

  opacitiesShowAll: false,
  microcalcShowAll: false,
};

export const imagesSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {
    setLoadedProjections: (state, action) => {
      state.loadedProjections = action.payload;
    },

    setMicrocalcOverlaysToShow: (state, action) => {
      state.microcalcOverlaysToShow = action.payload;
    },
    setOpacitiesOverlaysToShow: (state, action) => {
      state.opacitiesOverlaysToShow = action.payload;
    },

    setQualityOverlaysToShow: (state, action) => {
      state.qualityOverlaysToShow = action.payload;
    },

    setParenchymaCutsToShow: (state, action) => {
      state.parenchymaCutsToShow[action.payload] = true;
    },

    setNippleProfileToShow: (state, action) => {
      state.nippleProfileToShow[action.payload] = true;
    },

    hideParenchymaCutsShown: (state, action) => {
      state.parenchymaCutsToShow[action.payload] = false;
    },

    setLinesToShow: (state, action) => {
      state.linesToShow[action.payload] = true;
    },

    setPectAngleLinesToShow: (state, action) => {
      state.pectAngleLinesToShow[action.payload] = true;
    },

    setIMFToShow: (state, action) => {
      state.IMFToShow[action.payload] = true;
    },

    hideLinesShown: (state, action) => {
      state.linesToShow = initialState.linesToShow;
      state.pectAngleLinesToShow = initialState.pectAngleLinesToShow;
      state.nippleProfileToShow = initialState.nippleProfileToShow;
      state.IMFToShow = initialState.IMFToShow;
    },

    setPectLevelToShow: (state, action) => {
      state.pectLevelToShow[action.payload] = true;
    },
    hidePectLevel: (state, action) => {
      state.pectLevelToShow[action.payload] = false;
    },

    setInitialImages: (state) => {
      state = initialState;
    },

    setOpacitiesShowAll: (state, action) => {
      state.opacitiesShowAll = action.payload;
    },

    setMicrocalcShowAll: (state, action) => {
      state.microcalcShowAll = action.payload;
    },
  },
});

export const {
  setLoadedProjections,
  setMicrocalcOverlaysToShow,
  setOpacitiesOverlaysToShow,
  setQualityOverlaysToShow,
  setInitialImages,
  setParenchymaCutsToShow,
  setNippleProfileToShow,
  hideParenchymaCutsShown,
  setLinesToShow,
  setIMFToShow,
  setPectAngleLinesToShow,
  setPectLevelToShow,
  hidePectLevel,
  hideLinesShown,
  setOpacitiesShowAll,
  setMicrocalcShowAll,
} = imagesSlice.actions;

export default imagesSlice.reducer;

// DERIVED

// Hide all overlays (quality and diagnostics)
export const hideAllLesions = () => {
  return (dispatch, getState) => {
    dispatch(setSingleBoxHover(initialState.singleBoxHover));
    dispatch(setMicrocalcOverlaysToShow(initialState.microcalcOverlaysToShow));
    dispatch(setOpacitiesOverlaysToShow(initialState.opacitiesOverlaysToShow));
    dispatch(setQualityOverlaysToShow(initialState.qualityOverlaysToShow));
  };
};

export const hideAllMicrocalc = () => {
  return (dispatch, getState) => {
    dispatch(setSingleBoxHover(initialState.singleBoxHover));
    dispatch(setMicrocalcOverlaysToShow(initialState.microcalcOverlaysToShow));
  };
};

export const hideAllOpacities = () => {
  return (dispatch, getState) => {
    dispatch(setSingleBoxHover(initialState.singleBoxHover));
    dispatch(setOpacitiesOverlaysToShow(initialState.opacitiesOverlaysToShow));
  };
};

export const changeMicrocalcToShow = (type, newValue) => {
  return (dispatch, getState) => {
    const microcalcOverlays = getState().images.microcalcOverlaysToShow;
    const allowedTypes = ['birads2', 'birads3', 'birads4', 'birads5'];
    if (!allowedTypes.includes(type)) {
      return;
    }
    const updatedValues = Object.fromEntries(
      Object.entries(microcalcOverlays[type]).map(([key, value]) => [
        key,
        newValue || newValue === false ? newValue : !value,
      ])
    );

    const newLesionsToShow = {
      ...microcalcOverlays,
      [type]: { ...microcalcOverlays[type], ...updatedValues },
    };

    dispatch(setMicrocalcOverlaysToShow(newLesionsToShow));
  };
};

export const changeOpacitiesToShow = (type, newValue) => {
  return (dispatch, getState) => {
    const opacitiesOverlays = getState().images.opacitiesOverlaysToShow;
    const allowedTypes = ['birads2', 'birads3', 'birads4', 'birads5', 'lesionKnown'];
    if (!allowedTypes.includes(type)) {
      return;
    }
    const updatedValues = Object.fromEntries(
      Object.entries(opacitiesOverlays[type]).map(([key, value]) => [
        key,
        newValue || newValue === false ? newValue : !value,
      ])
    );

    const newLesionsToShow = {
      ...opacitiesOverlays,
      [type]: { ...opacitiesOverlays[type], ...updatedValues },
    };

    dispatch(setOpacitiesOverlaysToShow(newLesionsToShow));
  };
};

// Quality overlays handlers

export const showOverlayOfKind = (kindArray) => {
  return (dispatch) => {
    const newOverlayToShow = { ...initialState.qualityOverlaysToShow };

    const kinds = Array.isArray(kindArray) ? kindArray : [kindArray];

    for (let overlayType in newOverlayToShow) {
      for (let kind of kinds) {
        if (overlayType.includes(kind)) {
          newOverlayToShow[overlayType] = true;
          break;
        }
      }
    }

    dispatch(setQualityOverlaysToShow(newOverlayToShow));
  };
};
