import { createSlice } from '@reduxjs/toolkit';

import { fetchGet } from '../../helpers/fetching';
import { getNewSelected, unwindDevices } from '../../helpers/misc';
import { setSnackbar } from './gui';
import { createOperatorList } from './../../helpers/misc';

const initialState = {
    device_list: {},
    operator_list: [],
}

export const operatorsDevicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setOperatorList: (state, action) => {
        state.operator_list = action.payload;
    },
    setDeviceList: (state, action) => {
        state.device_list = action.payload;
    },
  },
});

export const { setOperatorList, setDeviceList } = operatorsDevicesSlice.actions;

export default operatorsDevicesSlice.reducer;

// Asynchronous
export const fetchOperators = () => {
    return async (dispatch, getState) => {
      let operatorResponse = fetchGet('operators');
      let userResponse = fetchGet('users?role=technician');
      
      operatorResponse = await operatorResponse;
      if (!operatorResponse.success) {
        dispatch(
          setSnackbar({
            msg: operatorResponse.msg,
            severity: 'error',
          })
        );
        return;
      }
  
      userResponse = await userResponse;
      if (!userResponse.success) {
        dispatch(
          setSnackbar({
            msg: userResponse.msg,
            severity: 'error',
          })
        );
        return;
      }
  
      const new_operator_list = createOperatorList(
        operatorResponse.data,
        userResponse.data
      );
  
      dispatch(setOperatorList(new_operator_list));
    };
};

export const fetchDevices = () => {
  return async (dispatch, getState) => {
    const response = await fetchGet('devices');

    if (!response.success) {
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
      return;
    }

    dispatch(setDeviceList(response.data));
  };
};
