import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { IconButton, Grid } from "@material-ui/core";
import { LinkedIn, Language, ContactMail } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  socialMedia: {
  },
}));

function MediaLinks() {
  const classes = useStyles();

  return (
    <Grid
      className={classes.socialMedia}
      container
      direction="row"
      spacing={2}
      justifyContent="center"
    >
      <Grid item>
        <IconButton target="_blank" href="https://www.b-rayz.ch" size="medium">
          <Language />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          target="_blank"
          href="https://ch.linkedin.com/company/b-rayz"
          size="medium"
        >
          <LinkedIn />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          target="_blank"
          href="https://www.b-rayz.ch/contact"
          size="medium"
        >
          <ContactMail />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default MediaLinks;
