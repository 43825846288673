import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  GridItem: {
    height: '100%',
  },
}));

export default function ButtonGroup(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Grid
      wrap="nowrap"
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={1}
    >
      <Grid className={classes.GridItem} item>
        <Button
          onClick={() => props.onClick(0, 'rcc')}
          variant={props.cmp_projections.includes(0) ? 'contained' : 'outlined'}
          style={{
            backgroundColor: props.cmp_projections.includes(0)
              ? theme.palette.projections.rcc
              : undefined,
            color: props.cmp_projections.includes(0)
              ? 'white'
              : theme.palette.projections.rcc,
            border: `1px solid ${theme.palette.projections.rcc}`,
          }}
          id="rcc"
          size="small"
        >
          {t('Dashboard.rcc').toUpperCase()}
        </Button>
      </Grid>
      <Grid className={classes.GridItem} item>
        <Button
          onClick={() => props.onClick(1, 'lcc')}
          variant={props.cmp_projections.includes(1) ? 'contained' : 'outlined'}
          style={{
            backgroundColor: props.cmp_projections.includes(1)
              ? theme.palette.projections.lcc
              : undefined,
            color: props.cmp_projections.includes(1)
              ? 'white'
              : theme.palette.projections.lcc,
            border: `1px solid ${theme.palette.projections.lcc}`,
          }}
          id="lcc"
          size="small"
        >
          {t('Dashboard.lcc').toUpperCase()}
        </Button>
      </Grid>
      <Grid className={classes.GridItem} item>
        <Button
          onClick={() => props.onClick(2, 'rmlo')}
          variant={props.cmp_projections.includes(2) ? 'contained' : 'outlined'}
          style={{
            backgroundColor: props.cmp_projections.includes(2)
              ? theme.palette.projections.rmlo
              : undefined,
            color: props.cmp_projections.includes(2)
              ? 'white'
              : theme.palette.projections.rmlo,
            border: `1px solid ${theme.palette.projections.rmlo}`,
          }}
          id="rmlo"
          size="small"
        >
          {t('Dashboard.rmlo').toUpperCase()}
        </Button>
      </Grid>
      <Grid className={classes.GridItem} item>
        <Button
          onClick={() => props.onClick(3, 'lmlo')}
          variant={props.cmp_projections.includes(3) ? 'contained' : 'outlined'}
          style={{
            backgroundColor: props.cmp_projections.includes(3)
              ? theme.palette.projections.lmlo
              : undefined,
            color: props.cmp_projections.includes(3)
              ? 'white'
              : theme.palette.projections.lmlo,
            border: `1px solid ${theme.palette.projections.lmlo}`,
          }}
          id="lmlo"
          size="small"
        >
          {t('Dashboard.lmlo').toUpperCase()}
        </Button>
      </Grid>
    </Grid>
  );
}
