import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  risk_button: {
    display: 'flex',
    color: 'white',
    backgroundColor: theme.palette.complementary.turquoiseMedium,
    '&:hover': {
      backgroundColor: theme.palette.complementary.turquoise,
    },
  },

  risk_container_filled: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  risk_header: {
    display: 'flex',
    justifyContent: 'space-around',
  },

  risk_button_container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },

  risk_content_container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '4px',
  },

  risk_content_container_empty: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    marginLeft: '16px',
  },

  patient_risk_title: {
    marginRight: '10px',
  },

  life_risk_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  patient_relative_risk: {
    fontSize: theme.fonts.responsiveBig,
    letterSpacing: '0.2rem',
    margin: theme.spacing(1),
  },

  patient_risk_high: {
    color: theme.palette.projections.risk_high,
  },

  patient_risk_moderate: {
    color: theme.palette.projections.risk_moderate,
  },

  patient_risk_low: {
    color: theme.palette.projections.risk_low,
  },

  risk_assessment_container_filled: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
  },

  risk_assessment_container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    marginLeft: '16px',
  },

  patient_recommendations_title: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.texts.secondary,
    fontSize: theme.fonts.responsiveMediumBig,
    whiteSpace: 'nowrap',
    margin: '2px 8px',
  },

  recomendation_text: {
    fontSize: theme.fonts.responsiveMediumBig,
    display: 'flex',
    whiteSpace: 'normal',
    letterSpacing: '0.08em',
    alignItems: 'center',
  },

  recommendation_list: {
    marginTop: '1%',
    display: 'flex',
    alignItems: 'center',
  },

  risk_avatar: {
    width: '20px',
    height: '20px',
  },
  risk_icon: {
    color: '#424242',
  },
  patient_risk_high_icon: {
    background: theme.palette.projections.risk_high,
  },

  patient_risk_moderate_icon: {
    background: theme.palette.projections.risk_moderate,
  },

  patient_risk_low_icon: {
    background: theme.palette.projections.risk_low,
  },

  risk_info_text: {
    color: '#ffffffb3',
    fontSize: theme.fonts.responsiveMedium,
    display: 'flex',
    whiteSpace: 'normal',
    letterSpacing: '0.08em',
  },

  risk_info_placeholder: {
    fontSize: theme.fonts.responsiveMedium,
    display: 'flex',
    whiteSpace: 'normal',
    letterSpacing: '0.08em',
    maxWidth: '95%',
  },

  summary_fabs: {
    margin: '4px',
    height: '26px',
    width: '26px',
    minHeight: '26px',
    minWidth: '26px',
    backgroundColor: theme.palette.complementary.turquoise,

    '&:hover': {
      backgroundColor: theme.palette.complementary.turquoiseMedium,
      boxShadow: '0px 0px 5px 0px #d7c2c2',
    },
  },

  risk_btn_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '4px',
  },

  recommendation_container: {
    width: '70%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginLeft: '15%',
    justifyContent: 'center',
  },

  tooltip_text: {
    letterSpacing: '0.1rem',
    fontSize: '12px',
  },
}));
