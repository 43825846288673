import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Container, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import SMS from '../../../resources/icons/sms_Logo.png';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  footer_container: {
    padding: theme.spacing(0.5),
    display: 'flex',
    justifyContent: 'space-between',
    height: '4%',
    width: '60%',
    position: 'fixed',
    bottom: 0,
    zIndex: '600',
    alignItems: 'center',
  },
  linkText: {
    color: theme.palette.texts.secondary,
    fontSize: theme.fonts.responsiveSmall,
  },
  link: {
    textDecoration: 'underline',
  },

  sms_logo: { width: 'auto', height: '100%', marginRight: '10px' },

  text_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
  },
}));

export default function Copyright() {
  const classes = useStyles();
  const { t } = useTranslation();
  const darkState = useSelector((store) => store.gui.darkState);

  return (
    <Container maxWidth={'xl'} className={classes.footer_container}>
      <div className={classes.text_container}>
        {/* <Typography className={classes.linkText} variant="body2" align="center">
          {t('Layout.footer_disclaimer')}
        </Typography> */}
        <Typography className={classes.linkText} align='center'>
          {t('Layout.footer_disclaimer_important')}
          <Link className={classes.link} color='inherit' href='https://b-rayZ.ch/'>
            b-rayZ AG {new Date().getFullYear()}
          </Link>
          {'©'}
        </Typography>
      </div>
      {/* <img src={SMS} className={classes.sms_logo} alt='swiss made' /> */}
    </Container>
  );
}
