import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  GridItem: {
    height: '100%',
  },
}));

export default function ButtonGroupProjections(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Grid
      wrap="nowrap"
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={1}
    >
      <Grid className={classes.GridItem} item>
        <Button
          onClick={() => props.onClick('rcc')}
          variant={
            props.performanceProjections.includes('rcc')
              ? 'contained'
              : 'outlined'
          }
          style={{
            backgroundColor: props.performanceProjections.includes('rcc')
              ? theme.palette.projections.rcc
              : undefined,
            color: props.performanceProjections.includes('rcc')
              ? 'white'
              : theme.palette.projections.rcc,
            border: `1px solid ${theme.palette.projections.rcc}`,
          }}
          id="rcc"
          size="small"
        >
          {t('Dashboard.rcc').toUpperCase()}
        </Button>
      </Grid>
      <Grid className={classes.GridItem} item>
        <Button
          onClick={() => props.onClick('lcc')}
          variant={
            props.performanceProjections.includes('lcc')
              ? 'contained'
              : 'outlined'
          }
          style={{
            backgroundColor: props.performanceProjections.includes('lcc')
              ? theme.palette.projections.lcc
              : undefined,
            color: props.performanceProjections.includes('lcc')
              ? 'white'
              : theme.palette.projections.lcc,
            border: `1px solid ${theme.palette.projections.lcc}`,
          }}
          id="lcc"
          size="small"
        >
          {t('Dashboard.lcc').toUpperCase()}
        </Button>
      </Grid>
      <Grid className={classes.GridItem} item>
        <Button
          onClick={() => props.onClick('rmlo')}
          variant={
            props.performanceProjections.includes('rmlo')
              ? 'contained'
              : 'outlined'
          }
          style={{
            backgroundColor: props.performanceProjections.includes('rmlo')
              ? theme.palette.projections.rmlo
              : undefined,
            color: props.performanceProjections.includes('rmlo')
              ? 'white'
              : theme.palette.projections.rmlo,
            border: `1px solid ${theme.palette.projections.rmlo}`,
          }}
          id="rmlo"
          size="small"
        >
          {t('Dashboard.rmlo').toUpperCase()}
        </Button>
      </Grid>
      <Grid className={classes.GridItem} item>
        <Button
          onClick={() => props.onClick('lmlo')}
          variant={
            props.performanceProjections.includes('lmlo')
              ? 'contained'
              : 'outlined'
          }
          style={{
            backgroundColor: props.performanceProjections.includes('lmlo')
              ? theme.palette.projections.lmlo
              : undefined,
            color: props.performanceProjections.includes('lmlo')
              ? 'white'
              : theme.palette.projections.lmlo,
            border: `1px solid ${theme.palette.projections.lmlo}`,
          }}
          id="lmlo"
          size="small"
        >
          {t('Dashboard.lmlo').toUpperCase()}
        </Button>
      </Grid>
    </Grid>
  );
}
