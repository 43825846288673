import { createSlice } from '@reduxjs/toolkit';

import { fetchGet, fetchPost } from '../../helpers/fetching';
import { setSnackbar } from '../index';
import { DIAGNOSTICS_MICROCALC, DIAGNOSTICS_OPACITIES } from '../../config';
import { setActiveDialog } from '../index';

const initialState = {
  exam_id: '',
  folder: '',
  pat_name: '',
  patient_id: '',
  birth: '0000-00-00T00:00:00.000Z',
  referring: '',
  device: '',
  operator: '',
  notes: '',

  life_time_risk: null,

  applied_density: 'bbox',
  applied_quality: 'bbox',
  applied_opacities: 'bbox',
  applied_microcalc: 'bbox',

  confirmed_density: null,
  confirmed_quality: null,
  confirmed_opacities: null,
  confirmed_microcalc: null,

  shown_density: 'bbox',
  shown_quality: 'bbox',
  shown_opacities: 'bbox',
  shown_microcalc: 'bbox',

  projections: {
    rcc: null,
    lcc: null,
    rmlo: null,
    lmlo: null,
  },

  evaluations: {},

  second_captures: {
    rcc: [],
    lcc: [],
    rmlo: [],
    lmlo: [],
  },

  // shownResults: null,
};

export const resultsSlice = createSlice({
  name: 'results',
  initialState,
  reducers: {
    setResults: (_, action) => {
      return { ...action.payload };
    },
    setInitialResult: () => {
      return { ...initialState };
    },
    setOperatorNotes: (state, action) => {
      state.notes = action.payload;
    },
    setShownDensity: (state, action) => {
      state.shown_density = action.payload;
    },
    setShownQuality: (state, action) => {
      state.shown_quality = action.payload;
    },
    setShownMicrocalc: (state, action) => {
      state.shown_microcalc = action.payload;
    },
    setShownOpacities: (state, action) => {
      state.shown_opacities = action.payload;
    },
  },
});

export const {
  setResults,
  setInitialResult,
  setOperatorNotes,
  setShownDensity,
  setShownQuality,
  setShownMicrocalc,
  setShownOpacities,
} = resultsSlice.actions;

export default resultsSlice.reducer;

// Derived
export const setConfirmed = (type, confirmed_by) => {
  return async (dispatch, getState) => {
    const key = `confirmed_${type}`;
    const currentState = { ...getState().results };

    if (!currentState.hasOwnProperty(key)) {
      console.log(`Bad result type: ${type}`);
      return;
    }

    currentState[key] = confirmed_by;
    dispatch(setResults(currentState));
  };
};

export const setApplied = (type, created_by) => {
  return async (dispatch, getState) => {
    const key = `applied_${type}`;
    const currentState = { ...getState().results };

    if (!currentState.hasOwnProperty(key)) {
      console.log(`Bad result type: ${type}`);
      return;
    }

    currentState[key] = created_by;
    dispatch(setResults(currentState));
  };
};

// Async
export const fetchResults = () => {
  return async (dispatch, getState) => {
    const currentExam = getState().examinations.currentExam;

    if (!currentExam) return;

    const response = await fetchGet(`examination/${currentExam}`);

    if (!response.success) {
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
      return;
    }

    dispatch(setResults(response.data));
  };
};

export const postOperatorNotes = (note, t) => {
  return async (dispatch, getState) => {
    const exam_id = getState().examinations.currentExam;

    const response = await fetchPost('operator_notes', { exam_id, note });
    if (response.success) {
      dispatch(setOperatorNotes(note));
      dispatch(
        setSnackbar({
          msg: t('Limitations.limitations_update'),
          severity: 'success',
        })
      );
    } else {
      dispatch(setOperatorNotes('server error'));
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
    }
  };
};

export const sendReports = (reportModule, currentExam, t) => {
  return async (dispatch) => {
    if (reportModule === 'quality') {
      const responseQuality = await fetchPost(`report_quality/${currentExam}`, {}, 'bbox');

      if (responseQuality.success) {
        dispatch(
          setSnackbar({
            msg:
              responseQuality.success
                ? t('report.report_sent')
                : responseQuality.Message,
            severity: responseQuality.success ? 'success' : 'warning',
          })
        );
      } else {
        dispatch(
          setSnackbar({
            msg: responseQuality.msg ? responseQuality.msg : t('report.error_quality'),
            severity: 'error',
          })
        );
      }
      dispatch(setActiveDialog(null));
    } else if (reportModule === 'diagnostics') {
      const responseDiagnostics = await fetchPost(`report_diagnostics/${currentExam}`, {}, 'bbox');

      if (responseDiagnostics.success) {
        dispatch(
          setSnackbar({
            msg:
              responseDiagnostics.success
                ? t('report.report_sent')
                : responseDiagnostics.Message,
            severity: responseDiagnostics.success ? 'success' : 'warning',
          })
        );
      } else {
        dispatch(
          setSnackbar({
            msg: responseDiagnostics.msg ? responseDiagnostics.msg : t('report.error_diagnostics'),
            severity: 'error',
          })
        );
      }
      dispatch(setActiveDialog(null));
    }
  };
};

export const sendSR = (currentExam, t) => {
  return async (dispatch) => {
    const response = await fetchPost(`dicom_sr/${currentExam}`, {}, 'bbox');
    if (response.success) {
      dispatch(
        setSnackbar({
          msg: response.success ? t('report.structured_sent') : response.Message,
          severity: response.success ? 'success' : 'warning',
        })
      );
      dispatch(setActiveDialog(null));
    } else {
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
      // setPending(null);
      dispatch(setActiveDialog(null));
    }
  };
};
