import React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { QUALITY_VALUES } from '../../../../config';

am4core.useTheme(am4themes_animated);

class MultiLineChartTwo extends React.Component {
  componentDidMount() {
    this.initChart();
  }
  initChart() {
    const chart = am4core.create(this.props.chartdiv, am4charts.XYChart);
    chart.data = this.props.data;
    chart.padding(0, 20, 0, 20);

    //Date X axis
    const dateAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    dateAxis.dataFields.category = 'date';
    dateAxis.renderer.grid.template.disabled = false;
    dateAxis.fontSize = this.props.theme.fonts.responsiveMedium;
    dateAxis.renderer.labels.template.fill = am4core.color(
      this.props.theme.palette.texts.secondary
    );

    dateAxis.renderer.line.strokeOpacity = 0.5;

    dateAxis.renderer.grid.template.opacity = 0.4;
    dateAxis.renderer.line.strokeWidth = 1;
    dateAxis.renderer.line.stroke = am4core.color(this.props.theme.palette.texts.secondary);
    dateAxis.renderer.grid.template.stroke = am4core.color(
      this.props.theme.palette.texts.secondary
    );

    dateAxis.renderer.minGridDistance = 200;

    // Value Axis
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.disabled = false;
    valueAxis.renderer.grid.template.strokeWidth = 0.5;
    valueAxis.renderer.grid.template.stroke = am4core.color(
      this.props.theme.palette.texts.secondary
    );
    valueAxis.min = -12;
    valueAxis.max = 120;
    valueAxis.renderer.grid.template.opacity = 0.4;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.renderer.line.strokeOpacity = 0.5;
    valueAxis.renderer.line.strokeWidth = 1;
    valueAxis.strictMinMax = true;
    valueAxis.renderer.line.stroke = am4core.color(this.props.theme.palette.texts.secondary);

    // Add labels to the y-axis
    const yAxisValues = (values, endValue, labels, labelColor) => {
      for (let index = 0; index < labels.length; index++) {
        const range = valueAxis.axisRanges.create();
        range.value = values[index];
        range.endValue = endValue[index];
        range.label.text = labels[index];
        range.label.fill = labelColor[index];
        range.label.fontSize = '1.2vh';
        range.label.inside = false;
        range.grid.strokeOpacity = 1;
        range.grid.strokeWidth = 1;
        range.grid.strokeDasharray = 4;
        range.grid.stroke = labelColor[index];
        range.label.horizontalCenter = 'middle';
        range.label.verticalCenter = 'middle';
        range.label.dy = 0;
        range.label.rotation = QUALITY_VALUES.length <= 3 ? -90 : 0;
      }
    };

    QUALITY_VALUES.length >= 3 &&
      yAxisValues(
        [1, 34, 67, 100],
        [1, 34, 67, 100],
        QUALITY_VALUES.map((val) => val.charAt(0).toUpperCase()).reverse(),
        [
          this.props.theme.palette.projections.inadequate,
          this.props.theme.palette.projections.moderate,
          this.props.theme.palette.projections.good,
          this.props.theme.palette.projections.perfect,
        ]
      );

    QUALITY_VALUES.length < 3 &&
      yAxisValues(
        [0, 100],
        [0, 100],
        QUALITY_VALUES.map((val) => val.charAt(0).toUpperCase()).reverse(),
        [
          this.props.theme.palette.projections.inadequate,
          this.props.theme.palette.projections.perfect,
        ]
      );

    // Create projection series
    function createSeries(chart, name, valueY, categoryX, projColor, onBulletClick) {
      const series = chart.series.push(new am4charts.LineSeries());
      series.name = name;
      series.dataFields.valueY = valueY;
      series.dataFields.categoryX = categoryX;
      series.strokeWidth = 2;
      series.fillOpacity = 0.5;
      series.stroke = projColor;
      series.tensionX = 0.95;
      series.tensionY = 0.9;
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = projColor;

      // Add bullets
      const circleBullet = series.bullets.push(new am4charts.CircleBullet());
      circleBullet.circle.fill = projColor;
      circleBullet.circle.radius = 4;
      circleBullet.circle.fillOpacity = 1;
      circleBullet.circle.stroke = projColor;
      circleBullet.circle.strokeWidth = 3;
      circleBullet.tooltipText = '{date} \n {name}';

      circleBullet.events.on('hit', (event) => {
        onBulletClick(event.target.dataItem.dataContext);
      });

      circleBullet.cursorOverStyle = am4core.MouseCursorStyle.pointer;

      return series;
    }

    const seriesRcc = createSeries(
      chart,
      'RCC',
      'rcc',
      'date',
      this.props.theme.palette.projections.rcc,
      this.props.onBulletClick
    );

    const seriesLcc = createSeries(
      chart,
      'LCC',
      'lcc',
      'date',
      am4core.color(this.props.theme.palette.projections.lcc),
      this.props.onBulletClick
    );

    const seriesRmlo = createSeries(
      chart,
      'RMLO',
      'rmlo',
      'date',
      am4core.color(this.props.theme.palette.projections.rmlo),
      this.props.onBulletClick
    );

    const seriesLmlo = createSeries(
      chart,
      'LMLO',
      'lmlo',
      'date',
      am4core.color(this.props.theme.palette.projections.lmlo),
      this.props.onBulletClick
    );

    var gradient = new am4core.LinearGradient();
    gradient.addColor(am4core.color(this.props.theme.palette.complementary.turquoiseDark), 0.5);
    gradient.addColor(am4core.color(this.props.theme.palette.complementary.turquoise), 0);
    gradient.rotation = 90;
    seriesRcc.fill = gradient;
    seriesLcc.fill = gradient;
    seriesRmlo.fill = gradient;
    seriesLmlo.fill = gradient;

    this.chart = chart;
    this.showHideProjections();
  }

  showHideProjections() {
    this.chart.series.values.forEach((series) => {
      if (this.props.activeProjections.includes(series.name.toLocaleLowerCase())) {
        series.show();
      } else {
        series.hide();
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.darkState !== this.props.darkState ||
      prevProps.theme.palette.primary.main !== this.props.theme.palette.primary.main
    ) {
      this.chart.dispose();
      this.initChart();
    } else {
      if (this.props.data.length !== prevProps.data.length) {
        this.chart.data = this.props.data;
        this.chart.validateData();
      }
    }
    this.showHideProjections();
  }

  componentWillUnmount() {
    if (this.chart) this.chart.dispose();
  }

  render() {
    return <div id={this.props.chartdiv} style={{ width: '100%', height: '100%' }}></div>;
  }
}

function mapStateToProps(state, myProps) {
  return { data: state.statistics[myProps.dataKey] };
}

export default connect(mapStateToProps)(MultiLineChartTwo);
