import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  GridItem: {
    height: '100%',
  },
}));

export default function ButtonGroupQuality(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Grid
      wrap="nowrap"
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={1}
    >
      <Grid className={classes.GridItem} item>
        <Button
          onClick={() => props.onClick('rcc')}
          variant={props.active_projection === 'rcc' ? 'contained' : 'outlined'}
          id="rcc_pgmi"
          size="small"
          style={{
            backgroundColor:
              props.active_projection === 'rcc'
                ? theme.palette.projections.rcc
                : undefined,
            color:
              props.active_projection === 'rcc'
                ? 'white'
                : theme.palette.projections.rcc,
            border: `1px solid ${theme.palette.projections.rcc}`,
          }}
        >
          {t('Dashboard.rcc').toUpperCase()}
        </Button>
      </Grid>
      <Grid className={classes.GridItem} item>
        <Button
          onClick={() => props.onClick('lcc')}
          variant={props.active_projection === 'lcc' ? 'contained' : 'outlined'}
          id="lcc_pgmi"
          size="small"
          style={{
            backgroundColor:
              props.active_projection === 'lcc'
                ? theme.palette.projections.lcc
                : undefined,
            color:
              props.active_projection === 'lcc'
                ? 'white'
                : theme.palette.projections.lcc,
            border: `1px solid ${theme.palette.projections.lcc}`,
          }}
        >
          {t('Dashboard.lcc').toUpperCase()}
        </Button>
      </Grid>
      <Grid className={classes.GridItem} item>
        <Button
          onClick={() => props.onClick('rmlo')}
          variant={
            props.active_projection === 'rmlo' ? 'contained' : 'outlined'
          }
          id="rmlo_pgmi"
          size="small"
          style={{
            backgroundColor:
              props.active_projection === 'rmlo'
                ? theme.palette.projections.rmlo
                : undefined,
            color:
              props.active_projection === 'rmlo'
                ? 'white'
                : theme.palette.projections.rmlo,
            border: `1px solid ${theme.palette.projections.rmlo}`,
          }}
        >
          {t('Dashboard.rmlo').toUpperCase()}
        </Button>
      </Grid>
      <Grid className={classes.GridItem} item>
        <Button
          onClick={() => props.onClick('lmlo')}
          variant={
            props.active_projection === 'lmlo' ? 'contained' : 'outlined'
          }
          id="lmlo_pgmi"
          size="small"
          style={{
            backgroundColor:
              props.active_projection === 'lmlo'
                ? theme.palette.projections.lmlo
                : undefined,
            color:
              props.active_projection === 'lmlo'
                ? 'white'
                : theme.palette.projections.lmlo,
            border: `1px solid ${theme.palette.projections.lmlo}`,
          }}
        >
          {t('Dashboard.lmlo').toUpperCase()}
        </Button>
      </Grid>
    </Grid>
  );
}
