import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button, InputBase, MenuItem, Select } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import 'react-datepicker/dist/react-datepicker.css';
import { formatDate, toDate } from '../../../../helpers/formatDate.js';
import * as actions from '../../../../store/index';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  custom_date_container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2%',
  },

  DateInput: {
    margin: '0 2%',
  },
}));

const CustomDatePicker = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { setDates, customDate, setCustomDate } = props;

  const setCustomDateFrom = (newDateFrom) => {
    const newCustomDate = [...customDate];
    try {
      newCustomDate[0] = formatDate(newDateFrom, '00:00');
      setCustomDate(newCustomDate);
      setDates(newCustomDate);
    } catch {}
  };

  const setCustomDateTo = (newDateTo) => {
    const newCustomDate = [...customDate];
    try {
      newCustomDate[1] = formatDate(newDateTo, '23:59');
      setCustomDate(newCustomDate);
      setDates(newCustomDate);
    } catch {}
  };

  return (
    <div className={classes.custom_date_container}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          className={classes.DateInput}
          key="from"
          value={toDate(customDate[0])}
          onChange={setCustomDateFrom}
          format="dd/MM/yyyy"
        />

        <KeyboardDatePicker
          className={classes.DateInput}
          key="to"
          value={toDate(customDate[1])}
          onChange={setCustomDateTo}
          format="dd/MM/yyyy"
        />
        {/* <Button
          className={classes.okButton}
          color="primary"
          variant="outlined"
          onClick={() => setDates(customDate)}
        >
          Apply
        </Button> */}
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default CustomDatePicker;
