import React from 'react';
import { Grid, useTheme } from '@material-ui/core';
import SummaryParagraphSubHeader from '../SummaryParagraphSubHeader';
import { useTranslation } from 'react-i18next';

export default function PatientExamDate(props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const inputDate = new Date(props.result);
  const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: false };
  const timeString = inputDate.toLocaleTimeString(undefined, timeOptions);

  return (
    <>
      <SummaryParagraphSubHeader subHeader={props.title} />
      <p
        style={{
          ...props.style,
          letterSpacing: '0.1rem',
          color: theme.palette.texts.primary,
        }}
      >
        {props.result.slice(0, 10)} - {timeString}
      </p>
    </>
  );
}
