import React, { useCallback, useState } from 'react';
import { useStyles } from './FilterDevicesOptions.style';
import { ExpandMore, FlashAutoRounded } from '@material-ui/icons';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Checkbox,
} from '@material-ui/core';

const FilterDeviceOptions = (props) => {
  const classes = useStyles();

  const checkboxChange = useCallback((e) => {
    props.onChange(props.title, e.target.name, e.target.checked);
    e.stopPropagation();
  });

  return (
    <>
      <Grid item>
        <div className={classes.SelectionBox}>
          {Object.keys(props.items).map((inst, i) => (
            <Accordion
              key={inst + i}
              className={classes.Accordion}
              TransitionProps={{ unmountOnExit: true }}
              data-test-id="devices-accordion"
            >
              <AccordionSummary
                key={inst + i.toString()}
                aria-label="Expand"
                className={classes.checkboxLabels}
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <FormControlLabel
                  data-test-id="devices-accordion-checkbox-form"
                  key={i + inst}
                  className={classes.checkboxLabels}
                  label={
                    <Typography className={classes.checkboxLabel}>
                      {inst}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      data-test-id="devices-accordion-checkbox"
                      size="small"
                      // className={classes.checkbox}
                      checked={props.items[inst].every((dev) =>
                        props.selected.includes(dev)
                      )}
                      name={inst}
                      onChange={props.onInstChange}
                      className={classes.checkboxLabels}
                    />
                  }
                />
              </AccordionSummary>
              <AccordionDetails className={classes.AccordionDetails} key={inst}>
                {props.items[inst].map((dev, i) => (
                  <FormControlLabel
                    key={dev + i.toString()}
                    label={
                      <Typography className={classes.checkboxLabel}>
                        {dev}
                      </Typography>
                    }
                    control={
                      <Checkbox
                        size={'small'}
                        onChange={(e) => {
                          checkboxChange(e);
                        }}
                        checked={props.selected.includes(dev)}
                        name={dev}
                      />
                    }
                  />
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </Grid>
    </>
  );
};
FilterDeviceOptions.displayName = 'FilterDeviceOptions';
export default FilterDeviceOptions;
