import React, { useCallback } from 'react';
import { Box, Checkbox, FormControl, FormGroup } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useStyles } from './FilterOptions.style';
import { nanoid } from 'nanoid';

const FiltersOptions = (props) => {
  const classes = useStyles();

  const flatArray = props.items.flatMap((checkboxItem) => checkboxItem);

  const checkboxTuppleObject = props?.labels?.map((checkboxLabel, i) => [
    checkboxLabel,
    flatArray[i],
  ]);

  const checkboxChange = useCallback((e, checkboxName) => {
    props.onChange(props.title, checkboxName, e.target.checked);
  });

  const optionsGroupClassDefinition = () => {
    if (
      (props.labels?.length >= 7) |
      (props.labels?.length <= 2) |
      (props.title === 'opacities') |
      (props.title === 'microcalc') |
      (props.title === 'operators')
    )
      return classes.optionsGridContainer_medium;
    if (props.labels?.length < 4) return classes.optionsGridContainer_xsmall;
    if (props.labels?.length >= 4) return classes.optionsGridContainer_small;
  };
  
  return (
    <>
      <Box sx={{ display: 'flex' }} key={nanoid()}>
        <FormControl
          sx={{ m: 3 }}
          component='fieldset'
          variant='standard'
          className={classes.formControl}
        >
          {props.title === 'operators' ? (
            <FormGroup key={nanoid()} className={optionsGroupClassDefinition()}>
              {props.items.map(operator => {
                const aliasesStr = operator.aliases ? operator.aliases.join(';') : '';
                return (
                  <FormControlLabel
                    className={classes.checkboxLabels}
                    key={nanoid()}
                    label={operator.name}
                    control={
                      <Checkbox
                        size={'small'}
                        name={operator.name}
                        checked={props.selected.toString().includes(aliasesStr || operator.name)}
                        onChange={e => checkboxChange(e, aliasesStr || operator.name)}
                        className={classes.checkboxLabels}
                      />
                    }
                  />)
              })}
            </FormGroup>
          ) : (
            <FormGroup key={nanoid()} className={optionsGroupClassDefinition()}>
              {props.type === 'object' ? (
                checkboxTuppleObject.map(([checkboxLabel, checkboxName, i]) => (
                  <FormControlLabel
                    className={classes.checkboxLabels}
                    key={nanoid()}
                    label={checkboxLabel.toString()}
                    control={
                      <Checkbox
                        size={'small'}
                        name={checkboxName?.toString()}
                        checked={props.selected.some(
                          (selectedValue) => selectedValue === checkboxName
                        )}
                        onChange={(e) => checkboxChange(e, checkboxName)}
                        className={classes.checkboxLabels}
                      />
                    }
                  />
                ))
              ) : (
                <FormControlLabel
                  key={nanoid()}
                  control={<Checkbox name={props.items} />}
                  label={props.labels}
                  onChange={(e) => checkboxChange(e, props.labels)}
                  checked={props.selected}
                />
              )}
            </FormGroup>
          )}
        </FormControl>
      </Box>
    </>
  );
};

FiltersOptions.displayName = 'FiltersOptions';
export default FiltersOptions;
