import React from 'react';
import { Button, Divider, Fab, Grid, Tooltip, Typography, Zoom } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  density_overlay_container: {
    display: 'flex',
    alignItems: 'center',
    padding: '1vh 0',
    position: 'relative',
    minHeight: '100%',
    justifyContent: 'center',
  },

  density_overlay: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '2%',
    width: '80%',
    // height: '55px',
  },

  acr_selected_text: {
    fontSize: theme.fonts.responsiveHeader,
    display: 'flex',
    flexWrap: 'nowrap',
    color: theme.palette.texts.primary,
    whiteSpace: 'nowrap',
  },

  acr_no_selected_text: {
    fontSize: theme.fonts.responsiveMedium,
    display: 'flex',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
  },

  acr_label: {
    position: 'absolute',
    left: 15,
    top: -10,
    width: '60px',
    height: '20px',
    zIndex: 9,
    background: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'center',
  },

  divider_line: {
    border: '1px solid gray',
    margin: 0,
    padding: 0,
    width: '100%',
  },

  grid_dens_container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

const DensityButtons = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const { landscape, zoomedProjection, darkState } = useSelector((state) => state.gui);
  const { densityInRevisionMode } = useSelector((state) => state.labeling);

  const densClasses = ['A', 'B', 'C', 'D'];
  const densColors = {
    A: theme.palette.projections.acrA,
    B: theme.palette.projections.acrB,
    C: theme.palette.projections.acrC,
    D: theme.palette.projections.acrD,
  };

  const renderButton = (densClass) => (
    <Grid item xs={props.dens_class === densClass ? 3 : 2} className={classes.grid_dens_container}>
      <Fab
        size={props.dens_class === densClass ? 'large' : 'small'}
        id={densClass.toLowerCase()}
        onClick={() => props.setDensClass(densClass)}
        style={
          props.dens_class === densClass
            ? {
                backgroundColor: densColors[densClass],
                borderColor: densColors[densClass],
                height: densityInRevisionMode ? '7vh' : '6vh',
                width: densityInRevisionMode ? '7vh' : '6vh',
                minHeight: densityInRevisionMode ? '7vh' : '6vh',
                minWidth: densityInRevisionMode ? '7vh' : '6vh',
                cursor: densityInRevisionMode ? 'pointer' : 'help',
              }
            : {
                color: densColors[densClass],
                backgroundColor: theme.palette.background.paper,
                boxShadow: '0px 0 0 0 rgba(0,0,0,0)',
                border: densityInRevisionMode
                  ? `1px solid ${densColors[densClass]}`
                  : '1px solid gray',
                height: densityInRevisionMode ? '5vh' : '4vh',
                width: densityInRevisionMode ? '5vh' : '4vh',
                minHeight: densityInRevisionMode ? '5vh' : '4vh',
                minWidth: densityInRevisionMode ? '5vh' : '4vh',
                cursor: densityInRevisionMode ? 'pointer' : 'help',
              }
        }
      >
        <Typography
          className={
            props.dens_class === densClass
              ? classes.acr_selected_text
              : classes.acr_no_selected_text
          }
        >
          {densClass}
        </Typography>
      </Fab>
    </Grid>
  );

  return (
    <Tooltip
      title={props.tooltipText}
      arrow
      interactive
      placement='top-end'
      TransitionComponent={Zoom}
    >
      <div
        className={classes.density_overlay_container}
        style={{
          margin: zoomedProjection && densityInRevisionMode ? '50px 0 18px' : '10px 0 18px',
        }}
      >
        <div
          className={classes.acr_label}
          style={{
            color: darkState ? '#d5d5d5' : 'black',
            fontSize:
              zoomedProjection && densityInRevisionMode
                ? theme.fonts.responsiveMedium
                : theme.fonts.responsiveSmall,
          }}
        >
          {props.label}
        </div>
        <Grid container spacing={0} className={classes.density_overlay}>
          {densClasses.map((densClass, index) => (
            <React.Fragment key={index}>
              {renderButton(densClass)}
              {index < densClasses.length - 1 && (
                <Grid item xs={1}>
                  <Divider className={classes.divider_line} />
                </Grid>
              )}
            </React.Fragment>
          ))}
        </Grid>
      </div>
    </Tooltip>
  );
};

export default DensityButtons;
