export function formatDate(date, hour = null) {
  try {
    let date_string = date;
    if (typeof date === 'object') {
      const corr_date = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      date_string = corr_date.toISOString();
    }
    if (hour) return date_string.slice(0, 10) + ' ' + hour;
    return date_string.slice(0, 10) + ' ' + date_string.slice(11, 16);
  } catch {
    return 'wrong date format';
  }
}

export function formatSimpleDateHour(date) {
  try {
    if (typeof date === 'object') {
      const corr_date = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      date = corr_date.toISOString();
    }
    const formattedDate = date.slice(0, 10) ?? '';
    const formattedHour = date.slice(11, 16) ?? '';
    return { date: formattedDate, hour: formattedHour };
  } catch {
    return { date: '', hour: '' };
  }
}

export function toDate(dateString) {
  if (typeof dateString !== String) return dateString;
  const time = dateString.split(' ');
  const timeStr = `${time[0]}T${time[1]}`;
  const date = new Date(timeStr);
  return date;
}

export function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}
