import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button, FormControl, InputBase, InputLabel, MenuItem, Select } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'react-datepicker/dist/react-datepicker.css';
import { formatDate, toDate } from '../../../../helpers/formatDate.js';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  menu_item_dates: {
    fontSize: 12,
  },
}));

const DatePicker = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setDates, customDate, setCustomDate } = props;
  const [datePreset, setDatePreset] = useState('');

  useEffect(() => {
    if (!props.selectedRange) {
      setDatePreset('day');
    }
    if (props.selectedRange) {
      setDatePreset(props.selectedRange);
    }
  }, [props]);

  const handleDatePreset = (newPreset) => {
    const todayBegin = formatDate(new Date(), '00:00');
    const todayEnd = formatDate(new Date(), '23:59');
    switch (newPreset) {
      case 'day':
        props.setDates([todayBegin, todayEnd]);
        props.setSelectedRange('day');
        break;
      case 'week':
        const lastWeek = new Date();
        lastWeek.setDate(lastWeek.getDate() - 6);
        props.setDates([formatDate(lastWeek, '00:00'), todayEnd]);
        props.setSelectedRange('week');
        break;
      case 'all':
        props.setDates(null);
        props.setSelectedRange('all');
        break;
      case 'custom':
        props.setDates(customDate);
        props.setSelectedRange('custom');
        break;
      default:
        new Error('Incorrect date preset.');
    }
    setDatePreset(newPreset);
  };

  const setCustomDateFrom = (newDateFrom) => {
    const newCustomDate = [...customDate];
    try {
      newCustomDate[0] = formatDate(newDateFrom, '00:00');
      setCustomDate(newCustomDate);
    } catch {}
  };

  const setCustomDateTo = (newDateTo) => {
    const newCustomDate = [...customDate];
    try {
      newCustomDate[1] = formatDate(newDateTo, '23:59');
      setCustomDate(newCustomDate);
    } catch {}
  };

  return (
    <FormControl variant='outlined' className={classes.formControl}>
      <InputLabel id='date-selector'>{t('Table.date')}</InputLabel>
      <Select
        labelId='date-selector'
        id='date-selector-id'
        value={datePreset}
        onChange={(e) => handleDatePreset(e.target.value)}
        label='Date'
        SelectDisplayProps={{
          style: { padding: '10px', fontSize: '12px' },
        }}
      >
        <MenuItem className={classes.menu_item_dates} value={'day'}>
          {t('DatePicker.day')}
        </MenuItem>
        <MenuItem className={classes.menu_item_dates} value={'week'}>
          {t('DatePicker.week')}
        </MenuItem>
        <MenuItem className={classes.menu_item_dates} value={'all'}>
          {t('DatePicker.all')}
        </MenuItem>
        <MenuItem className={classes.menu_item_dates} value={'custom'}>
          {t('DatePicker.custom')}
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default DatePicker;
