import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Switch,
  FormControlLabel,
  Button,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  FormControl,
  RadioGroup,
  Radio,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import io from 'socket.io-client';
import * as actions from '../../../../../store/index';
import { API_ENDPOINT, AI_ENDPOINT } from '../../../../../config';
import { fetchDelete } from '../../../../../helpers/fetching';
import { setSnackbar } from '../../../../../store';
import { LanguageRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  titleSettingsCard: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontSize: '1.1rem',
  },

  subtitleSettingsCard: {
    color: theme.palette.secondary.main,
    fontSize: '0.6rem',
  },
}));

function Other(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const languageSelected = useSelector((store) => store.gui.language);

  const lngs = {
    en: { nativeName: 'English', disable: false },
    fr: { nativeName: 'Français', disable: false },
    de: { nativeName: 'Deutsch', disable: false },
    es: { nativeName: 'Español', disable: false },
    it: { nativeName: 'Italiano', disable: true },
    el: { nativeName: 'Ελληνικά', disable: true },
  };
  const selectedValue =
    props.settings.remove_after_days === null ? '' : props.settings.remove_after_days;

  const onDicomsDelete = () => {
    dispatch(
      actions.setActionToConfirm({
        action: () => doDelete(),
        text: t('Settings.delete_dicoms_confirm'),
      })
    );
  };

  const doDelete = async () => {
    const response = await fetchDelete('settings');
    if (response.success) {
      props.updateSettingsValue({ target: { name: 'dicomCount', value: 0 } });
      dispatch(
        setSnackbar({
          msg: t('Settings.dicom_deleted'),
          severity: 'success',
        })
      );
    } else {
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
    }
  };

  useEffect(() => {
    i18n.changeLanguage(languageSelected);
  }, []);

  return (
    <Grid container direction='column' spacing={1}>
      <Grid item>
        <Card elevation={1}>
          <CardHeader
            disableTypography={true}
            classes={{
              action: classes.action,
            }}
            title={
              <Typography className={classes.titleSettingsCard} variant='h6'>
                {t('Settings.disc_space_management')}
              </Typography>
            }
          />
          <CardContent
            style={{
              paddingTop: '0px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <Typography>
                {t('Settings.available_space', {
                  gb: !!props.settings.discFree ? props.settings.discFree.toFixed(2) : 0,
                })}
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={!!props.settings.store_dicoms}
                    onChange={props.updateSettingsCheckbox}
                    name='store_dicoms'
                  />
                }
                label={t('Settings.store_dicoms')}
              />
              <Typography>{`Stored: ${props.settings.dicomCount}`}</Typography>
              <Button variant='outlined' color='primary' onClick={() => onDicomsDelete()}>
                {t('Settings.delete_dicoms')}
              </Button>
            </div>
            <div
              style={{
                paddingTop: '3%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'baseline',
              }}
            >
              <Typography
                style={{
                  marginLeft: '20%',
                  color: props.settings.remove_after_days ? 'inherit' : 'gray',
                }}
              >
                {t('Settings.auto_remove')}
              </Typography>
              <FormControl
                style={{
                  marginRight: '40%',
                  minWidth: '100px',
                }}
              >
                {/* <InputLabel id="days_to_delete">Days</InputLabel> */}
                <Select
                  labelId='days_to_delete'
                  id='days_to_delete'
                  value={selectedValue}
                  label={t('Settings.delete_after')}
                  name='remove_after_days'
                  onChange={props.updateSettingsValue}
                >
                  <MenuItem value={''}>{t('Settings.never')}</MenuItem>
                  <MenuItem value={30}>{`30 ${t('Settings.days')}`}</MenuItem>
                  <MenuItem value={90}>{`90 ${t('Settings.days')}`}</MenuItem>
                  <MenuItem value={180}>{`180 ${t('Settings.days')}`}</MenuItem>
                  <MenuItem value={360}>{`360 ${t('Settings.days')}`}</MenuItem>
                </Select>
              </FormControl>
            </div>
          </CardContent>
        </Card>
      </Grid>

      {/* Miscellaneous */}
      <Grid item>
        <Card elevation={1}>
          <CardHeader
            disableTypography={true}
            classes={{
              action: classes.action,
            }}
            title={
              <Typography className={classes.titleSettingsCard} variant='h6'>
                {t('Settings.misc')}
              </Typography>
            }
          />
          <CardContent style={{ paddingTop: '0px' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={!!props.settings.anonymize}
                  onChange={props.updateSettingsCheckbox}
                  name='anonymize'
                />
              }
              label={t('Settings.anonymize')}
            />
          </CardContent>
        </Card>
      </Grid>

      {/* Language */}
      <Grid item>
        <Card elevation={1}>
          <CardHeader
            disableTypography={true}
            classes={{
              action: classes.action,
            }}
            title={
              <Typography className={classes.titleSettingsCard} variant='h6'>
                {t('Menu.language')}
              </Typography>
            }
          />
          <CardContent style={{ paddingTop: '0px' }}>
            <RadioGroup
              name='language'
              value={i18n.language}
              onChange={(event) => {
                i18n.changeLanguage(event.target.value);
                props.updateLanguage(event);
              }}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {Object.keys(lngs).map((lng) => (
                <FormControlLabel
                  key={lng}
                  value={lng}
                  control={<Radio />}
                  label={lngs[lng].nativeName}
                  disabled={lngs[lng].disable}
                />
              ))}
            </RadioGroup>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Other;
