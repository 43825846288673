import { useMediaQuery } from '@material-ui/core';
import React from 'react';

const useResponsive = () => {
  const isXSmallScreen = useMediaQuery('(max-width: 600px)');
  const isSmallScreen = useMediaQuery(
    '(min-width: 601px) and (max-width: 1000px)'
  );
  const isMediumScreen = useMediaQuery(
    '(min-width: 1001px) and (max-width: 1400px)'
  );
  const isLargeScreen = useMediaQuery(
    '(min-width: 1401px) and (max-width: 2000px)'
  );
  const isXLargeScreen = useMediaQuery(
    '(min-width: 2001px) and (max-width: 3500px)'
  );

  const isXXLargeScreen = useMediaQuery('(min-width: 3501px)');

  return {
    isXSmallScreen,
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
    isXLargeScreen,
    isXXLargeScreen,
  };
};
export default useResponsive;
