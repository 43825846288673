import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { CheckCircleOutline, HighlightOff, Error } from '@material-ui/icons';
import { LinearProgress } from '@material-ui/core';

import DraggableDialog from "../DraggableDialog/DraggableDialog";
import { fetchDelete } from '../../../../helpers/fetching';
import { setCurrentlyTrained, setSnackbar, 
         setTrainingProgress, startTraining } from '../../../../store';
import { formatModelName } from '../../../../helpers/misc';


const useStyles = makeStyles(theme => ({
    TrainingDialog: {
        fontSize: theme.fonts.responsiveBig,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        caretColor: 'rgba(0,0,0,0)',
        alignItems: 'center'
    },

    FinishedIcon: {
        color: theme.palette.success.main,
        minWidth: 100,
        minHeight: 100,
        margin: 'auto'
    },

    RedIcon: {
        color: theme.palette.error.dark,
        minWidth: 100,
        minHeight: 100,
        margin: 'auto'
    },

    ProgressBar: {
        fontSize: theme.fonts.responsiveMedium,
        height: '0.5rem',
        borderRadius: '5px',
        width: '100%'
    }
}));

export default function TrainingDialog(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { trainingProgress, currentlyTrained } = useSelector(state => state.models);

    async function stopTraining() {
        if (!currentlyTrained) {
          dispatch(setSnackbar(
            {
                msg: 'No model is currently trained',
                severity: "warning"
            }
          ));
          return;
        }
    
        const response = await fetchDelete('train', {}, 'models');
        if (response.success) {
            dispatch(setCurrentlyTrained(null));
            dispatch(setSnackbar(
                {
                    msg: 'Training model aborted',
                    severity: "info"
                }
            ));
            dispatch(setTrainingProgress({
                status: 'aborted'
            }))
        } else {
          dispatch(setSnackbar(
            {
                msg: response.msg,
                severity: "error"
            }
          ));
        }
    }

    function onClose() {
        if (trainingProgress.status === 'finished' ||
            trainingProgress.status === 'aborted' ||
            trainingProgress.status === 'failed' ||
            trainingProgress.status === 'confirm')
          props.onClose();
        else
          dispatch(setSnackbar({
            msg: 'Please, wait untill finished.',
            severity: "warning",
          }));
    }

    let dialogContent = null;
    let actions = []
    if (trainingProgress.status === 'confirm') {
        dialogContent = <p className={classes.Paragraph} key='confirm'>
                            {`Do you want to start the training of ${formatModelName(trainingProgress.modelName)}?`}
                        </p>
        actions = [
            { onClick: () => dispatch(startTraining(trainingProgress.modelName)), 
                label: 'start training',
                color: 'primary' },
            { onClick: onClose, 
              color: 'primary', 
              label: 'Close' },
        ]
    }
    else if (trainingProgress.status === 'preparing') {
        dialogContent = <p className={classes.Paragraph} key='prep'>
                            Preparing training data...
                        </p>
        actions = [ 
            { onClick: stopTraining, 
              color: 'primary', 
              label:'Stop Training' }
            ]    
    }
    else if (trainingProgress.status === 'finished') {
        dialogContent = <>
                            <p className={classes.Paragraph} >
                                Training Finished
                            </p>
                            <CheckCircleOutline className={classes.FinishedIcon}/>
                        </>
        actions = [ 
            { onClick: onClose, 
                color: 'primary', 
                label: 'Close' }
            ]  
    }
    else if (trainingProgress.status === 'aborted') {
        dialogContent = <>
                            <p className={classes.Paragraph} >
                                Training Aborted
                            </p>
                            <HighlightOff className={classes.RedIcon}/>
                        </>
        actions = [ 
            { onClick: onClose, 
              color: 'primary', 
              label: 'Close' }
            ] 
    }
    else if (trainingProgress.status === 'failed') {
        dialogContent = <>
                            <p className={classes.Paragraph} >
                                Training Failed
                            </p>
                            <Error className={classes.RedIcon}/>
                        </>
        actions = [ 
            { onClick: onClose, 
                color: 'primary', 
                label: 'Close' }
            ] 
    }
    else if (trainingProgress.status === 'evaluation')
        dialogContent = <p className={classes.Paragraph} key='prep'>
                            Evaluating the new model...
                        </p>
    else if (trainingProgress.status === 'training') {
        dialogContent = <>
                            <p key='name'>
                                {`Training ${currentlyTrained}`}
                            </p> 
                            <p key='epochs'>
                                {`Epoch ${trainingProgress.currentEpoch} / ${trainingProgress.totalEpochs}`}
                            </p>
                            {Object.keys(trainingProgress.metrics).map(k => (
                            <p key={k}>
                                {`${k}: ${trainingProgress.metrics[k].toFixed(3)}`}
                            </p>))}
                            <LinearProgress className={classes.ProgressBar}
                                    value={100 * trainingProgress.currentEpoch / trainingProgress.totalEpochs}
                                    variant="determinate" 
                            />
                        </> 
        actions = [ 
            { onClick: stopTraining, 
              color: 'primary', 
              label:'Stop Training' }
            ]
    }

    return (
        <DraggableDialog
            maxWidth="sm"
            title="Training"
            open={props.open}
            actions={actions}
            onClose={onClose} >

            <div className={classes.TrainingDialog}>
                {dialogContent}
            </div>
        
        </DraggableDialog>
    )
}
